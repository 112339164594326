import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { INoteCategory } from '@model/interfaces/note-category';
import { INote } from '@model/interfaces/note';
import { NoteCategoryService } from '../../services/note-category.service';
import { NoteDynamicConfig } from './note.dynamic-config';
import { forkJoin } from 'rxjs';

import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { NoteCategories } from '@model/enums/note-categories.enum';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { NoteTypes } from '@model/enums/note-types.enum';
import { NoteService } from '@common/services/note.service';
import { IModalOptions } from '@mt-ng2/modal-module';
import { formatDate } from '@angular/common';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
})
export class NotesComponent implements OnInit {
    @Input() entityId: number;
    @Input() entityType: NoteTypes;
    @Input() canEdit: boolean;
    // used for shipping notes, donor(s) will be linked on BE
    @Input() linkedEntityIds: number[];
    @Input()
    set categoryFilter(value) {
        this._catFilter = value;
        if (this.categoriesLoaded) {
            this.setNoteCategories();
        }
    }
    get categoryFilter(): NoteCategories[] {
        return this._catFilter;
    }
    _catFilter;

    isEditing = false;
    isViewing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: NoteDynamicConfig<INote>;
    doubleClickIsDisabled = false;
    notes: INote[];
    allCategories: INoteCategory[];
    allCategorySearchFilterItems: MtSearchFilterItem[];
    filteredNoteCategories: INoteCategory[];
    // filteredCategorySearchFilterItems: MtSearchFilterItem[];
    selectedNote: INote;
    currentUser: ILoggedIn;
    currentPage = 1;
    itemsPerPage = 5;
    query = '';
    freshList = true;
    allFetched: boolean;
    showConfirm = false;
    confirmOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };

    get categoriesLoaded(): boolean {
        return this.allCategories?.length > 0 && this.allCategorySearchFilterItems?.length > 0;
    }

    constructor(
        private noteService: NoteService,
        private authService: AuthService,
        private claimsService: ClaimsService,
        private noteCategoryService: NoteCategoryService,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {

        forkJoin([this.noteCategoryService.getItems(), this.noteCategoryService.getSearchFilterItems()])
            .subscribe(
            ([categories, categoryFilterItems]) => {
                this.allCategories = categories.filter((c) => c.NoteTypes.map((nt) => nt.Id).includes(this.entityType));
                this.allCategorySearchFilterItems = categoryFilterItems.filter((c) => c.Item.NoteTypes.map((nt) => nt.Id).includes(this.entityType));
                this.setNoteCategories();
                this.getNotes();

                let editNoteClaimType = 0;
                switch (this.entityType) {
                    case NoteTypes.Application:
                        editNoteClaimType = ClaimTypes.Edit_Application_Notes;
                        break;
                    case NoteTypes.Donor:
                        editNoteClaimType = ClaimTypes.Edit_Donor_Notes;
                        break;
                    case NoteTypes.Recipient:
                        editNoteClaimType = ClaimTypes.Edit_Recipient_Notes;
                        break;
                    case NoteTypes.Clinics:
                        editNoteClaimType = ClaimTypes.Edit_Clinic_Notes;
                        break;
                    default:
                        break;
                }

                const canEditNotes = this.claimsService.hasClaim(editNoteClaimType, [ClaimValues.FullAccess]);
                this.canEdit = (canEditNotes ? canEditNotes && this.canEdit : false);
            },
        );
        this.authService.currentUser.subscribe((user) => (this.currentUser = user));
    }

    setNoteCategories(): void {
        // if we're filtering notes to view, limit available categories to those kinds of notes
        // at this time we're trusting categoryFilter to be within filtered categories
        if (this.categoryFilter && this.categoryFilter.length) {
            this.filteredNoteCategories = this.allCategories.filter((cat) => this.categoryFilter.includes(cat.Id));
        } else {
            this.filteredNoteCategories = this.allCategories.filter((cat) => {
                // Donor cannot select 'Shared' Shipping Category, hard coding for now
                const ShippingCategoryId = 8;
                if (this.entityType === NoteTypes.Donor && cat.Id !== ShippingCategoryId) {
                    return cat;
                } else if (this.entityType !== NoteTypes.Donor) {
                    return cat;
                }
                return null;
            });
        }
    }

    getNotes(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'DateCreated',
            orderDirection: 'desc',
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        if (this.categoryFilter && this.categoryFilter.length) {
            searchEntity.extraParams.push({ name: 'NoteCategoryIds', valueArray: this.categoryFilter });
        }

        const searchparams = new SearchParams(searchEntity);

        this.noteService.getNotes(this.entityType, this.entityId, searchparams).subscribe((resp) => {
            if (resp.body.length === 0) {
                this.allFetched = true;
            }
            if (this.freshList) {
                this.notes = resp.body;
                this.freshList = false;
            } else {
                this.notes.push(...resp.body);
            }
        });
    }

    buildSearch(): ExtraSearchParams[] {
        const selectedCycleStatusIds: number[] = this.getSelectedFilters(this.allCategorySearchFilterItems);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'NoteCategoryIds',
                valueArray: selectedCycleStatusIds,
            }),
        );

        return _extraSearchParams;
    }

    filterSelectionChanged(): void {
        this.setListAsRefresh();
        this.getNotes();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    search(query: string): void {
        this.setListAsRefresh();
        this.query = query;
        this.getNotes();
    }

    setListAsRefresh(): void {
        this.currentPage = 1;
        this.allFetched = false;
        this.freshList = true;
    }

    ///
    /// Construct a tooltip listing the prior value(s) of the note title, text, created-by, etc.
    ///
    getNoteHistoryTooltip(note: INote): string {
        let result = 'Note History\r\n--------------\r\n';

        if (note.NoteHistories && note.NoteHistories.length > 0) {
            note.NoteHistories.forEach((noteHistory) => {
                result += noteHistory.Title + ': ' + noteHistory.NoteText + '\r\n' + formatDate(noteHistory.DateCreated, 'MM/dd/yyyy HH:mm', 'en-US') + ', ' + noteHistory.User.FirstName + ' ' + noteHistory.User.LastName + '\r\n\r\n';
            });
        }
        return result;
    }

    setConfig(configControls?: string[]): void {
        const users = null;
        this.formFactory = new NoteDynamicConfig<INote>(this.selectedNote, this.filteredNoteCategories, users, configControls);
        this.config = this.formFactory.getForUpdate();
    }

    createNote(): void {
        this.selectedNote = this.noteService.getEmptyNote();
        if (this.categoryFilter && this.categoryFilter?.length === 1) {
            this.selectedNote.CategoryId = this.categoryFilter[0];
        }
        this.setConfig();
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
        this.isViewing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.selectedNote, form.value.Note);
            this.selectedNote.UserId = this.currentUser.Id;
            this.selectedNote.DateCreated = new Date();
            this.saveNote();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    showNote(note): void {
        this.selectedNote = note;

        if (this.canEdit) {
            this.setConfig();
            this.isEditing = true;
        } else {
            this.setConfig(['Title', 'NoteText', 'CategoryId', 'UserId', 'DateCreated']);
            this.isViewing = true;
        }
    }

    onCheckInView(inView: boolean): void {
        // scroll-check will be in view before notes load, we don't want to pull on initial load
        // We also don't want to pull the list if coming back from editing since success will do that
        if (inView && !this.freshList && !this.allFetched) {
            this.currentPage++;
            this.getNotes();
        }
    }

    private saveNote(): void {
        this.noteService
            .saveNote(this.entityType, this.entityId, this.selectedNote, this.linkedEntityIds)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(() => {
                this.success();
            });
    }

    private success(): void {
        this.setListAsRefresh();
        this.getNotes();
        this.isEditing = false;
        this.setConfig();
        this.noteService.emitChange(this.selectedNote);
        this.notificationsService.success('Note saved successfully.');
    }

    openDeleteNote(): void {
        this.showConfirm = true;
    }

    cancelDeleteNote(): void {
        this.showConfirm = false;
    }

    deleteNote(): void {
        this.showConfirm = false;
        this.noteService.deleteNote(this.entityType, this.entityId, this.selectedNote.Id).subscribe(() => {
            this.setListAsRefresh();
            this.getNotes();
            this.cancelClick();
        });
    }
}
