<div>
    <div class="row">
        <div class="col-md-7">
            <h2>
                <route-back-button></route-back-button>
                <mt-page-title [pageTitle]="header"></mt-page-title>
            </h2>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
