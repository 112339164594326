<div class="miles-editable miles-card padded" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4>
        Applicant ID
        <button *ngIf="canEdit" type="button" class="btn btn-flat btn-success pull-right" (click)="showUploadPhotoModal = true">
            <span class="fa fa-plus"></span> Add ID
        </button>
        <div style="clear: both"></div>
    </h4>
    <div class="table-responsive">
        <table class="table scrollable-table" aria-describedby="Applicant identity images">
            <thead>
                <tr class="no-pointer">
                    <th>
                        <strong>Name</strong>
                    </th>
                    <th>
                        <strong>Date Uploaded</strong>
                    </th>
                    <th class="text-center" *ngIf="canDelete">
                        <strong>Delete</strong>
                    </th>
                    <th class="text-center" *ngIf="canDownload">
                        <strong>Download</strong>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let image of applicantImages; let i = index" class="no-pointer">
                    <td *ngIf="image">
                        <ng-container *ngIf="image.OriginalName && image.OriginalName.length > 0; else noName">
                            <span> {{ image.OriginalName }}</span>
                        </ng-container>
                        <ng-template #noName>
                            <span>No name</span>
                        </ng-template>
                    </td>
                    <td *ngIf="image && image.DateUploaded">
                        <span> {{ image.DateUploaded | date: 'short' }}</span>
                    </td>
                    <td class="text-center" *ngIf="canDelete">
                        <button type="button" (mtConfirm)="deletePhoto(image, $event)" class="btn btn-sm fa fa-trash fa-lg pointer"></button>
                    </td>
                    <td class="text-center" *ngIf="canDownload">
                        <button type="button" (click)="downloadImage(image, $event)" class="btn btn-sm fa fa-download fa-lg pointer"></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- preview modal -->
<modal-wrapper *ngIf="showUploadPhotoModal" [options]="uploadModalOptions" (cancelClick)="showUploadPhotoModal = false"
    ><app-application-photo-uploader
        (onFinished)="onFinishedUpload($event)"
        [applicationId]="applicationId"
        [IsIdentityImage]="true"
    ></app-application-photo-uploader>
</modal-wrapper>
