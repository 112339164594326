import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IApplication } from '@model/interfaces/application';
import { Observable } from 'rxjs';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { SearchParams } from '@mt-ng2/common-classes';
import { IVwApplicationInformation } from '@model/interfaces/vw-application-information';
import { catchError } from 'rxjs/operators';

export const emptyApplication: IApplication = {
    ApplicationStatusId: 0,
    Archived: null,
    DateCreated: null,
    DonorId: 0,
    Id: 0,
    UserId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationService extends BaseService<IApplication> {
    constructor(public http: HttpClient) {
        super('/applications', http);
    }

    denyApplication(application: IApplication): Observable<IApplication> {
        return this.http.put<IApplication>(`/api/v1/applications/${application.Id}/deny`, application);
    }

    removeDenial(application: IApplication): Observable<any> {
        return this.http.put(`/api/v1/applications/${application.Id}/remove-denial`, application);
    }

    getEmptyApplication(): IApplication {
        return { ...emptyApplication };
    }

    getApplicationSsn(id: number): Observable<string> {
        return this.http.get<string>(`/api/v1/applications/${id}/ssn`);
    }

    getPersonalInformationMetaData(): Observable<IPersonalInformationMetaData> {
        return this.http.get<IPersonalInformationMetaData>('/options/personal-information');
    }

    getViews(searchparams: SearchParams): Observable<HttpResponse<IVwApplicationInformation[]>> {
        let params = this.getHttpParams(searchparams);
        return this.http
            .get<IVwApplicationInformation[]>('/api/v1/applications/_search-views', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));

    }
}
