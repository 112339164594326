export enum UserRoles {
    Admin = 1,
    Default = 2,
    Donor = 3,
    DonorRecruiter = 4,
    LabEmployee = 5,
    ClinicalStaff = 6,
    Embryologist = 7,
    AccountManager = 8,
    ApiUser = 9,
    AccountingStaff = 10,
}
