import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IColumnSortedEvent, SortDirection, EntityListExportConfig } from '@mt-ng2/entity-list-module';

import { entityListModuleConfig } from '@common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';

import { DonorCycleReportEntityListConfig } from './donor-cycle-report.entity-list-config';
import { DonorCycleReportService } from '../services/donor-cycle-report.service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IVwDonorCycleReport } from '@model/interfaces/vw-donor-cycle-report';

@Component({
    selector: 'app-donor-cycles',
    templateUrl: './donor-cycle-report.component.html',
})
export class DonorCycleReportComponent implements OnInit {
    donorCycles: IVwDonorCycleReport[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new DonorCycleReportEntityListConfig();
    order = 'StimStartDate';
    orderDirection = 'desc';

    canAddDonorCycle = false;
    months: MtSearchFilterItem[];

    constructor(private donorCycleService: DonorCycleReportService, private claimsService: ClaimsService, private router: Router) {}

    ngOnInit(): void {
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Donor Cycles Report',
            getDataForExport: this.getDonorCyclesForExport.bind(this),
        });
        this.months = this.buildMonthSearchFilter();
        this.canAddDonorCycle = this.claimsService.hasClaim(ClaimTypes.DonorCycles, [ClaimValues.FullAccess]);
        this.getDonorCycles();
    }

    getDonorCyclesCall(options = { forExport: false }): Observable<HttpResponse<IVwDonorCycleReport[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: options.forExport ? 0 : (this.currentPage - 1) * this.itemsPerPage,
            take: options.forExport ? this.total : this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorCycleService.get(searchparams);
    }

    getDonorCycles(): void {
        this.getDonorCyclesCall().subscribe((answer) => {
            this.donorCycles = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getDonorCyclesForExport(): Observable<IVwDonorCycleReport[]> {
        return this.getDonorCyclesCall({ forExport: true }).pipe(map((answer) => answer.body));
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getDonorCycles();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getDonorCycles();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDonorCycles();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const monthIds: number[] = this.getSelectedFilters(this.months);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'MonthId',
                valueArray: monthIds,
            }),
        );

        return _extraSearchParams;
    }

    private buildMonthSearchFilter(): MtSearchFilterItem[] {
        let monthFilter = [];
        const currentMonth = new Date().getMonth();
        for (let i = 0; i < 12; i++) {
            let selected = currentMonth === i ? true : false;
            monthFilter.push(new MtSearchFilterItem({ Id: i + 1, Name: new Date(2000, i).mtDate.format('MMMM') }, selected));
        }
        return monthFilter;
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.getDonorCycles();
    }
}
