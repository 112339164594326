import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ClaimValues } from '@mt-ng2/auth-module';

import { MyReportsComponent } from '../my-reports/my-reports.component';
import { ReportDetailComponent } from '../report-detail/report-detail.component';
import { ReportHeaderComponent } from '../report-header/report-header.component';
import { ReportRenderComponent } from '../report-render/report-render.component';
import { CurrentUserResolver } from '../route-resolvers/current-user-route-resolver';
import { ReportingAuthGuard } from './reporting-auth.guard';

const listRoleGuard: any = {
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'My Reports',
};

const addRoleGuard: any = {
    claimValues: [ClaimValues.FullAccess],
};

const renderRoleGuard: any = {
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Render Report',
};

const editRoleGuard: any = {
    claimValues: [ClaimValues.FullAccess],
    title: 'Edit Report',
};

/**
 * define routes for reporting below
 */

const lazyLoadedRoutes: Routes = [
    {
        canActivate: [ReportingAuthGuard],
        component: MyReportsComponent,
        data: listRoleGuard,
        path: '',
        resolve: { currentUser: CurrentUserResolver },
    },
    {
        canActivate: [ReportingAuthGuard],
        children: [{ path: '', component: ReportDetailComponent, pathMatch: 'full' }],
        component: ReportHeaderComponent,
        data: addRoleGuard,
        path: 'add',
        resolve: { currentUser: CurrentUserResolver },
    },
    {
        canActivate: [ReportingAuthGuard],
        children: [
            { path: '', component: ReportRenderComponent, pathMatch: 'full', resolve: { currentUser: CurrentUserResolver } },
            {
                component: ReportDetailComponent,
                data: editRoleGuard,
                path: 'edit',
                pathMatch: 'full',
                resolve: { currentUser: CurrentUserResolver },
            },
        ],
        component: ReportHeaderComponent,
        data: renderRoleGuard,
        path: ':reportId',
        resolve: { currentUser: CurrentUserResolver },
    },
];

const eagerLoadedRoutes: Routes = [
    {
        canActivate: [ReportingAuthGuard],
        component: MyReportsComponent,
        data: listRoleGuard,
        path: 'my-reports',
        resolve: { currentUser: CurrentUserResolver },
    },
    {
        canActivate: [ReportingAuthGuard],
        children: [{ path: '', component: ReportDetailComponent, pathMatch: 'full' }],
        component: ReportHeaderComponent,
        data: addRoleGuard,
        path: 'my-reports/add',
        resolve: { currentUser: CurrentUserResolver },
    },
    {
        canActivate: [ReportingAuthGuard],
        children: [
            { path: '', component: ReportRenderComponent, pathMatch: 'full', resolve: { currentUser: CurrentUserResolver } },
            {
                component: ReportDetailComponent,
                data: editRoleGuard,
                path: 'edit',
                pathMatch: 'full',
                resolve: { currentUser: CurrentUserResolver },
            },
        ],
        component: ReportHeaderComponent,
        data: renderRoleGuard,
        path: 'my-reports/:reportId',
        resolve: { currentUser: CurrentUserResolver },
    },
];

/**
 * lazy loaded module
 */
@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(lazyLoadedRoutes)],
})
export class AdvancedReportingRoutingModule {}

/**
 * eager loaded module
 */
@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(eagerLoadedRoutes)],
})
export class AdvancedReportingEagerLoadedRoutingModule {}
