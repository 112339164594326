import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { IReportGroupOption } from '../model/interfaces/report-group-option';
import { IReport } from '../model/interfaces/report';
import { IReportColumn } from '../model/interfaces/report-column';
import { IReportGroup } from '../model/interfaces/report-group';
import { Subscription } from 'rxjs';
import { ReportChangedService, ReportChangeType } from '../services/report-changed.service';
import { IGroupAsOptions } from '../model/interfaces/group-as-options';
import { filter } from 'rxjs/operators';

@Component({
    animations: [
        trigger('enter', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0 }),
                animate('300ms', style({ transform: 'translateX(0)', opacity: 1 })),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0)', opacity: 1 }),
                animate('300ms', style({ transform: 'translateX(100%)', opacity: 0 })),
            ]),
        ]),
    ],
    selector: 'report-groups',
    styles: [
        `
            .group-header {
                width: 100%;
                background-color: gray;
            }
            .group-header .groups-as-text {
                color: white;
                padding-left: 8px;
            }
            .fa.disabled,
            .fa[disabled],
            .disabled > .fa,
            [disabled] > .fa {
                color: lightgrey;
                opacity: 0.5;
                pointer-events: none;
            }
        `,
    ],
    template: `
        <span ngbDropdown class="dropdown">
            <div class="group-header">
                <span
                    *ngIf="groupsAsOptions.length !== 0 && canEdit"
                    ngbDropdownToggle
                    [style.cursor]="canEdit ? 'pointer' : 'default'"
                    class="btn btn-primary btn-flat"
                >
                    <i class="fa fa-object-group"></i>
                    <ul ngbDropdownMenu class="dropdown-menu">
                        <li *ngFor="let group of groupsAsOptions; let i = index" ngbDropdownItem>
                            <a (click)="selectGroup(group.name)">
                                {{ group.name }}
                            </a>
                        </li>
                    </ul>
                </span>
                <span
                    *ngIf="groupsAsOptions.length === 0 || !canEdit"
                    ngbDropdownToggle
                    [style.cursor]="'not-allowed'"
                    class="btn btn-primary btn-flat"
                >
                    <i class="fa fa-object-group disabled"></i>
                </span>
                <span
                    *ngIf="report?.ReportColumns.length"
                    class="groups-as-text"
                    (mouseover)="showDeleteGroup = true"
                    (mouseleave)="showDeleteGroup = false"
                >
                    <span *ngFor="let group of groupsAsText; let isLast = last; let i = index">
                        {{ group.Name }}
                        <span *ngIf="showDeleteGroup && canEdit" [@enter] [style.cursor]="'pointer'" (click)="removeGroup(i)"
                            ><i class="fa fa-window-close"></i></span
                        >{{ isLast ? '' : ', ' }}
                    </span>
                </span>
                <span class="groups-as-text" *ngIf="!groupsAsText.length"> No groups set </span>
            </div>
        </span>
    `,
})
export class ReportGroupsComponent implements OnInit, OnDestroy {
    @Input()
    groupsAsText: IReportGroupOption[] = [];
    @Input()
    groupsAsOptions: IGroupAsOptions[] = [];
    @Input()
    report: IReport;
    @Input()
    canEdit: boolean;

    @Output()
    onRemoveGroup: EventEmitter<IReportGroupOption> = new EventEmitter<IReportGroupOption>();

    @Output()
    onSelectGroup: EventEmitter<IReportColumn> = new EventEmitter<IReportColumn>();

    showDeleteGroup = false;
    subscriptions = new Subscription();

    constructor(private reportChangedServices: ReportChangedService) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.reportChangedServices.reportChanged
                .pipe(filter((rc) => rc.eventType === ReportChangeType.columnHeaderUpdated))
                .subscribe((changeValue) => {
                    const value = changeValue.value as IGroupAsOptions;
                    const groupOption = this.groupsAsOptions.find((go) => go.id === value.id);
                    if (groupOption) {
                        groupOption.name = value.name;
                    }
                }),
        );
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    /**
     * Finds the Report Column Record by name selected in the DropDown.
     * It then adds a new ReportGroup object on that ReportColumn.  Then
     * it emits the onSelectGroup with this ReportColumn objec to be saved
     * @param columnName
     */
    selectGroup(columnName: string): void {
        let column = this.report.ReportColumns.find((col) => col.DisplayAs === columnName);
        column.ReportGroup = this.createGroup(column);
        this.onSelectGroup.emit(column);
    }

    createGroup(reportColumn: IReportColumn): IReportGroup {
        return {
            GroupOrder: this.getGroupOrder(this.groupsAsText), // TODO: JJG ADD THIS BACK AFTER POPULATING // this.getGroupOrder(this.groupOptions),
            Id: 0,
        };
    }

    /**
     * Get an initial group order for the newly added Report Group
     * This method will iterate the array and get the max group order,
     * add 1 to it and assign that as the order for the new Report Group
     * @param groups
     */
    getGroupOrder(groups: IReportGroupOption[]): number {
        return groups.length === 0
            ? 0
            : Math.max.apply(
                  Math,
                  groups.map((o) => {
                      return o.GroupOrder + 1;
                  }),
              );
    }

    removeGroup(index: number): void {
        this.onRemoveGroup.emit(this.groupsAsText[index]);
    }
}
