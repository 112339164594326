import {
    EntityListConfig,
    EntityListColumn,
    SortDirection,
} from '@mt-ng2/entity-list-module';

export const AuditLogsEntityConfig: EntityListConfig = new EntityListConfig({
    columns: [
        new EntityListColumn({
            accessors: ['EventDateUTC'],
            name: 'Date',
            sort: {
                defaultDirection: SortDirection.Desc,
                isDefaultForSort: true,
            },
        }),
        new EntityListColumn({
            accessors: ['TypeFullName'],
            name: 'Type',
        }),
        new EntityListColumn({
            accessors: ['EventType'],
            name: 'Event Type',
        }),
        new EntityListColumn({
            accessors: ['UserName'],
            name: 'User',
        }),
    ],
});
