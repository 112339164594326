<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && user.Id > 0"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        User Region
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div class="row">
        <div class="col-md-12">
            <span *ngIf="!userRegions || !userRegions.length"><i>No Regions Assigned</i></span>
            <div *ngIf="userRegions && userRegions.length">
                <div *ngFor="let region of displayRegionNames(userRegions)">
                    <span>{{ region }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div [hidden]="!isEditing" class="miles-form padded">
    <form *ngIf="formCreated" [formGroup]="userRegionsForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12">
                <h4>User Region</h4>
                <dynamic-field
                    [field]="abstractUserRegionControls.CountryCode"
                    [form]="userRegionsForm"
                    (valueChanges)="onCountryChanged($event)"
                ></dynamic-field>
                <dynamic-field *ngIf="showSelectUsStates" [field]="abstractUserRegionControls.UsStateCodes" [form]="userRegionsForm"></dynamic-field>
                <dynamic-field *ngIf="showSelectAuStates" [field]="abstractUserRegionControls.AuStateCodes" [form]="userRegionsForm"></dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
        </div>
    </form>
</div>
