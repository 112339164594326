import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IWindowSize {
    height: number;
    width: number;
}

// The service makes the current window size available throughout the application.
// It is updated on resize via a host listener set in app.component.ts.

@Injectable({
    providedIn: 'root',
})
export class WindowSizeService {
    private _windowSize = new BehaviorSubject<IWindowSize>({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    get windowSize(): Observable<IWindowSize> {
        return this._windowSize.asObservable();
    }

    updateWindowSize(size: IWindowSize): void {
        this._windowSize.next(size);
    }
}
