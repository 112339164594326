import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IClinicEntity } from '@model/interfaces/clinic-entity';
import { IClinicContact } from '@model/interfaces/clinic-contact';
import { IClinicEntityPhone } from '@model/interfaces/clinic-entity-phone';
import { Observable } from 'rxjs';

export const emptyClinicEntity: IClinicEntity = {
    AddressId: 0,
    ClinicContactId: 0,
    ClinicEntityTypeId: 0,
    ClinicId: 0,
    Id: 0,
};

export const emptyClinicContact: IClinicContact = {
    Email: null,
    FirstName: null,
    Id: 0,
    LastName: null,
    MiddleInitial: null,
};

export const emptyClinicPhone: IClinicEntityPhone = {
    ClinicEntityId: 0,
    Extension: '',
    IsPrimary: true,
    Phone: '',
    PhoneTypeId: 2, // Work
};

@Injectable({
    providedIn: 'root',
})
export class ClinicEntityService extends BaseService<IClinicEntity> {
    constructor(public http: HttpClient) {
        super('/clinic-entities', http);
    }

    getEmptyClinicEntity(entityTypeId: number, clinicId: number): IClinicEntity {
        const newEntity = { ...emptyClinicEntity };
        newEntity.ClinicId = clinicId;
        newEntity.ClinicEntityTypeId = entityTypeId;
        newEntity.ClinicContact = { ...emptyClinicContact };
        newEntity.ClinicEntityPhones = [{ ...emptyClinicPhone }];
        return newEntity;
    }

    getByClinicId(clinicId: number, entityTypeId: number): Observable<IClinicEntity> {
        return this.http.get<IClinicEntity>(`/clinic-entities/${clinicId}/${entityTypeId}`);
    }

    createClinicEntity(clinicEntity: IClinicEntity): Observable<IClinicEntity> {
        if (clinicEntity.Address.CountryCode.toLowerCase() !== 'us') {
            clinicEntity.Address.StateCode = null;
        }
        return this.http.post<IClinicEntity>('/clinic-entities', clinicEntity);
    }

    updateClinicEntity(clinicEntity: IClinicEntity): Observable<IClinicEntity> {
        return this.http.put<IClinicEntity>(`/clinic-entities/${clinicEntity.ClinicId}/${clinicEntity.ClinicEntityTypeId}`, clinicEntity);
    }
}
