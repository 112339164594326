import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'results-per-page',
    template: `
    <span class="pull-right" style="height=auto">
        <span class="form-inline">
            <span class="form-group">
                <label>Results Per Page</label>
                <select class="form-control" [ngModel]="defaultPageSize" (ngModelChange)="onChange($event)" name="pageSize">
                    <option [value]="i" *ngFor="let i of pageSizeOptions">{{i}}</option>
                </select>
            </span>
        </span>
</span>
    `,
})

export class ResultsPerPageComponent {
    @Output() onSizeChange = new EventEmitter<number>();

    defaultPageSize = 25;
    pageSizeOptions = [
        25,
        50,
        100,
        200,
    ];

    constructor() { }

    onChange(value: number): void {
        this.onSizeChange.emit(value);
    }
}
