import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { NotificationDynamicControls, INotificationDynamicControlsParameters } from '@model/form-controls/notification.form-controls';
import { INotification } from '@model/interfaces/notification';
import { IApplicationStatus } from '@model/interfaces/application-status';
import { IDonorStatus } from '@model/interfaces/donor-status';
import { INotificationType } from '@model/interfaces/notification-type';
import { NotificationDynamicControlsPartial } from '@model/partials/notification-partial.form-controls';

export class NotificationDynamicConfig<T extends INotification> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private notification: T,
        private applicationStatuses: IApplicationStatus[],
        private donorStatuses: IDonorStatus[],
        private notificationTypes: INotificationType[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: INotificationDynamicControlsParameters = {
            applicationStatuses: this.applicationStatuses,
            donorStatuses: this.donorStatuses,
            notificationTypes: this.notificationTypes,
        };
        const dynamicControls = new NotificationDynamicControlsPartial(this.notification, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'Subject'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
