import { INoteDynamicControlsParameters } from '@model/form-controls/note.form-controls';
import { IMetaItem } from '@model/interfaces/base';
import { INote } from '@model/interfaces/note';
import { INoteCategory } from '@model/interfaces/note-category';
import { IUser } from '@model/interfaces/user';
import { NoteDynamicControlsPartial } from '@model/partials/note-partial.form-controls';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

export class NoteDynamicConfig<T extends INote> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private donorNote: T, private noteCategories: INoteCategory[], private users?: IUser[], private configControls?: string[]) {
        super();
        const additionalParamters: INoteDynamicControlsParameters = {
            categories: this.noteCategories,
            users: this.users,
        };
        const dynamicControls = new NoteDynamicControlsPartial(this.donorNote, additionalParamters);
        if (!configControls) {
            this.configControls = ['Title', 'NoteText', 'CategoryId'];
        }
        if (additionalParamters.categories && additionalParamters.categories.length < 2) {
            this.configControls = ['Title', 'NoteText'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
