import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IClinicVerification } from '@model/interfaces/clinic-verification';
import {
    ClinicVerificationDynamicControlsPartial,
    IClinicVerificationDynamicControlsParametersPartial,
} from '@model/partials/clinic-verification-partial.form-controls';

export class ClinicVerificationDynamicConfig<T extends IClinicVerification> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private clinicVerification: T,
        private additionalParams: IClinicVerificationDynamicControlsParametersPartial,
        private configControls?: string[],
    ) {
        super();
        const dynamicControls = new ClinicVerificationDynamicControlsPartial(this.clinicVerification, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['PAndESentDate', 'PAndEReceivedDate', 'IsClinicVerified', 'VerifiedDate', 'VerifiedById'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
