<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && application.Id > 0"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Application Info
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div class="display-padded ng-star-inserted">
        <b>Email: </b>
        <span>{{ application.User.Email }}</span>
    </div>
    <div class="display-padded ng-star-inserted">
        <b>Username: </b>
        <span>{{ application.User.AuthUser.Username }}</span>
    </div>
    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Application Info</h4>
    <form *ngIf="formCreated" [formGroup]="applicationForm" (ngSubmit)="formSubmitted($event)">
        <dynamic-field [field]="abstractUserControls.Email" [form]="applicationForm"></dynamic-field>
        <button *ngIf="application.ApplicationStatusId === deniedStatusId" type="button" class="btn btn-flat btn-primary" (click)="removeDenial()">
            Undo Denial
        </button>
        <!--deny applicant-->
        <button
            *ngIf="application.ApplicationStatusId !== deniedStatusId"
            type="button"
            class="btn btn-flat btn-danger"
            (click)="showDenialModal = true"
        >
            Deny
        </button>
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </form>
</div>

<modal-wrapper *ngIf="showDenialModal" [options]="denialModalOptions" (cancelClick)="showDenialModal = false">
    <div class="modal-header">
        <h3 class="modal-title">Specify Denial Reason</h3>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="denial-reason">Denial Reason</label>
            <textarea class="form-control" required [(ngModel)]="manualDenialReason" rows="3"></textarea>
        </div>
        <button type="button" (click)="denyApplication()" class="btn btn-flat btn-danger">Deny</button>
        <button type="button" class="btn btn-flat btn-default" (click)="showDenialModal = false">Cancel</button>
    </div>
</modal-wrapper>
