import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IDonorStatus } from '@model/interfaces/donor-status';

@Injectable()
export class DonorStatusesService extends StaticMetaItemService<IDonorStatus> {
    constructor(public http: HttpClient) {
        super('DonorStatusesService', 'Donor Statuses', 'DonorStatusIds', '/options/donorStatuses', http);
    }
}
