import { CommonService } from './../../donor-portal/common/services/common.service';
import { NgModule } from '@angular/core';
import { SharedModule } from '@common/shared.module';

import { DonorCycleReportComponent } from './donor-cycle-report/donor-cycle-report.component';
import { InventoryDonorReportComponent } from './inventory-donor-report/inventory-donor-report.component';
import { RecipientsSalesReportComponent } from './recipients-sales-report/recipients-sales-report.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { QualitySummaryReportComponent } from './quality-summary-report/quality-summary-report.component';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';

@NgModule({
    declarations: [DonorCycleReportComponent, RecipientsSalesReportComponent, InventoryDonorReportComponent, QualitySummaryReportComponent],
    exports: [],
    imports: [SharedModule, ReportsRoutingModule, TypeAheadModule],
    providers: [CommonService],
})
export class ReportsModule {}
