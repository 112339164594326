import { Component, Input, OnInit } from '@angular/core';
import { FileItem } from 'ng2-file-upload';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IDocumentType } from '@model/interfaces/document-type';
import { UploadDocumentsService } from '../../../services/upload-documents.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonorDocument } from '@model/interfaces/donor-document';
import { saveAs } from 'file-saver';
import { DocumentEntityTypes } from '@model/enums/document-entity-types';

@Component({
    selector: 'app-upload-documents',
    templateUrl: './upload-documents.component.html',
})
export class UploadDocumentsComponent implements OnInit {
    @Input() entityId: number;
    @Input() entityType: DocumentEntityTypes;
    @Input('documentTypes') documentTypes: IDocumentType[];
    @Input('canEdit') canUpload = false;
    @Input('canDelete') canDelete = true;
    @Input('canDownload') canDownload = true;

    showUploadArea = false;
    isUploading = false;

    headerTitle = 'Uploaded Documents';
    maxFileSize = 10000000;
    allowedDocumentTypes: string[] = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ];
    documentTypeSelected: IDocumentType;

    tempFile: FileItem;
    modalOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };
    showModal = false;

    documentArray: IDonorDocument[] = [];

    mustSelectType = false;

    constructor(public uploadDocumentsService: UploadDocumentsService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.getDocuments();
        if (this.documentTypes && this.documentTypes.length > 1) {
            this.mustSelectType = true;
        } else {
            this.documentTypeSelected = this.documentTypes[0];
        }
    }

    confirm(file: FileItem): void {
        this.tempFile = file;
        this.showModal = true;
    }

    error(errorMessage: string): void {
        this.notificationsService.error(errorMessage);
    }

    save(): void {
        this.isUploading = true;
        this.uploadDocumentsService.saveDocument(this.entityType, this.entityId, this.documentTypeSelected.Id, this.tempFile._file).subscribe(() => {
            this.getDocuments();
            this.closePreview();
        });
    }

    closePreview(): void {
        this.showModal = false;
        this.showUploadArea = false;
        this.isUploading = false;
    }

    cancel(): void {
        this.showUploadArea = false;
    }

    getDocuments(): void {
        this.uploadDocumentsService.getDocuments(this.entityType, this.entityId).subscribe((documents) => {
            const typeIds = this.documentTypes.map((x) => x.Id);
            this.documentArray = documents.filter((doc) => typeIds.includes(doc.DocumentTypeId));
        });
    }

    deleteDocument(documentId: number): void {
        this.uploadDocumentsService.deleteDocument(this.entityType, this.entityId, documentId).subscribe(() => {
            this.getDocuments();
        });
    }

    downloadDocument(index: number, documentId: number): void {
        this.uploadDocumentsService.getDocumentById(this.entityType, this.entityId, documentId).subscribe((doc: any) => {
            const thefile = new Blob([<ArrayBuffer>doc], {
                type: 'application/octet-stream',
            });
            saveAs(thefile, this.documentArray[index].Document.Name);
        });
    }
}
