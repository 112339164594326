import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';

import { IEmail, ISendEmailModalApi, ISendEmailModalResult } from '@mt-ng2/email-modal-module';
import { INotificationOptionsDto } from '@model/interfaces/custom/notification-options-dto';

export interface ISendEmailTemplateModalApi {
    /**
     * @param {string} email - "To" email
     * @param {number} templateId - Id corresponding to the intended template
     * @param {INotificationOptionsDto} [options] - Denotes the Ids to be used to populate any given merge fields for the template. Defaults to an empty object if merge fields are not part of the given template
     * @returns {Observable<ISendEmailModalResult>} Observable of ISendEmailModalResult
     */
    show: (email: string, templateId: number, options?: INotificationOptionsDto) => Observable<ISendEmailModalResult>;
    close: () => void;
}
// Wrapper around send-email-modal component to get template from id and configure to email
@Component({
    selector: 'send-email-template',
    template: `<send-email-modal (ready)="modalApi = $event"></send-email-modal>`,
})
export class SendEmailTemplateComponent implements OnInit {
    @Output() ready = new EventEmitter<ISendEmailTemplateModalApi>();

    modalApi: ISendEmailModalApi;
    outgoingEmail: IEmail = {
        Body: '',
        Subject: '',
        To: '',
    };
    constructor(private notificationService: NotificationService) {}

    ngOnInit(): void {
        this.ready.emit({ show: this.show.bind(this), close: this.close.bind(this) });
    }

    show(email: string, templateId: number, options: INotificationOptionsDto = {}): Observable<ISendEmailModalResult> {
        return this.notificationService.getPopulatedNotificationTemplate(templateId, options).pipe(
            tap((answer) => {
                this.outgoingEmail.Body = answer.Body;
                this.outgoingEmail.Subject = answer.Subject;
                this.outgoingEmail.To = email;
            }),
            mergeMap(() => {
                return this.modalApi.show(this.outgoingEmail);
            }),
        );
    }

    close(): void {
        this.modalApi.close();
    }
}
