<div class="miles-editable miles-card padded" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <div *ngIf="!isEditing">
        <h4 (click)="edit()">
            {{ addressType }}
            <span *ngIf="!isEditing" class="pull-right">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h4>
        <div *ngIf="!isEditing && !noAddress()" (click)="edit()" class="list-group-item">{{ formattedAddress }}</div>
        <div *ngIf="!isEditing && noAddress()">No address</div>
    </div>
    <div *ngIf="isEditing">
        <h4>{{ addressType }}</h4>
    </div>
    <div>
        <address [address]="address" [Address]="addressFormGroup" [isEditing]="isEditing" [canEdit]="canEdit" [showButtons]="showButtons" [showDelete]="showDelete" [showButtons]="true"
            (onCancelClicked)="cancel()" (onSave)="save($event)" (onDelete)="delete($event)" >
        </address>
    </div>
    <div *ngIf="canEdit && noAddress() " class="fab-wrap">
        <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="createAddress()">
            <span class="fa fa-plus"></span>
        </button>
    </div>
</div>
