import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { INotification } from '@model/interfaces/notification';
import { NotificationService } from '../../common/services/notification.service';

@Component({
    templateUrl: './notification-header.component.html',
})
export class NotificationHeaderComponent implements OnInit, OnDestroy {
    notification: INotification;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private notificationService: NotificationService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('notificationId');
        // set the header based on the id
        if (id > 0) {
            this.notificationService.getById(id).subscribe((notification) => {
                this.setHeader(notification);
            });
        }
        // TODO: else redirect to list page
        // else {
        //     this.setHeader(this.notificationService.getEmptyNotification());
        // }
        // subscribe to any changes in the notification service
        // which should update the header accordingly
        this.subscriptions.add(
            this.notificationService.changeEmitted$.subscribe((notification) => {
                this.setHeader(notification);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(notification: INotification): void {
        this.notification = notification;
        this.header = `Notification: ${notification.Name} (${notification.NotificationType.Name})`;
    }
}
