import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { NotificationService } from '../../common/services/notification.service';
import { INotification } from '@model/interfaces/notification';
import { ClaimTypes } from '@model/ClaimTypes';
import { getIdFromRoute } from '@common/libraries/get-id-from-route.library';

@Component({
    templateUrl: './notification-detail.component.html',
})
export class NotificationDetailComponent implements OnInit {
    notification: INotification;
    canEdit: boolean;
    canAdd: boolean;

    constructor(
        private notificationService: NotificationService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Notifications, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = getIdFromRoute(this.route, 'notificationId');
        if (id) {
            this.getNotificationById(id);
        } else {
            this.router.navigate(['notifications']); // if no id found, go back to list
        }
    }

    getNotificationById(id: number): void {
        this.notificationService.getById(id).subscribe((notification) => {
            if (notification === null) {
                this.notificationsService.error('Notification not found');
                this.router.navigate(['notifications']);
            }
            this.notification = notification;
        });
    }
}
