import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, SortDirection, IItemSelectedEvent } from '@mt-ng2/entity-list-module';

import { MyReportsEntityListConfig } from './my-reports.entity-list-config';
import { ReportsService } from '../services/reports.service';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';
import { IReport } from '../model/interfaces/report';
import { AdvancedReportingModuleConfig } from '../services/advanced-reporting-module-config.service';

@Component({
    template: `
        <div class="row">
            <div class="col-md-7">
                <h2>
                    <mt-page-title pageTitle="My Reports"></mt-page-title>
                </h2>
            </div>
        </div>

        <div>
            <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
        </div>
        <div>
            <mt-search-filter-checkbox (onValueChanged)="includeArchivedChanged($event)" text="Include Archived"></mt-search-filter-checkbox>
        </div>
        <br />
        <entity-list
            *ngIf="entityListConfig"
            [entities]="reports"
            [total]="total"
            [(currentPage)]="currentPage"
            [itemsPerPage]="itemsPerPage"
            (onPageChanged)="getReports()"
            (onItemSelected)="reportSelected($event)"
            (onColumnSorted)="columnSorted($event)"
            [entityListConfig]="entityListConfig"
        >
        </entity-list>

        <div *ngIf="canAdd" class="fab-wrap-b-r">
            <a class="btn btn-primary btn-fab-lg" [routerLink]="['/my-reports', 'add']">
                <span class="fa fa-plus"></span>
            </a>
        </div>
    `,
})
export class MyReportsComponent implements OnInit {
    query = '';

    reports: IReport[];
    total: number;
    currentPage = 1;
    itemsPerPage = 12;
    entityListConfig: MyReportsEntityListConfig;
    order: string;
    orderDirection: string;

    canAdd = false;
    includeArchived = false;

    constructor(
        private reportsService: ReportsService,
        private claimsService: ClaimsService,
        private router: Router,
        private reportingModuleConfig: AdvancedReportingModuleConfig,
    ) {}

    ngOnInit(): void {
        this.canAdd = this.claimsService.hasClaim(this.reportingModuleConfig.reportingClaimTypeId, [ClaimValues.FullAccess]);
        this.reportingModuleConfig.reportUsersService.getUsers().subscribe((users) => {
            this.entityListConfig = new MyReportsEntityListConfig(users);
            this.order = this.entityListConfig.getDefaultSortProperty();
            this.orderDirection = this.entityListConfig.getDefaultSortDirection();
            this.getReports();
        });
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        if (this.includeArchived) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'includeArchived',
                    value: 'true',
                }),
            );
        }

        return _extraSearchParams;
    }

    getReports(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search ?? '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.reportsService.get(searchparams).subscribe((answer) => {
            this.reports = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getReports();
    }

    includeArchivedChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.includeArchived = event.value;
        this.getReports();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getReports();
    }

    reportSelected(event: IItemSelectedEvent): void {
        if (this.reports.find((x) => x.Id === event.entity.Id).ReportColumns.length === 0) {
            this.router.navigate([`/my-reports/${event.entity.Id}/edit`]);
        } else {
            this.router.navigate(['/my-reports', event.entity.Id]);
        }
    }
}
