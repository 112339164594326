export enum DenialReasons {
    BMI = 1,
    Country = 2,
    HIV = 3,
    Age = 4,
    Duplicate = 5,
    Manual = 6,
    Gender = 7,
    STD = 8,
    Ovaries = 9,
    PreviouslyScreened = 10,
    Education = 11,
}
