<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Donor Cycle Report"></mt-page-title>
        </h2>
    </div>
</div>
<div class="row col-md-12">
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
</div>
<div class="row col-md-11">
    <mt-search-filter-select [items]="months" entity="Month" (onSelectionChanged)="filterSelectionChanged()"> </mt-search-filter-select>
    <results-per-page (onSizeChange)="onPageSizeChange($event)"></results-per-page>
</div>
<div class="row col-md-12">
    <entity-list
        [entities]="donorCycles"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (onPageChanged)="getDonorCycles()"
        (onColumnSorted)="columnSorted($event)"
        [entityListConfig]="entityListConfig"
    >
    </entity-list>
</div>
