<ng-container *ngIf="showDocumentsCard">
    <div *ngIf="!showUploadArea">
        <div *ngIf="documentArray != null && documentArray.length">
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table scrollable-table">
                            <thead>
                                <tr class="no-pointer">
                                    <th>
                                        <b>Name</b>
                                    </th>
                                    <th>
                                        <b>Date Uploaded</b>
                                    </th>
                                    <th>
                                        <b>Document Type</b>
                                    </th>
                                    <th>
                                        <b>Edit</b>
                                    </th>
                                    <th>
                                        <b>Delete</b>
                                    </th>
                                    <th>
                                        <b>Download</b>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let doc of documentArray; let i = index" class="no-pointer">
                                    <td *ngIf="doc">
                                        <span> {{
                                            doc.Document
                                            ? doc.Document.Name
                                            : "N/A"
                                         }}</span>
                                    </td>
                                    <td *ngIf="doc">
                                        <span> {{
                                            doc.Document
                                            ? (doc.Document.DateUpload | date: 'short')
                                            : "N/A"
                                        }}</span>
                                    </td>
                                    <td *ngIf="doc">
                                        <span> {{ doc.DocumentType.Name }}</span>
                                    </td>
                                    <td class="text-center">
                                        <button
                                            type="button"
                                            title="Edit Genetic Screening"
                                            (click)="editGeneticScreening(doc)"
                                            class="btn btn-sm fa fa-edit fa-lg pointer"
                                        ></button>
                                    </td>
                                    <td class="text-center">
                                        <button
                                            type="button"
                                            (mtConfirm)="deleteGeneticScreeningDocument(doc)"
                                            class="btn btn-sm fa fa-trash fa-lg pointer"
                                        ></button>
                                    </td>
                                    <td class="text-center">
                                        <button
                                            *ngIf="doc.Document"
                                            type="button"
                                            (click)="downloadDocument(i, doc.DocumentId)"
                                            class="btn btn-sm fa fa-download fa-lg pointer"
                                        ></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <em *ngIf="!documentArray.length">No Documents</em>
        <div *ngIf="canUpload" class="fab-wrap">
            <button type="button" class="btn btn-primary btn-fab-md btn-fab-center" (click)="showUploadArea = true">
                <span class="fa fa-plus"></span>
            </button>
        </div>
    </div>
    <div [hidden]="!showUploadArea">
        <div>
            <button type="button" class="btn btn-flat btn-primary" (click)="selectType(GeneticScreeningTypes.CarrierTesting)">Genetic Testing Labs</button>
            <button type="button" class="btn btn-flat btn-primary" (click)="selectType(GeneticScreeningTypes.GeneticConsult)">Genetic Consult/Screening</button>
        </div>
        <br />
        <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
    </div>
</ng-container>
<!-- preview modal -->
<modal-wrapper *ngIf="showModal" [options]="modalOptions" (cancelClick)="closePreview()">
    <div *ngIf="!isUploading">
        <h2>{{
            geneticScreeningType === GeneticScreeningTypes.CarrierTesting
            ? 'Genetic Testing Labs'
            : geneticScreeningType === GeneticScreeningTypes.GeneticConsult
            ? 'Genetic Consult/Screening'
            : 'Genetic Screening Info'
        }}</h2>
        <p><strong>Name:</strong> {{
            this.tempFile
            ? this.tempFile.file.name
            : currentlyEditedFileName
            ? currentlyEditedFileName
            : 'N/A' }}
        </p>
        <br />
        <hr />
        <app-dynamic-form *ngIf="config" [config]="config" [form]="form"> </app-dynamic-form>
        <mt-document
            [allowedMimeType]="allowedDocumentTypes"
            [maxFileSize]="maxFileSize"
            (onAfterAddingFile)="confirm($event)"
            (onAddingFileFailed)="error($event)"
        ></mt-document>
        <br />
        <div *ngIf="mustSelectType">
            <select [(ngModel)]="documentTypeSelected" [ngModelOptions]="{ standalone: true }" class="form-control">
                <option [value]="'none'" selected disabled hidden>Document Type</option>
                <option *ngFor="let type of documentTypes" [ngValue]="type">
                    {{ type.Name }}
                </option>
            </select>
            <br />
        </div>
        <button *ngIf="documentTypeSelected" type="button" class="btn btn-success" (click)="save()">Save Document</button>
        <button type="button" class="btn btn-default" (click)="closePreview()">Discard</button>
    </div>

    <div *ngIf="isUploading">
        <h2>Your File Is Being Uploaded</h2>
    </div>
</modal-wrapper>
