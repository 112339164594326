import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { ICurrentReportUser } from '@mt-ng2/advanced-reporting-module-config';
import { ModalService } from '@mt-ng2/modal-module';

import { ReportsService } from '../services/reports.service';
import { ReportFormatterService, attachDatapointsToReport } from '../services/report-formatter.service';
import { DatasetDatapointsService, flattenDataPoints } from '../services/dataset-datapoints.service';
import { IDatapoint } from '../model/interfaces/datapoint';
import { IReport } from '../model/interfaces/report';
import { FrontEndFormats } from '../libraries/datapoints.library';
import { AdvancedReportingModuleConfig } from '../services/advanced-reporting-module-config.service';

@Component({
    selector: 'download-report-button',
    styles: [
        `
            .download-icon {
                color: dodgerblue;
            }
        `,
    ],
    template: `
        <span *ngIf="reportId" (click)="downloadReport($event)" class="btn btn-xs btn-transparent" title="download">
            <i class="fa fa-fw fa-lg fa-download download-icon"></i>
        </span>
    `,
})
export class DownloadReportButtonComponent {
    @Input() reportId: number;

    private datapointOptions: IDatapoint[];

    constructor(
        private reportsService: ReportsService,
        private reportFormatterService: ReportFormatterService,
        private modalService: ModalService,
        private datasetDatapointsService: DatasetDatapointsService,
        private route: ActivatedRoute,
        private reportingModuleConfig: AdvancedReportingModuleConfig,
    ) {}

    downloadReport(event: Event): void {
        event?.stopPropagation();

        forkJoin([this.reportsService.getById(this.reportId), this.reportsService.renderReport(this.reportId)]).subscribe((answers) => {
            let [report, data] = answers;
            this.datasetDatapointsService.getDatapoints(report.DatasetId).subscribe((answer: IDatapoint[]) => {
                this.datapointOptions = flattenDataPoints(answer);
                report = attachDatapointsToReport(report, this.datapointOptions);
                if (this.isDataSizeAboveWarningThreshold(data, report)) {
                    this.modalService
                        .showModal({
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            cancelButtonText: 'No, export the report',
                            confirmButtonText: 'Yes, email the report instead',
                            showCancelButton: true,
                            text: 'This dataset is large, and it may take a while to export.  Would you rather the report be emailed to you?',
                            titleText: 'Large Dataset',
                        })
                        .subscribe((result) => {
                            if (result.value) {
                                this.emailReport(report);
                            } else {
                                this.exportReport(data, report);
                            }
                        });
                } else {
                    this.exportReport(data, report);
                }
            });
        });
    }

    private emailReport(report: IReport): void {
        const currentUser = this.route.snapshot.data.currentUser as ICurrentReportUser;

        this.reportsService.emailReport({
            Email: currentUser.Email,
            ReportId: report.Id,
            UserName: currentUser.Name,
        });
    }

    private exportReport(data: any[], report: IReport): void {
        data = this.reportFormatterService.formatData(data, this.datapointOptions);
        this.reportsService.exportReportDataToCsv(data, report);
    }

    private isDataSizeAboveWarningThreshold(data: any[], report: IReport): boolean {
        const threshold = this.reportingModuleConfig.reportSizeExportWarningThreshold || 15000;
        const formatsCount = report.ReportColumns.filter((col) => col.DataFormatOptions.FrontEndFormat !== FrontEndFormats.General).length;
        return data.length * formatsCount > threshold;
    }
}
