<div *ngIf="whoAreYou.Id > 0 && !isEditing">
    <div class="form-section-border">
        <h3>
            Who Are You
            <span class="pull-right" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h3>
    </div>
 
    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>

    <div *ngIf="motherAncestries && motherAncestries.length">
        <b style="padding-left: 5px">Other Mother Ancestries:</b>
        <ul>
            <li *ngFor="let motherAncestry of motherAncestries">{{ motherAncestry.Name }}</li>
        </ul>
    </div>
    <div *ngIf="fatherAncestries && fatherAncestries.length">
        <b style="padding-left: 5px">Other Father Ancestries:</b>
        <ul>
            <li *ngFor="let fatherAncestry of motherAncestries">{{ fatherAncestry.Name }}</li>
        </ul>
    </div>
</div>
<div *ngIf="isEditing">
    <application-who-are-you-form
        [whoAreYou]="whoAreYou"
        [whoAreYouMetaItems]="whoAreYouMetaItems"
        (onFinish)="onEditFinished($event)"
        [donor]="donor"
        [preScreening]="preScreening"
        [application]="application"
    ></application-who-are-you-form>
</div>
