import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IClinicEntityType } from '@model/interfaces/clinic-entity-type';

@Injectable({
    providedIn: 'root',
})
export class ClinicEntityTypeService extends StaticMetaItemService<IClinicEntityType> {
    constructor(public http: HttpClient) {
        super('ClinicEntityTypeService', 'Type', 'TypeIds', '/options/clinic-entity-types', http);
    }
}
