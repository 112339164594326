import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUser } from '@model/interfaces/user';
import { forkJoin, Observable, of } from 'rxjs';
import { IClinic } from '@model/interfaces/clinic';
import { IMetaItem } from '@model/interfaces/base';
import { ClinicService } from '../services/clinic.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { UserService } from '../../users/user.service';
import { UserRoles } from '@model/enums/user-roles.enum';
import { ISelectionChangedEvent, ITypeAheadAPI, VirtualTypeAheadGetItemsFunction } from '@mt-ng2/type-ahead-control';

@Component({
    selector: 'app-clinic-embryologists',
    templateUrl: './clinic-embryologists.component.html',
})
export class ClinicEmbryologistsComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() clinic: IClinic;

    clinicEmbryologistsControls: DynamicField;
    embryologistsForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    selectedValue = 'null';

    typeAheadApi: ITypeAheadAPI;
    embryologists: IUser[];

    embryologistMetaItem: IMetaItem[];
    selectedEmbryologists: IMetaItem[] = [];
    get availableEmbryologists(): IMetaItem[] {
        return this.embryologistMetaItem?.filter((item) => !this.selectedEmbryologists.some((emb) => emb.Id === item.Id)) ?? [];
    }

    isEditing: boolean;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private clinicService: ClinicService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.userService.getUsersByRole(UserRoles.Embryologist)]).subscribe(([embryologists]) => {
            this.embryologists = embryologists;
            this.embryologistMetaItem = this.generateMetaItemsFromUser(this.embryologists);
            this.selectedEmbryologists = this.generateMetaItemsFromUser(this.clinic.Users);
            this.createForm();
        });
    }

    generateMetaItemsFromUser(userSet: IUser[]): IMetaItem[] {
        return userSet.map((u) => {
            return {
                Id: u.Id,
                Name: u.FirstName + ' ' + u.LastName,
            };
        });
    }

    createForm(): void {
        this.getControls();
        this.embryologistsForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    typeAheadControlReady(controlApi: ITypeAheadAPI): void {
        this.typeAheadApi = controlApi;
    }

    getControls(): void {
        this.clinicEmbryologistsControls = new DynamicField({
            formGroup: 'Embryologists',
            label: 'Embryologists',
            name: 'Embryologists',
            options: this.embryologistMetaItem,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
            }),
            validation: [],
            validators: {},
            value: this.getClinicsMultiselectValue(this.clinic.Users, this.embryologists),
        });
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Embryologists: this.fb.group({}),
        });
    }

    getClinicsMultiselectValue(value: IUser[], options: any[]): number[] {
        const embryologistIds = value.map((itm) => itm.Id);
        return options.filter((itm) => embryologistIds.includes(itm.Id)).map((itm) => itm.Id);
    }

    edit(): void {
        this.isEditing = true;
    }

    selectionChanged(event: ISelectionChangedEvent): void {
        if (event.selection) {
            this.selectedEmbryologists.push(event.selection);
            this.typeAheadApi.clearValue();
        }
    }

    formSubmitted(): void {
        if (this.embryologistsForm.valid) {
            const userIds: number[] = this.selectedEmbryologists.map((emb) => emb.Id);
            if (userIds?.length) {
                this.clinic.Users = this.embryologists.filter((c) => userIds.includes(c.Id));
            } else {
                this.clinic.Users = [];
            }
            this.clinicService.linkUsersToClinic(this.clinic.Id, this.clinic.Users).subscribe((clinic) => {
                this.clinic = clinic;
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.embryologistsForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    removeEmbryologist(embryologistId: number): void {
        const embryologist = this.embryologists.find((c) => (c.Id = embryologistId));
        this.selectedEmbryologists.forEach((item, index) => {
            if (item.Id === embryologistId) {
                this.selectedEmbryologists.splice(index, 1);
            }
        });
        this.clinicService.removeClinicUserLink(this.clinic.Id, embryologist.Id).subscribe((clinic) => {
            this.clinic = clinic;
            this.success();
        });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Clinics saved successfully.');
        this.enableDoubleClick();
        this.isEditing = false;
    }
}
