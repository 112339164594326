import { Component, Input, OnInit } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { environment } from '../../environments/environment';
import { IDonorImage } from '@model/interfaces/donor-image';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IApplication } from '@model/interfaces/application';
import { IApplicantImage } from '@model/interfaces/applicant-image';
import { ApplicationImageService } from '../services/application-image.service';

@Component({
    selector: 'app-application-photo-upload',
    styleUrls: [],
    templateUrl: './application-photo-upload.component.html',
})
export class ApplicationPhotoUploadComponent implements OnInit {
    @Input() application: IApplication;
    @Input() canEdit: boolean;
    applicationId: number;
    applicantImages: IApplicantImage[] = [];

    imagesPath: string;
    useLocalImages: boolean;
    localImagePath: string;
    orderedPhotos: IDonorImage[];
    selectedPhoto: IApplicantImage;

    modalOptions: IModalOptions = {
        background: 'rgb(0,0,0,.8)',
        showCloseButton: true,
        showConfirmButton: false,
        width: '50%',
    };

    showUploadPhotoModal: boolean;
    uploadModalOptions: IModalOptions = {
        showCloseButton: true,
        showConfirmButton: false,
        width: '50%',
    };
    donor: any;

    constructor(private notificationsService: NotificationsService, private applicationImageService: ApplicationImageService) {}

    ngOnInit(): void {
        this.imagesPath = environment.s3ImgPath;
        this.useLocalImages = environment.useLocalImages;
        this.localImagePath = environment.imgPath;
        this.applicationId = this.application.Id;

        let identityImages = this.application?.ApplicantImages.filter((ai) => !ai.IsIdentityImage);
        this.applicantImages.push(...identityImages);
    }

    deletePhoto(image: IApplicantImage, e: Event): void {
        e.stopPropagation();
        this.applicationImageService.deleteImage(image.ApplicationId, image.ImageId).subscribe(() => {
            const index = this.applicantImages.findIndex((a) => a.ImageId === image.ImageId);
            this.applicantImages.splice(index, 1);
            this.notificationsService.success('Photo deleted successfully.');
        });
    }

    previewImage(photo: IApplicantImage): void {
        this.selectedPhoto = photo;
    }

    closePreview(): void {
        this.selectedPhoto = null;
    }

    onFinishedUpload(newImages: IApplicantImage[]): void {
        this.showUploadPhotoModal = false;
        if (newImages) {
            this.applicantImages.push(...newImages);
        }
    }
}
