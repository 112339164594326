import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InventoryVesselService } from '@common/components/inventory-vessels/inventory-vessel.service';
import { InventoryTypes } from '@model/enums/inventory-types.enum';
import { IExpandableObject } from '@model/expandable-object';
import { InventoryDynamicControls } from '@model/form-controls/inventory.form-controls';
import { IInventoryPositionDto } from '@model/interfaces/custom/inventory-position-dto';
import { IInventory } from '@model/interfaces/inventory';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'donor-multiple-inventory-locations-form',
    templateUrl: 'multiple-inventory-locations-form.component.html',
})
export class MultipleInventoryLocationsFormComponent implements OnInit {
    @Input() inventory: IInventory;
    @Input() parentId: number; // donor cycle Id (egg) or sales order Id (embryo)
    @Input() donorId: number; // Donor table Id
    @Input() donorNumber: string; // Donor field DonorId
    @Input() retrievalStepRetrievalDate: Date; // retrieval date from retrieval step of this cycle

    @Output() savedInventories = new EventEmitter<IInventory[]>();
    @Output() cancel = new EventEmitter<void>();

    quantity = 1;
    inventoryPosition: IInventoryPositionDto;

    doubleClickIsDisabled = false;

    showConfirm = false;
    confirmOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };

    private newInventoryEntry: IInventory = {
        Comments: null,
        CountPerStraw: null,
        GobletId: null,
        Id: 0,
        InventoryTypeId: InventoryTypes.Eggs,
        ReserveForResearch: null,
        RetrievalDate: null,
        StrawCount: 1,
        StrawNumber: null,
    };

    form: FormGroup;
    formCreated = false;
    controls: IExpandableObject;
    quantityControl: DynamicField;

    constructor(private notificationsService: NotificationsService, private inventoryService: InventoryVesselService, private fb: FormBuilder) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.form = this.fb.group({
            Inventory: this.fb.group({}),
            Quantity: this.fb.group({}),
        });
        this.createControls();
        this.formCreated = true;
    }

    createControls(): void {
        this.newInventoryEntry.RetrievalDate = this.retrievalStepRetrievalDate;
        this.controls = new InventoryDynamicControls(this.newInventoryEntry).Form;
        this.quantityControl = new DynamicField({
            formGroup: 'Quantity',
            label: 'Quantity of Straws',
            name: 'Quantity',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: 1,
        });
    }

    cancelClick(): void {
        this.cancel.emit();
    }

    cancelSave(): void {
        this.showConfirm = false;
        this.doubleClickIsDisabled = false;
    }

    setQuantity(event: number): void {
        this.quantity = event;
    }

    formSubmitted(): void {
        if (this.form.valid && this.inventoryPosition?.Goblet?.Id) {
            this.showConfirm = true;
            this.inventory = Object.assign({}, this.inventory, this.form.value.Inventory);
            this.inventory.GobletId = this.inventoryPosition.Goblet.Id;
            this.quantity = this.form.value.Quantity.Quantity;
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    saveEggInventory(): void {
        this.showConfirm = false;
        this.inventoryService
            .saveMultipleInventoryLocations(this.donorId, this.parentId, this.inventory, this.quantity)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((answer) => {
                this.savedInventories.emit(answer);
                this.cancel.emit();
                this.success();
            });
    }

    private success(): void {
        this.notificationsService.success('Egg inventory saved successfully.');
    }
}
