import { Component, Input, OnInit } from '@angular/core';
import { IntroduceYourselfDynamicConfig } from './application-introduce-yourself.dynamic-config';
import { IApplicationIntroduceYourself } from '@model/interfaces/application-introduce-yourself';
import { IApplication } from '@model/interfaces/application';
import { ICurrentSection, ApplicationDataService } from '../../services/application-data.service';
import { FormSections } from '@model/enums/form-sections.enum';
import { ApplicationService } from '../../../services/application.service';

const CONFIG_CONTROLS = [
    'TalentsAbilities',
    'Favorites',
    'CharacterDescription',
    'Goals',
    'ExerciseRoutine',
    'WhyInterestedInBecomingEggDonor',
    'LanguagesSpoken',
];

@Component({
    selector: 'introduce-yourself',
    templateUrl: './application-introduce-yourself.component.html',
})
export class AppIntroduceYourselfComponent implements OnInit {
    @Input() introduceYourself: IApplicationIntroduceYourself;
    @Input() application: IApplication;

    currentSection: ICurrentSection = {
        formGroup: null,
        nextTab: 'tab-whoAreYou',
        nextTabLabel: 'Who Are You?',
        previousTab: 'tab-preliminaryHistory',
        previousTabLabel: 'Preliminary History',
        routerLink: null,
        sectionId: FormSections.IntroduceYourself,
    };

    isEditing = false;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: IntroduceYourselfDynamicConfig<IApplicationIntroduceYourself>;
    doubleClickIsDisabled = false;

    private configControls: string[];

    constructor(private applicationService: ApplicationDataService, private appService: ApplicationService) { }

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.configControls = [...CONFIG_CONTROLS];
        this.formFactory = new IntroduceYourselfDynamicConfig<IApplicationIntroduceYourself>(
            this.introduceYourself,
            this.configControls,
        );
        this.config = this.formFactory.getForUpdate();
        this.applicationService.currentSection.next(this.currentSection);
    }

    onEditFinished(updatedValue?: IApplicationIntroduceYourself): void {
        if (updatedValue) {
            this.introduceYourself = updatedValue;
            this.setConfig();
            this.isEditing = false;
        }
        this.isEditing = false;
        this.appService.emitChange(this.application);
    }

    edit(): void {
        this.isEditing = true;
    }
}
