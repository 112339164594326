import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import { IUser } from '@model/interfaces/user';
import { Observable } from 'rxjs';

export const emptyApplicationWhoAreYou: IApplicationWhoAreYou = {
    Complete: null,
    DateCreated: null,
    Id: 0,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationWhoAreYouService extends BaseService<IApplicationWhoAreYou> {
    constructor(public http: HttpClient) {
        super('/application-who-are-yous', http);
    }

    ///
    /// Update who-are-you and user (first name, last name etc.) information
    ///
    updateWhoAreYouEtc(whoAreYou: IApplicationWhoAreYou, user: IUser, hasAcknowldegedWaiver: boolean): Observable<any> {
        const data = { ApplicationWhoAreYou: whoAreYou, User: user, HasAcknowldegedWaiver : hasAcknowldegedWaiver };
        return this.http.post(`/application-who-are-yous/update-who-are-you-etc/`, data);
    }

    getEmptyApplicationWhoAreYou(): IApplicationWhoAreYou {
        return { ...emptyApplicationWhoAreYou };
    }
}
