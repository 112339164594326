<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && user"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Clinics
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div class="row">
        <div class="col-md-12">
            <span *ngIf="!user.Clinics || !user.Clinics.length"><i>No Clinics Assigned</i></span>
            <div *ngIf="user.Clinics || user.Clinics.length">
                <div *ngFor="let clinic of user.Clinics">
                    <span>{{ clinic.Name }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div [hidden]="!isEditing" class="miles-form padded">
    <form *ngIf="formCreated" [formGroup]="clinicsForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12">
                <h4>Clinics</h4>
                <dynamic-field [field]="embryologistClinicsControls" [form]="clinicsForm"></dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
        </div>
    </form>
</div>
