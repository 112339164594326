import { Component, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { YesNo } from '@model/enums/yes-no.enum';
import { ICustomCancelControlValue } from '@model/interfaces/custom/custom-cancel-control-value';
import { IYesNoOption } from '@model/interfaces/yes-no-option';
import {
    CustomFormControlComponentBase,
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    IDynamicField,
    IDynamicFieldType,
    LabelPosition,
    LabelPositions,
} from '@mt-ng2/dynamic-form';

import { CycleDataService } from '../../donors/services/cycle-data.service';

interface IFormValue {
    Canceled: number;
    ReasonForCancellation: string;
}

@Component({
    styles: [
        `
            div.pad {
                padding-left: 15px;
                padding-right: 15px;
            }
        `,
    ],
    styleUrls: ['../override-bootstrap.less'],
    template: `
        <div *ngIf="controlInitialized">
            <dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="wrapperForm">
                <div class="row">
                    <div class="col-xs-12 pad">
                        <dynamic-field [form]="wrapperForm" [field]="cancelField" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                    </div>
                    <div class="col-xs-12 pad" *ngIf="showCancelReason">
                        <dynamic-field [form]="wrapperForm" [field]="reasonForCancelField" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                    </div>
                </div>
            </dynamic-form-input-wrapper>
        </div>
    `,
})
export class CustomCancelControlComponent extends CustomFormControlComponentBase {
    wrapperForm: FormGroup;
    dateField: DynamicField;
    disabled = false;

    currentValue: ICustomCancelControlValue;

    cancelField: DynamicField;
    reasonForCancelField: DynamicField;
    controlInitialized: boolean;
    yesNoOptions: IYesNoOption[];
    showCancelReason: boolean;

    constructor(changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private cycleDataService: CycleDataService) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.cycleDataService.getCycleMetaData().subscribe((data) => {
            this.yesNoOptions = data.YesNoOption;

            let value: ICustomCancelControlValue;
            if (this.config.value) {
                value = JSON.parse(this.config.value as string);
            } else {
                value = {
                    Cancel: null,
                    ReasonForCancellation: null,
                };
            }

            this.currentValue = value;

            this.wrapperForm = this.formBuilder.group({
                [this.config.formGroup]: this.formBuilder.group({}),
            });
            this.setFormFields();
            this.controlInitialized = true;
        });

        // wiring up the disabled property to propagate to our controls
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                const isDisabled = status === 'DISABLED';
                if (isDisabled && !this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.disable();
                    }, 0);
                    this.disabled = true;
                } else if (!isDisabled && this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.enable();
                    }, 0);
                    this.disabled = false;
                }
            }),
        );

        // subscribe to form value changes
        this.subscriptions.add(
            this.wrapperForm.valueChanges.subscribe((value) => {
                this.formValueChanged(value);
            }),
        );
    }

    formValueChanged(value: any): void {
        this.showCancelReason = value.CycleStep.Cancel === YesNo.Yes ? true : false;
        this.getControl().patchValue(JSON.stringify(value.CycleStep));
    }

    private setFormFields(): void {
        this.cancelField = this.buildSelectField('Cancel', 'Canceled', this.yesNoOptions);
        this.reasonForCancelField = this.buildInputField('ReasonForCancellation', 'Reason For Cancellation');
    }

    private buildSelectField(field: string, title: string, options: any[]): DynamicField {
        return new DynamicField({
            formGroup: this.config.formGroup,
            label: title,
            labelPosition: new LabelPosition({ position: LabelPositions.Top }),
            name: field,
            options: options,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            value: this.currentValue[field] ? this.currentValue[field] : null,
        } as IDynamicField);
    }

    private buildInputField(field: string, title: string): DynamicField {
        return new DynamicField({
            formGroup: this.config.formGroup,
            label: title,
            labelPosition: new LabelPosition({ position: LabelPositions.Top }),
            name: field,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            value: this.currentValue && this.currentValue[field] ? this.currentValue[field] : null,
        } as IDynamicField);
    }
}
