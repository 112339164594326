import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IMetaItem, BaseService } from '@mt-ng2/base-service';

import { IAuditConfiguration } from '../interfaces/audit-configuration';

import { IAuditLog } from '@mt-ng2/audit-logging-module-config';

@Injectable({
    providedIn: 'root',
})
export class AuditLogService extends BaseService<IAuditLog> {
    constructor(public http: HttpClient) {
        super('/api/v1/changeLogs', http);
    }

    convertMetaItemsToSearchFilters(metaItems: IMetaItem[]): MtSearchFilterItem[] {
        // used for UserMetaItems, EventTypes, and AuditEntities
        return metaItems.map((item: IMetaItem) => {
            return new MtSearchFilterItem(item, false);
        });
    }

    getEntities(): Observable<IAuditConfiguration[]> {
        return this.http.get<IAuditConfiguration[]>('/api/v1/changeLogs/entities');
    }

    getForRecord(recordId: number, entity: string): Observable<IAuditLog[]> {
        return this.http.get<IAuditLog[]>(`/api/v1/changeLogs/records/${entity}/${recordId}`);
    }

    getRecords(selectedAuditEntities: string): Observable<IMetaItem[]> {
        return this.http.get<IMetaItem[]>(`/api/v1/changeLogs/records/${selectedAuditEntities}`);
    }
}
