import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from '@mt-ng2/base-service';
import { IDonorReview } from '@model/interfaces/donor-review';

@Injectable({
    providedIn: 'root',
})

export class DonorReviewService extends BaseService<IDonorReview> {
    constructor(public http: HttpClient) {
        super('/', http);
    }

    ///
    /// Fetches any required reviews for this donor
    ///
    getReviews(donorId: number): Observable<HttpResponse<IDonorReview[]>> {
        return this.http
            .get<IDonorReview[]>(`/donor-reviews/${donorId}/get-all`, {
                observe: 'response',
            })
            .pipe(catchError(this.handleError));
    }

    ///
    /// Updates a review for this donor
    ///
    updateReview(donorId: number, donorReview: IDonorReview): Observable<number> {

        return this.http.put<number>(`/donor-reviews/${donorId}/update`, donorReview);
    }
}
