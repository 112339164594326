import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';

import { IEmail } from '../model/email.interface';
import { ISendEmailModalApi, ISendEmailModalResult } from '../model/send-email-modal.interface';

@Component({
    selector: 'send-email-modal',
    template: `
        <modal-wrapper (ready)="modalApi = $event" [autoShow]="false" [options]="combinedModalOptions" (cancelClick)="onFormResponse()">
            <app-email-form
                [outgoingEmail]="emailDetails"
                (onSendEmail)="onFormResponse($event)"
                (onCancel)="onFormResponse($event)"
            ></app-email-form>
        </modal-wrapper>
    `,
})
export class SendEmailModalComponent implements OnInit {
    @Input() modalOptions: IModalOptions;
    @Output() ready = new EventEmitter<ISendEmailModalApi>();

    get combinedModalOptions(): IModalOptions {
        return Object.assign({}, this.defaultModalOptions, this.modalOptions);
    }

    protected defaultModalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '60%',
    };

    modalApi: IModalWrapperApi;

    emailDetails: IEmail;

    resultSubject$ = new Subject<ISendEmailModalResult>();

    constructor() {}

    ngOnInit(): void {
        this.ready.emit({ show: this.show.bind(this), close: this.close.bind(this) });
    }

    show(email: IEmail): Observable<ISendEmailModalResult> {
        this.emailDetails = email;
        this.modalApi.show();
        return this.resultSubject$.asObservable().pipe(take(1));
    }

    onFormResponse(value?: IEmail): void {
        this.resultSubject$.next({
            cancelled: value ? false : true,
            email: value,
        });
        this.close();
    }

    close(): void {
        this.modalApi.close();
        this.emailDetails = null;
    }
}
