<div class="form-section-border">
    <h4>
        {{ sectionTitle }}
        <span class="pull-right" (click)="edit()">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
</div>
<app-dynamic-view *ngIf="!isEditing" [config]="config.viewOnly"></app-dynamic-view>
<div *ngIf="isEditing && formCreated">
    <form [formGroup]="FamilyHistoryForm" (ngSubmit)="formSubmitted()">
        <div *ngIf="isSibling">
            <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.IsSibling" [form]="FamilyHistoryForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.IsFullSibling" [form]="FamilyHistoryForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.IsHalfSiblingMother" [form]="FamilyHistoryForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.IsHalfSiblingFather" [form]="FamilyHistoryForm"></dynamic-field>
        </div>
        <dynamic-field
            [field]="abstractApplicationFamilyMemberHistoryControls.Deceased"
            [form]="FamilyHistoryForm"
            (valueChanges)="onDeceasedValueChange($event)"
        ></dynamic-field>
        <dynamic-field
            *ngIf="isDeceased"
            [field]="abstractApplicationFamilyMemberHistoryControls.AgeAtDeath"
            [form]="FamilyHistoryForm"
        ></dynamic-field>
        <dynamic-field
            *ngIf="isDeceased"
            [field]="abstractApplicationFamilyMemberHistoryControls.CauseOfDeath"
            [form]="FamilyHistoryForm"
        ></dynamic-field>
        <dynamic-field *ngIf="!isDeceased" [field]="abstractApplicationFamilyMemberHistoryControls.Age" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.EyeColorId" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.HairColorId" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.HairTextureId" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.SkinTypeId" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.RaceId" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field
            [field]="abstractApplicationFamilyMemberHistoryControls.SelectedHeightUnit"
            [form]="FamilyHistoryForm"
            (valueChanges)="onHeightUnitChange($event)"
        ></dynamic-field>
        <div *ngIf="!isMetric">
            <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.Feet" [form]="FamilyHistoryForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.Inches" [form]="FamilyHistoryForm"></dynamic-field>
        </div>
        <dynamic-field *ngIf="isMetric" [field]="abstractApplicationFamilyMemberHistoryControls.Meters" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.SelectedWeightUnit" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.Weight" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.EducationCompletedTypeId" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.Occupation" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.Skills" [form]="FamilyHistoryForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationFamilyMemberHistoryControls.PlaceOfBirth" [form]="FamilyHistoryForm"></dynamic-field>

        <div class="row col-md-12" *ngIf="isEditing">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </div>
        <br />
    </form>
</div>
