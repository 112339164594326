import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { NotificationAddComponent } from './notification-add/notification-add.component';
import { NotificationDetailComponent } from './notification-detail/notification-detail.component';
import { NotificationsComponent } from './notification-list/notifications.component';
import { NotificationService } from '../common/services/notification.service';
import { NotificationHeaderComponent } from './notification-header/notification-header.component';

import { ClaimTypes } from '@model/ClaimTypes';

const notificationEntityConfig = {
    claimType: ClaimTypes.Notifications,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'notificationId',
    service: NotificationService,
    title: 'Notification Detail',
};

const notificationListRoleGuard: any = {
    claimType: ClaimTypes.Notifications,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Notifications',
};

const notificationAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Notifications,
    claimValues: [ClaimValues.FullAccess],
};

export const notificationPaths = {
    notifications: 'notifications',
    // notificationsAdd: 'notifications/add',
};

const notificationRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: NotificationsComponent,
        data: notificationListRoleGuard,
        path: notificationPaths.notifications,
    },
    // {
    //     canActivate: [AuthGuard],
    //     children: [ { component: NotificationAddComponent, path: '', pathMatch: 'full' } ],
    //     component: NotificationHeaderComponent,
    //     data: notificationAddRoleGuard,
    //     path: notificationPaths.notificationsAdd,
    // },
    {
        canActivate: [AuthGuard],
        children: [{ component: NotificationDetailComponent, path: '', pathMatch: 'full' }],
        component: NotificationHeaderComponent,
        data: notificationEntityConfig,
        path: `notifications/:${notificationEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(notificationRoutes)],
})
export class NotificationRoutingModule {}
