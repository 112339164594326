import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IClinic } from '@model/interfaces/clinic';
import { ClinicService } from '../services/clinic.service';

@Component({
    templateUrl: './clinic-header.component.html',
})
export class ClinicHeaderComponent implements OnInit, OnDestroy {
    clinic: IClinic;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private clinicService: ClinicService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('clinicId');
        // set the header based on the id
        if (id > 0) {
            this.clinicService.getById(id).subscribe((clinic) => {
                this.setHeader(clinic);
            });
        } else {
            this.setHeader(this.clinicService.getEmptyClinic());
        }
        // subscribe to any changes in the clinic service
        // which should update the header accordingly
        this.subscriptions.add(
            this.clinicService.changeEmitted$.subscribe((clinic) => {
                this.setHeader(clinic);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(clinic: IClinic): void {
        this.clinic = clinic;
        this.header = clinic && clinic.Id ? `Clinic: ${clinic.Name}` : 'Add Clinic';
    }
}
