export enum NoteCategories {
    DonorGeneral = 1,
    ScreeningNotes,
    RetreivalNotes,
    PlanningNotes,
    StimNotes,
    InventoryNotes,
    RecipientGeneral,
    ShippingNotes,
    AccountingNotes,
}
