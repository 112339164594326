import { Component, Input } from '@angular/core';
import { IAuditConfiguration } from '../../interfaces/audit-configuration';
import { IAuditFormatting } from '../../interfaces/audit-formatting';
import { DatePipe } from '@angular/common';
import { IAuditPropertyNames } from '../../interfaces/audit-propertynames';

import { IAuditLogDetail } from '@mt-ng2/audit-logging-module-config';

@Component({
    selector: '[app-audit-log-detail-row]',
    templateUrl: './audit-log-detail-row.component.html',
})
export class AuditLogDetailRowComponent {
    @Input() detail: IAuditLogDetail;
    @Input() auditConfiguration: IAuditConfiguration;
    constructor(private datePipe: DatePipe) {}

    getFieldName(val: any): any {
        if (!val) {
            return '';
        }
        if (!this.auditConfiguration || !this.auditConfiguration.PropertyNames) {
            return val;
        }
        let auditPropertyNameIndex = this.auditConfiguration.PropertyNames.findIndex((fp) => {
            return fp.PropertyName === this.detail.PropertyName;
        });
        if (auditPropertyNameIndex === -1) {
            return val;
        }

        let auditPropertyName: IAuditPropertyNames = this.auditConfiguration.PropertyNames[auditPropertyNameIndex];
        return auditPropertyName.PropertyNameDisplay;
    }

    getValue(val: any): any {
        if (!val) {
            return 'N/A';
        }
        if (!this.auditConfiguration || !this.auditConfiguration.AuditFormattings) {
            return val;
        }
        let auditFormatIndex = this.auditConfiguration.AuditFormattings.findIndex((fp) => {
            return fp.FormattedProperty === this.detail.PropertyName;
        });
        if (auditFormatIndex > -1) {
            let auditFormat: IAuditFormatting = this.auditConfiguration.AuditFormattings[auditFormatIndex];
            let formatString = auditFormat.FormatString;
            if (auditFormat.IsDate) {
                if (auditFormat.IsDateUTC) {
                    let utcString = new Date(val).toString() + 'Z';
                    return this.datePipe.transform(utcString, formatString);
                } else {
                    return this.datePipe.transform(val, formatString);
                }
            } else {
                return val; // How do we format properties that are not dates?
            }
        }
        return val;
    }
}
