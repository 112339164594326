<div>
    <div>
        <form [formGroup]="form" *ngIf="formCreated">
            <dynamic-field [field]="quantityControl" [form]="form" (valueChanges)="setQuantity($event)"></dynamic-field>
            <dynamic-field [field]="controls.RetrievalDate" [form]="form"></dynamic-field>
            <dynamic-field [field]="controls.CountPerStraw" [form]="form"></dynamic-field>
            <dynamic-field [field]="controls.Comments" [form]="form"></dynamic-field>
            <dynamic-field [field]="controls.ReserveForResearch" [form]="form"></dynamic-field>
            <app-inventory-vessel [position]="inventoryPosition" (onGobletSelected)="inventoryPosition = $event"></app-inventory-vessel>
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success"
                (click)="formSubmitted()">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </form>
    </div>
</div>
<!-- Confirm Modal -->
<modal-wrapper *ngIf="showConfirm" [options]="confirmOptions">
    <div>
        <h2>Inventory Summary</h2>
        <p *ngIf="donorNumber"><b>Donor:</b> {{ donorNumber }}</p>
        <p><b>Quantity of Straws:</b> {{ quantity }}</p>
        <p><b>Retrieval Date:</b> {{ inventory.RetrievalDate | date: 'mediumDate' }}</p>
        <p><b>Oocytes #:</b> {{ inventory.CountPerStraw }}</p>
        <p><b>Tank:</b> {{ inventoryPosition.Tank.Name }} - {{ inventoryPosition.Tank.Number }}</p>
        <p><b>Can:</b> {{ inventoryPosition.Can.Number }}</p>
        <p><b>Goblet:</b> {{ inventoryPosition.Goblet.Number }}</p>
    </div>
    <div class="row col-md-12">
        <button (click)="saveEggInventory()" class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelSave()">Cancel</button>
    </div>
</modal-wrapper>
