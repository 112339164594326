<div *ngIf="addressControls && isEditing">
    <div class="input-scaffold__group">
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.CountryCode" [form]="Address"
                (valueChanges)="setStatesForCountry($event)"
                (controlCreated)="setShowStatesCurrentAddress($event)"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.Address1" [form]="Address"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.Address2" [form]="Address"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.City" [form]="Address"></dynamic-field>
        </div>
    </div>
    <div class="input-scaffold__group">
        <div class="input-scaffold">
            <label *ngIf="!addressControls.Form.CountryCode" class="input-scaffold__label">State <span
                    style="color: red;"> *</span></label>
            <dynamic-field [field]="addressControls.Form.StateCode" [form]="Address"
                [hidden]="!(showStatesCurrentAddress$ | async)">
            </dynamic-field>
            <dynamic-field [field]="addressControls.Form.Province" [form]="Address"
                [hidden]="showStatesCurrentAddress$ | async"> </dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.Zip" [form]="Address"></dynamic-field>
        </div>
    </div>
    <div *ngIf="isEditing && showButtons">
        <span>
            <button class="btn btn-success btn-flat" (click)="submit()">
                Save
            </button>
            <button class="btn btn-default btn-flat" (click)="cancel()">
                Cancel
            </button>
        </span>
        <span *ngIf="showDelete" class="pull-right">
            <button class="btn btn-danger btn-flat" (click)="delete()">
                Delete
            </button>
        </span>
    </div>
</div>