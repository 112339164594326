<dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
    <br />
    <div class="audited-date-control-wrapper">
        <div class="date-field">
            <dynamic-field
                *ngIf="dateField"
                [form]="wrapperForm"
                [field]="dateField"
                (blur)="onBlur()"
                (focus)="onFocus()"
                (valueChanges)="dateValueChanged($event)"
            ></dynamic-field>
        </div>
        <div *ngIf="currentValue?.AuditUserId">
            <span>entered on {{ currentValue?.AuditDate | date }} by {{ currentValue?.AuditUserName }}</span>
        </div>
    </div>
</dynamic-form-input-wrapper>
