import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { ReportsService } from '../services/reports.service';
import { IReport } from '../model/interfaces/report';

@Component({
    styles: [
        `
            .header-relative-container {
                position: relative;
                height: 61px;
            }
            .freeze-header {
                position: fixed;
                background-color: #ecf0f5;
                z-index: 100;
            }
            .freeze-header > hr {
                margin-top: -8px;
                margin-bottom: 0;
                border: 0;
                border-top: 1px solid RGBA(0, 0, 0, 0.15);
            }
        `,
    ],
    template: `
        <div class="header-relative-container">
            <div class="row">
                <div class="col-md-12 freeze-header">
                    <h2>
                        <route-back-button></route-back-button>
                        <mt-page-title [pageTitle]="header"></mt-page-title>
                        <report-actions
                            [report]="report"
                            [showEditButton]="showEditButton"
                            [showRenderButton]="showRenderButton"
                            (rebind)="rebind()"
                        ></report-actions>
                    </h2>
                    <hr />
                </div>
            </div>
        </div>
        <router-outlet></router-outlet>
    `,
})
export class ReportHeaderComponent implements OnInit, OnDestroy {
    report: IReport;
    header: string;

    subscriptions: Subscription = new Subscription();

    showEditButton: boolean;
    showRenderButton: boolean;

    constructor(private reportsService: ReportsService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.reportsService.changeEmitted$.subscribe((report) => {
                this.setHeader(report);
            }),
        );
        const reportId = +this.route.snapshot.paramMap.get('reportId');
        if (reportId > 0) {
            this.reportsService.getById(reportId).subscribe((report) => {
                this.setHeader(report);
            });
        } else {
            this.header = 'Add Report';
            this.report = this.reportsService.getEmptyReport();
        }

        this.setButtonVisibilityBasedOnUrl(this.router.url);
        this.subscriptions.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.setButtonVisibilityBasedOnUrl(event.url);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setButtonVisibilityBasedOnUrl(url: string): void {
        this.showEditButton = !url.endsWith('edit');
        this.showRenderButton = url.endsWith('edit');
    }

    setHeader(report: IReport): void {
        this.report = report;
        this.header = `Report: ${report.Name}`;
    }

    rebind(): void {
        this.reportsService.getById(this.report.Id).subscribe((report) => {
            this.report = report;
        });
    }
}
