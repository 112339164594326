<div class="miles-editable miles-card padded" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4>
        Photo Management
        <button *ngIf="canEdit" type="button" class="btn btn-flat btn-success pull-right" (click)="showUploadPhotoModal = true">
            <span class="fa fa-plus"></span> Add Photos
        </button>
        <div style="clear: both"></div>
    </h4>
    <br />
    <div class="img-container" *ngIf="applicantImages.length" cdkDropListGroup>
        <div *ngFor="let photo of applicantImages; let i = index">
            <div class="img-box cdkDrag">
                <div class="img">
                    <img *ngIf="useLocalImages" src="{{ localImagePath }}thumb-{{ photo.Image.ImagePath }}" alt="Applicant image" />
                    <img
                        *ngIf="!useLocalImages"
                        src="{{ imagesPath }}/static/photos/application-photos/{{ photo.ApplicationId }}/large-thumbs/{{ photo.Image.FileName }}"
                        fallback="{{ imagesPath }}/static/photos/application-photos/{{ photo.ApplicationId }}/large-thumbs/{{
                            photo.Image.FileName | fileextension: 'jpg'
                        }}"
                        alt="Applicant image"
                    />
                    <button
                        type="button"
                        class="trash-button btn btn-sm fa fa-trash"
                        (mtConfirm)="deletePhoto(photo, $event)"
                        *ngIf="canEdit"
                    ></button>
                    <div class="preview-overlay" (click)="previewImage(photo)"></div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="applicantImages.length === 0">
        <div class="text-center">
            <em>Applicant has no photos.</em>
        </div>
    </div>
</div>

<!-- preview modal -->
<modal-wrapper *ngIf="selectedPhoto" [options]="modalOptions" (cancelClick)="closePreview()">
    <img
        style="max-width: 120%; height: auto"
        *ngIf="useLocalImages"
        src="{{ localImagePath }}{{ selectedPhoto.Image.ImagePath }}"
        alt="Applicant image"
    />
    <img
        style="max-width: 100%; height: auto"
        *ngIf="!useLocalImages"
        src="{{ imagesPath }}/static/photos/application-photos/{{ applicationId }}/{{ selectedPhoto.Image.FileName }}"
        alt="Applicant image"
    />
</modal-wrapper>

<!-- preview modal -->
<modal-wrapper *ngIf="showUploadPhotoModal" [options]="uploadModalOptions" (cancelClick)="showUploadPhotoModal = false">
    <app-application-photo-uploader
        (onFinished)="onFinishedUpload($event)"
        [applicationId]="applicationId"
        [IsIdentityImage]="false"
    ></app-application-photo-uploader>
</modal-wrapper>
