import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApplicationStatusService } from '@common/services/application-status.service';
import { ApplicationStatuses } from '@model/enums/application-statuses.enum';
import { IApplication } from '@model/interfaces/application';
import { IApplicationStatus } from '@model/interfaces/application-status';
import { ApplicationReivewDynamicControlsPartial } from '@model/partials/application-review-partial.form-controls';
import { UserDynamicControlsPartial } from '@model/partials/user-partial.form-controls';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserService } from '../../users/user.service';

import { ApplicationDynamicConfig } from '../application.dynamic-config';
import { ApplicationService } from '../services/application.service';

@Component({
    selector: 'app-application-basic-info',
    templateUrl: './application-basic-info.component.html',
})
export class ApplicationBasicInfoComponent implements OnInit {
    @Input() application: IApplication;
    @Input() canEdit: boolean;

    // abstract controls
    abstractApplicationControls: any;
    abstractUserControls: any;

    applicationForm: FormGroup;
    formCreated = false;
    isEditing = false;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: ApplicationDynamicConfig<IApplication>;
    doubleClickIsDisabled = false;
    applicationStatuses: IApplicationStatus[];
    deniedStatusId = ApplicationStatuses.Denied;

    showDenialModal = false;
    manualDenialReason = '';
    denialModalOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };

    get isNewApplication(): boolean {
        return this.application && this.application.Id ? false : true;
    }

    constructor(
        private applicationService: ApplicationService,
        private applicationStatusService: ApplicationStatusService,
        private notificationsService: NotificationsService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        this.applicationStatusService.getItems().subscribe((answer) => {
            this.applicationStatuses = answer;
            this.initForm();
        });
    }

    initForm(): void {
        this.createForm();
        this.setConfig();
    }

    createForm(): void {
        this.getControls();
        this.applicationForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractApplicationControls = new ApplicationReivewDynamicControlsPartial(
            this.application,
            {
                applicationStatuses: this.applicationStatusService.items,
                formGroup: 'Application',
            },
        ).Form;
        this.abstractUserControls = new UserDynamicControlsPartial(
            this.application.User,
            {
                formGroup: 'User',
            },
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Application: this.fb.group({}),
            User: this.fb.group({}),
        });
    }

    setConfig(): void {
        let configControls = ['DonorId', 'ApplicationStatusId'];
        if (this.application.DenialReasonId) {configControls.push('DenialReasonId'); }
        if (this.application.ManualDenialReason) {configControls.push('ManualDenialReason'); }
        this.formFactory = new ApplicationDynamicConfig<IApplication>(this.application, this.application.ApplicationStatus, this.applicationStatuses, configControls);
        this.config = this.formFactory.getForUpdate();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    removeDenial(): void {
        this.applicationService.removeDenial(this.application).subscribe((answer) => {
            this.application = answer;
            this.initForm();
            this.isEditing = false;
        });
    }

    formSubmitted(): void {
        if (this.applicationForm.valid) {
            this.formFactory.assignFormValues(this.application, this.applicationForm.value.Application);
            const updatedUser = Object.assign({}, this.application.User, this.applicationForm.value.User);
            this.saveApplication(updatedUser);
        } else {
            markAllFormFieldsAsTouched(this.applicationForm);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveApplication(updatedUser): void {
        forkJoin([
        this.applicationService
            .update(this.application),
            this.userService.update(updatedUser),
        ])
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(() => {
                this.application.User = updatedUser;
                this.success();
            });
    }

    denyApplication(): void {
        const application = Object.assign({}, this.application, { ManualDenialReason: this.manualDenialReason });
        this.applicationService.denyApplication(application).subscribe((answer) => {
            this.application = answer;
            this.initForm();
            this.isEditing = false;
            this.showDenialModal = false;
            this.notificationsService.success('Application denied successfully.');
        });
    }

    private success(): void {
        this.isEditing = false;
        this.initForm();
        this.applicationService.emitChange(this.application);
        this.notificationsService.success('Application saved successfully.');
    }
}
