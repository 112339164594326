import { Injector } from '@angular/core';
import { Component, ChangeDetectorRef } from '@angular/core';

import { pluralize } from '@mt-ng2/common-functions';
import { FormSelectBaseWithNullableOption, IDynamicField } from '@mt-ng2/dynamic-form';
import { IModalOptions } from '@mt-ng2/modal-module';

/**
 * This component displays a Yes/No multiselect when used
 * It extends the Dynamic Field interface to allow a confirmation dialog
 * to be configured per instance.
 * See qc-investigation-partial.form-controls.ts lines 23-26 for implementation
 * 5/18/2021 - B.C.
 */
export interface IYesNoDynamicField extends IDynamicField {
    confirmOnYes: boolean;
    confirmMessage?: string;
}

@Component({
    styles: [
        `
            .inside-box-validation {
                position: relative !important;
                top: -35px;
                bottom: -35px;
                margin-bottom: -35px;
                right: 3px;
                float: right;
                font-size: 70%;
            }
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
        `,
    ],
    template: `<dynamic-form-select-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
        <br />
        <div
            #inputElement
            class="btn-group btn-group-toggle"
            ngbRadioGroup
            [formControlName]="config.name"
            [class.disabled]="isDisabled"
            (blur)="onBlur()"
            (focus)="onFocus()"
        >
            <label
                *ngIf="yesNoConfig.confirmOnYes"
                ngbButtonLabel
                class="btn-primary-inverse"
                [attr.disabled]="isDisabled ? 'disabled' : null"
                [class.disabled]="isDisabled"
                (mtConfirm)="onConfirm()"
                [mtConfirmOptions]="customConfirmOptions"
            >
                <input ngbButton type="radio" (blur)="onBlur()" (focus)="onFocus()" [value]="true" />
                Yes
            </label>
            <label
                *ngIf="!yesNoConfig.confirmOnYes"
                ngbButtonLabel
                class="btn-primary-inverse"
                [attr.disabled]="isDisabled ? 'disabled' : null"
                [class.disabled]="isDisabled"
            >
                <input ngbButton type="radio" (blur)="onBlur()" (focus)="onFocus()" [value]="true" />
                Yes
            </label>
            <label ngbButtonLabel class="btn-primary-inverse" [attr.disabled]="isDisabled ? 'disabled' : null" [class.disabled]="isDisabled">
                <input ngbButton type="radio" (blur)="onBlur()" (focus)="onFocus()" [value]="false" />
                No
            </label>
        </div>
    </dynamic-form-select-wrapper> `,
})
export class FormYesNoButtonComponent extends FormSelectBaseWithNullableOption {
    get yesNoConfig(): IYesNoDynamicField {
        return this.config as any;
    }

    customConfirmOptions: IModalOptions = {
        confirmButtonText: 'Confirm',
        text: 'Are you sure?',
        type: 'question',
    };
    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.customConfirmOptions.text = this.yesNoConfig.confirmMessage;
    }

    getNoItemsErrorMessage(): string {
        return `no ${pluralize(this.config.label.toLowerCase())} available`;
    }

    onConfirm(): void {
        this.thisControl.getControl().setValue(true);
    }
}
