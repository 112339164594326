import { IDonorReviewDynamicControlsParameters } from '@model/form-controls/donor-review.form-controls';
import { IMetaItem } from '@model/interfaces/base';
import { IDonorReview } from '@model/interfaces/donor-review';
import { IReviewType } from '@model/interfaces/review-type';
import { IUser } from '@model/interfaces/user';
import { DonorReviewDynamicControlsPartial } from '@model/partials/donor-review-partial.form-controls';
import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IReviewOutcome } from '@model/interfaces/review-outcome';

export class DonorReviewsDynamicConfiguration<T extends IDonorReview> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private donorReview: T, private reviewTypes: IReviewType[], private outcomes: IReviewOutcome[], private users?: IUser[], private configControls?: string[]) {
        super();
        const additionalParamters: IDonorReviewDynamicControlsParameters = {
            donorReview: this.donorReview,
            reviewTypes: this.reviewTypes,
            outcomes: this.outcomes,
            users: this.users,
        };
        const dynamicControls = new DonorReviewDynamicControlsPartial(this.donorReview, additionalParamters);
        if (!configControls) {
            this.configControls = ['ReviewStatusId'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
