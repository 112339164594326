import { ISalesOrderReservation } from '@model/interfaces/sales-order-reservation';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class SalesOrderReservationsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Recipient', 'Id'],
                    name: 'Recipient ID',
                }),
                new EntityListColumn({
                    accessors: ['Recipient', 'Email'],
                    name: 'Recipient Email',
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: ISalesOrderReservation): string => {
                        return this.buildName(reservation.Recipient);
                    },
                    name: 'Recipient',
                    sort: {
                        sortProperty: 'Recipient.LastName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: ISalesOrderReservation): string => {
                        return this.enforceLineBreaks(reservation.SalesOrderReservationDonors.map((d) => d.Donor.DonorId));
                    },
                    bindToInnerHtml: true,
                    name: 'Donor IDs',
                    sort: {
                        disableSort: true,
                    },
                    style: {
                        width: 10,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: ISalesOrderReservation): string => {
                        return this.enforceLineBreaks(reservation.SalesOrderReservationDonors.map((d) => d.Donor.User.Email));
                    },
                    bindToInnerHtml: true,
                    name: 'Donor Emails',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: ISalesOrderReservation): string => {
                        return this.enforceLineBreaks(reservation.SalesOrderReservationDonors.map((d) => this.buildName(d.Donor.User)));
                    },
                    bindToInnerHtml: true,
                    name: 'Donors',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: ISalesOrderReservation): string => {
                        return this.enforceLineBreaks(reservation.SalesOrderReservationDonors.map((d) => d.EggCountReserved));
                    },
                    bindToInnerHtml: true,
                    name: '# Reserved',
                    sort: {
                        disableSort: true,
                    },
                    style: {
                        width: 10,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: ISalesOrderReservation): string => {
                        return reservation.SalesOrderReservationDonors.length
                            ? reservation.SalesOrderReservationDonors.map((d) => d.EggCountReserved)
                                  .reduce((c, p) => c + p)
                                  .toString()
                            : '';
                    },
                    name: 'Total Reserved',
                    sort: {
                        disableSort: true,
                    },
                    style: {
                        width: 10,
                    },
                }),
                new EntityListColumn({
                    accessors: ['ExpirationDate'],
                    name: 'Expiration Date',
                    pipes: ['date:mediumDate'],
                }),
                new EntityListColumn({
                    accessorFunction: (reservation: ISalesOrderReservation): string => {
                        return this.buildName(reservation.User);
                    },
                    name: 'Created By',
                    sort: {
                        sortProperty: 'User.LastName',
                    },
                }),
                new EntityListColumn({
                    accessors: ['CreatedDate'],
                    name: 'Created Date',
                    pipes: ['date:mediumDate'],
                }),
                new EntityListColumn({
                    accessors: ['Notes'],
                    excludeFromExport: false,
                    excludeFromView: true,
                    name: 'Notes',
                }),
            ],
        };
        super(listConfig);
    }

    buildName(value: any): string {
        return `${value.FirstName} ${value.LastName}`;
    }

    enforceLineBreaks(arr: any[]): string {
        return `${arr.join('<br>')}`;
    }
}
