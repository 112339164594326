import { NgModule } from '@angular/core';

import { SharedModule } from '@common/shared.module';
import { ApplicationRoutingModule } from './application-routing.module';
import { ApplicationDetailComponent } from './application-detail/application-detail.component';
import { ApplicationsComponent } from './application-list/applications.component';
import { ApplicationHeaderComponent } from './application-header/application-header.component';
import { ApplicationDataComponent } from './application-data/application-data.component';
import { AppFamilyHistoryComponent } from './application-data/sections/family-history/family-history.component';
import { AppMedicalIssuesComponent } from './application-data/sections/medical-issues/medical-issues.component';
import { AppWhoAreYouComponent } from './application-data/sections/who-are-you/who-are-you.component';
import { IndividualFamilyHistoryComponent } from './application-data/sections/family-history/individual-family-history-component/individual-family-history.component';
import { DonorMedicalIssuesComponent } from './application-data/sections/medical-issues/donor-medical-issue/donor-medical-issue.component';
import { ApplicationWhoAreYouFormComponent } from './application-data/sections/who-are-you/who-are-you-form/who-are-you-form.component';
import { IssueSelectorComponent } from './application-data/sections/medical-issues/issue-selector/issue-selector.component';
import { ApplicationPhotoUploadComponent } from './application-photo-upload/application-photo-upload.component';
import { ApplicationPhotoUploaderComponent } from './application-photo-uploader/application-photo-uploader.component';
import { ApplicationPhotoIdentityUploadComponent } from './application-photo-identity-upload/application-photo-identity-upload.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { FileUploadModule } from 'ng2-file-upload';
import { ApplicationPreScreeningComponent } from './application-data/sections/pre-screening/application-pre-screening.component';
import { BirthsComponent } from '@common/components/births/births-component';
import { ApplicationBasicInfoComponent } from './application-basic-info/application-basic-info.component';
import { AppIntroduceYourselfComponent } from './application-data/sections/introduce-yourself/application-introduce-yourself.component';
import { ApplicationIntroduceYourselfFormComponent } from './application-data/sections/introduce-yourself/introduce-yourself-form/application-introduce-yourself-form.component';

@NgModule({
    declarations: [
        ApplicationBasicInfoComponent,
        ApplicationsComponent,
        ApplicationHeaderComponent,
        ApplicationDetailComponent,
        ApplicationDataComponent,
        ApplicationPreScreeningComponent,
        BirthsComponent,
        AppFamilyHistoryComponent,
        AppMedicalIssuesComponent,
        AppWhoAreYouComponent,
        IndividualFamilyHistoryComponent,
        AppIntroduceYourselfComponent,
        ApplicationIntroduceYourselfFormComponent,
        DonorMedicalIssuesComponent,
        ApplicationWhoAreYouFormComponent,
        IssueSelectorComponent,
        ApplicationPhotoUploadComponent,
        ApplicationPhotoUploaderComponent,
        ApplicationPhotoIdentityUploadComponent,
    ],
    imports: [SharedModule, ApplicationRoutingModule, ModalModule, FileUploadModule],
})
export class ApplicationModule { }
