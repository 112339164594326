import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IRecipientSalesOrder } from '@model/interfaces/recipient-sales-order';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { Subscription } from 'rxjs';
import { RecipientSalesOrderService } from '../../recipients/recipient-cycles/services/recipient-sales-order.service';
import { RecipientsSalesReportEntityListConfig } from './recipients-sales-report.entity-list-config';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';
import { EntityListExportConfig, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ClinicService } from '../../clinics/services/clinic.service';
import { debounceTime, map } from 'rxjs/operators';
import { entityListModuleConfig } from '@common/shared.module';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-recipient-sales',
    styles: [
        `
            .dropdown > button {
                background: #ffffff;
                border-color: #d2d6de;
                box-shadow: none;
            }
        `,
    ],
    templateUrl: './recipients-sales-report.component.html',
})
export class RecipientsSalesReportComponent implements OnInit {
    searchForm: FormGroup = new FormGroup({
        donorId: new FormControl(),
        recipientEmail: new FormControl(),
        recipientId: new FormControl(),
        recipientName: new FormControl(),
        saleDateEnd: new FormControl(),
        saleDateStart: new FormControl(),
    });

    recipientSales: IRecipientSalesOrder[] = [];

    clinics: MtSearchFilterItem[] = [];
    startDate: Date;
    endDate: Date;

    total = 0;
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    order = 'RecipientCycleId';
    orderDirection = 'desc';

    entityListConfig = new RecipientsSalesReportEntityListConfig(null);
    searchFormValueChangesSub: Subscription;
    constructor(private salesOrderService: RecipientSalesOrderService, private clinicService: ClinicService) {}

    ngOnInit(): void {
        this.initializeEntityListConfig(null);
        this.clinicService.getAll().subscribe((answer) => (this.clinics = answer.map((answer) => new MtSearchFilterItem(answer, false))));
        this.setSearchFormValueChangesSub();
        this.search();
    }

    private setSearchFormValueChangesSub(): void {
        if (this.searchFormValueChangesSub) {
            this.searchFormValueChangesSub.unsubscribe();
        }
        let valueChanges$ = this.searchForm.valueChanges;

        this.searchFormValueChangesSub = valueChanges$.pipe(debounceTime(350)).subscribe((response) => {
            if (response.donorId) {
                this.initializeEntityListConfig(response.donorId);
            } else {
                this.initializeEntityListConfig(null);
            }
            this.search();
        });
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    search(): void {
        this.getSearchCall().subscribe((answer) => {
            this.recipientSales = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
        this.currentPage = 1;
    }

    initializeEntityListConfig(donorId: number): void {
        this.entityListConfig = new RecipientsSalesReportEntityListConfig(donorId);
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Recipient Sales Report',
            getDataForExport: this.getRecipientSalesForExport.bind(this),
        });
    }

    getRecipientSalesForExport(): any {
        return this.getSearchCall({ forExport: true }).pipe(map((answer: any) => answer.body));
    }

    getSearchCall(options = { forExport: false }): any {
        const selectedClinicIds: number[] = this.getSelectedFilters(this.clinics);
        const _extraSearchParams: ExtraSearchParams[] = [];
        if (this.searchForm?.get('recipientId')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recipientId',
                    value: this.searchForm.get('recipientId').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('donorId')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'donorId',
                    value: this.searchForm.get('donorId').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('recipientEmail')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recipientEmail',
                    value: this.searchForm.get('recipientEmail').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('recipientName')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recipientName',
                    value: this.searchForm.get('recipientName').value.toString(),
                }),
            );
        }

        if (selectedClinicIds.length > 0) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'clinicIds',
                    valueArray: selectedClinicIds,
                }),
            );
        }

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'SaleDateStart',
                value: this.startDate ? this.getDateValueForParams(this.startDate) : '',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'SaleDateEnd',
                value: this.endDate ? this.getDateValueForParams(this.endDate) : '',
            }),
        );

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
            skip: options.forExport ? 0 : (this.currentPage - 1) * this.itemsPerPage,
            take: options.forExport ? this.total : this.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);

        return this.salesOrderService.search(searchparams);
    }

    dateSelectionChanged(value: ISearchFilterDaterangeValue): void {
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.searchForm.get('saleDateStart').setValue(value.startDate);
        this.searchForm.get('saleDateEnd').setValue(value.endDate);
        this.searchForm.updateValueAndValidity();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.search();
    }

    getDateValueForParams(date: Date): string {
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return `${month}-${day}-${year}`;
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.search();
    }
}
