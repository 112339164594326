import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { EmbryologistDetailService } from './embryologist-detail.service';
import { EmbryologistDetailDynamicConfig } from './embryologist-detail.dynamic-config';

import { IEmbryologistDetail } from '@model/interfaces/embryologist-detail';
import { EmbryologistDetailDynamicControls } from '@model/form-controls/embryologist-detail.form-controls';

@Component({
    selector: 'app-embryologist-details',
    templateUrl: './embryologist-detail.component.html',
})
export class EmbryologistDetailComponent implements OnInit {
    @Input() userId: number;
    @Input() canEdit: boolean;

    embryologistDetail: IEmbryologistDetail;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: EmbryologistDetailDynamicConfig<IEmbryologistDetail>;
    doubleClickIsDisabled = false;
    abstractEmbryologistDetailControls: any;
    form: FormGroup;
    formCreated = false;
    embryologistIsVerified: boolean;

    get isNewEmbryologistDetail(): boolean {
        return this.embryologistDetail && this.embryologistDetail.Id ? false : true;
    }

    constructor(
        private embryologistDetailService: EmbryologistDetailService,
        private notificationsService: NotificationsService,
        private router: Router,
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.embryologistDetailService.getEmbryologistDetailsByUserId(this.userId).subscribe((details) => {
            this.embryologistDetail = details ? details : this.embryologistDetailService.getEmptyEmbryologistDetail(this.userId);
            this.embryologistIsVerified = this.embryologistDetail.IsVerified;
            this.createForm();
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new EmbryologistDetailDynamicConfig<IEmbryologistDetail>(this.embryologistDetail);

        if (this.isNewEmbryologistDetail) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    createForm(): void {
        this.getControls();
        this.form = this.fb.group({
            EmbryologistDetail: this.fb.group({}),
        });
        this.formCreated = true;
    }

    getControls(): void {
        this.abstractEmbryologistDetailControls = new EmbryologistDetailDynamicControls(this.embryologistDetail).Form;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewEmbryologistDetail) {
            this.router.navigate(['/embryologistDetails']);
        } else {
            this.isEditing = false;
        }
    }

    isVerifiedChanged(event: boolean): void {
        this.embryologistIsVerified = event;
    }

    formSubmitted(): void {
        if (this.form.valid) {
            Object.assign(this.embryologistDetail, this.form.value.EmbryologistDetail);
            this.saveEmbryologistDetail();
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveEmbryologistDetail(): void {
        if (this.isNewEmbryologistDetail) {
            this.embryologistDetailService
                .create(this.embryologistDetail)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.embryologistDetail.Id = answer;
                    this.success();
                });
        } else {
            this.embryologistDetailService
                .update(this.embryologistDetail)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe(() => {
                    this.success();
                });
        }
    }

    private success(): void {
        this.setConfig();
        this.getControls();
        this.isEditing = false;
        this.embryologistDetailService.emitChange(this.embryologistDetail);
        this.notificationsService.success('Embryologist details saved successfully.');
    }
}
