import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileItem } from 'ng2-file-upload';
import { IModalOptions } from '@mt-ng2/modal-module';
import { IDocumentType } from '@model/interfaces/document-type';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDonorDocument } from '@model/interfaces/donor-document';
import { saveAs } from 'file-saver';
import { DocumentEntityTypes } from '@model/enums/document-entity-types';
import { UploadDocumentsComponent } from '@common/components/upload-documents/upload-documents/upload-documents.component';
import { UploadDocumentsService } from '@common/services/upload-documents.service';
import { AuthService, ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { GeneticScreeningDynamicConfig } from './genetic-screening.dynamic-config';
import { IAcceptedRejectedOption } from '@model/interfaces/accepted-rejected-option';
import { IGeneticScreening } from '@model/interfaces/genetic-screening';
import { GeneticScreeningService } from './genetic-screening.service';
import { GeneticScreeningTypes } from '@model/enums/genetic-screening-types.enum';
import { IGeneticScreeningDocument } from '@model/interfaces/genetic-screening-document';

@Component({
    selector: 'app-upload-genetic-tests',
    styles: [
        `
            .addMoreButton {
                margin-top: -16px;
            }
        `,
    ],
    templateUrl: './upload-genetic-tests.component.html',
})
export class UploadGeneticTestsComponent extends UploadDocumentsComponent {
    showDocumentsCard = false;
    @Input() claimType: ClaimTypes;
    @Input() acceptedRejectedOptions: IAcceptedRejectedOption[];
    @Output('onGeneticTestsChange') onGeneticTestsChange: EventEmitter<void> = new EventEmitter<void>();
    readonly GeneticScreeningTypes = GeneticScreeningTypes;
    headerTitle = 'Uploaded Genetic Screening Documents';
    form: FormGroup;
    config: IDynamicField[];
    currentlyEditedFileName: string;
    currentlyEditedGeneticScreeningId: number;
    currentlyEditedGeneticScreeningDocumentId: number;
    geneticScreeningType: GeneticScreeningTypes = null;

    constructor(
        public uploadDocumentsService: UploadDocumentsService,
        private claimsService: ClaimsService,
        public notificationService: NotificationsService,
        private fb: FormBuilder,
        private geneticScreeningService: GeneticScreeningService,
        private authService: AuthService,
    ) {
        super(uploadDocumentsService, notificationService);
    }
    ngOnInit(): void {
        this.modalOptions.width = '500px';
        this.tempFile = null;
        this.mustSelectType = false;
        this.canUpload = this.claimsService.hasClaim(this.claimType, [ClaimValues.FullAccess]);
        this.showDocumentsCard = this.claimsService.hasClaim(this.claimType, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.uploadDocumentsService.getDocumentTypes(this.entityType, this.entityId).subscribe((documentTypes) => {
            this.documentTypes = [...documentTypes];
            this.getDocuments();
            if (this.documentTypes && this.documentTypes.length > 1) {
                this.mustSelectType = true;
            } else {
                this.documentTypeSelected = this.documentTypes[0];
            }
        });
    }

    selectType(gsType: GeneticScreeningTypes): void {
        this.geneticScreeningType = gsType;
        this.showModal = true;
        this.setConfigForCreate();
        this.form = this.fb.group({});
    }

    confirm(file: FileItem): void {
        this.tempFile = file;
    }

    setConfigForCreate(): void {
        const configControls = this.getConfigControls();
        const config = new GeneticScreeningDynamicConfig(
            this.acceptedRejectedOptions,
            this.geneticScreeningService.getEmptyGeneticScreening(),
            configControls,
        );
        this.config = config.getForCreate().formObject;
    }

    setConfigForUpdate(doc: IGeneticScreeningDocument): void {
        const configControls = this.getConfigControls();
        const config = new GeneticScreeningDynamicConfig(this.acceptedRejectedOptions, doc.GeneticScreenings[0], configControls);
        this.config = config.getForCreate().formObject;
    }

    getConfigControls(): string[] {
        let configControls = null;
        switch (this.geneticScreeningType) {
            case GeneticScreeningTypes.CarrierTesting:
                configControls = [
                    'CarrierTestingDrawDate',
                    'CarrierTestingResultsReceivedDate',
                    'CarrierTestingResultsReceivedBy',
                    'CarrierTestingAcceptedRejected',
                    'CarrierTestingAcceptedRejectedDate',
                    'CarrierTestingAcceptedRejectedBy',
                ];
                break;
            case GeneticScreeningTypes.GeneticConsult:
                configControls = [
                    'GeneticConsultSentToCounselorDate',
                    'GeneticConsultPerformedDate',
                    'GeneticConsultReportReceivedDate',
                    'GeneticConsultReportReceivedBy',
                    'GeneticConsultAcceptedRejected',
                    'GeneticConsultAcceptedRejectedDate',
                    'GeneticConsultAcceptedRejectedBy',
                ];
                break;
            default:
                break;
        }
        return configControls;
    }

    getDocuments(): void {
        this.uploadDocumentsService.getGeneticScreeningDocuments(this.entityType, this.entityId).subscribe((documents) => {
            const typeIds = this.documentTypes.map((x) => x.Id);
            this.documentArray = documents.Value.filter((doc) => typeIds.includes(doc.DocumentTypeId));
        });
    }

    save(): void {
        let geneticScreeningFormValue = this.form.value.GeneticScreening;
        if (this.currentlyEditedGeneticScreeningDocumentId) {
            this.updateGeneticScreening(geneticScreeningFormValue);
        } else {
            this.saveNewGeneticScreening(geneticScreeningFormValue);
        }
    }

    private saveNewGeneticScreening(geneticScreeningFormValue: any): void {
        this.isUploading = true;
        let geneticScreening = this.normalizeGeneticScreening(geneticScreeningFormValue);
        let geneticScreeningTypeId = geneticScreening.GeneticScreeningTypeId;
        const createCall = this.tempFile
            ? this.uploadDocumentsService.saveDocument(
                  this.entityType,
                  this.entityId,
                  this.documentTypeSelected.Id,
                  this.tempFile._file,
                  geneticScreeningTypeId,
              )
            : this.uploadDocumentsService.createWithoutUpload(this.entityType, this.entityId, this.documentTypeSelected.Id);

        createCall.subscribe((response) => {
            if (!this.tempFile) {
                let geneticScreeningDocumentId = response;
                this.saveNewGeneticScreeningCall(geneticScreening, geneticScreeningDocumentId);
            } else {
                let documentId = response;
                this.uploadDocumentsService.getGeneticScreeningDocuments(this.entityType, this.entityId).subscribe((documents) => {
                    this.documentArray = documents.Value;
                    let geneticScreeningDocumentId = this.documentArray.find((x) => x.DocumentId === documentId).Id;
                    this.saveNewGeneticScreeningCall(geneticScreening, geneticScreeningDocumentId);
                });
            }
        });
    }

    private saveNewGeneticScreeningCall(geneticScreening: IGeneticScreening, geneticScreeningDocumentId: number): void {
        geneticScreening.DocumentEntityId = geneticScreeningDocumentId;
        this.geneticScreeningService.create(geneticScreening).subscribe(() => {
            this.success('Genetic Screening Document successfully uploaded');
        });
    }

    private updateGeneticScreening(geneticScreeningFormValue: any): void {
        let geneticScreening = this.normalizeGeneticScreening(geneticScreeningFormValue);
        geneticScreening.Id = this.currentlyEditedGeneticScreeningId;
        geneticScreening.DocumentEntityId = this.currentlyEditedGeneticScreeningDocumentId;
        if (this.tempFile) {
            this.uploadDocumentsService
                .updateDocumentEntity(this.entityType, this.entityId, geneticScreening.DocumentEntityId, this.tempFile._file)
                .subscribe(() => {
                    this.updateGeneticScreeningCall(geneticScreening);
                });
        } else {
            this.updateGeneticScreeningCall(geneticScreening);
        }
    }

    private updateGeneticScreeningCall(geneticScreening: IGeneticScreening): void {
        this.geneticScreeningService.update(geneticScreening).subscribe(() => {
            this.success('Genetic Screening Document successfully updated');
        });
    }

    private normalizeGeneticScreening(geneticScreeningFormValue: any): IGeneticScreening {
        let geneticScreening = this.serializeDateData(geneticScreeningFormValue);

        geneticScreening.GeneticScreeningAcceptedRejected =
            geneticScreeningFormValue.GeneticScreeningAcceptedRejected === 2 ? false : true;
        geneticScreening.GeneticsLabsAcceptedRejected =
            geneticScreeningFormValue.GeneticsLabsAcceptedRejected === 2 ? false : true;
        geneticScreening.CarrierTestingAcceptedRejected =
            geneticScreeningFormValue.CarrierTestingAcceptedRejected === 2 ? false : true;
        geneticScreening.GeneticConsultAcceptedRejected =
            geneticScreeningFormValue.GeneticConsultAcceptedRejected === 2 ? false : true;
        if (this.geneticScreeningType !== GeneticScreeningTypes.GeneticConsult) {
            geneticScreening.GeneticConsultAcceptedRejected = null;
        }
        if (this.geneticScreeningType !== GeneticScreeningTypes.CarrierTesting) {
            geneticScreening.CarrierTestingAcceptedRejected = null;
        }
        if (this.geneticScreeningType) {
            geneticScreening.GeneticsLabsAcceptedRejected = null;
            geneticScreening.GeneticScreeningAcceptedRejected = null;
        }

        return geneticScreening;
    }

    deleteDocument(documentId: number): void {
        let geneticScreening = (this.documentArray.find((x) => x.DocumentId === documentId) as any)?.GeneticScreenings[0];
        if (geneticScreening) {
            this.geneticScreeningService.delete(geneticScreening.Id).subscribe(() => {
                this.uploadDocumentsService.deleteDocument(this.entityType, this.entityId, documentId).subscribe(() => {
                    this.getDocuments();
                    this.onGeneticTestsChange.emit();
                });
            });
        } else {
            this.uploadDocumentsService.deleteDocument(this.entityType, this.entityId, documentId).subscribe(() => {
                this.getDocuments();
                this.onGeneticTestsChange.emit();
            });
        }
    }

    serializeDateData(form: IGeneticScreening): IGeneticScreening {
        form.GeneticsLabsSentDate = form.GeneticsLabsSentDate ? this.appendDateAuditInfo(this.convertToISO(form.GeneticsLabsSentDate)) : null;
        form.GeneticsLabsReceivedDate = form.GeneticsLabsReceivedDate
            ? this.appendDateAuditInfo(this.convertToISO(form.GeneticsLabsReceivedDate))
            : null;
        form.GeneticsLabsPerformedByDate = form.GeneticsLabsPerformedByDate
            ? this.appendDateAuditInfo(this.convertToISO(form.GeneticsLabsPerformedByDate))
            : null;
        form.GeneticsLabsApprovedDate = form.GeneticsLabsApprovedDate
            ? this.appendDateAuditInfo(this.convertToISO(form.GeneticsLabsApprovedDate))
            : null;
        form.GeneticScreeningAcceptedRejectedDate = form.GeneticScreeningAcceptedRejectedDate
            ? this.appendDateAuditInfo(this.convertToISO(form.GeneticScreeningAcceptedRejectedDate))
            : null;
        form.GeneticConsultSentToCounselorDate = form.GeneticConsultSentToCounselorDate
            ? this.appendDateAuditInfo(this.convertToISO(form.GeneticConsultSentToCounselorDate))
            : null;
        form.GeneticConsultPerformedDate = form.GeneticConsultPerformedDate
            ? this.appendDateAuditInfo(this.convertToISO(form.GeneticConsultPerformedDate))
            : null;
        form.GeneticConsultReportReceivedDate = form.GeneticConsultReportReceivedDate
            ? this.appendDateAuditInfo(this.convertToISO(form.GeneticConsultReportReceivedDate))
            : null;
        form.CarrierTestingDrawDate = form.CarrierTestingDrawDate
            ? this.appendDateAuditInfo(this.convertToISO(form.CarrierTestingDrawDate))
            : null;
        form.CarrierTestingResultsReceivedDate = form.CarrierTestingResultsReceivedDate
            ? this.appendDateAuditInfo(this.convertToISO(form.CarrierTestingResultsReceivedDate))
            : null;
        form.CarrierTestingAcceptedRejectedDate = form.CarrierTestingAcceptedRejectedDate
            ? this.appendDateAuditInfo(this.convertToISO(form.CarrierTestingAcceptedRejectedDate))
            : null;
        form.GeneticConsultAcceptedRejectedDate = form.GeneticConsultAcceptedRejectedDate
            ? this.appendDateAuditInfo(this.convertToISO(form.GeneticConsultAcceptedRejectedDate))
            : null;
        form.GeneticScreeningTypeId = this.geneticScreeningType;
        return form;
    }

    appendDateAuditInfo(formDate: string): string {
        const currentUser = this.authService.currentUser.getValue();
        return `{"AuditDate":"${new Date().toISOString()}","AuditUserId":"${currentUser.Id}","AuditUserName":"${
            currentUser.Name
        }","Date":"${formDate}"}`;
    }

    convertToISO(date: string): string {
        return new Date(date).toISOString();
    }

    editGeneticScreening(doc: IGeneticScreeningDocument): void {
        this.geneticScreeningType = doc.GeneticScreenings[0].GeneticScreeningTypeId;
        this.currentlyEditedGeneticScreeningId = doc.GeneticScreenings[0].Id;
        this.currentlyEditedGeneticScreeningDocumentId = doc.Id;
        this.currentlyEditedFileName = doc.Document?.Name || null;
        this.tempFile = null;
        this.showModal = true;
        this.form = this.fb.group({});
        this.setConfigForUpdate(doc);
    }

    deleteGeneticScreeningDocument(doc: IGeneticScreeningDocument): void {
        if (doc.DocumentId) {
            super.deleteDocument(doc.DocumentId);
        } else {
            this.uploadDocumentsService.deleteWithoutDocument(this.entityType, this.entityId, doc.Id).subscribe(() => {
                this.success('Genetic Screening Document Deleted');
            });
        }
    }

    closePreview(): void {
        super.closePreview();
        this.currentlyEditedFileName = null;
        this.currentlyEditedGeneticScreeningDocumentId = null;
        this.currentlyEditedGeneticScreeningId = null;
    }

    success(message: string): void {
        this.getDocuments();
        this.closePreview();
        this.currentlyEditedGeneticScreeningId = null;
        this.currentlyEditedGeneticScreeningDocumentId = null;
        this.tempFile = null;
        this.notificationService.success(message);
        this.onGeneticTestsChange.emit();
    }
}
