import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ApplicationSsnService } from '@common/services/application-ssn.service';
import { DonorSsnService } from '@common/services/donor-ssn.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { IDonorBasicInfo } from '@model/interfaces/donor-basic-info';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ApplicationService } from '../../applications/services/application.service';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';

@Component({
    selector: 'custom-ssn',
    template: `
        <div class="display-padded ng-star-inserted">
            <ng-container *ngIf="!isEditing">
                <b>Social Security Number: </b>
                <span>{{ formattedSsn }}</span>
            </ng-container>
            <ng-container *ngIf="canViewEdit && isEditing" class="col-md-12">
                <div class="row">
                    <dynamic-field class="col-md-10" [field]="field" [form]="form"> </dynamic-field>
                    <div class="col-md-2">
                        <span (click)="save()">
                            <i class="fa fa-lg fa-floppy-o"></i>
                        </span>
                        <span (click)="cancel()">
                            <i class="fa fa-lg fa-times"></i>
                        </span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="canViewEdit && !isEditing">
                <span *ngIf="showSsn && ssn" (click)="hideSsn()">
                    <i class="fa fa-lg fa-eye-slash"></i>
                </span>
                <span *ngIf="!showSsn && ssn" (click)="getSsn()">
                    <i class="fa fa-lg fa-eye"></i>
                </span>
                <span *ngIf="showEdit" (click)="edit()">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
            </ng-container>
        </div>
    `,
})
export class CustomSsnComponent {
    @Input() ssn: string;
    @Input() id: number;
    @Input() type: 'app' | 'donor';
    @Input() form: FormGroup;

    formattedSsn: string;
    showSsn = false;
    canViewEdit = false;
    isEditing = false;

    field: DynamicField;

    private rawSsn: string;

    get showEdit(): boolean {
        return this.canViewEdit;
    }

    constructor(
        private appService: ApplicationService,
        private donorSsnService: DonorSsnService,
        private applicationSsnService: ApplicationSsnService,
        private claimService: ClaimsService,
        private notificationsService: NotificationsService,
    ) {
        this.canViewEdit = this.claimService.hasClaim(ClaimTypes.ViewEditSSN, [ClaimValues.FullAccess]);
    }

    ngOnInit(): void {
        if (this.ssn) {
            this.formatSsn(this.ssn);
        }
    }

    formatSsn(ssn): void {
        this.formattedSsn = ssn.slice(0, 3) + '-' + ssn.slice(3, 5) + '-' + ssn.slice(5);
    }

    getSsn(): void {
        if (this.rawSsn) {
            this.formatSsn(this.rawSsn);
            this.showSsn = true;
        } else {
            if (this.type === 'app') {
                this.appService.getApplicationSsn(this.id).subscribe((data) => {
                    this.rawSsn = data;
                    this.formatSsn(data);
                    this.showSsn = true;
                });
            }
            if (this.type === 'donor') {
                this.donorSsnService.getDonorSsn(this.id).subscribe((data) => {
                    this.rawSsn = data;
                    this.formatSsn(data);
                    this.showSsn = true;
                });
            }
        }
    }

    hideSsn(): void {
        this.formatSsn('*********');
        this.showSsn = false;
    }

    edit(): void {
        if (this.canViewEdit) {
            if (this.rawSsn) {
                this.buildSsnField();
            } else if (this.type === 'donor') {
                this.donorSsnService.getDonorSsn(this.id).subscribe((data) => {
                    this.rawSsn = data;
                    this.buildSsnField();
                });
            } else {
                this.applicationSsnService.getApplicationSsn(this.id).subscribe((data) => {
                    this.rawSsn = data;
                    this.buildSsnField();
                });
            }
        }
    }

    buildSsnField(): void {
        const formGroupName: string = this.type === 'donor' ? 'DonorBasicInfo' : 'ApplicationWhoAreYou';
        this.field = new DynamicField({
            formGroup: formGroupName,
            label: 'Social Security Number',
            name: 'Ssn',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(9), Validators.minLength(9)],
            validators: { required: true, maxlength: 9, minlength: 9 },
            value: this.rawSsn,
        });
        this.isEditing = true;
    }

    save(): void {
        if (this.form.valid) {
            if (this.type === 'donor') {
                const saveData = {
                    Id: this.id,
                    Ssn: this.form.value.DonorBasicInfo.Ssn,
                } as IDonorBasicInfo;
                this.donorSsnService.updateDonorSsn(this.id, saveData).subscribe((resp) => {
                    this.rawSsn = saveData.Ssn;
                    this.isEditing = false;
                    this.notificationsService.success('Social security number successfully updated');
                });
            } else {
                const saveData = {
                    Id: this.id,
                    Ssn: this.form.value.ApplicationWhoAreYou.Ssn,
                } as IApplicationWhoAreYou;
                this.applicationSsnService.updateApplicationSsn(this.id, saveData).subscribe((resp) => {
                    this.rawSsn = saveData.Ssn;
                    this.isEditing = false;
                    this.notificationsService.success('Social security number successfully updated');
                });
            }
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
        }
    }

    cancel(): void {
        this.isEditing = false;
    }
}
