import { IAddress } from '@model/interfaces/base';
import { IClinicDto } from '@model/interfaces/custom/clinic-dto';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';
import { format } from '@mt-ng2/format-functions';
import { DeleteClinicComponent } from './delete-clinic.component';

export class ClinicsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessorFunction: function(clinic: IClinicDto): string {
                        return clinic.Address ? format.address(<IAddress>clinic.Address) : null;
                    },
                    name: 'Address',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Phone'],
                    name: 'Phone',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Email'],
                    name: 'Email',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function(clinic: IClinicDto): string {
                        return clinic.ApprovedMonitoringClinic ? 'Yes' : 'No';
                    },
                    name: 'Approved Monitoring Clinic',
                    sort: {
                        sortProperty: 'ApprovedMonitoringClinic',
                    },
                }),
                new EntityListColumn({
                    component: DeleteClinicComponent,
                    excludeFromExport: true,
                    name: 'Delete',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
