import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IVitMediaType } from '@model/interfaces/vit-media-type';

@Injectable({
    providedIn: 'root',
})
export class VitMediaTypeService extends MetaItemService<IVitMediaType> {
    constructor(public http: HttpClient) {
        super('VitMediaTypeService', 'Type', 'TypeIds', '/vit-media-types', http);
    }
}
