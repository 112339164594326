import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { ManagedListRoutingModule } from './managed-list-routing.module';

import { ManagedListComponent } from './managed-list.component';

@NgModule({
    declarations: [ManagedListComponent],
    imports: [SharedModule, ManagedListRoutingModule],
})
export class ManagedListModule {
    static forRoot(): any {
        return {
            ngModule: ManagedListModule,
        };
    }
}
