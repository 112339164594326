import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IClinicContact } from '../interfaces/clinic-contact';

export interface IClinicContactDynamicControlsParameters {
    formGroup?: string;
}

export class ClinicContactDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private cliniccontact?: IClinicContact, additionalParameters?: IClinicContactDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ClinicContact';

        this.Form = {
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(75), Validators.email ],
                validators: { 'maxlength': 75, 'email': true },
                value: this.cliniccontact && this.cliniccontact.hasOwnProperty('Email') && this.cliniccontact.Email !== null ? this.cliniccontact.Email.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.cliniccontact && this.cliniccontact.hasOwnProperty('FirstName') && this.cliniccontact.FirstName !== null ? this.cliniccontact.FirstName.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.cliniccontact && this.cliniccontact.hasOwnProperty('LastName') && this.cliniccontact.LastName !== null ? this.cliniccontact.LastName.toString() : '',
            }),
            MiddleInitial: new DynamicField({
                formGroup: this.formGroup,
                label: 'Middle Initial',
                name: 'MiddleInitial',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.cliniccontact && this.cliniccontact.hasOwnProperty('MiddleInitial') && this.cliniccontact.MiddleInitial !== null ? this.cliniccontact.MiddleInitial.toString() : '',
            }),
        };

        this.View = {
            Email: new DynamicLabel(
                'Email',
                this.cliniccontact && this.cliniccontact.hasOwnProperty('Email') && this.cliniccontact.Email !== null ? this.cliniccontact.Email.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            FirstName: new DynamicLabel(
                'First Name',
                this.cliniccontact && this.cliniccontact.hasOwnProperty('FirstName') && this.cliniccontact.FirstName !== null ? this.cliniccontact.FirstName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastName: new DynamicLabel(
                'Last Name',
                this.cliniccontact && this.cliniccontact.hasOwnProperty('LastName') && this.cliniccontact.LastName !== null ? this.cliniccontact.LastName.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MiddleInitial: new DynamicLabel(
                'Middle Initial',
                this.cliniccontact && this.cliniccontact.hasOwnProperty('MiddleInitial') && this.cliniccontact.MiddleInitial !== null ? this.cliniccontact.MiddleInitial.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
