import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IClinic } from '../interfaces/clinic';
import { IClinicVerification } from '../interfaces/clinic-verification';

export interface IClinicDynamicControlsParameters {
    formGroup?: string;
    clinicVerifications?: IClinicVerification[];
}

export class ClinicDynamicControls {

    formGroup: string;
    clinicVerifications: IClinicVerification[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private clinic?: IClinic, additionalParameters?: IClinicDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Clinic';
        this.clinicVerifications = additionalParameters && additionalParameters.clinicVerifications || undefined;

        this.Form = {
            ApprovedForRetrievals: new DynamicField({
                formGroup: this.formGroup,
                label: 'Approved For Retrievals',
                name: 'ApprovedForRetrievals',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.clinic && this.clinic.hasOwnProperty('ApprovedForRetrievals') && this.clinic.ApprovedForRetrievals !== null ? this.clinic.ApprovedForRetrievals : false,
            }),
            ApprovedMonitoringClinic: new DynamicField({
                formGroup: this.formGroup,
                label: 'Approved Monitoring Clinic',
                name: 'ApprovedMonitoringClinic',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.clinic && this.clinic.hasOwnProperty('ApprovedMonitoringClinic') && this.clinic.ApprovedMonitoringClinic !== null ? this.clinic.ApprovedMonitoringClinic : false,
            }),
            ClinicVerificationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Clinic Verification',
                name: 'ClinicVerificationId',
                options: this.clinicVerifications,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.clinic && this.clinic.ClinicVerificationId || null,
            }),
            Cost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cost',
                name: 'Cost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.clinic && this.clinic.hasOwnProperty('Cost') && this.clinic.Cost !== null ? this.clinic.Cost.toString() : '',
            }),
            LastVerified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Verified',
                name: 'LastVerified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.clinic && this.clinic.hasOwnProperty('LastVerified') && this.clinic.LastVerified !== null ? this.clinic.LastVerified.toString() : '',
            }),
            MonitoringContact: new DynamicField({
                formGroup: this.formGroup,
                label: 'Monitoring Contact',
                name: 'MonitoringContact',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.clinic && this.clinic.hasOwnProperty('MonitoringContact') && this.clinic.MonitoringContact !== null ? this.clinic.MonitoringContact.toString() : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.clinic && this.clinic.hasOwnProperty('Name') && this.clinic.Name !== null ? this.clinic.Name.toString() : '',
            }),
        };

        this.View = {
            ApprovedForRetrievals: new DynamicLabel(
                'Approved For Retrievals',
                this.clinic && this.clinic.hasOwnProperty('ApprovedForRetrievals') && this.clinic.ApprovedForRetrievals !== null ? this.clinic.ApprovedForRetrievals : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ApprovedMonitoringClinic: new DynamicLabel(
                'Approved Monitoring Clinic',
                this.clinic && this.clinic.hasOwnProperty('ApprovedMonitoringClinic') && this.clinic.ApprovedMonitoringClinic !== null ? this.clinic.ApprovedMonitoringClinic : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ClinicVerificationId: new DynamicLabel(
                'Clinic Verification',
                getMetaItemValue(this.clinicVerifications, this.clinic && this.clinic.hasOwnProperty('ClinicVerificationId') && this.clinic.ClinicVerificationId !== null ? this.clinic.ClinicVerificationId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Cost: new DynamicLabel(
                'Cost',
                this.clinic && this.clinic.hasOwnProperty('Cost') && this.clinic.Cost !== null ? this.clinic.Cost.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            LastVerified: new DynamicLabel(
                'Last Verified',
                this.clinic && this.clinic.hasOwnProperty('LastVerified') && this.clinic.LastVerified !== null ? this.clinic.LastVerified.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MonitoringContact: new DynamicLabel(
                'Monitoring Contact',
                this.clinic && this.clinic.hasOwnProperty('MonitoringContact') && this.clinic.MonitoringContact !== null ? this.clinic.MonitoringContact.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.clinic && this.clinic.hasOwnProperty('Name') && this.clinic.Name !== null ? this.clinic.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
