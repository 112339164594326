import { EntityListConfig, SortDirection, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IReportUser } from '@mt-ng2/advanced-reporting-module-config';

import { MyReportActionsComponent } from './my-report-actions.component';
import { IReport } from '../model/interfaces/report';

export class MyReportsEntityListConfig extends EntityListConfig {
    constructor(private users: IReportUser[]) {
        super({
            columns: [
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Desc,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Dataset', 'Name'],
                    name: 'Dataset',
                }),
                new EntityListColumn({
                    accessorFunction: null,
                    name: 'Owner',
                }),
                new EntityListColumn({
                    component: MyReportActionsComponent,
                    name: '',
                    sort: {
                        disableSort: true,
                    },
                    style: { width: 235 },
                }),
            ],
        });

        this.columns.find((column) => {
            return column.name === 'Owner';
        }).accessorFunction = this.getOwnerName.bind(this);

        this.rowClass = (entity: IReport) => (entity.Archived ? 'archived' : null);
    }

    private getOwnerName(entity: IReport): string {
        const owner = this.users.find((user) => user.Id === entity.OwnerId);
        return owner?.Name ?? '';
    }
}
