<div *ngIf="isReady">
    <div
        class="miles-editable miles-card padded"
        *ngIf="!isEditing && clinicVerification.Id > 0"
        [style.cursor]="canEdit ? 'pointer' : 'default'"
    >
        <h4>
            Clinic Verification Info
            <span class="pull-right" *ngIf="canEdit" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h4>
        <button type="button" class="btn btn-flat btn-primary" (click)="sendRemoteVerificationEmail()">Remote Verification Email</button>
        <button type="button" class="btn btn-flat btn-primary" (click)="sendReverificationEmail()">Re-Verification Email</button>
        <br />
        <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
    </div>
    <div *ngIf="isEditing" class="miles-form padded">
        <h4>Clinic Verification Info</h4>
        <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </app-dynamic-form>
    </div>
</div>

<send-email-template (ready)="emailModalApi = $event"></send-email-template>
