import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { INotificationOptionsDto } from '@model/interfaces/custom/notification-options-dto';
import { INotification } from '@model/interfaces/notification';
import { Observable } from 'rxjs';
import { AppNotificationTypes } from '@model/enums/app-notification-types.enum';

@Injectable({
    providedIn: 'root',
})
export class NotificationService extends MetaItemService<INotification> {
    constructor(public http: HttpClient) {
        super('NotificationService', 'Notification', 'NotificationIds', '/notifications', http);
    }

    getPopulatedNotificationTemplate(templateId: number, options: INotificationOptionsDto): Observable<INotification> {
        return this.http.post<INotification>(`/notifications/templates/${templateId}`, options);
    }

    // Used to populate select templates
    getNotificationsTemplatesByType(notificationTypeId: AppNotificationTypes): Observable<INotification[]> {
        return this.http.get<INotification[]>(`/notifications/type/${notificationTypeId}`);
    }
}
