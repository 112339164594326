import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IInventoryVessel } from '../interfaces/inventory-vessel';

export interface IInventoryVesselDynamicControlsParameters {
    formGroup?: string;
}

export class InventoryVesselDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private inventoryvessel?: IInventoryVessel, additionalParameters?: IInventoryVesselDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'InventoryVessel';

        this.Form = {
            Level: new DynamicField({
                formGroup: this.formGroup,
                label: 'Level',
                name: 'Level',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.inventoryvessel && this.inventoryvessel.hasOwnProperty('Level') && this.inventoryvessel.Level !== null ? this.inventoryvessel.Level.toString() : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.inventoryvessel && this.inventoryvessel.hasOwnProperty('Name') && this.inventoryvessel.Name !== null ? this.inventoryvessel.Name.toString() : '',
            }),
            Number: new DynamicField({
                formGroup: this.formGroup,
                label: 'Number',
                name: 'Number',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.inventoryvessel && this.inventoryvessel.hasOwnProperty('Number') && this.inventoryvessel.Number !== null ? this.inventoryvessel.Number.toString() : '',
            }),
        };

        this.View = {
            Level: new DynamicLabel(
                'Level',
                this.inventoryvessel && this.inventoryvessel.hasOwnProperty('Level') && this.inventoryvessel.Level !== null ? this.inventoryvessel.Level.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.inventoryvessel && this.inventoryvessel.hasOwnProperty('Name') && this.inventoryvessel.Name !== null ? this.inventoryvessel.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Number: new DynamicLabel(
                'Number',
                this.inventoryvessel && this.inventoryvessel.hasOwnProperty('Number') && this.inventoryvessel.Number !== null ? this.inventoryvessel.Number.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
