import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IShipper } from '@model/interfaces/shipper';

@Injectable({
    providedIn: 'root',
})
export class ShipperService extends MetaItemService<IShipper> {
    constructor(public http: HttpClient) {
        super('ShipperService', 'Shipper', 'ShipperIds', '/shippers', http);
    }
}
