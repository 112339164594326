import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IEmail } from './email.interface';
import { EmailDynamicControls, IEmailDynamicControlsParameters } from './email.form-controls';

export class EmailDynamicConfig<T extends IEmail> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private email: T, private configControls?: string[]) {
        super();
        const additionalParams: IEmailDynamicControlsParameters = {};
        const dynamicControls = new EmailDynamicControls(this.email, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['To', 'Subject', 'Body'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
