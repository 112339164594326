import { Observable, of, forkJoin } from 'rxjs';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';

import { MtSearchFilterDaterangeComponent } from '@mt-ng2/search-filter-daterange-control';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';
import { IMetaItem } from '@mt-ng2/base-service';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { EntityListConfig, SortDirection } from '@mt-ng2/entity-list-module';
import { ISelectionChangedEvent } from '@mt-ng2/type-ahead-control';

import { AuditLogService } from '../../services/audit-logs.service';
import { AuditLogsEntityConfig } from './audit-logs-list.entity-list-config';
import { EventTypeService } from '../../services/event-types.service';
import { IAuditLogSortProperty } from '../../interfaces/audit-log-sort-property';
import { AuditLogSortProperties } from '../../interfaces/audit-log-sort-properties';
import { IAuditConfiguration } from '../../interfaces/audit-configuration';
import { IEventType } from '../../interfaces/event-type';
import {
    IAuditLogUser,
    IAuditLogModuleConfig,
    IAuditLogUserService,
    AuditLoggingModuleUserServiceToken,
    AuditLoggingModuleConfigToken,
    IAuditLog,
} from '@mt-ng2/audit-logging-module-config';
import { defaultExportConfig, exportLogs } from '../../libraries/export-audit-logs.library';

@Component({
    selector: 'app-audit-logs',
    styleUrls: ['./audit-logs-list.component.less'],
    templateUrl: './audit-logs-list.component.html',
})
export class AuditLogsListComponent implements OnInit {
    title = 'Audit Logs';
    @ViewChild('datePicker', { static: true }) datePicker: MtSearchFilterDaterangeComponent;
    // search
    startDate: Date;
    endDate: Date;
    auditLogUsers: IAuditLogUser[] = [];
    userSearchFilters: MtSearchFilterItem[] = [];
    auditEntities: MtSearchFilterItem[] = [];
    minCharactersToSearchUsers: number;
    // paging
    auditLogs: IAuditLog[];
    total: number;
    currentPage = 1;
    itemsPerPage: number;
    // sort
    entityListConfig: EntityListConfig = AuditLogsEntityConfig;
    order: string = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    sortEnum = SortDirection;
    sortProperties: IAuditLogSortProperty[] = AuditLogSortProperties;

    auditConfigurations: IAuditConfiguration[];
    getUserItems = this._getUserItems.bind(this);

    eventTypes: IEventType[];
    eventTypeFilter: MtSearchFilterItem[] = [];
    recordFilter: MtSearchFilterItem[] = [];
    ignorePageTitleCalls: boolean;
    exportEnabled: boolean;

    constructor(
        private auditLogService: AuditLogService,
        @Inject(AuditLoggingModuleUserServiceToken)
        private auditLogUserService: IAuditLogUserService,
        private eventTypeService: EventTypeService,
        @Inject(AuditLoggingModuleConfigToken)
        private auditLogModuleConfig: IAuditLogModuleConfig,
    ) {
        this.minCharactersToSearchUsers = this.auditLogModuleConfig.minCharactersToSearchUsers;
        this.itemsPerPage = this.auditLogModuleConfig.itemsPerPage;
        this.ignorePageTitleCalls = this.auditLogModuleConfig.ignorePageTitleCalls;
        this.exportEnabled = this.auditLogModuleConfig.exportEnabled;
    }

    ngOnInit(): void {
        this.filterOutFutureContextualDates();
        forkJoin([this.auditLogService.getEntities(), this.eventTypeService.getItems()]).subscribe((forkJoinReturns) => {
            const [entities, eventTypes] = forkJoinReturns;
            this.auditConfigurations = entities;
            this.eventTypes = eventTypes;
            this.getSearchFilters();
            this.getAuditLogs();
        });
    }

    exportAuditLogs(): void {
        const exportConfig = Object.assign({}, defaultExportConfig, this.auditLogModuleConfig.exportConfig);
        exportLogs(this.auditLogs, this.eventTypes, this.auditConfigurations, this.auditLogUsers, exportConfig);
    }

    filterOutFutureContextualDates(): any {
        this.datePicker.contextualDateTypes = this.datePicker.contextualDateTypes.filter((type) => !type.Name.includes('Next'));
    }

    getSearchFilters(): void {
        this.getAuditLogUsers();
        this.getAuditEntitySearchFilters();
        this.eventTypeFilter = this.eventTypes.map((item) => new MtSearchFilterItem(<any>item, false));
    }

    getAuditLogUsers(): void {
        this.auditLogUserService.getUsers().subscribe((users: IAuditLogUser[]) => {
            this.auditLogUsers = users;
            this.getUserSearchFilters();
        });
    }

    private _getUserItems(searchText: string): Observable<IMetaItem[]> {
        let users = this.auditLogUsers
            .filter((auditLogUser: IAuditLogUser) => {
                return auditLogUser.Name.toLowerCase().includes(searchText.toLowerCase());
            })
            .map((auditLogUser: IAuditLogUser) => {
                return {
                    Id: auditLogUser.AuthUserId,
                    Name: auditLogUser.Name,
                };
            });
        return of(users);
    }

    getUserSearchFilters(): void {
        this.userSearchFilters = this.auditLogService.convertMetaItemsToSearchFilters(
            this.auditLogUsers.map((auditLogUser: IAuditLogUser) => {
                return {
                    Id: auditLogUser.AuthUserId,
                    Name: auditLogUser.Name,
                };
            }),
        );
    }

    getAuditEntitySearchFilters(): void {
        let auditEntityMetaItems: IMetaItem[] = this.auditConfigurations
            .filter((auditConfiguration: IAuditConfiguration) => {
                return auditConfiguration.ShowEntity;
            })
            .map((ac, index) => {
                return {
                    Entity: ac.Entity,
                    Id: index,
                    LinkFormatString: ac.LinkFormatString,
                    Name: ac.DisplayName,
                    TypeFullName: ac.TypeFullName,
                };
            });
        this.auditEntities = this.auditLogService.convertMetaItemsToSearchFilters(auditEntityMetaItems);
    }

    getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    dateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.startDate = range.startDate;
        this.endDate = range.endDate;
        this.getAuditLogs();
    }

    usersChanged(event: ISelectionChangedEvent): void {
        this.currentPage = 1;
        let userId = event && event.selection ? event.selection.Id : null;
        this.userSearchFilters.map((usfi) => {
            if (usfi.Item.Id === userId) {
                usfi.Selected = true;
            } else {
                usfi.Selected = false;
            }
            return usfi;
        });
        this.getAuditLogs();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getAuditLogs();
    }

    buildSearch(): ExtraSearchParams[] {
        const selectedUserIds: number[] = this.getSelectedFilters(this.userSearchFilters);
        const selectedEventTypeIds: number[] = this.getSelectedFilters(this.eventTypeFilter);
        const selectedAuditEntityIds: string = this.auditEntities
            .filter((fi) => fi.Selected)
            .map((fi) => (fi as any).Item.Entity)
            .join();
        const selectedRecordIds: number[] = this.getSelectedFilters(this.recordFilter);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'UserIds',
                valueArray: selectedUserIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'EventTypeIds',
                valueArray: selectedEventTypeIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AuditEntityIds',
                value: selectedAuditEntityIds,
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'RecordIds',
                valueArray: selectedRecordIds,
            }),
        );
        if (this.startDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'StartDate',
                    value: this.startDate.toUTCString(),
                }),
            );
        }
        if (this.endDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'EndDate',
                    value: this.endDate.toUTCString(),
                }),
            );
        }

        return _extraSearchParams;
    }

    getAuditLogs(): void {
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.auditLogService.get(searchparams).subscribe((answer) => {
            this.auditLogs = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    setSort(prop: string): void {
        if (this.order === prop) {
            this.orderDirection = this.orderDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
        } else {
            this.order = prop;
            this.orderDirection = SortDirection.Desc;
        }
        this.getAuditLogs();
    }

    entitySelectionChanged(): void {
        const selectedAuditEntities: MtSearchFilterItem[] = this.auditEntities.filter((fi) => fi.Selected);
        if (selectedAuditEntities.length === 1) {
            const typeFullName = selectedAuditEntities[0].Item.TypeFullName;
            this.auditLogService.getRecords(typeFullName).subscribe((answer) => {
                this.recordFilter = this.auditLogService.convertMetaItemsToSearchFilters(answer);
            });
        } else {
            this.recordFilter = [];
        }
    }
}
