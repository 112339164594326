<dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="wrapperForm">
    <br />
    <div class="row">
        <div class="col-md-12">
            <div class="col-xs-9">
                <b>Total #: </b><span>{{ currentValue?.OocytesReceived || 0 }}</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="col-xs-9">
                <label>#MII</label>
                <mt-numeric formControlName="MiiNumber" [type]="numericControlTypes.Integer" [disabled]="isDisabled"> </mt-numeric>
            </div>
        </div>
        <div class="col-md-6">
            <div class="col-xs-9">
                <label>#MI</label>
                <mt-numeric formControlName="MiNumber" [type]="numericControlTypes.Integer" [disabled]="isDisabled"> </mt-numeric>
            </div>
        </div>
        <div class="col-md-6">
            <div class="col-xs-9">
                <label>#GV</label>
                <mt-numeric formControlName="GvNumber" [type]="numericControlTypes.Integer" [disabled]="isDisabled"> </mt-numeric>
            </div>
        </div>
        <div class="col-md-6">
            <div class="col-xs-9">
                <label>#Atretic/Broken/Abnormal</label>
                <mt-numeric formControlName="AtreticBrokenAbnormalNumber" [type]="numericControlTypes.Integer" [disabled]="isDisabled"> </mt-numeric>
            </div>
        </div>
    </div>
</dynamic-form-input-wrapper>
