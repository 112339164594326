import { Component, Input, OnInit, ViewChild, OnDestroy, Injector, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { flattenDataPoints, DatasetDatapointsService } from '../services/dataset-datapoints.service';
import { sortReportColumnsByOrder } from '../services/report-columns.service';
import { GridComponentBase } from '../base-classes/grid.base';
import { IReport } from '../model/interfaces/report';
import { IDatapoint } from '../model/interfaces/datapoint';
import { IReportGroup } from '../model/interfaces/report-group';
import { IReportColumn } from '../model/interfaces/report-column';
import { IColumnDefinition } from '../model/interfaces/column-definition';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'report-columns',
    styleUrls: ['./report-column.styles.css'],
    templateUrl: './report-columns.component.html',
})
export class ReportColumnsComponent extends GridComponentBase implements OnInit, OnDestroy {
    @Input()
    report: IReport;
    @Input()
    canEdit: boolean;
    @Input()
    set dataPoints(value: IDatapoint[]) {
        if (value?.length) {
            this.datapointOptions = flattenDataPoints(value);
        } else {
            this.datapointOptions = null;
        }
    }

    @ViewChild('addColumnDropdown', { static: false }) addColumnDropdown: NgbDropdown;
    @ViewChild('grid', { static: false }) grid: any;
    @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;

    datapointOptions: IDatapoint[] = [];
    groupOptions: IReportGroup[] = [];
    data: any[] = [];

    constructor(injector: Injector, private datasetDatapointsService: DatasetDatapointsService) {
        super(injector);
    }

    ngOnInit(): void {
        this.setColumnDefs();
        this.subscribeToDeletionEvent();
    }

    setColumnDefs(): void {
        sortReportColumnsByOrder(this.report.ReportColumns);
        this.columnDefs = this.report.ReportColumns.map((column: IReportColumn) => {
            let columnDef: IColumnDefinition = {
                cellClass: 'test',
                headerTemplate: this.headerTemplate,
                name: column.DisplayAs,
                prop: column.DatapointPropertyName,
                reportColumn: column,
                reportColumnId: column.Id,
                resizeable: this.canEdit,
                width: column.Width,
            };
            return columnDef;
        });

        this.setData();
    }

    // TODO: JJG MOVE THIS TO THE SERVICE
    createGroup(): IReportGroup {
        return {
            GroupOrder: this.getGroupOrder(this.groupOptions),
            Id: 0,
        };
    }

    getGroupOrder(groups: IReportGroup[]): number {
        return groups.length === 0
            ? 0
            : Math.max.apply(
                  Math,
                  groups.map((o) => {
                      return o.GroupOrder + 1;
                  }),
              );
    }

    setData(): void {
        this.data = this.datasetDatapointsService.generateDummyData(this.datapointOptions, this.columnDefs);

        let columnsReference = this.columnDefs.map((cd: IColumnDefinition) => {
            cd.prop = cd.prop.replace('_', '.');
            return cd;
        });
        this.columnDefs = [...columnsReference];
    }

    selectDataPoint(dataPoint: IDatapoint): void {
        // save the new column

        let newColumn: IReportColumn = {
            DatapointPropertyName: dataPoint.Name,
            DisplayAs: dataPoint.DisplayAs,
            Id: 0,
            Order: this.report.ReportColumns.length + 1, // 1 indexed for order
            ReportId: this.report.Id,
            Width: 200, // default width,
        };
        this.saveReportColumn(newColumn);
        this.addColumnDropdown.close();
    }
}
