import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SalesOrderReservationDonorDynamicControls } from '@model/form-controls/sales-order-reservation-donor.form-controls';
import { SalesOrderReservationDynamicControls } from '@model/form-controls/sales-order-reservation.form-controls';
import { IRecipient } from '@model/interfaces/recipient';

import { ISalesOrderReservation } from '@model/interfaces/sales-order-reservation';
import { IVwDonorAvailableInventory } from '@model/interfaces/vw-donor-available-inventory';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IDynamicField } from '@mt-ng2/dynamic-form';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { SalesOrderReservationService } from '../services/sales-order-reservation.service';

@Component({
    templateUrl: './sales-order-reservation-add.component.html',
})
export class SalesOrderReservationAddComponent implements OnInit {
    salesOrderReservation: ISalesOrderReservation;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    abstractSalesOrderReservationDonorControls: any;

    reservationForm: FormGroup;
    donorsFormArray: FormArray;
    donorsControl: any;
    doubleClickIsDisabled = false;
    formReady = false;
    donorDynamicControls = SalesOrderReservationDonorDynamicControls;

    recipientSelected: IRecipient;

    currentUser: ILoggedIn;

    eggCountField: IDynamicField;

    showDonorSearch = true;

    constructor(
        private router: Router,
        private salesOrderReservationService: SalesOrderReservationService,
        private notificationsService: NotificationsService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.authService.currentUser.subscribe((user) => {
            this.currentUser = user;
        });
        this.salesOrderReservation = this.salesOrderReservationService.getEmptySalesOrderReservation();
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.reservationForm = this.assignFormGroups();
        this.donorsControl = (this.reservationForm.controls.SalesOrderReservationDonor as FormArray).controls;
        this.formReady = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractSalesOrderReservationDonorControls = new SalesOrderReservationDynamicControls(null, {
            formGroup: 'SalesOrderReservation',
        }).Form;
    }

    assignFormGroups(): FormGroup {
        this.donorsFormArray = this.fb.array([]);
        return this.fb.group({
            SalesOrderReservation: this.fb.group({}),
            SalesOrderReservationDonor: this.donorsFormArray,
        });
    }

    onDonorSelected(value: IVwDonorAvailableInventory): void {
        const fg = this.fb.group(value);
        this.donorsFormArray.push(fg);
        this.cdr.detectChanges();
        this.showDonorSearch = false;
    }

    deleteDonor(index: number): void {
        this.donorsFormArray.removeAt(index);
        if (this.donorsFormArray.length === 0) {
            this.showDonorSearch = true;
        }
    }

    onRecipientSelected(value: IRecipient): void {
        this.recipientSelected = value;
    }

    changeRecipient(): void {
        this.recipientSelected = null;
    }

    formSubmitted(): void {
        if (this.reservationForm.valid) {
            if (!this.reservationForm.value.SalesOrderReservationDonor.length || !this.recipientSelected) {
                markAllFormFieldsAsTouched(this.reservationForm);
                this.notificationsService.error('Donor and recipient must be selected');
                this.enableDoubleClick();
            } else {
                this.buildSoReservationForSave();
                this.createSalesOrderReservation();
            }
        } else {
            markAllFormFieldsAsTouched(this.reservationForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    buildSoReservationForSave(): void {
        Object.assign(this.salesOrderReservation, this.reservationForm.value.SalesOrderReservation);
        this.salesOrderReservation.RecipientId = this.recipientSelected.Id;
        this.salesOrderReservation.CreatedDate = new Date();
        this.salesOrderReservation.CreatedById = this.currentUser.Id;
        this.salesOrderReservation.SalesOrderReservationDonors = this.reservationForm.value.SalesOrderReservationDonor.map((d) => {
            return { DonorId: d.Id, EggCountReserved: d.EggCountReserved };
        });
     }

    createSalesOrderReservation(): void {
        this.salesOrderReservationService
            .createWithFks(this.salesOrderReservation)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((answer) => {
                this.success();
            });
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Reservation saved successfully.');
        this.router.navigate(['reservations']);
    }
}
