import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { ClinicService } from '../services/clinic.service';
import { ClinicDynamicConfig } from '../clinic.dynamic-config';

import { IClinic } from '@model/interfaces/clinic';

@Component({
    selector: 'app-clinic-donor-monitoring',
    templateUrl: './clinic-donor-monitoring.component.html',
})
export class ClinicDonorMonitoringComponent implements OnInit {
    @Input() clinic: IClinic;
    @Input() canEdit: boolean;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: ClinicDynamicConfig<IClinic>;
    doubleClickIsDisabled = false;

    constructor(private clinicService: ClinicService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new ClinicDynamicConfig<IClinic>(
            this.clinic,
            ['ApprovedMonitoringClinic', 'MonitoringContact', 'Cost', 'LastVerified'],
        );
        this.config = this.formFactory.getForUpdate();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.clinic, form.value.Clinic);
            this.clinicService
            .update(this.clinic)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(() => {
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.clinicService.emitChange(this.clinic);
        this.notificationsService.success('Clinic saved successfully.');
    }
}
