import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InventoryVesselService } from '@common/components/inventory-vessels/inventory-vessel.service';
import { InventoryTypes } from '@model/enums/inventory-types.enum';
import { IInventoryDto } from '@model/interfaces/custom/inventory-dto';
import { IInventoryPositionDto } from '@model/interfaces/custom/inventory-position-dto';
import { IInventory } from '@model/interfaces/inventory';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { InventoryDynamicConfig } from './egg-inventory.dynamic-config';

@Component({
    selector: 'donor-inventory-location-form',
    templateUrl: 'inventory-location-form.component.html',
})
export class InventoryLocationFormComponent implements OnInit {
    @Input() inventory: IInventoryDto;
    @Input() canEdit: boolean;
    @Input() parentId: number; // donor cycle Id (egg) or sales order Id (embryo)
    @Input() donorId: number; // Donor table Id
    @Input() donorNumber: string; // Donor field DonorId

    inventoryPosition: IInventoryPositionDto;

    isEditing = false;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: InventoryDynamicConfig<IInventory>;
    doubleClickIsDisabled = false;
    viewReady = false;

    showConfirm = false;
    confirmOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };

    // Config defaults to Egg
    EmbryoConfigControls = ['StrawNumber', 'CountPerStraw', 'Comments'];

    get isNewEggInventory(): boolean {
        return this.inventory && this.inventory.Id ? false : true;
    }

    constructor(private notificationsService: NotificationsService, private inventoryService: InventoryVesselService) {}

    ngOnInit(): void {
        if (this.isNewEggInventory) {
            this.setConfig();
        } else {
            this.inventoryService.getInventoryPositionByGoblet(this.inventory.GobletId).subscribe((invPosition) => {
                this.inventoryPosition = invPosition;
                this.setConfig();
            });
        }
    }

    setConfig(): void {
        const configControls = this.inventory.InventoryTypeId === InventoryTypes.Embryos ? this.EmbryoConfigControls : null;
        this.formFactory = new InventoryDynamicConfig<IInventory>(this.inventory, configControls);
        if (this.isNewEggInventory) {
            this.config = this.formFactory.getForCreate();
            this.isEditing = true;
        } else {
            this.config = this.formFactory.getForUpdate();
        }
        this.viewReady = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    cancelSave(): void {
        this.showConfirm = false;
        this.doubleClickIsDisabled = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.showConfirm = true;
            this.formFactory.assignFormValues(this.inventory, form.value.Inventory);
            this.inventory.GobletId = this.inventoryPosition.Goblet.Id;
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    saveEggInventory(): void {
        this.showConfirm = false;

        if (this.inventory.StrawNumber === null || this.inventory.StrawNumber === undefined) { this.inventory.StrawNumber = 0; }

        this.inventoryService
            .saveInventoryLocation(this.donorId, this.parentId, this.inventory)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((answer) => {
                Object.assign(this.inventory, answer);
                this.success();
            });
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.notificationsService.success('Egg inventory saved successfully.');
    }
}
