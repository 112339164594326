import { Component, OnInit, Injector, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICurrentReportUser } from '@mt-ng2/advanced-reporting-module-config';
import { sortByProperty } from '@mt-ng2/common-functions';

import { ReportsService } from '../services/reports.service';
import { GridComponentBase } from '../base-classes/grid.base';
import { IReport } from '../model/interfaces/report';
import { IDatapoint } from '../model/interfaces/datapoint';
import { IReportGroupOption } from '../model/interfaces/report-group-option';
import { DatasetDatapointsService, flattenDataPoints } from '../services/dataset-datapoints.service';
import { getCanEdit } from '../libraries/reports.library';
import { ReportGroupOption, ToggleGroups } from '../libraries/report-groups.library';
import { getFiltersAsText } from '../libraries/report-filters.library';
import { IReportColumn } from '../model/interfaces/report-column';
import { IReportFilterGroup } from '../model/interfaces/report-filter-group';
import { IGroupAsOptions } from '../model/interfaces/group-as-options';
import { ReportFormatterService, attachDatapointsToReport } from '../services/report-formatter.service';
import { AdvancedReportingModuleConfig } from '../services/advanced-reporting-module-config.service';
import { getSummaryFunc } from '../libraries/report-aggregates.library';
import { sortReportColumnsByOrder } from '../services/report-columns.service';
import { IColumnDefinition } from '../model/interfaces/column-definition';
import { getComparatorForDataFormat } from '../libraries/custom-column-comparator.library';

@Component({
    styles: [
        `
            .summary-container {
                white-space: normal;
            }
            ::ng-deep .ngx-datatable.material.expandable .datatable-summary-row .datatable-body-row .datatable-body-cell {
                white-space: normal;
            }
            .grid-container {
                padding-top: 8px;
            }
            .refresh-button {
                position: relative;
            }
            .refresh-button .btn {
                position: absolute;
                top: -20px;
                left: 0;
                border-radius: 3px 3px 0 0;
                background-color: #ccc;
                border: 0;
            }
        `,
    ],
    templateUrl: './report-render.component.html',
})
export class ReportRenderComponent extends GridComponentBase implements OnInit {
    reportId: number;
    report: IReport;
    data: any;
    displayFiltersOnRenderedReport: boolean;
    datapoints: IDatapoint[] = [];
    datapointOptions: IDatapoint[] = [];
    filters: IReportFilterGroup[] = [];
    groupsAsText: IReportGroupOption[] = [];
    groupsAsOptions: IGroupAsOptions[] = [];

    @ViewChild('grid', { static: false }) grid: any;
    @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;

    constructor(
        private reportService: ReportsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private claimsService: ClaimsService,
        public notificationsService: NotificationsService,
        private reportingModuleConfig: AdvancedReportingModuleConfig,
        private datasetDatapointsService: DatasetDatapointsService,
        private reportFormatterService: ReportFormatterService,
        injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(this.reportingModuleConfig.reportingClaimTypeId, [ClaimValues.FullAccess]);
        this.displayFiltersOnRenderedReport = this.reportingModuleConfig.displayFiltersOnRenderedReport;
        this.reportId = +this.activatedRoute.parent.snapshot.params.reportId;

        this.reportService.getById(this.reportId).subscribe((report: IReport) => {
            this.getDataPointsAndAttachToReport(report);

            const hasAccessToDataset = this.claimsService.hasClaim(report.Dataset.PermissionId, [ClaimValues.FullAccess]);
            this.handleAccessRouting(hasAccessToDataset, report);
            this.reportService.renderReport(this.reportId).subscribe((answer) => {
                this.data = this.reportFormatterService.formatData(answer, this.datapointOptions);
                // get the total from here and check it out
                this.setColumnDefs(answer.length);
            });

            this.report = report;
            if (report?.Filters) {
                this.filters = JSON.parse(report.Filters);
            }
            if (this.canEdit) {
                const user = this.getCurrentReportUser();
                this.canEdit = getCanEdit(this.report, user.Id, user.RoleId);
            }
            this.subscribeToDeletionEvent();
            this.getSorts();
            this.getAndAssignGroups();
            this.subscribeToColumnsChange();
        });
    }

    setColumnDefs(totalRecordCount = 0): void {
        sortReportColumnsByOrder(this.report.ReportColumns);
        this.hasSummaryRow = this.report.ReportColumns.some((c) => c.ReportAggregate !== null);
        this.columnDefs = this.report.ReportColumns.map((column: IReportColumn) => {
            let columnDef: IColumnDefinition = {
                cellClass: this.getColumnClass(column.DataFormatOptions),
                comparator: getComparatorForDataFormat(column.DataFormatOptions),
                group: column.ReportGroup,
                headerTemplate: this.headerTemplate,
                name: column.DisplayAs,
                prop: column.DatapointPropertyName,
                reportColumn: column,
                reportColumnId: column.Id,
                resizeable: this.canEdit,
                summaryFunc: getSummaryFunc(column, totalRecordCount),
                width: column.Width,
            };
            return columnDef;
        });
        this.groupRowsBy = this.columnDefs
            .filter((cd) => {
                return cd.group !== null;
            })
            .sort((a, b) => {
                return a.group.GroupOrder - b.group.GroupOrder;
            })
            .map((cd) => cd.prop);
    }

    subscribeToColumnsChange(): void {
        this.subscriptions.add(
            this.reportColumnsService.reportColumnsChanged.subscribe(() => {
                if (this.columnDefs) {
                    this.onColumnsChanged();
                }
            }),
        );
    }

    getDataPointsAndAttachToReport(report: IReport): void {
        this.datasetDatapointsService.getDatapoints(report.DatasetId).subscribe((answer: IDatapoint[]) => {
            this.datapoints = answer;
            this.datapointOptions = flattenDataPoints(this.datapoints);
            report = attachDatapointsToReport(report, this.datapointOptions);
        });
    }

    private handleAccessRouting(hasAccessToDataset: boolean, report: IReport): void {
        if (!hasAccessToDataset) {
            this.router.navigate(['/my-reports']);
            this.notificationsService.error(`You do not have access to the ${report.Dataset.Name} dataset.`);
            return;
        }
        if (report.ReportColumns.length === 0) {
            if (this.canEdit) {
                this.router.navigate([`/my-reports/${report.Id}/edit`]);
                this.notificationsService.info(`There are no columns setup for this report.`);
            } else {
                this.router.navigate([`/my-reports/`]);
                this.notificationsService.error(`This report is not setup.`);
            }
        }
    }

    private getCurrentReportUser(): ICurrentReportUser {
        return this.activatedRoute.snapshot.data.currentUser;
    }

    /**
     * This method loops over the Reports.ReportColumns object and looks for
     * any columns that have groups associated to them.  If they do it will create
     * an IReportGroupOption object and add them to the GroupAsText array to be
     * displayed in thre Report-Groups component.  If they don't have a group
     * it is added to the GroupsAsOptions array to be displayed in the DDL.
     */
    getAndAssignGroups(): void {
        this.groupsAsText = [];
        this.groupsAsOptions = [];
        this.report.ReportColumns.forEach((column) => {
            if (column.ReportGroup?.Id > 0) {
                const groupOption: IReportGroupOption = {
                    DatapointPropertyName: column.DatapointPropertyName,
                    GroupOrder: column.ReportGroup.GroupOrder,
                    Id: column.ReportGroup.Id,
                    Name: column.DisplayAs,
                };
                this.groupsAsText.push(new ReportGroupOption(groupOption));
            } else {
                this.groupsAsOptions.push({
                    id: column.Id,
                    name: column.DisplayAs,
                });
            }
        });
        sortByProperty(this.groupsAsText, 'GroupOrder');
    }

    addGroupOption(option: string): void {
        const reportColumn = this.getReportColumn(option);
        if (
            this.groupsAsOptions.indexOf({ id: reportColumn.Id, name: option }) === -1 &&
            this.groupsAsText.findIndex((x) => x.Name === option) === -1
        ) {
            this.groupsAsOptions.push({ id: reportColumn.Id, name: option });
        }
    }

    private getReportColumn(option: string): IReportColumn {
        return this.report.ReportColumns.find((rc) => {
            return rc.DisplayAs === option;
        });
    }

    removeGroupOption(option: string): void {
        const reportColumn = this.getReportColumn(option);
        this.groupsAsOptions.splice(this.groupsAsOptions.indexOf({ id: reportColumn.Id, name: option }), 1);
    }

    refresh(): void {
        this.subscriptions.unsubscribe();
        this.resetData();
        this.ngOnInit();
    }

    edit(): void {
        this.router.navigate([`/my-reports/${this.report.Id}/edit`]);
    }

    resetData(): void {
        this.datapoints = [];
        this.datapointOptions = [];
        this.filters = [];
        this.groupsAsText = [];
        this.groupsAsOptions = [];
    }

    toggleExpandGroup(group): void {
        this.grid.groupHeader.toggleExpandGroup(group);
    }

    exportToCSV(): void {
        this.reportService.exportReportDataToCsv(this.data, this.report);
    }

    getGroupHeaderText(group: any): string {
        const groupValues: string[] = JSON.parse(group.key);
        const groupings: string[] = [];
        this.groupRowsBy.forEach((groupName, index) => {
            let column = this.columnDefs.find((cd) => cd.prop === groupName);
            if (column) {
                groupings.push(`${column.name} - ${groupValues[index]}`);
            }
        });
        return groupings.join(', ');
    }

    filtersAsText(): string {
        const noFiltersMessage = `No filter defined.`;
        if (!this.filters || !this.filters.length) {
            return noFiltersMessage;
        }
        if (this.datapointOptions?.length) {
            let filtersText = getFiltersAsText(this.filters, this.datapointOptions);
            return filtersText;
        }
        return noFiltersMessage;
    }

    removeGroup(reportOption: IReportGroupOption): void {
        this.toggleGroups(ToggleGroups.Remove, reportOption);
    }

    addGroup(column: IReportColumn): void {
        const groupOption: IReportGroupOption = {
            DatapointPropertyName: column.DatapointPropertyName,
            GroupOrder: column.ReportGroup.GroupOrder,
            Id: 0,
            Name: column.DisplayAs,
        };
        this.toggleGroups(ToggleGroups.Add, groupOption);
    }

    /**
     * This function is used to toggle the groups from 1 array to another.
     * When you add a group it needs to be removed from the groupsAsOptions,
     * and added to the groupsAsText.  Then when a group is removed the
     * reverse happens.  This makes it so the user can only add a record to
     * a group once.
     * @param groupToggleType
     * @param groupOption
     */
    toggleGroups(groupToggleType: ToggleGroups, groupOption: IReportGroupOption): void {
        const column = this.report.ReportColumns.find((c) => c.ReportGroup && c.DatapointPropertyName === groupOption.DatapointPropertyName);
        if (groupToggleType === ToggleGroups.Add) {
            this.groupsAsText.push(new ReportGroupOption(groupOption));
            this.removeGroupOption(groupOption.Name);
            column.ReportGroup = groupOption;
        } else if (groupToggleType === ToggleGroups.Remove) {
            this.groupsAsText.splice(this.groupsAsText.indexOf(groupOption), 1);
            this.addGroupOption(groupOption.Name);
            column.ReportGroup = null;
        }
        this.setColumnDefs();
    }

    onColumnsChanged(): void {
        this.groupRowsBy = this.columnDefs
            .filter((cd) => {
                return cd.group !== null;
            })
            .sort((a, b) => {
                return a.group.GroupOrder - b.group.GroupOrder;
            })
            .map((cd) => cd.prop);
        this.getAndAssignGroups();
    }
}
