import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { ClinicVerificationService } from '../services/clinic-verification.service';
import { ClinicVerificationDynamicConfig } from './clinic-verification.dynamic-config';

import { IClinicVerification } from '@model/interfaces/clinic-verification';
import { IClinic } from '@model/interfaces/clinic';
import { UserService } from '../../users/user.service';
import { UserRoles } from '@model/enums/user-roles.enum';
import { IEntitySearchParams, ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IMetaItem } from '@model/interfaces/base';
import { ClinicService } from '../services/clinic.service';
import { TemplateTypes } from '@model/enums/template-type.enum';
import { ISendEmailTemplateModalApi } from '@common/components/send-email/send-email-template.component';
import { IEmail, ISendEmailModalResult } from '@mt-ng2/email-modal-module';
import { INotificationOptionsDto } from '@model/interfaces/custom/notification-options-dto';
import { EmailService } from '@common/services/email.service';
import { ClinicEntityTypeService } from '../services/clinic-entity-type.service';
import { ClinicEntityService } from '../services/clinic-entity.service';
import { ClinicEntityTypes } from '@model/enums/clinic-entity-types.enum';
import { IClinicEntity } from '@model/interfaces/clinic-entity';

@Component({
    selector: 'app-clinic-verification-basic-info',
    templateUrl: './clinic-verification-basic-info.component.html',
})
export class ClinicVerificationBasicInfoComponent implements OnInit {
    @Input() clinic: IClinic;
    @Input() canEdit: boolean;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: ClinicVerificationDynamicConfig<IClinicVerification>;
    doubleClickIsDisabled = false;

    clinicVerification: IClinicVerification;

    clinics: IClinic[];
    users: IMetaItem[];

    isReady = false;

    emailModalApi: ISendEmailTemplateModalApi;

    labContactDetails: IClinicEntity;

    get isNewClinicVerification(): boolean {
        return this.clinicVerification && this.clinicVerification.Id ? false : true;
    }

    constructor(
        private clinicVerificationService: ClinicVerificationService,
        private notificationsService: NotificationsService,
        private userService: UserService,
        private clinicService: ClinicService,
        private emailService: EmailService,
        private clinicEntityService: ClinicEntityService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.getUsers(), this.clinicEntityService.getByClinicId(this.clinic.Id, ClinicEntityTypes.LabShippingAddress)]).subscribe(
            ([users, labContact]) => {
                this.users = users;
                this.labContactDetails = labContact;
                this.clinicVerification = this.clinic.ClinicVerification;
                if (!this.clinicVerification) {
                    this.clinicVerification = this.clinicVerificationService.getEmptyClinicVerification();
                }
                this.setConfig();
            },
        );
    }

    getUsers(): Observable<IMetaItem[]> {
        const searchEntity: IEntitySearchParams = {
            extraParams: [
                new ExtraSearchParams({
                    name: 'ExcludeRoleIds',
                    valueArray: [UserRoles.Donor],
                }),
            ],
            order: 'FirstName',
            orderDirection: 'ASC',
            query: null,
        };
        const searchparams = new SearchParams(searchEntity);

        return this.userService.getMetaItems(searchparams).pipe(
            map((answer) => answer.body),
        );
    }

    setConfig(): void {
        this.formFactory = new ClinicVerificationDynamicConfig<IClinicVerification>(this.clinicVerification, { users: this.users });

        if (this.isNewClinicVerification) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
        this.isReady = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.clinicVerification, form.value.ClinicVerification);
            this.saveClinicVerification();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            this.enableDoubleClick();
        }
    }

    sendReverificationEmail(): void {
        this.getPopulatedEmailTemplate(TemplateTypes.ReverificationEmail);
    }

    sendRemoteVerificationEmail(): void {
        this.getPopulatedEmailTemplate(TemplateTypes.RemoteVerificationDetailEmail);
    }

    getPopulatedEmailTemplate(templateId: number): void {
        const email = this.labContactDetails?.ClinicContact?.Email ? this.labContactDetails.ClinicContact.Email : '';
        this.emailModalApi.show(email, templateId, { ClinicId: this.clinic.Id }).subscribe((response: ISendEmailModalResult) => {
            if (!response.cancelled) {
                this.sendEmail(response.email, templateId);
            }
            this.enableDoubleClick();
        });
    }

    sendEmail(emailData: IEmail, templateId: number): void {
        const emailDto = { ...emailData, TemplateId: templateId };
        this.emailService.sendEmail(emailDto).subscribe((answer) => {
            this.success();
            this.enableDoubleClick();
        });
    }

    private saveClinicVerification(): void {
        if (this.isNewClinicVerification) {
            this.clinicVerificationService
                .create(this.clinicVerification)
                .pipe(finalize(() => this.enableDoubleClick()))
                .subscribe((answer) => {
                    this.clinicVerification.Id = answer;
                    this.clinic.ClinicVerificationId = answer;
                    this.clinicService.addVerification(this.clinic).subscribe((answer) => {
                        this.success();
                    });
                });
        } else {
            this.clinicVerificationService
                .update(this.clinicVerification)
                .pipe(finalize(() => this.enableDoubleClick()))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.clinicVerificationService.emitChange(this.clinicVerification);
        this.notificationsService.success('Clinic verification saved successfully.');
    }

    private enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }
}
