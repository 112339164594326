import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { INote } from '../interfaces/note';
import { INoteCategory } from '../interfaces/note-category';
import { IUser } from '../interfaces/user';

export interface INoteDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
    categories?: INoteCategory[];
}

export class NoteDynamicControls {

    formGroup: string;
    users: IUser[];
    categories: INoteCategory[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private note?: INote, additionalParameters?: INoteDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Note';
        this.users = additionalParameters && additionalParameters.users || undefined;
        this.categories = additionalParameters && additionalParameters.categories || undefined;

        this.Form = {
            CategoryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Category',
                name: 'CategoryId',
                options: this.categories,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.note && this.note.CategoryId || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.note && this.note.DateCreated || null,
            }),
            IsArchived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Archived',
                name: 'IsArchived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.note && this.note.hasOwnProperty('IsArchived') && this.note.IsArchived !== null ? this.note.IsArchived : false,
            }),
            NoteText: new DynamicField({
                formGroup: this.formGroup,
                label: 'Note Text',
                name: 'NoteText',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.note && this.note.hasOwnProperty('NoteText') && this.note.NoteText !== null ? this.note.NoteText.toString() : '',
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.note && this.note.hasOwnProperty('Title') && this.note.Title !== null ? this.note.Title.toString() : '',
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.note && this.note.UserId || null,
            }),
        };

        this.View = {
            CategoryId: new DynamicLabel(
                'Category',
                getMetaItemValue(this.categories, this.note && this.note.hasOwnProperty('CategoryId') && this.note.CategoryId !== null ? this.note.CategoryId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.note && this.note.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            IsArchived: new DynamicLabel(
                'Is Archived',
                this.note && this.note.hasOwnProperty('IsArchived') && this.note.IsArchived !== null ? this.note.IsArchived : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            NoteText: new DynamicLabel(
                'Note Text',
                this.note && this.note.hasOwnProperty('NoteText') && this.note.NoteText !== null ? this.note.NoteText.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            ),
            Title: new DynamicLabel(
                'Title',
                this.note && this.note.hasOwnProperty('Title') && this.note.Title !== null ? this.note.Title.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            UserId: new DynamicLabel(
                'User',
                getMetaItemValue(this.users, this.note && this.note.hasOwnProperty('UserId') && this.note.UserId !== null ? this.note.UserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
