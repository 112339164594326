import { NgModule } from '@angular/core';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';
import { EntityComponentsNotesModule } from '@mt-ng2/entity-components-notes';
import { EntityComponentsPhonesModule } from '@mt-ng2/entity-components-phones';
import { EntityComponentsAddressesModule } from '@mt-ng2/entity-components-addresses';
import { EntityComponentsDocumentsModule } from '@mt-ng2/entity-components-documents';
import { MtManagedListControlModule } from '@mt-ng2/managed-list-control';
import { SharedEntitiesModule } from '@mt-ng2/shared-entities-module';
import { MtSearchFilterCheckboxModule } from '@mt-ng2/search-filter-checkbox-control';

@NgModule({
    exports: [
        MtSearchBarControlModule,
        EntityListModule,
        MtSearchFilterSelectModule,
        DynamicFormModule,
        EntityComponentsNotesModule,
        EntityComponentsPhonesModule,
        EntityComponentsAddressesModule,
        EntityComponentsDocumentsModule,
        MtManagedListControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
    ],
    imports: [
        MtSearchBarControlModule,
        EntityListModule,
        MtSearchFilterSelectModule,
        DynamicFormModule,
        EntityComponentsNotesModule,
        EntityComponentsPhonesModule,
        EntityComponentsAddressesModule,
        EntityComponentsDocumentsModule,
        MtManagedListControlModule,
        SharedEntitiesModule,
        MtSearchFilterCheckboxModule,
    ],
})
export class FeatureModule {}
