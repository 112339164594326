import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICurrentReportUser } from '@mt-ng2/advanced-reporting-module-config';
import { sortByProperty } from '@mt-ng2/common-functions';

import { ReportsService } from '../services/reports.service';
import { DatasetDatapointsService } from '../services/dataset-datapoints.service';
import { IReport } from '../model/interfaces/report';
import { IDatapoint } from '../model/interfaces/datapoint';
import { IReportGroupOption } from '../model/interfaces/report-group-option';
import { getCanEdit } from '../libraries/reports.library';
import { ReportGroupOption, ToggleGroups } from '../libraries/report-groups.library';
import { IReportColumn } from '../model/interfaces/report-column';
import { ReportGroupsService } from '../services/report-groups.service';
import { IReportGroup } from '../model/interfaces/report-group';
import { IGroupAsOptions } from '../model/interfaces/group-as-options';
import { SortDirectionTypes } from '../report-sort/report-sort.component';
import { ReportColumnsService } from '../services/report-columns.service';
import { AdvancedReportingModuleConfig } from '../services/advanced-reporting-module-config.service';

@Component({
    styles: [
        `
            .group-header {
                width: 100%;
                background-color: gray;
            }
            .group-header .groups-as-text {
                color: white;
                padding-left: 8px;
            }
            .fa.disabled,
            .fa[disabled],
            .disabled > .fa,
            [disabled] > .fa {
                color: lightgrey;
                /*or*/
                opacity: 0.5;
                pointer-events: none;
            }
            .button-or-text {
                vertical-align: middle;
                line-height: 35px;
                font-weight: 900;
                font-size: x-large;
            }
        `,
    ],
    template: `
        <div *ngIf="report?.Id > 0">
            <div class="row">
                <div class="col-md-6">
                    <report-basic-info [report]="report" [canEdit]="canEdit"></report-basic-info>
                </div>
                <div class="col-md-6">
                    <div class="col-md-6">
                        <h5 class="compress-header">Groups:</h5>
                        <report-groups
                            *ngIf="report && dataPoints && groupsAsText"
                            [canEdit]="canEdit"
                            [groupsAsText]="groupsAsText"
                            [groupsAsOptions]="groupsAsOptions"
                            [report]="report"
                            (onSelectGroup)="addGroup($event)"
                            (onRemoveGroup)="removeGroup($event)"
                        >
                        </report-groups>
                    </div>
                    <div class="col-md-6">
                        <h5 class="compress-header">Default Sort:</h5>
                        <report-sort *ngIf="report?.ReportColumns" [canEdit]="canEdit" [report]="report" [reportColumns]="report.ReportColumns">
                        </report-sort>
                    </div>
                    <div class="col-md-6">
                        <h5 class="compress-header">Aggregates:</h5>
                        <report-aggregates *ngIf="report?.ReportColumns" [canEdit]="canEdit" [reportColumns]="report.ReportColumns">
                        </report-aggregates>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <report-columns *ngIf="dataPoints.length" [dataPoints]="dataPoints" [report]="report" [canEdit]="canEdit"></report-columns>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <br />
                    <a routerLink="/my-reports" class="btn btn-default">Close</a>
                    <a [routerLink]="'/my-reports/' + report.Id" class="btn btn-primary">Run</a>
                </div>
            </div>
        </div>
        <div *ngIf="report?.Id === 0">
            <div class="row">
                <div class="col-md-12">
                    <report-basic-info [report]="report"></report-basic-info>
                </div>
            </div>
        </div>
    `,
})
export class ReportDetailComponent implements OnInit, OnDestroy {
    report: IReport;
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    dataPoints: IDatapoint[] = [];
    subscriptions: Subscription = new Subscription();
    groupsAsText: IReportGroupOption[] = [];
    groupsAsOptions: IGroupAsOptions[] = [];

    constructor(
        private reportService: ReportsService,
        private reportColumnsService: ReportColumnsService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private route: ActivatedRoute,
        private router: Router,
        private datasetDatapointsService: DatasetDatapointsService,
        private reportingModuleConfig: AdvancedReportingModuleConfig,
        private reportGroupsService: ReportGroupsService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(this.reportingModuleConfig.reportingClaimTypeId, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = this.route.parent.snapshot.params.reportId;
        // try load if id > 0
        if (this.id > 0) {
            this.getReportById(this.id);
        } else {
            // set report to emptyReport
            this.report = this.reportService.getEmptyReport();
        }

        this.subscriptions.add(
            this.reportService.changeEmitted$.subscribe((report: IReport) => {
                this.setReport(report);
            }),
        );
        this.subscribeToColumnsChanged();
    }

    getReportById(id: number): void {
        this.reportService.getById(id).subscribe((report) => {
            const hasAccessToDataset = this.claimsService.hasClaim(report.Dataset.PermissionId, [ClaimValues.FullAccess]);
            if (!hasAccessToDataset) {
                this.router.navigate(['/my-reports']);
                this.notificationsService.error(`You do not have access to the ${report.Dataset.Name} dataset.`);
                return;
            }
            this.setReport(report);
            this.getAndAssignGroups();
        });
    }

    setReport(report: IReport): void {
        this.id = report.Id;
        this.report = report;
        this.reportColumnsService.reportColumnsChanged.next(this.report.ReportColumns);
        // now that we have the report object, if we can edit this page
        // we should ensure we have permission based on ownerId and/or shared canEdit
        if (this.canEdit) {
            const user = this.getCurrentReportUser();
            this.canEdit = getCanEdit(report, user?.Id || 0, user?.RoleId || 0);
        }
        this.getColumns(report.DatasetId);
    }

    private getCurrentReportUser(): ICurrentReportUser {
        return this.route.snapshot.data.currentUser;
    }

    /**
     * This method loops over the Reports.ReportColumns object and looks for
     * any columns that have groups associated to them.  If they do it will create
     * an IReportGroupOption object and add them to the GroupAsText array to be
     * displayed in thre Report-Groups component.  If they don't have a group
     * it is added to the GroupsAsOptions array to be displayed in the DDL.
     */
    getAndAssignGroups(): void {
        this.groupsAsText = [];
        this.groupsAsOptions = [];
        this.report.ReportColumns.forEach((column) => {
            if (column.ReportGroup?.Id > 0) {
                const groupOption: IReportGroupOption = {
                    DatapointPropertyName: column.DatapointPropertyName,
                    GroupOrder: column.ReportGroup.GroupOrder,
                    Id: column.ReportGroup.Id,
                    Name: column.DisplayAs,
                };
                this.groupsAsText.push(new ReportGroupOption(groupOption));
            } else {
                this.groupsAsOptions.push({
                    id: column.Id,
                    name: column.DisplayAs,
                });
            }
        });
        sortByProperty(this.groupsAsText, 'GroupOrder');
    }

    getColumns(dataSetId: number): void {
        this.datasetDatapointsService.getDatapoints(dataSetId).subscribe((answer: IDatapoint[]) => {
            this.dataPoints = answer;
        });
    }

    removeGroup(reportOption: IReportGroupOption): void {
        this.reportGroupsService.delete(reportOption.Id).subscribe(() => {
            this.toggleGroups(ToggleGroups.Remove, reportOption);
        });
    }

    addGroup(column: IReportColumn): void {
        let newGroup: IReportGroup = {
            GroupOrder: 0,
            Id: column.Id,
        };

        this.reportGroupsService.create(newGroup).subscribe((groupId: number) => {
            const groupOption: IReportGroupOption = {
                DatapointPropertyName: column.DatapointPropertyName,
                GroupOrder: column.ReportGroup.GroupOrder,
                Id: groupId,
                Name: column.DisplayAs,
            };
            this.toggleGroups(ToggleGroups.Add, groupOption);
            if (!this.report.SortProperty) {
                this.report.SortProperty = column.DatapointPropertyName;
                this.report.SortDirection = SortDirectionTypes[0].Name;
                this.reportService.update(this.report).subscribe();
            }
        });
    }

    /**
     * This function is used to toggle the groups from 1 array to another.
     * When you add a group it needs to be removed from the groupsAsOptions,
     * and added to the groupsAsText.  Then when a group is removed the
     * reverse happens.  This makes it so the user can only add a record to
     * a group once.
     * @param groupToggleType
     * @param groupOption
     */
    toggleGroups(groupToggleType: ToggleGroups, groupOption: IReportGroupOption): void {
        if (groupToggleType === ToggleGroups.Add) {
            this.groupsAsText.push(new ReportGroupOption(groupOption));
            this.groupsAsOptions.splice(
                this.groupsAsOptions
                    .map((go) => {
                        return go.name;
                    })
                    .indexOf(groupOption.Name),
                1,
            );
        } else if (groupToggleType === ToggleGroups.Remove) {
            this.groupsAsText.splice(this.groupsAsText.indexOf(groupOption), 1);
            this.groupsAsOptions.push({
                id: groupOption.Id,
                name: groupOption.Name,
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    subscribeToColumnsChanged(): void {
        this.subscriptions.add(
            this.reportColumnsService.reportColumnsChanged.subscribe((reportColumns) => {
                this.onColumnsChanged(reportColumns);
            }),
        );
    }

    onColumnsChanged(reportColumns: IReportColumn[]): void {
        this.report.ReportColumns = [...reportColumns];
        this.getAndAssignGroups();
    }
}
