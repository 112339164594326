import { Injector, Injectable, Inject } from '@angular/core';
import { DatePipe, PercentPipe, CurrencyPipe, DecimalPipe } from '@angular/common';
import { IDatapoint } from '../model/interfaces/datapoint';
import { FrontEndFormats } from '../libraries/datapoints.library';
import { numberWithCommas } from '../libraries/formats.library';
import { IFormats } from '../model/interfaces/formats';
import { IReport } from '../model/interfaces/report';
import { formatPhone } from '@mt-ng2/format-functions';

export function attachDatapointsToReport(report: IReport, datapointOptions: IDatapoint[]): IReport {
    report.ReportColumns.forEach((column) => {
        const dataOption = datapointOptions.find((dpo) => dpo.Name === column.DatapointPropertyName);
        column.DataFormatOptions = {
            FrontEndFormat: dataOption.FrontEndFormat,
            Type: dataOption.Type,
        };
    });
    return report;
}

@Injectable()
export class ReportFormatterService {
    private datePipe: DatePipe;
    private percentagePipe: PercentPipe;
    private currencyPipe: CurrencyPipe;
    private decimalPipe: DecimalPipe;

    constructor(@Inject(Injector) injector: Injector) {
        this.datePipe = injector.get(DatePipe);
        this.percentagePipe = injector.get(PercentPipe);
        this.currencyPipe = injector.get(CurrencyPipe);
        this.decimalPipe = injector.get(DecimalPipe);
    }

    formatData(data: any[], datapointOptions: IDatapoint[]): any[] {
        const result = [];
        if (data.length) {
            const formats = this.buildFormats(data, datapointOptions);
            data.forEach((datum) => {
                formats.forEach((datapoint: IFormats) => {
                    datum[datapoint.property] = this.formatDatapoint(datum[datapoint.property], datapoint);
                });

                result.push(datum);
            });
        }
        return result;
    }

    private buildFormats(reportData: any[], datapointOptions: IDatapoint[]): IFormats[] {
        const formats: IFormats[] = [];
        const firstItem = reportData[0];
        for (let property in firstItem) {
            if (firstItem.hasOwnProperty(property)) {
                const format = datapointOptions.find((dp) => dp.Name === property);
                if (format?.FrontEndFormat > 0 || format?.Type === 'number') {
                    formats.push({
                        frontEndFormat: format.FrontEndFormat,
                        property: property,
                        type: format.Type,
                    });
                }
            }
        }
        return formats;
    }

    private getTimeZone(): string {
        const now = new Date();
        return now.toTimeString().match(/([\+|-]\d+)/)[1];
    }

    private formatDatapoint(value: any, dataFormat: IFormats): any {
        let result: any;
        const timeZone = this.getTimeZone();
        switch (dataFormat.frontEndFormat) {
            case FrontEndFormats.Percentage: {
                result = this.percentagePipe.transform(value);
                break;
            }
            case FrontEndFormats.Currency: {
                result = this.currencyPipe.transform(value);
                break;
            }
            case FrontEndFormats.Decimal: {
                result = this.decimalPipe.transform(value);
                break;
            }
            case FrontEndFormats.Date_Full: {
                result = this.datePipe.transform(value, 'full', timeZone);
                break;
            }
            case FrontEndFormats.Date_FullDate: {
                result = this.datePipe.transform(value, 'fullDate', timeZone);
                break;
            }
            case FrontEndFormats.Date_FullTime: {
                result = this.datePipe.transform(value, 'fullTime', timeZone);
                break;
            }
            case FrontEndFormats.Date_Short: {
                result = this.datePipe.transform(value, 'short', timeZone);
                break;
            }
            case FrontEndFormats.Date_ShortDate: {
                result = this.datePipe.transform(value, 'shortDate', timeZone);
                break;
            }
            case FrontEndFormats.Date_ShortTime: {
                result = this.datePipe.transform(value, 'shortTime', timeZone);
                break;
            }
            case FrontEndFormats.Date_Medium: {
                result = this.datePipe.transform(value, 'medium', timeZone);
                break;
            }
            case FrontEndFormats.Date_MediumDate: {
                result = this.datePipe.transform(value, 'mediumDate', timeZone);
                break;
            }
            case FrontEndFormats.Date_MediumTime: {
                result = this.datePipe.transform(value, 'mediumTime', timeZone);
                break;
            }
            case FrontEndFormats.Date_Long: {
                result = this.datePipe.transform(value, 'long', timeZone);
                break;
            }
            case FrontEndFormats.Date_LongDate: {
                result = this.datePipe.transform(value, 'longDate', timeZone);
                break;
            }
            case FrontEndFormats.Date_LongTime: {
                result = this.datePipe.transform(value, 'longTime', timeZone);
                break;
            }
            case FrontEndFormats.Phone: {
                result = formatPhone(value);
                break;
            }
            case FrontEndFormats.General: {
                result = value;
                break;
            }
            default: {
                if (value && dataFormat.type === 'number') {
                    value = numberWithCommas(value);
                }
                result = value;
                break;
            }
        }
        return result;
    }
}
