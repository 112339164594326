import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ApplicationService } from '../services/application.service';
import { IApplication } from '@model/interfaces/application';
import { ClaimTypes } from '@model/ClaimTypes';
import { getIdFromRoute } from '@common/libraries/get-id-from-route.library';
import { Subscription } from 'rxjs';
import { NoteTypes } from '@model/enums/note-types.enum';

@Component({
    templateUrl: './application-detail.component.html',
})
export class ApplicationDetailComponent implements OnInit {
    application: IApplication;
    canEdit: boolean;
    canAdd: boolean;
    noteType = NoteTypes.Application;

    subs = new Subscription();

    constructor(
        private applicationService: ApplicationService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.DonorApplication, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = getIdFromRoute(this.route, 'applicationId');
        if (id) {
            this.getApplicationById(id);
        } else {
            this.router.navigate(['applications']); // if no id found, go back to list
        }
        // refresh data when a change is emitted
        this.subs.add(
            this.applicationService.changeEmitted$.subscribe(() => {
                // application has loaded
                if (this.application) {
                    this.getApplicationById(this.application.Id);
                    this.application = null;
                    this.cdr.detectChanges();
                }
            }),
        );
    }

    getApplicationById(id: number): void {
        this.applicationService.getById(id).subscribe((application) => {
            if (application === null) {
                this.notificationsService.error('Application not found');
                this.router.navigate(['applications']);
            }
            this.application = application;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
