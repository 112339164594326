import { InjectionToken } from '@angular/core';

import { IReportUsersService } from './report-users-service';

export interface IReportingModuleConfig {
    reportUsersService: IReportUsersService;
    reportingClaimTypeId: number;
    displayFiltersOnRenderedReport?: boolean;
    reportSizeExportWarningThreshold?: number;
}

export const AdvancedReportingModuleConfigToken = new InjectionToken<IReportingModuleConfig>('advanced_reporting_module_config');

export const AdvancedReportingModuleUserServiceToken = new InjectionToken<IReportUsersService>('advanced_reporting_module_users_service');
