import { Injectable, Inject } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { take, skipWhile } from 'rxjs/operators';

import { ICurrentReportUser } from '@mt-ng2/advanced-reporting-module-config';

import { AdvancedReportingModuleConfig } from '../services/advanced-reporting-module-config.service';

// this resolver is to be used with any route which need to know the current report user
@Injectable()
export class CurrentUserResolver implements Resolve<Observable<ICurrentReportUser>> {
    constructor(private reportModuleConfig: AdvancedReportingModuleConfig) {}

    resolve(): Observable<ICurrentReportUser> {
        // first get the value from the reportUsersService
        let user: ICurrentReportUser = this.reportModuleConfig.reportUsersService.currentReportUser.getValue();
        if (user) {
            // so long as that value is not null, then return it as the observable
            return of(user).pipe(take(1));
        } else {
            // else we need to return the observable with a wait til not null using skipWhile
            return this.reportModuleConfig.reportUsersService.currentReportUser.pipe(skipWhile((answer) => answer === null)).pipe(take(1));
        }
    }
}
