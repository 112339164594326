import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class InventoryDonorReportEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['DonorId'],
                    name: 'Donor #',
                }),
                new EntityListColumn({
                    accessors: ['RecipientId'],
                    name: 'Recipient #',
                }),
                new EntityListColumn({
                    accessors: ['RecipientName'],
                    name: 'Recipient Name',
                }),
                new EntityListColumn({
                    accessors: ['ClinicName'],
                    name: 'Clinic Name',
                }),
                new EntityListColumn({
                    accessors: ['ApplicationStage'],
                    name: 'Application Stage',
                }),
                new EntityListColumn({
                    accessors: ['WarmingDate'],
                    name: 'Warming Date',
                    pipes: ['date:mediumDate'],
                }),
                new EntityListColumn({
                    accessors: ['Outcome'],
                    name: 'Outcome',
                }),
                new EntityListColumn({
                    accessors: ['AvailableInventory'],
                    name: 'Available Inventory',
                }),
                new EntityListColumn({
                    accessors: ['RemainingEmbryos'],
                    name: 'Remaining Embryos',
                }),
            ],
        };
        super(listConfig);
    }
}
