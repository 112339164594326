import { Injectable, Inject, Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { MtAuthGuard, IRoleGuarded } from '@mt-ng2/auth-module';
import { IAuditLogModuleConfig, AuditLoggingModuleConfigToken } from '@mt-ng2/audit-logging-module-config';

@Injectable({
    providedIn: 'root',
})
export class AuditLogAuthGuard extends MtAuthGuard {
    constructor(
        @Inject(AuditLoggingModuleConfigToken)
        private auditLogModuleConfig: IAuditLogModuleConfig,
        private injector: Injector,
    ) {
        super(injector);
    }

    // overriding getRoleGuard to get the claimTypeId from the config
    getRoleGuard(route: ActivatedRouteSnapshot): IRoleGuarded {
        let isRoleGuarded = route.data && route.data.claimValues ? true : false;
        if (!isRoleGuarded) {
            return null;
        }
        return {
            claimType: this.auditLogModuleConfig.auditLogClaimTypeId,
            claimValues: this.auditLogModuleConfig.enabled ? route.data.claimValues : [],
        };
    }
}
