import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';
import { IArchivable } from '../model/interfaces/archivable';
import { IArchivableService } from '../model/interfaces/archivable-service';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    selector: 'toggle-archived',
    styles: [
        `
            .not-archived {
                color: red;
            }
            .archived {
                color: green;
            }
        `,
    ],
    template: `
        <ng-container>
            <span
                *ngIf="!toggleableEntity?.Archived"
                (mtConfirm)="toggleArchived($event)"
                [mtConfirmOptions]="confirmOptions"
                class="btn btn-xs btn-transparent toggle-archived-button"
                title="archive"
            >
                <i class="fa fa-lg fa-fw toggle-archived-icon fa-trash not-archived"></i>
            </span>
            <span
                *ngIf="toggleableEntity?.Archived"
                (click)="toggleArchived($event)"
                class="btn btn-xs btn-transparent toggle-archived-button"
                title="un-archive"
            >
                <i class="fa fa-lg fa-fw toggle-archived-icon fa-undo archived"></i>
            </span>
        </ng-container>
    `,
})
export class ToggleArchivedComponent {
    public componentErrorHandler = new ComponentErrorHandler('ToggleArchivedComponent', '@mt-ng2/advanced-reporting-module');

    public toggleableEntity: IArchivable;
    @Input()
    set entity(value: IArchivable) {
        this.toggleableEntity = { ...value };
    }
    @Input() service: IArchivableService;
    @Output() onToggleArchived: EventEmitter<any> = new EventEmitter<any>();

    confirmOptions: IModalOptions = {
        confirmButtonText: 'Yes, archive it!',
        text: 'note: this can be reverted later',
    };

    constructor() {}

    toggleArchived(event: Event): void {
        event?.stopPropagation();
        if (!this.service?.toggleArchived) {
            this.componentErrorHandler.addError('input [service] either is not present or does not have method toggleArchived');
            return;
        }
        this.service.toggleArchived(this.toggleableEntity.Id).subscribe(() => {
            this.onToggleArchived.emit();
        });
    }
}
