import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { common } from '@mt-ng2/common-functions';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FileItem } from 'ng2-file-upload';

import { EmailDynamicConfig } from '../../model/email.dynamic-config';
import { IEmail } from '../../model/email.interface';

@Component({
    selector: 'app-email-form',
    styles: [
        `
            .miles-header {
                color: #652365;
                font-weight: bold;
                border-bottom: solid 1px #ccc;
                padding-bottom: 5px;
                text-align: left;
            }
        `,
    ],
    templateUrl: './email-form.component.html',
})
export class EmailFormComponent {
    @Input()
    set outgoingEmail(outgoingEmail: IEmail) {
        this._email = outgoingEmail;
        if (outgoingEmail) {
            if (this.emailForm?.get) {
                this.emailForm.get('Email').setValue(outgoingEmail);
                this.emailForm.updateValueAndValidity();
            } else {
                this.setConfig();
            }
        }
    }
    get outgoingEmail(): IEmail {
        return this._email;
    }
    private _email: IEmail;

    @Output() onSendEmail: EventEmitter<IEmail> = new EventEmitter<IEmail>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    config: any = { formObject: [], viewOnly: [] };
    formFactory: EmailDynamicConfig<IEmail>;
    doubleClickIsDisabled = false;

    formReady: boolean;

    emailForm: FormGroup;

    showUploadModal: boolean;

    protected uploadModalOptions: IModalOptions = {
        showConfirmButton: false,
        width: '40%',
    };

    constructor(private notificationsService: NotificationsService) {}

    setConfig(): void {
        let configControls = null;
        this.formFactory = new EmailDynamicConfig<IEmail>(this.outgoingEmail, configControls);
        this.config = this.formFactory.getForUpdate();
        this.formReady = true;
    }

    cancelClick(): void {
        this.onCancel.emit();
        this.enableDoubleClick();
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.outgoingEmail, form.value.Email);
            this.onSendEmail.emit(this.outgoingEmail);
            this.enableDoubleClick();
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    onFileQueueChange(files: FileItem[]): void {
        this.outgoingEmail.Attachments = files;
    }

    onAddingFileFailed(error: string): void {
        this.notificationsService.error(error);
    }
}
