import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { IUser } from '@model/interfaces/user';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '../user.service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IAddress } from '@model/interfaces/base';

import { ClaimTypes } from '@model/ClaimTypes';
import { AuthService } from '@mt-ng2/auth-module';
import { AuthEntityService } from '../../auth-entity/auth-entity.service';
import { UserRoles } from '@model/enums/user-roles.enum';
import { DocumentEntityTypes } from '@model/enums/document-entity-types';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
})
export class UserDetailComponent implements OnInit {
    user: IUser;
    currentUser;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    userAddress: IAddress;
    canAdd: boolean;
    myProfile: boolean;
    accountLocked: boolean;

    showUserRegionsPanel: boolean;

    documentEntityType = DocumentEntityTypes.Users;
    claimType = ClaimTypes.Users;

    get isEmbryologist(): boolean {
        return this.user?.AuthUser?.UserRole?.Id && this.user.AuthUser.UserRole.Id === UserRoles.Embryologist;
    }

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private authEntityService: AuthEntityService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        // get current id from route
        let id = +this.route.snapshot.paramMap.get('userId');
        // check if this is the my-profile path
        if (this.route.snapshot.routeConfig.path.includes('my-profile')) {
            this.myProfile = true;
            id = this.currentUser = this.authService.currentUser.getValue().Id;
            this.canEdit = true;
        }
        // try load if id > 0
        if (id > 0) {
            this.getUserById(id);
        } else {
            // set user to emptyUser
            this.user = this.userService.getEmptyUser();
        }

        this.editingComponent.next('');
    }

    unlock(): void {
        this.authEntityService.unlockAccount(this.user.AuthUserId).subscribe((success) => {
            this.accountLocked = false;
            this.notificationsService.success('User account unlocked successfully');
        });
    }

    getUserById(id: number): void {
        this.userService.getById(id).subscribe((user) => {
            this.user = user;
            if (this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess])) {
                // If the user is not able to unlock an account, we don't really care if it's locked or not
                this.authEntityService.isAccountLocked(this.user.AuthUserId).subscribe((locked: boolean) => (this.accountLocked = locked));
            }
            this.canEdit = this.user.AuthUser && this.user.AuthUser.IsEditable ? this.canEdit : false;
            this.currentUser = this.authService.currentUser.getValue();
            if (user.Address) {
                this.userAddress = user.Address;
            } else {
                this.userAddress = null;
            }
            if (this.user.AuthUser.UserRole.Id === UserRoles.AccountManager) {
                this.showUserRegionsPanel = true;
            }
        });
    }

    saveAddress(address): void {
        this.userService.saveAddress(this.user.Id, address).subscribe((answer) => {
            if (answer?.AddressId) {
                address.Id = answer.AddressId;
            }
            this.notificationsService.success('Address Saved Successfully');
            this.user.Address = address;
            this.userAddress = address;
        });
    }

    deleteAddress(address): void {
        this.userService.deleteAddress(this.user.Id).subscribe((answer) => {
            this.notificationsService.success('Address Deleted Successfully');
            this.userAddress = null;
            this.user.Address = null;
        });
    }

    savePhones(phoneCollection: any): void {
        this.userService.savePhones(this.user.Id, phoneCollection).subscribe((answer) => {
            this.notificationsService.success('Phones Saved Successfully');
            this.user.UserPhones = phoneCollection.Phones;
            this.editingComponent.next('');
        });
    }

    updateVersion(version): void {
        this.user.Version = version;
    }
}
