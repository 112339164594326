import { UserRegionDynamicControls, IUserRegionDynamicControlsParameters } from '../form-controls/user-region.form-controls';
import { IUserRegion } from '../interfaces/user-region';
import { IUser } from '../interfaces/user';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { ICountry } from '@model/interfaces/country';
import { IState } from '@model/interfaces/state';

export interface IUserRegionDynamicControlsParametersPartial extends IUserRegionDynamicControlsParameters {
    regions: ICountry[];
    regionStates: IState[];
}

export class UserRegionDynamicControlsPartial extends UserRegionDynamicControls {
    constructor(userRegionPartial?: IUserRegion[], additionalParameters?: IUserRegionDynamicControlsParametersPartial) {
        super(null, additionalParameters);

        const { regions, regionStates } = additionalParameters;
        const auStates = regionStates.filter((val) => val.CountryCode === 'AU');
        const usStates = regionStates.filter((val) => val.CountryCode === 'US');

        this.Form = {
            AuStateCodes: new DynamicField({
                formGroup: this.formGroup,
                label: 'AU States',
                name: 'AuStateCodes',
                options: auStates,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                }),
                value: this.getStateMultiselectValue(this.parseStates(userRegionPartial, 'AU'), auStates),
            }),
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Regions',
                name: 'CountryCodes',
                options: regions,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                }),
                value: this.getCountryMultiselectValue(this.parseCountries(userRegionPartial), regions),
            }),
            UsStateCodes: new DynamicField({
                formGroup: this.formGroup,
                label: 'US States',
                name: 'UsStateCodes',
                options: usStates,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                }),
                value: this.getStateMultiselectValue(this.parseStates(userRegionPartial, 'US'), usStates),
            }),
        };
    }

    parseStates(partial: IUserRegion[], country: string): IUserRegion[] {
        return partial.filter((itm) => itm.CountryCode === country && itm.StateCode);
    }

    parseCountries(partial: IUserRegion[]): IUserRegion[] {
        return partial.filter((itm) => !itm.StateCode);
    }

    getCountryMultiselectValue(value: IUserRegion[], options: any[]): number[] {
        const countryCodes = value.map((itm) => itm.CountryCode);
        return options.filter((itm) => countryCodes.includes(itm.CountryCode)).map((itm) => itm.Id);
    }

    getStateMultiselectValue(value: IUserRegion[], options: any[]): number[] {
        const stateCodes = value.map((itm) => itm.StateCode);
        return options.filter((itm) => stateCodes.includes(itm.StateCode)).map((itm) => itm.Id);
    }
}
