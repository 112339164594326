import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class DonorCycleReportEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['DonorId'],
                    name: 'Donor ID',
                }),
                new EntityListColumn({
                    accessors: ['Name'],
                    name: 'Donor Name',
                }),
                new EntityListColumn({
                    accessors: ['CycleNumber'],
                    name: 'Cycle #',
                }),
                new EntityListColumn({
                    accessors: ['Month'],
                    name: 'Month',
                }),
                new EntityListColumn({
                    accessors: ['Status'],
                    name: 'Status',
                }),
                new EntityListColumn({
                    accessors: ['Location'],
                    name: 'Location',
                }),
                new EntityListColumn({
                    accessors: ['OcpStatus'],
                    name: 'OCP Status',
                }),
                new EntityListColumn({
                    accessors: ['Lh'],
                    name: 'LH',
                }),
                new EntityListColumn({
                    accessors: ['Amh'],
                    name: 'AMH',
                }),
            ],
        };
        super(listConfig);
    }
}
