import { Component, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IAuditedDate } from '@model/interfaces/custom/audited-date';
import { AuthService } from '@mt-ng2/auth-module';
import {
    CustomFormControlComponentBase,
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    IDynamicField,
    IDynamicFieldType,
    InputTypes,
    LabelPosition,
    LabelPositions,
} from '@mt-ng2/dynamic-form';

interface IDateParts {
    month: number;
    day: number;
    year: number;
}

@Component({
    styles: [
        `
            .audited-date-control-wrapper {
                display: inline-block;
            }
            .audited-date-control-wrapper .date-field {
                margin-top: -20px;
            }
        `,
    ],
    templateUrl: './audited-date-control.component.html',
})
export class AuditedDateControlComponent extends CustomFormControlComponentBase {
    wrapperForm: FormGroup;
    dateField: DynamicField;
    disabled = false;

    currentValue: IAuditedDate;

    constructor(changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private authService: AuthService) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.config.value) {
            this.currentValue = JSON.parse(this.config.value as string);
            this.currentValue.Date = new Date(this.currentValue.Date);
        }

        this.wrapperForm = this.formBuilder.group({
            [this.config.formGroup]: this.formBuilder.group({}),
        });

        this.dateField = new DynamicField({
            formGroup: this.config.formGroup,
            label: 'Date',
            labelPosition: new LabelPosition({ position: LabelPositions.Hidden }),
            name: 'Date',
            type: new DynamicFieldType({
                datepickerOptions: this.config.type.datepickerOptions,
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.DateInput,
            } as IDynamicFieldType),
            value: this.currentValue && this.currentValue.Date ? this.currentValue.Date : null,
        } as IDynamicField);

        // wiring up the disabled property to propagate to our controls
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                const isDisabled = status === 'DISABLED';
                if (isDisabled && !this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.disable();
                    }, 0);
                    this.disabled = true;
                } else if (!isDisabled && this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.enable();
                    }, 0);
                    this.disabled = false;
                }
            }),
        );
    }

    dateValueChanged(date: Date): void {
        // this is fired on first load with currentValue and no date, so just return if that's the case
        if (!this.currentValue && !date) {
            return;
        }
        // if the date hasn't actually changed, then just return
        if (this.currentValue && this.currentValue.Date && this.areDatesSame(this.currentValue.Date, date)) {
            return;
        }
        const currentUser = this.authService.currentUser.getValue();
        this.currentValue = {
            AuditDate: new Date(),
            AuditUserId: currentUser.Id,
            AuditUserName: currentUser.Name,
            Date: date,
        };
        this.getControl().patchValue(JSON.stringify(this.currentValue));
    }

    private areDatesSame(date1: Date, date2: Date): boolean {
        // if either doesn't exist, than just do a straight comparison
        if (!date1 || !date2) {
            return date1 === date2;
        }
        const dateParts1 = this.getDateParts(date1);
        const dateParts2 = this.getDateParts(date2);
        return dateParts1.day === dateParts2.day && dateParts1.month === dateParts2.month && dateParts1.year === dateParts2.year;
    }

    private getDateParts(date: Date): IDateParts {
        return {
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
        };
    }
}
