export enum CycleDetailEnums {
    PsychAcceptedRejected = 1,
    TWEBInitiated = 2,
    MonitoringClinic = 3,
    InitialLabWorkSent = 4,
    AMHOutcome = 5,
    GCChlamydiaOutcome = 6,
    BloodType = 7,
    PsychEvalInstructionsSent = 8,
    PsychCompletedDate = 9,
    GeneticLabsReceived = 10,
    MMPICompleted = 11,
    PsychApproved = 12,
    InfoSenttoMonitoringClinic = 13,
    ANZICASent = 14,
    InitialAppointment = 15,
    KaryotypeOutcome = 16,
    KaryotypeDrawDate = 17,
    CMVOutcome = 18,
    CMVDrawDate = 19,
    HTLVIandIIOutcome = 20,
    HTLVIandIIDrawDate = 21,
    AFCRight = 50,
    AFCLeft = 51,
    HeightWeightBMI = 54,
    StartStimDate = 55,
    DonorInsurancePurchased = 56,
    FDALabsDate = 57,
    AdditionalLabs = 58,
    SORReceivedDate = 59,
    FDABookDate = 60,
    HepBsAgOutcome = 61,
    HepBsAgDrawDate = 62,
    HepBCoreOutcome = 63,
    HepBCoreDrawDate = 64,
    HIV102AbOutcome = 65,
    HIV102AbDate = 66,
    HIV1NATOutcome = 67,
    HIV1NATDate = 68,
    HBVNATOutcome = 69,
    HBVNATDrawDate = 70,
    HCVNATOutcome = 71,
    HCVNATDrawDate = 72,
    AntiHepC = 73,
    AnitHepCDrawDate = 74,
    SyphilisOutcome = 75,
    SyphilisDrawDate = 76,
    SyphilisTest = 77,
    ChlamydiaNATOutcome = 78,
    ChlamydiaNATDrawDate = 79,
    GonorrheaNATOutcome = 80,
    GonorrheaNATDrawDate = 81,
    WNVNAT = 82,
    WNVNATDrawDate = 83,
    Physical = 84,
    HistoryPhysical = 85,
    HistoryDateMedicalQuestionnaireResult = 86,
    MedicalQuestionnaireResultDate = 87,
    TissueRecoveryDate = 88,
    IstissueeligibleforTransfer = 89,
    EligibilityDeterminationDate = 90,
    InitialsofReleasingPerson = 91,
    LabEmployee = 92,
    Physician = 93,
    RetrievalDateandStartTime = 94,
    DenudingStartTime = 95,
    Procedure = 96,
    NumOocytesReceived = 97,
    NumVitrified = 98,
    VitrificationStart = 99,
    VitrificationEnd = 100,
    Embryologist = 101,
    IncubatorNumber = 102,
    IDVerifiedatRetrievalBy = 103,
    VitMethod = 104,
    VitDeviceType = 105,
    VitMediaType = 106,
    NumDeviceswith2Oocytes = 107,
    NumDeviceswith1Oocyte = 108,
    KitLotNumber = 109,
    DeviceLotNumber = 110,
    RetrievalEndTime = 111,
    DenudingEndTime = 112,
    MedicationOrdered = 113,
    MedicationPaid = 114,
    OCPStartDate = 115,
    OCPEndDate = 116,
    NumDaysofOCP = 117,
    StimType = 119,
    RetrievalDateandTime = 120,
    ArrivalTime = 121,
    UrineTime = 122,
    Trigger = 123,
    LastMedDateandTime = 124,
    Canceled = 125,
    BP = 126,
    Pulse = 127,
    O2Sat = 128,
    Height = 129,
    Weight = 130,
    Temp = 131,
    RhFactor = 132,
}
