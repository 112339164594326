import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IDeviceLotNumber } from '../interfaces/device-lot-number';

export interface IDeviceLotNumberDynamicControlsParameters {
    formGroup?: string;
}

export class DeviceLotNumberDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private devicelotnumber?: IDeviceLotNumber, additionalParameters?: IDeviceLotNumberDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'DeviceLotNumber';

        this.Form = {
            ExpirationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Expiration Date',
                name: 'ExpirationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.devicelotnumber && this.devicelotnumber.ExpirationDate || null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.devicelotnumber && this.devicelotnumber.hasOwnProperty('Name') && this.devicelotnumber.Name !== null ? this.devicelotnumber.Name.toString() : '',
            }),
            OutOfUse: new DynamicField({
                formGroup: this.formGroup,
                label: 'Out Of Use',
                name: 'OutOfUse',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.devicelotnumber && this.devicelotnumber.hasOwnProperty('OutOfUse') && this.devicelotnumber.OutOfUse !== null ? this.devicelotnumber.OutOfUse : false,
            }),
        };

        this.View = {
            ExpirationDate: new DynamicLabel(
                'Expiration Date',
                this.devicelotnumber && this.devicelotnumber.ExpirationDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.devicelotnumber && this.devicelotnumber.hasOwnProperty('Name') && this.devicelotnumber.Name !== null ? this.devicelotnumber.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            OutOfUse: new DynamicLabel(
                'Out Of Use',
                this.devicelotnumber && this.devicelotnumber.hasOwnProperty('OutOfUse') && this.devicelotnumber.OutOfUse !== null ? this.devicelotnumber.OutOfUse : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
