<div *ngIf="preScreening.Id > 0 && !isEditing">
    <div class="form-section-border">
        <h3>
            Pre-screening
            <span class="pull-right" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h3>
    </div>

    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing">
    <form [formGroup]="preScreeningForm" (ngSubmit)="formSubmitted()">
        <h2 class="auth-layout__h2">Pre-screening</h2>
        <dynamic-field [field]="preScreeningControls.BirthYear" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.Email" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.HeightUnitId" (valueChanges)="onHeightUnitsValueChange($event)"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field *ngIf="!usingMeters()" [field]="preScreeningControls.Feet"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field *ngIf="!usingMeters()" [field]="preScreeningControls.Inches"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field *ngIf="usingMeters()" [field]="preScreeningControls.Meters"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.WeightUnitId" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.Weight" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.GenderId" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.EducationCompletedTypeId"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.IsUsCitizenOrGreenCardHolder"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.CountryCode" (valueChanges)="setStatesForCountry($event)"
            (controlCreated)="setShowStatesCurrentAddress($event)" [form]="preScreeningForm"></dynamic-field>

        <dynamic-field *ngIf="(showStatesCurrentAddress$ | async)" [field]="preScreeningControls.StateCode" 
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field *ngIf="!(showStatesCurrentAddress$ | async)" [field]="preScreeningControls.Province"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.HasStd" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.HasHiv" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.IsAdopted" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.HasFamilyMedicalHistory" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.HasBothOvaries" [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.HasGivenBirth" (valueChanges)="onHasGivenBirthValueChange($event)"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field *ngIf="hasGivenBirth" [field]="preScreeningControls.HowManyLiveBirths"
            (valueChanges)="onHowManyLiveBirthsValueChanges($event)" [form]="preScreeningForm"></dynamic-field>
        <div *ngIf="preScreening.HowManyLiveBirths > 0">
            <br />
            <app-births [preScreening]="preScreening" [genders]="genders"
                [numberOfLiveBirths]="preScreening.HowManyLiveBirths"
                (onBirthsChanging)="onBirthsChanging($event)"></app-births>
        </div>
        <dynamic-field [field]="preScreeningControls.IsPregnantOrBreastFeeding"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.HasPreviouslyDonatedEggs"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.HasRegularPeriodsWithoutBirthControl"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field [field]="preScreeningControls.ReferralTypeId" (valueChanges)="onReferralTypeValueChange($event)"
            [form]="preScreeningForm"></dynamic-field>
        <dynamic-field *ngIf="otherReferralType()" [field]="preScreeningControls.OtherReferral"
            [form]="preScreeningForm"></dynamic-field>
        <div>
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                Class="btn btn-flat btn-success">Save</button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </div>
    </form>
</div>