import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { MtAuthGuard, IRoleGuarded } from '@mt-ng2/auth-module';

import { AdvancedReportingModuleConfig } from '../services/advanced-reporting-module-config.service';

@Injectable()
export class ReportingAuthGuard extends MtAuthGuard {
    constructor(private reportingModuleConfig: AdvancedReportingModuleConfig, private injector: Injector) {
        super(injector);
    }

    // overriding getRoleGuard to get the claimTypeId from the config
    getRoleGuard(route: ActivatedRouteSnapshot): IRoleGuarded {
        let isRoleGuarded = route.data?.claimValues ? true : false;
        if (!isRoleGuarded) {
            return null;
        }
        return {
            claimType: this.reportingModuleConfig.reportingClaimTypeId,
            claimValues: route.data.claimValues,
        };
    }
}
