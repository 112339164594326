import { DynamicField, LabelPosition, LabelPositions } from '@mt-ng2/dynamic-form';
import { InventoryVesselDynamicControls, IInventoryVesselDynamicControlsParameters } from '../form-controls/inventory-vessel.form-controls';
import { IInventoryVessel } from '../interfaces/inventory-vessel';

export class InventoryVesselDynamicControlsPartial extends InventoryVesselDynamicControls {
    constructor(inventoryvesselPartial?: IInventoryVessel, additionalParameters?: IInventoryVesselDynamicControlsParameters) {
        super(inventoryvesselPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.Level).type.fieldType = DynamicFieldTypes.Input;
        (<DynamicField>this.Form.Name).labelPosition.position = LabelPositions.Top;
        (<DynamicField>this.Form.Number).labelPosition.position = LabelPositions.Top;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.Level).label = 'Some other Label';
        // (<DynamicLabel>this.View.Name).label = 'Some other Label';
        // (<DynamicLabel>this.View.Number).label = 'Some other Label';
    }
}
