import { NgModule } from '@angular/core';

import { SharedModule } from '@common/shared.module';

import { UserRoleRoutingModule } from './user-role-routing.module';
import { UserRolesComponent } from './user-role-list/user-roles.component';
import { UserRoleDetailComponent } from './user-role-detail/user-role-detail.component';
import { UserRoleHeaderComponent } from './user-role-header/user-role-header.component';
import { UserRoleBasicInfoComponent } from './user-role-basic-info/user-role-basic-info.component';
import { UserRolePermissionsComponent } from './user-role-permissions/user-role-permissions.component';
import { DashboardModule } from '@mt-ng2/dashboard';

@NgModule({
    declarations: [UserRolesComponent, UserRoleDetailComponent, UserRoleHeaderComponent, UserRoleBasicInfoComponent, UserRolePermissionsComponent],
    imports: [SharedModule, DashboardModule, UserRoleRoutingModule],
})
export class UserRoleModule { }
