<div *ngIf="medicalIssues.Id > 0">
    <div class="form-section-border">
        <h3>
            Medical Issues
            <span class="pull-right" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h3>
    </div>
    <div *ngIf="formCreated && !isEditing">
        <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
        <!-- For each ApplicationMedicalIssue Create Component and pass in ApplicationMedicalIssue -->
        <div *ngFor="let type of medicalIssueTypes">
            <div class="form-section-border">
                <h3>{{ type.Name }}</h3>
            </div>
            <div *ngFor="let issue of getIssuesByTypeId(type.Id)">
                <donor-medical-issue [donorIssueMedicalIssue]="issue"
                    [medicalIssueRelationshipTypes]="medicalIssueRelationshipTypes">
                </donor-medical-issue>
                <br />
            </div>
        </div>
    </div>
    <div *ngIf="formCreated && isEditing">
        <form class="padded row" [formGroup]="applicationIssuesForm" (ngSubmit)="formSubmitted()">
            <dynamic-field [field]="abstractApplicationIssueControls.BirthControlTypeId" [form]="applicationIssuesForm"
                (valueChanges)="onBirthControlTypeIdChanging($event)"></dynamic-field>
            <dynamic-field *ngIf="showOtherBirthControlMethod"
                [field]="abstractApplicationIssueControls.OtherBirthControlMethod" [form]="applicationIssuesForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationIssueControls.IsTakingMedication" [form]="applicationIssuesForm"
                (valueChanges)="onTakingMedicationValueChange($event)"></dynamic-field>
            <dynamic-field *ngIf="isTakingMedication" [field]="abstractApplicationIssueControls.CurrentMedications"
                [form]="applicationIssuesForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationIssueControls.HasSurgeries" [form]="applicationIssuesForm"
                (valueChanges)="onHasSurgeriesValueChange($event)"></dynamic-field>
            <dynamic-field *ngIf="hasSurgeries" [field]="abstractApplicationIssueControls.Surgeries"
                [form]="applicationIssuesForm"></dynamic-field>
            <dynamic-field [field]="abstractApplicationIssueControls.OtherIssues"
                [form]="applicationIssuesForm"></dynamic-field>
            <div *ngFor="let type of medicalIssueTypes">
                <div class="form-section-border">
                    <h3>{{ type.Name }}</h3>
                </div>
                <div *ngFor="let issue of getIssuesByTypeId(type.Id)">
                    <issue-selector (subissueChange)="subIssueChanged($event)" [applicationIssueMedicalIssue]="issue"
                        [medicalIssueRelationshipTypes]="medicalIssueRelationshipTypes">
                    </issue-selector>
                    <br />
                </div>
            </div>
            <div class="row col-md-12">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                    Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </div>
        </form>
    </div>
</div>