import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';

import { IReportColumn } from '../model/interfaces/report-column';
import { ReportChangedService } from './report-changed.service';
import { tap } from 'rxjs/operators';

const emptyReportColumn: IReportColumn = {
    DatapointPropertyName: null,
    DisplayAs: null,
    Id: 0,
    Order: null,
    ReportAggregate: null,
    ReportGroup: null,
    ReportId: 0,
    Width: 200,
};

export function sortReportColumnsByOrder(reportColumns: IReportColumn[]): IReportColumn[] {
    return reportColumns.sort((a: IReportColumn, b: IReportColumn) => {
        return a.Order - b.Order;
    });
}

@Injectable()
export class ReportColumnsService extends BaseService<IReportColumn> {
    public reportColumnsChanged = new Subject<IReportColumn[]>();

    constructor(public http: HttpClient, private reportChangedService: ReportChangedService) {
        super('/reports/columns', http);
    }

    getEmptyReportColumn(reportId: number): IReportColumn {
        const newReportColumn = { ...emptyReportColumn };
        newReportColumn.ReportId = reportId;
        return newReportColumn;
    }

    delete(reportColumnId: number): Observable<any> {
        // TODO: is there a reason delete is not in the base service?
        return this.http.delete('/reports/columns/' + reportColumnId);
    }

    update(reportColumn: IReportColumn): Observable<any> {
        return super.update(reportColumn).pipe(
            tap(() => {
                this.reportChangedService.columnHeaderUpdated({
                    id: reportColumn.Id,
                    name: reportColumn.DisplayAs,
                });
            }),
        );
    }
}
