<dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="wrapperForm">
    <br />
    <div #inputElement class="btn-group btn-group-toggle" ngbRadioGroup formControlName="MeasurementTypeId" [class.disabled]="config.disabled">
        <label ngbButtonLabel class="btn-primary-inverse" [attr.disabled]="isDisabled ? 'disabled' : null" [class.disabled]="isDisabled">
            <input ngbButton type="radio" [value]="1" /> Imperial (ft / lb)
        </label>
        <label ngbButtonLabel class="btn-primary-inverse" [attr.disabled]="isDisabled ? 'disabled' : null" [class.disabled]="isDisabled">
            <input ngbButton type="radio" [value]="2" /> Metric (m / kg)
        </label>
    </div>
    <div class="row">
        <ng-container *ngIf="currentValue?.MeasurementTypeId == 1">
            <div class="col-md-4">
                <div class="col-xs-9">
                    <mt-numeric formControlName="HeightFeet" [type]="numericControlTypes.Integer" [doNotAllowZero]="true" [disabled]="isDisabled">
                    </mt-numeric>
                </div>
                <div class="col-xs-3 measurement-label-text">ft</div>
            </div>
            <div class="col-md-4">
                <div class="col-xs-9">
                    <mt-numeric formControlName="HeightInches" [type]="numericControlTypes.Integer" [doNotAllowZero]="false" [disabled]="isDisabled">
                    </mt-numeric>
                </div>
                <div class="col-xs-3 measurement-label-text">in</div>
            </div>
        </ng-container>
        <ng-container *ngIf="currentValue?.MeasurementTypeId == 2">
            <div class="col-md-4">
                <div class="col-xs-9">
                    <mt-numeric
                        formControlName="HeightMetric"
                        [type]="numericControlTypes.Float"
                        [scale]="2"
                        [doNotAllowZero]="true"
                        [disabled]="isDisabled"
                    >
                    </mt-numeric>
                </div>
                <div class="col-xs-3 measurement-label-text">m</div>
            </div>
        </ng-container>
        <div class="col-md-4">
            <div class="col-xs-9">
                <mt-numeric formControlName="Weight" [type]="numericControlTypes.Float" [scale]="1" [doNotAllowZero]="true" [disabled]="isDisabled">
                </mt-numeric>
            </div>
            <div class="col-xs-3 measurement-label-text">{{ currentValue?.MeasurementTypeId == 1 ? 'lb' : 'kg' }}</div>
        </div>
    </div>
    <div class="bmi-value">
        <span *ngIf="currentValue?.BMI">calculated BMI {{ currentValue?.BMI }}</span>
    </div>
</dynamic-form-input-wrapper>
