import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { FamilyMemberHistoryDynamicConfig } from './individual-family-history.dynamic-config';
import { IDonorFamilyMemberHistory } from '@model/interfaces/donor-family-member-history';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IFamilyHistory } from '@model/interfaces/family-history';
import { DonorFamilyMemberHistoryDynamicControlsPartial } from '@model/partials/donor-family-member-history-partial.form-controls';
import { FamilyHistoryService } from '../../../services/family-history.service';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { CommonService } from '@common/services/common.service';

const CONFIG_CONTROLS = [
    'EyeColorId',
    'HairColorId',
    'HairTextureId',
    'SkinTypeId',
    'RaceId',
    'Height',
    'HeightUnitId',
    'Weight',
    'WeightUnitId',
    'EducationCompletedTypeId',
    'Occupation',
    'Skills',
    'PlaceOfBirth',
];

@Component({
    selector: 'individual-family-history',
    templateUrl: './individual-family-history.component.html',
})
export class IndividualFamilyHistoryComponent implements OnInit {
    @Input('individual') individual: IDonorFamilyMemberHistory;
    @Input('metaData') metaData: IPersonalInformationMetaData;
    @Input() heightUnits: IUnitsOfMeasurement[];
    @Input() weightUnits: IUnitsOfMeasurement[];
    @Input() siblingIndex: number;
    @Input() sectionTitle: string;
    @Output() onSave = new EventEmitter<boolean>();

    formFactory: any;
    config: any;
    configControls: string[];
    heightUnitId: number;
    isDeceased: boolean;

    // abstract controls
    abstractApplicationFamilyMemberHistoryControls: any;
    FamilyHistoryForm: FormGroup;

    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;
    countries: ICountryMetaItem[];

    get isMetric(): boolean {
        return this.heightUnitId === UnitsOfMeasurementEnums.Meters ? true : false;
    }

    get isSibling(): boolean {
        return this.siblingIndex !== null && this.siblingIndex !== undefined;
    }

    get isHalfSibling(): boolean {
        return !this.individual.IsFullSibling;
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private familyHistoryService: FamilyHistoryService,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        this.heightUnitId = this.individual.HeightUnitId;
        this.isDeceased = this.individual.Deceased;
        this.commonService.getCountries().subscribe(() => {
            this.countries = this.commonService.getCountryMetaItems();
            this.initForm();
        });
    }

    initForm(): void {
        this.setConfig();
        this.createForm();
    }

    setConfigControls(): void {
        this.configControls = [...CONFIG_CONTROLS];
        if (this.isSibling) {
            this.configControls = this.configControls.filter((c) => c !== 'PlaceOfBirth');
        }
        if (this.isDeceased) {
            this.configControls.unshift('Deceased', 'AgeAtDeath', 'CauseOfDeath');
        } else {
            this.configControls.unshift('Deceased', 'Age');
        }
        if (this.isSibling && this.isHalfSibling) {
            this.configControls.unshift('IsHalfSiblingFather', 'IsHalfSiblingMother');
        }
        if (this.isSibling && !this.isHalfSibling) {
            this.configControls.unshift('IsFullSibling');
        }
    }

    setConfig(): void {
        this.setConfigControls();
        this.formFactory = new FamilyMemberHistoryDynamicConfig<IDonorFamilyMemberHistory>(
            this.individual,
            this.metaData.EducationCompletedTypes,
            this.metaData.EyeColors,
            this.metaData.HairColors,
            this.metaData.HairTextures,
            this.heightUnits,
            this.metaData.Races,
            this.metaData.SkinTypes,
            this.weightUnits,
            this.countries,
            this.configControls,
        );
        this.config = this.formFactory.getForUpdate();
    }

    createForm(): void {
        this.getControls();
        this.formCreated = true;
        this.FamilyHistoryForm = this.assignFormGroups();
        this.cdr.detectChanges();
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            FamilyHistory: this.fb.group({}),
        });
    }

    getControls(): void {
        this.abstractApplicationFamilyMemberHistoryControls = new DonorFamilyMemberHistoryDynamicControlsPartial(this.individual, {
            countries: this.countries,
            educationCompletedTypes: this.metaData.EducationCompletedTypes,
            eyeColors: this.metaData.EyeColors,
            formGroup: 'FamilyHistory',
            hairColors: this.metaData.HairColors,
            hairTextures: this.metaData.HairTextures,
            heightUnits: this.heightUnits,
            races: this.metaData.Races,
            skinTypes: this.metaData.SkinTypes,
            weightUnits: this.weightUnits,
        }).Form;
    }

    onHeightUnitChange(value): void {
        this.heightUnitId = value;
    }

    onDeceasedValueChange(value): void {
        this.isDeceased = value;
    }

    formSubmitted(): void {
        if (this.FamilyHistoryForm.valid) {
            const updatedHistory = this.normalizeSubmittedData(this.FamilyHistoryForm.value.FamilyHistory);
            this.familyHistoryService.update(updatedHistory).subscribe((resp) => {
                this.success();
            });
        } else {
            this.error();
        }
    }

    normalizeSubmittedData(value: any): IFamilyHistory {
        const basicInfo = Object.assign(this.individual, value);
        if (this.isMetric) {
            basicInfo.Height = Number(basicInfo.Meters);
        } else {
            basicInfo.Height = Number(basicInfo.Feet) * 12 + Number(basicInfo.Inches);
        }
        basicInfo.HeightUnitId = value.SelectedHeightUnit;
        basicInfo.WeightUnitId = value.SelectedWeightUnit;
        if (basicInfo.PlaceOfBirth !== null && basicInfo.PlaceOfBirth !== undefined) {
            basicInfo.PlaceOfBirth = this.countries.find((c) => c.Id === basicInfo.PlaceOfBirth).CountryCode;
        }
        return basicInfo;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        markAllFormFieldsAsTouched(this.FamilyHistoryForm);
        this.notificationsService.error('Save failed. Please check the form and try again.');
        this.enableDoubleClick();
    }

    success(): void {
        this.initForm();
        this.notificationsService.success('Family History saved successfully.');
        this.enableDoubleClick();
        this.isEditing = false;
        this.onSave.emit(true);
    }
}
