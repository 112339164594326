<app-upload-documents
    *ngIf="showDocumentsCard && documentTypes"
    [entityId]="entityId"
    [canEdit]="canUpload"
    [canDownload]="canDownload"
    [canDelete]="canDelete"
    [entityType]="entityType"
    [documentTypes]="documentTypes"
>
</app-upload-documents>
