import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { NotificationService } from '../../common/services/notification.service';
import { NotificationDynamicConfig } from '../notification.dynamic-config';
import { ApplicationStatusService } from '@common/services/application-status.service';
import { DonorStatusesService } from '@common/services/donor-statuses.service';
import { NotificationTypeService } from '../services/notification-type.service';

import { INotification } from '@model/interfaces/notification';
import { IApplicationStatus } from '@model/interfaces/application-status';
import { IDonorStatus } from '@model/interfaces/donor-status';
import { INotificationType } from '@model/interfaces/notification-type';
import { AppNotificationTypes } from '@model/enums/app-notification-types.enum';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { CommonService } from '@common/services/common.service';
import { IWysiwygComponentConfig } from '@mt-ng2/wysiwyg-control';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { notificationTypes } from '@model/enums/notification-types-enums';

@Component({
    selector: 'app-notification-basic-info',
    templateUrl: './notification-basic-info.component.html',
})
export class NotificationBasicInfoComponent implements OnInit {
    get isNewNotification(): boolean {
        return this.notification && this.notification.Id ? false : true;
    }
    @Input() notification: INotification;
    @Input() canEdit: boolean;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: NotificationDynamicConfig<INotification>;
    doubleClickIsDisabled = false;

    applicationStatuses: IApplicationStatus[];
    donorStatuses: IDonorStatus[];
    notificationTypes: INotificationType[];

    bodyCurrentLength = 0;
    showBodyLength = false;
    inputWYSIWYG: DynamicField;
    bodyMaxLength = 10000;

    logoFull: string;

    isNotificationTypeText = false;

    protected environmentService: EnvironmentService;

    noteWysiwygConfig: IWysiwygComponentConfig = {
        height: 200, // in pixels
        toolbar: ['bold', 'italic', 'underline', 'strike', 'link', 'bullet-list', 'ordered-list'],
    };

    constructor(
        private notificationService: NotificationService,
        private applicationStatusService: ApplicationStatusService,
        private donorStatusService: DonorStatusesService,
        private notificationTypeService: NotificationTypeService,
        private notificationsService: NotificationsService,
        private router: Router,
        private commonService: CommonService,
        injector: Injector,
    ) {
        this.environmentService = injector.get(EnvironmentService);
        this.logoFull = `${this.environmentService.config.imgPath}logo-full.png`;
    }

    ngOnInit(): void {
        forkJoin(this.applicationStatusService.getItems(), this.donorStatusService.getItems(), this.notificationTypeService.getItems()).subscribe(
            (answers) => {
                const [applicationStatuses, donorStatuses, notificationTypes] = answers;
                this.applicationStatuses = applicationStatuses;
                this.donorStatuses = donorStatuses;
                this.notificationTypes = notificationTypes;

                this.setConfig();
            },
        );
        this.isNotificationTypeText = notificationTypes.DonorText === this.notification.NotificationTypeId;
    }

    bodyContentChanged(text: string): void {
        if (text) {
            let textWithNoHTML = this.commonService.stripHtml(text);
            this.bodyCurrentLength = textWithNoHTML.length;
        }
    }

    setBodyLength(): void {
        this.showBodyLength = true;
        let pseudoElement = document.createElement('span');
        pseudoElement.innerHTML = this.notification.Body;
        let textWithNoHTML = this.commonService.stripHtml(pseudoElement.innerText);
        this.bodyCurrentLength = textWithNoHTML.length;
    }

    setConfig(): void {
        let configControls = null;
        if (this.notification.NotificationTypeId === AppNotificationTypes.DonorText) {
            configControls = ['Name', 'Body'];
        }
        this.formFactory = new NotificationDynamicConfig<INotification>(
            this.notification,
            this.applicationStatuses,
            this.donorStatuses,
            this.notificationTypes,
            configControls,
        );
        this.config = this.formFactory.getForUpdate();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewNotification) {
            this.router.navigate(['/notifications']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.notification, form.value.Notification);
            if (this.bodyCurrentLength > this.bodyMaxLength) {
                markAllFormFieldsAsTouched(form);
                this.notificationsService.error('Body character limit exceeded');
                setTimeout(() => {
                    this.doubleClickIsDisabled = false;
                });
            } else {
                this.notification.Body = this.insertCompanyLogo(this.notification.Body);
                this.saveNotification();
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private insertCompanyLogo(notificationBody: string): string {
        let companyLogoImg = document.createElement('img');
        companyLogoImg.src = this.logoFull;
        companyLogoImg.alt = 'logo';
        companyLogoImg.style.maxWidth = '15%';
        let companyLogoImgHtml = companyLogoImg.outerHTML;
        notificationBody = notificationBody.replace('[[the_world_egg_bank_logo]]', companyLogoImgHtml);
        return notificationBody;
    }

    private saveNotification(): void {
        if (this.isNewNotification) {
            this.notificationService
                .create(this.notification)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.notification.Id = answer;
                    this.success(true);
                });
        } else {
            this.notificationService
                .update(this.notification)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    private success(newNotificationSave?: boolean): void {
        if (newNotificationSave) {
            this.router.navigate([`/notifications/${this.notification.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.notificationService.emitChange(this.notification);
        this.notificationsService.success('Notification saved successfully.');
    }
}
