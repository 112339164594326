import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { saveFileHelper } from '@common/libraries/file-save.library';
import { IMetaItem } from '@model/interfaces/base';
import { ExtraSearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClinicService } from '../../clinics/services/clinic.service';
import { QualitySummaryReportService } from '../services/quality-summary-report.service';

@Component({
    selector: 'app-quality-summary-report',
    templateUrl: './quality-summary-report.component.html',
})
export class QualitySummaryReportComponent implements OnInit {
    searchForm: FormGroup = new FormGroup({
        donorId: new FormControl(null, { validators: [Validators.pattern(/^[0-9]+$/)] }),
        email: new FormControl(),
        name: new FormControl(),
        recipientId: new FormControl(null, { validators: [Validators.pattern(/^[0-9]+$/)] }),
    });

    clinics: IMetaItem[];
    selectedClinic: IMetaItem;

    constructor(
        private clinicService: ClinicService,
        private qualitySummaryReportService: QualitySummaryReportService,
        private notification: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.clinicService.getClinicMetaItems().subscribe((clinics) => (this.clinics = clinics));
    }

    onGenerateReport(): void {
        if (this.searchForm.valid) {
            let searchParams = this.buildSearch();
            if (searchParams.length > 0 && searchParams.some((x) => x.value !== '')) {
                const searchEntity: IEntitySearchParams = {
                    extraParams: searchParams,
                    order: 'DonorId',
                    orderDirection: 'desc',
                    query: '',
                    skip: null,
                    take: null,
                };
                this.qualitySummaryReportService.get(searchEntity).subscribe(
                    (doc) => {
                        saveFileHelper(doc, `QualitySummaryReport.pdf`, 'application/pdf', true);
                    },
                    (err) => {
                        if (err.error?.ModelState[0]) {
                            this.notification.error(err.error.ModelState[0]);
                        } else {
                            this.notification.error('There was an unknown error generating the report.');
                        }
                    },
                );
            } else {
                this.notification.error('You need to add at least one filter value to generate a report.');
            }
        } else {
            this.notification.error('Input is invalid, please check the form and try again.');
        }
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        if (this.selectedClinic) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'clinicId',
                    value: this.selectedClinic.Id.toString(),
                }),
            );
        }

        if (this.searchForm?.get('recipientId')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recipientId',
                    value: this.searchForm.get('recipientId').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('donorId')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'donorId',
                    value: this.searchForm.get('donorId').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('email')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'email',
                    value: this.searchForm.get('email').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('name')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'name',
                    value: this.searchForm.get('name').value.toString(),
                }),
            );
        }

        return _extraSearchParams;
    }

    selectionChanged(event: any): void {
        if (event.selection) {
            this.selectedClinic = event.selection;
        } else {
            this.selectedClinic = null;
        }
    }
}
