import { IReportUser, IReportUserRole } from '@mt-ng2/advanced-reporting-module-config';
import { IMetaItem } from '@mt-ng2/base-service';

import { IReportAccessShare } from '../model/interfaces/report-access-share';

export const unselectedNewShare: IReportAccessShare = {
    CanEdit: false,
    Id: 0,
    Name: null,
    ReportId: 0,
    UserId: 0,
    UserRoleId: 0,
};

export function reorderReportAccessShares(
    reportAccessShares: IReportAccessShare[],
    users: IReportUser[],
    roles: IReportUserRole[],
): IReportAccessShare[] {
    const arrayToSort = [...reportAccessShares];

    arrayToSort.sort((a, b) => {
        if (a.UserRoleId && !b.UserRoleId) {
            return -1;
        }
        if (!a.UserRoleId && b.UserRoleId) {
            return 1;
        }
        let aName = '';
        let bName = '';
        if (a.UserRoleId && b.UserRoleId) {
            aName = getNameFromUsersOrRoles(roles, a.UserRoleId);
            bName = getNameFromUsersOrRoles(roles, b.UserRoleId);
        }
        if (!a.UserRoleId && !b.UserRoleId) {
            aName = getNameFromUsersOrRoles(users, a.UserId);
            bName = getNameFromUsersOrRoles(users, b.UserId);
        }
        if (aName > bName) {
            return 1;
        }
        if (aName < bName) {
            return -1;
        }
        return 0;
    });

    reportAccessShares = arrayToSort;
    return reportAccessShares;
}

function getNameFromUsersOrRoles(list: IMetaItem[], id: number): string {
    if (!list?.length) {
        return '';
    }
    const item = list.find((item) => item.Id === id);
    return item?.Name?.toLowerCase() ?? '';
}

export function getNewShareForUserRole(role: IReportUserRole, reportId: number): IReportAccessShare {
    return {
        CanEdit: false,
        Id: 0,
        Name: `User Role: ${role.Name}`,
        ReportId: reportId,
        UserId: null,
        UserRoleId: role.Id,
    };
}

export function getNewShareForUser(user: IReportUser, reportId: number): IReportAccessShare {
    return {
        CanEdit: false,
        Id: 0,
        Name: `User: ${user.Name}`,
        ReportId: reportId,
        UserId: user.Id,
        UserRoleId: null,
    };
}
