import { InjectionToken } from '@angular/core';

import { IAuditLogUserService } from './audit-log-user-service';
import { IAuditLogExportConfig } from './audit-log-export-config';

export interface IAuditLogModuleConfig {
    auditLogClaimTypeId: number;
    auditLogUserService: IAuditLogUserService;
    enabled: boolean;
    ignorePageTitleCalls?: boolean;
    itemsPerPage: number;
    minCharactersToSearchUsers: number;
    exportEnabled?: boolean;
    exportConfig?: IAuditLogExportConfig;
}

export const AuditLoggingModuleConfigToken = new InjectionToken<IAuditLogModuleConfig>('audit_log_module_config');

export const AuditLoggingModuleUserServiceToken = new InjectionToken<IAuditLogUserService>('audit_log_user_service');
