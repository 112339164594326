import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

export interface ISearchFilterCheckboxValueChangedEvent {
    event: Event;
    value: boolean;
}

@Component({
    selector: 'mt-search-filter-checkbox',
    styles: [
        `
            input[type='checkbox'] {
                margin: 0;
            }
        `,
    ],
    template: `
        <button class="btn btn-transparent" type="button" (click)="buttonClicked($event)">
            <input type="checkbox" [(ngModel)]="value" (ngModelChange)="checkboxValueChanged($event)" (click)="checkboxClicked($event)" />
            <span>{{ text }}</span>
        </button>
    `,
})
export class MtSearchFilterCheckboxComponent {
    private errorHandler = new ComponentErrorHandler('MtSearchFilterCheckboxComponent', '@mt-ng2/search-filter-checkbox-control');
    // value is two-way bound
    @Input() value = false;

    @Input() text: string;

    @Output('onValueChanged') valueChanged: EventEmitter<ISearchFilterCheckboxValueChangedEvent> = new EventEmitter<
        ISearchFilterCheckboxValueChangedEvent
    >();

    constructor() {}

    buttonClicked(event: Event): void {
        if (event === undefined) {
            this.errorHandler.addError('in buttonClicked event cannot be undefined');
        }
        this.value = !this.value;
        this.checkboxValueChanged(event);
    }

    checkboxClicked(event: Event): void {
        if (event === undefined) {
            this.errorHandler.addError('in checkboxClicked event cannot be undefined');
        }
        // checkbox is inside button, so stop propagation to button click doesn't also end up firing
        event.stopPropagation();
    }

    checkboxValueChanged(event: Event): void {
        if (event === undefined) {
            this.errorHandler.addError('in checkboxValueChanged event cannot be undefined');
        }
        this.valueChanged.emit({ event: event, value: this.value });
    }
}
