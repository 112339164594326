import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICycleResultOption } from '@model/interfaces/cycle-result-option';
import { IBloodType } from '@model/interfaces/blood-type';
import { ICarrierOption } from '@model/interfaces/carrier-option';
import { ICycleDetailManagedData } from '@model/interfaces/custom/cycle-detail-managed-data';
import { ICycleDetailStaticMetaDataDto } from '@model/interfaces/custom/cycle-detail-metadata-dto';
import { ICycleStep } from '@model/interfaces/cycle-step';
import { IMeasurementType } from '@model/interfaces/measurement-type';
import { IYesNoOption } from '@model/interfaces/yes-no-option';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { DeviceLotNumberService } from '../../managed-lists/services/device-lot-number.service';
import { IncubatorNumberService } from '../../managed-lists/services/incubator-number.service';
import { KitLotNumberService } from '../../managed-lists/services/kit-lot-number.service';
import { RetrievalPhysicianService } from '../../managed-lists/services/retrieval-physician.service';
import { TestingCompaniesService } from '../../managed-lists/services/testing-companies.service';
import { VitDeviceTypeService } from '../../managed-lists/services/vit-device-type.service';
import { VitMediaTypeService } from '../../managed-lists/services/vit-media-type.service';
import { VitMethodService } from '../../managed-lists/services/vit-method.service';

@Injectable({ providedIn: 'root' })
export class CycleDataService {
    private cycleDetailData: ICycleDetailStaticMetaDataDto;
    private callBeingMade = false;
    private callReturnedValue$ = new Subject<ICycleDetailStaticMetaDataDto>();

    constructor(
        public http: HttpClient,
        private incubatorNumberService: IncubatorNumberService,
        private kitLotNumberService: KitLotNumberService,
        private retrievalPhysicianService: RetrievalPhysicianService,
        private testingCompaniesService: TestingCompaniesService,
        private vitDeviceTypeService: VitDeviceTypeService,
        private vitMediaTypeService: VitMediaTypeService,
        private vitMethodService: VitMethodService,
        private deviceLotNumberService: DeviceLotNumberService,
    ) { }

    getCycleMetaData(): Observable<ICycleDetailStaticMetaDataDto> {
        if (!this.cycleDetailData) {
            return this.getCycleMetaDataFromApi();
        } else {
            return of(this.cycleDetailData);
        }
    }

    getActiveRetrievalPhysicians(cycleId: number): void {
        this.retrievalPhysicianService.getActiveRetrievalPhysicans(cycleId).subscribe((activePhysicians) => { return activePhysicians; });
    }

    getCycleManagedMetaData(cycleId: number): Observable<ICycleDetailManagedData> {
        return forkJoin(
            this.incubatorNumberService.getItems(),
            this.kitLotNumberService.getItems(),
            this.retrievalPhysicianService.getActiveRetrievalPhysicans(cycleId),
            this.testingCompaniesService.getItems(),
            this.vitDeviceTypeService.getItems(),
            this.vitMediaTypeService.getItems(),
            this.vitMethodService.getItems(),
            this.deviceLotNumberService.getItems(),
        ).pipe(
            map(
                ([
                    incubatorNumbers,
                    kitLotNumbers,
                    retrievalPhysicians,
                    testingCompanies,
                    vitDeviceTypes,
                    vitMediaTypes,
                    vitMethods,
                    deviceLotNumbers,
                ]) => {
                    return {
                        DeviceLotNumbers: deviceLotNumbers,
                        IncubatorNumbers: incubatorNumbers,
                        KitLotNumbers: kitLotNumbers,
                        RetrievalPhysicians: retrievalPhysicians,
                        TestingCompanies: testingCompanies,
                        VitDeviceTypes: vitDeviceTypes,
                        VitMediaTypes: vitMediaTypes,
                        VitMethods: vitMethods,
                    };
                },
            ),
        );
    }

    private getCycleMetaDataFromApi(): Observable<ICycleDetailStaticMetaDataDto> {
        if (this.callBeingMade) {
            return this.callReturnedValue$.pipe(take(1));
        } else {
            this.callBeingMade = true;
            return this.http.get<ICycleDetailStaticMetaDataDto>(`/donors/1/cycles/data`).pipe(
                tap((data) => {
                    this.cycleDetailData = data;
                    this.callBeingMade = false;
                    this.callReturnedValue$.next(data);
                }),
            );
        }
    }

    getCycleSteps(): Observable<ICycleStep[]> {
        return this.getCycleMetaData().pipe(map((data) => data.CycleSteps));
    }

    getAcceptableOptions(): Observable<ICycleResultOption[]> {
        return this.getCycleMetaData().pipe(map((data) => data.CycleResultOption));
    }

    getYesNoOptions(): Observable<IYesNoOption[]> {
        return this.getCycleMetaData().pipe(map((data) => data.YesNoOption));
    }

    getBloodTypeOptions(): Observable<IBloodType[]> {
        return this.getCycleMetaData().pipe(map((data) => data.BloodTypeOption));
    }

    getCarrierOptions(): Observable<ICarrierOption[]> {
        return this.getCycleMetaData().pipe(map((data) => data.CarrierOption));
    }

    getMeasurementTypes(): Observable<IMeasurementType[]> {
        return this.getCycleMetaData().pipe(map((data) => data.MeasurementTypes));
    }
}
