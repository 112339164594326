import { Component, Input, OnInit } from '@angular/core';

import { IMedicalIssue } from '@model/interfaces/medical-issue';
import { IDonorIssuesMedicalIssue } from '@model/interfaces/donor-issues-medical-issue';
import { IMedicalIssueRelationshipType } from '@model/interfaces/medical-issue-relationship-type';

@Component({
    selector: 'donor-medical-issue',
    templateUrl: './donor-medical-issue.component.html',
})
export class DonorMedicalIssuesComponent implements OnInit {
    @Input() donorIssueMedicalIssue: IDonorIssuesMedicalIssue;
    @Input() medicalIssueRelationshipTypes: IMedicalIssueRelationshipType[];

    medicalIssue: IMedicalIssue;
    selectedRelationships: IMedicalIssueRelationshipType[];
    subIssues: IMedicalIssue[];
    subissue1Relationships: IMedicalIssueRelationshipType[];
    subissue2Relationships: IMedicalIssueRelationshipType[];

    constructor() {}

    ngOnInit(): void {
        this.medicalIssue = this.donorIssueMedicalIssue.MedicalIssue;
        this.selectedRelationships = this.filterRelationshipTypes(this.donorIssueMedicalIssue);
        this.subIssues = this.donorIssueMedicalIssue.MedicalIssue.MedicalIssues.filter(
            (i) => i.DonorIssuesMedicalIssues[0] && this.relationshipIsSelected(i.DonorIssuesMedicalIssues[0]),
        );
        if (this.subIssues && this.subIssues.length) {
            this.subissue1Relationships = this.filterRelationshipTypes(this.subIssues[0].DonorIssuesMedicalIssues[0]);
            if (this.subIssues[1]) {
                this.subissue2Relationships = this.filterRelationshipTypes(this.subIssues[1].DonorIssuesMedicalIssues[0]);
            }
        }
    }

    relationshipIsSelected(issue: IDonorIssuesMedicalIssue): boolean {
        const props = ['Mother', 'Father', 'You', 'Sibling', 'Grandparent', 'Children', 'None'];
        return props.some((p) => issue[p]);
    }

    filterRelationshipTypes(issue: IDonorIssuesMedicalIssue): IMedicalIssueRelationshipType[] {
        return this.medicalIssueRelationshipTypes.filter((type) => issue[type.Name]);
    }

    get medicalIssueName(): string {
        return this.medicalIssue && this.medicalIssue.Name ? this.medicalIssue.Name : '';
    }
}
