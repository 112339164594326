<h2>Managed Lists</h2>
<div *ngIf="isLoaded">
    <div class="row">
        <div class="col-md-6">
            <select [ngModel]="selectedList" [ngModelOptions]="{ standalone: true }" class="form-control" (ngModelChange)="selectionChanged($event)">
                <option></option>
                <option *ngFor="let list of managedList" [ngValue]="list">
                    {{ list.Name }}
                </option>
            </select>
        </div>
    </div>
    <br />
    <div *ngIf="KitLotNumbersSelected || ReferralTypesSelected">
        <label for="chkIncludeNotInUse">Include Not In Use</label>
        <input type="checkbox" id="chkIncludeNotInUse" [(ngModel)]="IncludeNotInUse" (change)="filterItems()" />
    </div>
    <div class="row">
        <div class="col-md-6" *ngIf="isListLoaded && items" [ngClass]="{ 'referral-columns': ReferralTypesSelected }">
            <mt-managed-list
                componentTitle="{{ title }}"
                [items]="items"
                [dynamicForm]="dynamicForm"
                [fields]="fields"
                (onSave)="saveItems($event)"
            ></mt-managed-list>
        </div>
    </div>
</div>
