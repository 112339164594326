import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { ApplicationDetailComponent } from './application-detail/application-detail.component';
import { ApplicationsComponent } from './application-list/applications.component';
import { ApplicationService } from './services/application.service';
import { ApplicationHeaderComponent } from './application-header/application-header.component';

import { ClaimTypes } from '@model/ClaimTypes';

const applicationEntityConfig = {
    claimType: ClaimTypes.DonorApplication,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'applicationId',
    service: ApplicationService,
    title: 'Application Detail',
};

const applicationListRoleGuard: any = {
    claimType: ClaimTypes.DonorApplication,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Applications',
};

export const applicationPaths = {
    applications: 'applications',
};

const applicationRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ApplicationsComponent,
        data: applicationListRoleGuard,
        path: applicationPaths.applications,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: ApplicationDetailComponent, path: '', pathMatch: 'full' }],
        component: ApplicationHeaderComponent,
        data: applicationEntityConfig,
        path: `applications/:${applicationEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(applicationRoutes)],
})
export class ApplicationRoutingModule {}
