<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Donor Inventory Report"></mt-page-title>
        </h2>
    </div>
</div>
<div id="search-panel" [formGroup]="searchForm">
    <div class="row">
        <div class="col-md-2">
            <label>Recipient ID</label>
            <input
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                type="number"
                class="form-control"
                formControlName="recipientId"
            />
        </div>
        <div class="col-md-2">
            <label>Donor ID</label>
            <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" class="form-control" formControlName="donorId" />
        </div>
        <div class="col-md-2">
            <label>Email</label>
            <input type="text" class="form-control" formControlName="recipientEmail" />
        </div>
        <div class="col-md-2">
            <label>Name</label>
            <input type="text" class="form-control" formControlName="recipientName" />
        </div>
        <div class="col-md-2">
            <label>Clinic Name</label>
            <input type="text" class="form-control" formControlName="clinicName" />
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-2">
            <label>Country</label>
            <br />
            <mt-search-filter-select [items]="countries" entity="Countries" (onSelectionChanged)="search()"></mt-search-filter-select>
        </div>
        <div class="col-md-2">
            <label>Warming Date</label><br />
            <mt-search-filter-daterange
                [startDate]="startDate"
                [endDate]="endDate"
                entity="Warming Date"
                (onSelectionChanged)="dateSelectionChanged($event)"
            ></mt-search-filter-daterange>
        </div>
        <div class="col-md-2 col-md-offset-4">
            <results-per-page (onSizeChange)="onPageSizeChange($event)"></results-per-page>
        </div>
    </div>
    <br />
</div>

<entity-list
    [entities]="donorInventory"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (onPageChanged)="getDonorCycles()"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>
