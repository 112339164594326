import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

import { IReport } from './model/interfaces/report';
import { IDataset } from './model/interfaces/dataset';
import { IReportDynamicControlsParameters, ReportDynamicControls } from './model/form-controls/report.form-controls';

export class ReportDynamicConfig<T extends IReport> extends DynamicConfig<T> implements IDynamicConfig<T> {

    constructor(
        private report: T,
        private datasets: IDataset[],
        private configControls?: string[]) {
        super();
        const additionalParams: IReportDynamicControlsParameters = {
            datasets: this.datasets,
        };
        const dynamicControls = new ReportDynamicControls(this.report, additionalParams);
         // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'DatasetId'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        let formObject = this.getFormObject(additionalConfigs);
        formObject = formObject.filter((item) => item.name !== 'DatasetId');
        return {
            formObject: formObject,
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
