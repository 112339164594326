import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IRecipientCycle } from '@model/interfaces/recipient-cycle';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { INewRecipientCycleDto } from '@model/interfaces/custom/new-recipient-cycle-dto';
import { IRecipientSalesOrder } from '@model/interfaces/recipient-sales-order';

export const emptyRecipientCycle: INewRecipientCycleDto = {
    CreatedDate: new Date(),
    Id: 0,
    ProductTypeId: 0,
    RecipientId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class RecipientCycleService extends BaseService<IRecipientCycle> {
    constructor(public http: HttpClient) {
        super('/recipients', http);
    }

    getEmptyRecipientCycle(): INewRecipientCycleDto {
        return { ...emptyRecipientCycle };
    }

    getRecipientCycles(recipientId: number, searchparameters?: SearchParams): Observable<HttpResponse<IRecipientCycle[]>> {
        let params = this.getHttpParams(searchparameters);
        return this.http
            .get<IRecipientCycle[]>(`/recipients/${recipientId}/cycles/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }

    createNewRecipientCycle(recipientId: number, cycle: INewRecipientCycleDto): Observable<IRecipientCycle> {
        return this.http.post<IRecipientCycle>(`/recipients/${recipientId}/cycles`, cycle);
    }

    saveRecipientCycle(recipientId: number, cycle: IRecipientCycle): Observable<number> {
        // null FKs due to strict JSON parsing on the backend
        cycle.RecipientSalesOrders = null;
        cycle.RecipientCycleStatus = null;
        cycle.Recipient = null;
        return this.http.put<number>(`/recipients/${recipientId}/cycles`, cycle);
    }

    getCycleSalesOrders(recipientId: number, cycleId: number): Observable<IRecipientSalesOrder[]> {
        return this.http.get<IRecipientSalesOrder[]>(`/recipients/${recipientId}/cycles/${cycleId}/sales-orders`);
    }

    getSalesOrderDetails(salesOrderId: number): Observable<IRecipientSalesOrder> {
        return this.http.get<IRecipientSalesOrder>(`/sales-order/${salesOrderId}`);
    }
}
