import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDonorBasicInfo } from '@model/interfaces/donor-basic-info';

@Injectable({ providedIn: 'root' })
export class DonorSsnService {
    constructor(private httpClient: HttpClient) {}

    getDonorSsn(id: number): Observable<string> {
        return this.httpClient.get<string>(`/api/v1/donor-ssn/${id}`);
    }

    updateDonorSsn(id: number, data: IDonorBasicInfo): Observable<IDonorBasicInfo> {
        return this.httpClient.post<IDonorBasicInfo>(`/api/v1/donor-ssn/${id}`, data);
    }
}
