import { Validators } from '@angular/forms';

import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, noZeroRequiredValidator, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IDataset } from '../interfaces/dataset';
import { IReportColumn } from '../interfaces/report-column';
import { IReport } from '../interfaces/report';

export interface IReportDynamicControlsParameters {
    formGroup?: string;
    datasets?: IDataset[];
    columns?: IReportColumn[];
}

export class ReportDynamicControls {
    formGroup: string;
    datasets: IDataset[];
    columns: IReportColumn[];

    Form: any;
    View: any;

    constructor(private report?: IReport, additionalParameters?: IReportDynamicControlsParameters) {
        this.formGroup = additionalParameters?.formGroup ?? 'Report';
        this.datasets = additionalParameters?.datasets ?? undefined;

        this.Form = {
            Columns: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dataset',
                name: 'Columns',
                options: this.columns,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: null,
            }),
            DatasetId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dataset',
                name: 'DatasetId',
                options: this.datasets,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                }),
                validation: [noZeroRequiredValidator],
                validators: { required: true },
                value: this.report?.DatasetId ?? null,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                }),
                validation: [Validators.required, Validators.maxLength(50)],
                validators: { required: true, maxlength: 50 },
                value: this.report?.Name?.toString() ?? '',
            }),
        };

        this.View = {
            DatasetId: new DynamicLabel({
                label: 'Dataset',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                }),
                value: getMetaItemValue(this.datasets, this.report?.DatasetId ?? null),
            }),
            Name: new DynamicLabel({
                label: 'Name',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                }),
                value: this.report?.Name ?? '',
            }),
        };
    }
}
