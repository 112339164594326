import { ICountry } from '../../model/interfaces/country';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IVwDonorInventoryReport } from '@model/interfaces/vw-donor-inventory-report';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class InventoryDonorReportService extends BaseService<IVwDonorInventoryReport> {
    constructor(public http: HttpClient) {
        super('/inventory-donor-report', http);
    }

    get(searchParams: SearchParams): Observable<HttpResponse<IVwDonorInventoryReport[]>> {
        let params = this.getHttpParams(searchParams);
        return this.http
            .get<IVwDonorInventoryReport[]>(`/inventory-donor-report/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }
}
