import { ClinicVerificationDynamicControls } from '../form-controls/clinic-verification.form-controls';
import { IClinicVerification } from '../interfaces/clinic-verification';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IMetaItem } from '@model/interfaces/base';
import { getMetaItemValue } from '@mt-ng2/common-functions';

export class IClinicVerificationDynamicControlsParametersPartial {
    users: IMetaItem[];
    formGroup?: string;
}

export class ClinicVerificationDynamicControlsPartial extends ClinicVerificationDynamicControls {
    users: IMetaItem[];
    constructor(clinicverificationPartial?: IClinicVerification, additionalParameters?: IClinicVerificationDynamicControlsParametersPartial) {
        super(clinicverificationPartial, additionalParameters);
        this.users = additionalParameters.users;

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.PAndEReceivedDate).labelHtml = '<label>P&E Received Date</label>';
        (<DynamicField>this.Form.PAndESentDate).labelHtml = '<label>P&E Sent Date</label>';
        (<DynamicField>this.Form.VerifiedById) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Verified By',
            name: 'VerifiedById',
            options: this.users,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: (clinicverificationPartial && clinicverificationPartial.VerifiedById) || null,
        });

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        (<DynamicLabel>this.View.PAndEReceivedDate).label = 'P&E Received Date';
        (<DynamicLabel>this.View.PAndESentDate).label = 'P&E Sent Date';
        (<DynamicLabel>this.View.VerifiedById).value = getMetaItemValue(
            this.users,
            clinicverificationPartial && clinicverificationPartial.hasOwnProperty('VerifiedById') && clinicverificationPartial.VerifiedById !== null
                ? clinicverificationPartial.VerifiedById
                : null,
        );
    }
}
