import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IVwDonorEggInventory } from '@model/interfaces/vw-donor-egg-inventory';
import { IVwDonorAvailableInventory } from '@model/interfaces/vw-donor-available-inventory';

export class DonorsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'DonorId',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['FirstName'],
                    name: 'First Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['LastName'],
                    name: 'Last Name',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Email'],
                    name: 'Email',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (value: IVwDonorAvailableInventory): string {
                        return value.AvailableInventory ? value.AvailableInventory.toString() : '0';
                    },
                    name: 'Available Eggs',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['PotentialFamilies'],
                    name: 'Potential Families',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
