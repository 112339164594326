import { IMetaItem } from '@model/interfaces/base';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { DonorReviewDynamicControls, IDonorReviewDynamicControlsParameters } from '../form-controls/donor-review.form-controls';
import { IDonorReview } from '../interfaces/donor-review';

export class DonorReviewDynamicControlsPartial extends DonorReviewDynamicControls {
    constructor(donorReviewPartial?: IDonorReview, additionalParameters?: IDonorReviewDynamicControlsParameters) {
        super(donorReviewPartial, additionalParameters);
    }
}
