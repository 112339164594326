import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, IItemDeletedEvent, EntityListExportConfig } from '@mt-ng2/entity-list-module';

import { ClinicService } from '../services/clinic.service';
import { IClinic } from '@model/interfaces/clinic';
import { entityListModuleConfig } from '@common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClinicsEntityListConfig } from './clinics.entity-list-config';
import { StickySearchEntity, StickySearchStateService } from '@common/services/sticky-search.state.service';
import { ISearchbarControlAPI } from '@mt-ng2/searchbar-control';
import { IClinicDto } from '@model/interfaces/custom/clinic-dto';
import { IModalOptions } from '@mt-ng2/modal-module';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-clinics',
    templateUrl: './clinics.component.html',
})
export class ClinicsComponent implements OnInit {
    clinics: IClinicDto[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new ClinicsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddClinic = false;
    searchbarApi: ISearchbarControlAPI;
    clinicIdToDelete: number;
    showDeleteModal = false;

    deleteModalOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        icon: 'warning',
        showCloseButton: false,
        showConfirmButton: false,
    };

    get clinicToDelete(): string {
        if (this.clinics && this.clinicIdToDelete) {
            const clinic = this.clinics.find((c) => c.Id === this.clinicIdToDelete);
            if (clinic) {
                return clinic.Name;
            }
        }
        return '';
    }

    constructor(private clinicService: ClinicService, private claimsService: ClaimsService, private router: Router, private stickySearchService: StickySearchStateService) {}

    ngOnInit(): void {
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Clinics List',
            getDataForExport: this.getClinicsForExport.bind(this),
        });
        this.canAddClinic = this.claimsService.hasClaim(ClaimTypes.Clinics, [ClaimValues.FullAccess]);
    }

    getClinicsForExport(): Observable<IClinicDto[]> {
        return this.getClinicsCall(null, { forExport: true }).pipe(map((answer) => answer.body));
    }

    getClinicsCall(lastSearchEntity?: IEntitySearchParams, options = { forExport: false }): Observable<HttpResponse<IClinicDto[]>> {
        let searchparams: SearchParams;
        if (lastSearchEntity) {
            searchparams = new SearchParams(lastSearchEntity);
        } else {
            const search = this.query;
            const _extraSearchParams: ExtraSearchParams[] = [];

            const searchEntity: IEntitySearchParams = {
                extraParams: _extraSearchParams,
                order: this.order,
                orderDirection: this.orderDirection,
                query: search && search.length > 0 ? search : '',
                skip: options.forExport ? undefined : (this.currentPage - 1) * this.itemsPerPage,
                take: options.forExport ? undefined : this.itemsPerPage,
            };
            this.stickySearchService.setSearch(searchEntity, StickySearchEntity.Clinics);
            searchparams = new SearchParams(searchEntity);
        }
        return this.clinicService.search(searchparams);
    }

    getClinics(search?: IEntitySearchParams): void {
        this.getClinicsCall(search).subscribe((answer) => {
            this.clinics = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getClinics();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getClinics();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getClinics();
    }

    clinicSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/clinics', event.entity.Id]);
    }

    setStickySearch(searchBarApi: ISearchbarControlAPI): void {
        this.searchbarApi = searchBarApi;
        const lastSearch = this.stickySearchService.getSearch(StickySearchEntity.Clinics);
        if (lastSearch) {
            if (lastSearch.query) {
                this.searchbarApi.getSearchControl().patchValue(lastSearch.query);
            }
            this.getClinics(lastSearch);
        } else {
            this.getClinics();
        }
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.getClinics();
    }

    clinicDeleted(event: IItemDeletedEvent): void {
        this.clinicIdToDelete = event.entity.Id;
        this.showDeleteModal = true;
    }

    confirmDelete(): void {
        this.showDeleteModal = false;
        if (this.clinicIdToDelete) {
            this.clinicService.delete(this.clinicIdToDelete).subscribe(() => {
                this.getClinics();
            });
        }
    }

}
