import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ITestingCompany } from '@model/interfaces/testing-company';

@Injectable({
    providedIn: 'root',
})
export class TestingCompaniesService extends MetaItemService<ITestingCompany> {
    constructor(public http: HttpClient) {
        super('TestingCompaniesService', 'TestingCompany', 'TestingCompanyIds', '/testing-companies', http);
    }
}
