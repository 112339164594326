import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApplicantImage } from '@model/interfaces/applicant-image';
import { IApplicationImageDTO } from '@model/interfaces/custom/application-images-dto';
import { BaseService } from '@mt-ng2/base-service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApplicationImageService extends BaseService<IApplicantImage> {
    constructor(public http: HttpClient) {
        super('/application-image', http);
    }

    uploadImage(applicationId: number, files: File[]): Observable<IApplicantImage[]> {
        let formData: FormData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i], files[i].name);
        }
        return this.http.post<IApplicantImage[]>(`/application-image/${applicationId}`, formData, { observe: 'body' });
    }

    deleteImage(applicationId: number, imageId: number): Observable<object> {
        return this.http.delete(`/application-image/${applicationId}/${imageId}`, {
            responseType: 'text' as 'json',
        });
    }

    uploadIdentityImage(applicationId: number, files: File[]): Observable<IApplicantImage[]> {
        let formData: FormData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i], files[i].name);
        }
        return this.http.post<IApplicantImage[]>(`/application-image/identity-image/${applicationId}`, formData, { observe: 'body' });
    }

    deleteIdentityImage(applicationId: number, imageId: number): Observable<object> {
        return this.http.delete(`/application-image/identity-image/${applicationId}/${imageId}`, {
            responseType: 'text' as 'json',
        });
    }

    downloadApplicantIdentityImage(applicationId: number, imageId: number): Observable<any> {
        return this.http.get(`/application-image/identity-image/${applicationId}/${imageId}/download`);
    }
}
