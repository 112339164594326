import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module-config';

import { ClaimTypes } from '@model/ClaimTypes';
import { KeyboardKeys } from '@mt-ng2/keyboard-shortcuts-module';
import { ClaimValues } from '@mt-ng2/auth-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    new NavSidebarParentRowItem({
        children: [
            new NavSidebarRowItem({
                addLink: '/users/add',
                addClaimType: ClaimTypes.Users,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.Users,
                content: 'Users',
                icon: 'fa fa-fw fa-user',
                link: '/users',
                shortcut: {
                    keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'U'],
                    description: 'go to Users',
                },
            }),
            new NavSidebarRowItem({
                addLink: '/roles/add',
                addClaimType: ClaimTypes.UserRoles,
                addClaimValues: [ClaimValues.FullAccess],
                claimType: ClaimTypes.UserRoles,
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
                shortcut: {
                    keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'R'],
                    description: 'go to User Roles',
                },
            }),
            new NavSidebarRowItem({
                claimType: ClaimTypes.Notifications,
                content: 'Notifications',
                icon: 'fa fa-fw fa-envelope',
                link: '/notifications',
            }),
            new NavSidebarRowItem({
                claimType: ClaimTypes.ManagedLists,
                content: 'Managed Lists',
                icon: 'fa fa-list',
                link: '/managed-list',
            }),
        ],
        claimType: ClaimTypes.AppSettings,
        content: 'Administration',
        expanded: false,
        icon: 'fa fa-fw fa-university',
    }),
    new NavSidebarRowItem({
        addLink: '/clinics/add',
        addClaimType: ClaimTypes.Clinics,
        addClaimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.Clinics,
        content: 'Clinics',
        icon: 'fa fa-fw fa-building',
        link: '/clinics',
    }),
    new NavSidebarParentRowItem({
        children: [
            new NavSidebarRowItem({
                claimType: ClaimTypes.Donors,
                content: 'Donors',
                icon: 'fa fa-fw fa-user',
                link: '/donors',
            }),
            new NavSidebarRowItem({
                claimType: ClaimTypes.DonorApplication,
                content: 'Applications',
                icon: 'fa fa-pencil-square-o',
                link: '/applications',
            }),
            new NavSidebarRowItem({
                claimType: ClaimTypes.Appointments,
                content: 'Interview Appointments',
                icon: 'fa fa-fw fa-calendar',
                link: '/appointments',
            }),
        ],
        claimType: ClaimTypes.Donors,
        content: 'Donors',
        expanded: false,
        icon: 'fa fa-fw fa-users',
    }),
    new NavSidebarRowItem({
        addLink: '/recipients/add',
        addClaimType: ClaimTypes.Recipients,
        addClaimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.Recipients,
        content: 'Recipients',
        icon: 'fa fa-fw fa-user',
        link: '/recipients',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.Users,
        content: 'Dashboard',
        icon: 'fa fa-fw fa-list-alt',
        link: '/home',
    }),
    new NavSidebarRowItem({
        addLink: '/reservations/add',
        addClaimType: ClaimTypes.Reservations,
        addClaimValues: [ClaimValues.FullAccess],
        claimType: ClaimTypes.Reservations,
        content: 'Reservations',
        icon: 'fa fa-fw fa-pencil-square-o',
        link: '/reservations',
    }),
    new NavSidebarParentRowItem({
        children: [
            new NavSidebarRowItem({
                claimType: ClaimTypes.Appointments,
                content: 'Donor Cycle Report',
                icon: 'fa fa-fw fa-refresh',
                link: '/donor-cycle-report',
            }),
            new NavSidebarRowItem({
                content: 'Recipient Sales Report',
                icon: 'fa fa-fw fa-dollar',
                link: '/recipients-sales-report',
            }),
            new NavSidebarRowItem({
                content: 'Donor Inventory Report',
                icon: 'fa fa-list-alt',
                link: '/inventory-donor-report',
            }),
            new NavSidebarRowItem({
                content: 'Quality Summary Report',
                icon: 'fa fa-check-square-o',
                link: '/quality-summary-report',
            }),
        ],
        claimType: ClaimTypes.Recipients,
        content: 'Reports',
        expanded: false,
        icon: 'fa fa-fw fa-bar-chart',
    }),
    new NavSidebarRowItem({
        content: 'My Reports',
        icon: 'fa fa-fw fa-briefcase',
        link: '/my-reports',
        claimType: ClaimTypes.Reports,
        claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
        addLink: '/my-reports/add',
        addClaimValues: [ClaimValues.FullAccess],
    }),
];
// tslint:enable:object-literal-sort-keys
