import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IKitLotNumber } from '@model/interfaces/kit-lot-number';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class KitLotNumberService extends MetaItemService<IKitLotNumber> {
    constructor(public http: HttpClient) {
        super('KitLotNumberService', 'Number', 'NumberIds', '/kit-lot-numbers', http);
    }

    updateItems(items: IKitLotNumber[]): Observable<object> {
        // mt managed list defaults value to 0 or '', which results in a failure for the checkbox
        items.forEach((i) => (i.OutOfUse = i.OutOfUse ? true : false));
        return super.updateItems(items);
    }
}
