<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Applications"></mt-page-title>
        </h2>
    </div>
</div>
<div class="row col-md-12">
    <mt-search-bar (onSearch)="search($event)" (ready)="searchbarApi = $event; setStickySearch()"></mt-search-bar>
    <mt-search-filter-select [items]="applicationStatuses" entity="Application Status" (onSelectionChanged)="filterSelectionChanged()">
    </mt-search-filter-select>
    <results-per-page (onSizeChange)="onPageSizeChange($event)"></results-per-page>
    <div class="pull-right">
        <mt-search-filter-checkbox text="Include Denied" [value]="includeDenied" (onValueChanged)="toggleDenied($event)"></mt-search-filter-checkbox>
        <mt-search-filter-checkbox text="Include Archived" [value]="includeArchived" (onValueChanged)="toggleArchived($event)"></mt-search-filter-checkbox>
    </div>
</div>
<div class="row col-md-12">
    <entity-list
        [entities]="applications"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (onPageChanged)="getApplications()"
        (onItemSelected)="applicationSelected($event)"
        (onColumnSorted)="columnSorted($event)"
        [entityListConfig]="entityListConfig"
    >
    </entity-list>
</div>
