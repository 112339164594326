import { Component, Input, OnInit } from '@angular/core';
import { trigger, animate, style, transition } from '@angular/animations';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IMetaItem } from '@mt-ng2/base-service';
import { MultiselectItem, ISelectionChangedEvent } from '@mt-ng2/multiselect-control';
import { ICurrentReportUser } from '@mt-ng2/advanced-reporting-module-config';

import { ReportsService } from '../services/reports.service';
import { DatasetsService } from '../services/datasets.service';
import { ReportDynamicConfig } from '../report.dynamic-config';
import { IReport } from '../model/interfaces/report';
import { IDataset } from '../model/interfaces/dataset';
import { DatasetDatapointsService, flattenDataPoints } from '../services/dataset-datapoints.service';
import { IDatapoint } from '../model/interfaces/datapoint';
import { IReportColumn } from '../model/interfaces/report-column';

@Component({
    animations: [trigger('enterFromRight', [transition(':enter', [style({ right: '-50%' }), animate('.3s ease-in-out', style({ right: 0 }))])])],
    selector: 'report-basic-info',
    template: `
        <div
            class="miles-editable miles-card padded"
            *ngIf="!isEditing && report.Id > 0"
            (mouseenter)="isHovered = true"
            (mouseleave)="isHovered = false"
            (click)="edit()"
            [style.cursor]="canEdit ? 'pointer' : 'default'"
        >
            <h4>
                Report Info
                <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
            </h4>
            <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
        </div>
        <div *ngIf="isEditing" class="miles-form padded col-md-6">
            <h4>Report Info</h4>
            <app-dynamic-form
                [config]="config.formObject"
                ([isEditing])="(isEditing)"
                (submitted)="formSubmitted($event)"
                (formCreated)="subscribeToDatasetChanges($event)"
            >
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            </app-dynamic-form>
        </div>
        <div @enterFromRight *ngIf="dataPointOptions && multiSelectItems" class="miles-form padded col-md-5" style="margin-left: 10px">
            <h4>Columns</h4>
            <multiselect [items]="multiSelectItems" [filterMode]="false" (onSelectionChanged)="selectColumns($event)"></multiselect>
        </div>
    `,
})
export class ReportBasicInfoComponent implements OnInit {
    @Input() report: IReport;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    config: any = {};
    formFactory: ReportDynamicConfig<IReport>;
    datasets: IDataset[];
    doubleClickIsDisabled = false;
    dataPointOptions: IDatapoint[];
    multiSelectItems: MultiselectItem[];
    selectedColumns: IMetaItem[];
    subscriptions: Subscription = new Subscription();

    constructor(
        private claimsService: ClaimsService,
        private datasetsService: DatasetsService,
        private datapointsService: DatasetDatapointsService,
        private reportsService: ReportsService,
        private notificationsService: NotificationsService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.datasetsService.getItems().subscribe((datasets) => {
            const datasetsUserHasAccessTo = datasets.filter((dataset) => {
                return this.claimsService.hasClaim(dataset.PermissionId, [ClaimValues.FullAccess]);
            });
            this.datasets = datasetsUserHasAccessTo;
            this.setConfig();
        });
        this.subscriptions.add(
            this.reportsService.changeEmitted$.subscribe((report: IReport) => {
                this.report = report;
                this.setConfig();
            }),
        );
    }

    subscribeToDatasetChanges(form: FormGroup): void {
        let dataSetControl: AbstractControl = form.get('Report.DatasetId');
        if (dataSetControl) {
            this.subscriptions.add(
                dataSetControl.valueChanges.subscribe((answer) => {
                    if (answer != null) {
                        this.datapointsService.getDatapoints(answer).subscribe((dataPoints) => {
                            this.dataPointOptions = flattenDataPoints(dataPoints);
                            this.multiSelectItems = this.dataPointOptions.map((dataPoint, index) => {
                                let dataPointAsMetaItem: IMetaItem = {
                                    Id: index,
                                    Name: dataPoint.DisplayAs, // !!!@#$QWEFAFD
                                };
                                return new MultiselectItem(dataPointAsMetaItem, false);
                            });
                        });
                    }
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setConfig(): void {
        this.formFactory = new ReportDynamicConfig<IReport>(this.report, this.datasets);

        if (this.report.Id === 0) {
            // new report
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            // existing report
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.report.Id === 0) {
            this.router.navigate(['/my-reports']);
        } else {
            this.isEditing = false;
        }
    }

    private getCurrentReportUser(): ICurrentReportUser {
        return this.route.snapshot.data.currentUser;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.report, form.value.Report);
            if (!this.report.Id || this.report.Id === 0) {
                // handle new report save
                this.report.OwnerId = this.getCurrentReportUser().Id;
                if (this.selectedColumns?.length) {
                    let reportColumns: IReportColumn[] = this.selectedColumns.map((selectedColumn, index) => {
                        let newColumn: IReportColumn = {
                            DatapointPropertyName: this.getDataPointPropertyNameFromDisplayAs(selectedColumn.Name),
                            DisplayAs: selectedColumn.Name,
                            Id: 0,
                            Order: index + 1,
                            ReportId: 0,
                            Width: 200,
                        };
                        return newColumn;
                    });
                    this.report.ReportColumns = reportColumns;
                    this.reportsService
                        .createWithFks(this.report)
                        .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                        .subscribe((answer) => {
                            this.router.navigate([`/my-reports/${answer}/edit`]);
                            this.success();
                            this.reportsService.emitChange(this.report);
                        });
                } else {
                    this.notificationsService.error('Report must contain at least one column.');
                    setTimeout(() => {
                        this.doubleClickIsDisabled = false;
                    });
                }
            } else {
                // handle existing report save
                this.reportsService
                    .update(this.report)
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe(() => {
                        this.isEditing = false;
                        this.success();
                        this.reportsService.emitChange(this.report);
                        this.setConfig();
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Report saved successfully.');
    }

    selectColumns(event: ISelectionChangedEvent): void {
        this.selectedColumns = event.selectedItems;
    }

    getDataPointPropertyNameFromDisplayAs(displayAs: string): string {
        let dataPointOption: IDatapoint = this.dataPointOptions.find((dp) => dp.DisplayAs === displayAs);
        return dataPointOption ? dataPointOption.Name : displayAs;
    }
}
