import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IRecipient } from '@model/interfaces/recipient';
import { IAddress } from '@model/interfaces/base';
import { Observable } from 'rxjs';
import { IAddressResponse } from '@model/interfaces/custom/address-response';
import { IEmailDto } from '@model/interfaces/custom/email-dto';
import { AuthService } from '@mt-ng2/auth-module';
import { parseEmailAttachment } from '@common/libraries/parse-email-attachment.library';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ISalesOrderReservation } from '@model/interfaces/sales-order-reservation';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyRecipient: IRecipient = {
    Email: null,
    FirstName: null,
    Id: 0,
    LastName: null,
    PhoneNumber: null,
    PreferredContactTypeId: 0,
    StatusId: 1,
    OptedOutOfEmails: false,
};

@Injectable({
    providedIn: 'root',
})
export class RecipientService extends BaseService<IRecipient> {
    constructor(public http: HttpClient, private authService: AuthService) {
        super('/recipients', http);
    }

    getRecipientSalesReservation(recipientId: number): Observable<ISalesOrderReservation[]> {
        return this.http.get<ISalesOrderReservation[]>(`/recipients/reservation/${recipientId}`).pipe(catchError(this.handleError));
    }

    getEmptyRecipient(): IRecipient {
        return { ...emptyRecipient };
    }

    saveAddress(recipientId: number, address: IAddress): Observable<IAddressResponse> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<IAddressResponse>(`/recipients/${recipientId}/address`, address);
        } else {
            return this.http.put<IAddressResponse>(`/recipients/${recipientId}/address`, address);
        }
    }

    deleteAddress(recipientId: number): Observable<object> {
        return this.http.delete('/recipients/' + recipientId + '/address', {
            responseType: 'text' as 'json',
        });
    }

    sendWelcomeEmail(recipientId: number, email: IEmailDto): Observable<IRecipient> {
        email.CurrentUserId = this.authService.currentUser.getValue().Id;
        email.CurrentUserId = this.authService.currentUser.getValue().Id;
        if (email.Attachments) {
            const formData = parseEmailAttachment(email);
            return this.http.post<IRecipient>(`/recipients/${recipientId}/welcome-email-with-attachments`, formData);
        } else {
            return this.http.post<IRecipient>(`/recipients/${recipientId}/welcome-email`, email);
        }
    }

    exportRecipients(searchParams: SearchParams): Observable<any> {
        let params = this.getHttpParams(searchParams);
        return this.http.get<any>(`/recipients/export`, {
            params: params,
        });
    }
}
