import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';

import { IDataset } from '../model/interfaces/dataset';

@Injectable()
export class DatasetsService extends StaticMetaItemService<IDataset> {
    constructor(public http: HttpClient) {
        super('DatasetsService', 'Dataset', 'DatasetIds', '/reports/datasets', http);
    }
}
