import { InjectionToken } from '@angular/core';

import { DaysOfTheWeek, IContextualDate } from './model';

export interface IMtSearchFilterDaterangeModuleConfig {
    afterText?: string;
    beforeText?: string;
    contextualDates?: IContextualDate[];
    firstDayOfWeek?: DaysOfTheWeek;
    throughText?: string;
}

export const MtSearchFilterDateRangeModuleConfigToken = new InjectionToken<IMtSearchFilterDaterangeModuleConfig>(
    'search_filter_daterange_module_config',
);
