<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Sales Order Reservations"></mt-page-title>
        </h2>
    </div>
</div>

<div class="row col-md-12">
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
</div>
<div class="row col-md-11">
    <mt-search-filter-daterange
        [startDate]="createdStartDate"
        [endDate]="createdEndDate"
        [maxDate]="createdMaxDate"
        entity="Created Date"
        [availableContextualDates]="null"
        (onSelectionChanged)="createdDateSelectionChanged($event)"
    >
    </mt-search-filter-daterange>
    <mt-search-filter-daterange
        [startDate]="expStartDate"
        [endDate]="expEndDate"
        [maxDate]="expMaxDate"
        entity="Expiration Date"
        [availableContextualDates]="null"
        (onSelectionChanged)="expirationDateSelectionChanged($event)"
    >
    </mt-search-filter-daterange>
    <!-- <mt-search-filter-select
        [items]="reservationDispositionFilters"
        entity="Disposition"
        (onSelectionChanged)="filterSelectionChanged($event)"
    >
    </mt-search-filter-select> -->
    <results-per-page (onSizeChange)="onPageSizeChange($event)"></results-per-page>
</div>
<div class="row col-md-12">
    <entity-list
        [entities]="salesOrderReservations"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (onPageChanged)="getSalesOrderReservations()"
        (onItemSelected)="salesOrderReservationSelected($event)"
        (onColumnSorted)="columnSorted($event)"
        [entityListConfig]="entityListConfig"
    >
    </entity-list>
</div>

<div *ngIf="canAddSalesOrderReservation" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/reservations', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>

<modal-wrapper *ngIf="showReservationActions" [options]="modalOptions" (cancelClick)="showReservationActions = false" [style]="'text-align: left'">
    <div>
        <span>{{ selectedReservation.Recipient.FirstName }} {{ selectedReservation.Recipient.LastName }}</span
        ><br /><span>Expiration Date: {{ selectedReservation.ExpirationDate | date: 'mediumDate' }}</span>
    </div>
    <hr />
    <div *ngFor="let donor of selectedReservation.SalesOrderReservationDonors">
        <div>
            <span
                >{{ donor.Donor.User.FirstName }} {{ donor.Donor.User.LastName }} - Eggs Reserved:
                {{ donor.EggCountReserved }}
            </span>
        </div>
    </div>
    <div>
        <span>Total Reserved: {{ totalSelectedEggs }}</span
        ><br /><br />

        <span>Notes: {{ selectedReservation.Notes }}</span>
    </div>
    <br />
    <button type="button" Class="btn btn-flat btn-success" (click)="showProductTypeSelection = true; showReservationActions = false">
        Create Sales Order
    </button>
    <button type="button" Class="btn btn-flat btn-default" (click)="showUpdateReservation = true; showReservationActions = false">
        Edit Reservation
    </button>
    <button type="button" Class="btn btn-flat btn-warning" (click)="cancelReservation()">Cancel Reservation</button>
</modal-wrapper>

<modal-wrapper *ngIf="showProductTypeSelection" [options]="productTypeModalOptions" (cancelClick)="showProductTypeSelection = false">
    <div class="form-modal">
        <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="createSalesOrder($event)">
            <div style="margin-top: 15px">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Create Sales Order
                </button>
                <button type="button" Class="btn btn-flat btn-default" (click)="showProductTypeSelection = false">Cancel</button>
            </div>
        </app-dynamic-form>
    </div>
</modal-wrapper>

<modal-wrapper *ngIf="showUpdateReservation" [options]="updateReservationModalOptions" (cancelClick)="showUpdateReservation = false">
    <update-sales-order-reservation [reservation]="selectedReservation" (onEditComplete)="onEditComplete($event)"></update-sales-order-reservation>
</modal-wrapper>
