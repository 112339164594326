import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IRecipientSalesOrder } from '@model/interfaces/recipient-sales-order';
import { Observable } from 'rxjs';
import { IVwDonorEggInventory } from '@model/interfaces/vw-donor-egg-inventory';
import { tap } from 'rxjs/operators';
import { IVwDonorAvailableInventory } from '@model/interfaces/vw-donor-available-inventory';
import { IRecipientSalesOrderDonor } from '@model/interfaces/recipient-sales-order-donor';
import { IInventory } from '@model/interfaces/inventory';
import { SearchParams } from '@mt-ng2/common-classes';

export const emptyRecipientSalesOrder: IRecipientSalesOrder = {
    CreatedDate: null,
    Id: 0,
    ProductTypeId: 0,
    RecipientCycleId: 0,
    StatusId: 1,
};

@Injectable({
    providedIn: 'root',
})
export class RecipientSalesOrderService extends BaseService<IRecipientSalesOrder> {
    constructor(public http: HttpClient) {
        super('/recipient-sales-orders', http);
    }

    updateWithFks(data: IRecipientSalesOrder): Observable<IRecipientSalesOrder> {
        return super.updateWithFks(data);
    }

    getEmptyRecipientSalesOrder(): IRecipientSalesOrder {
        return { ...emptyRecipientSalesOrder };
    }

    getById(salesOrderId: number): Observable<IRecipientSalesOrder> {
        return super.getById(salesOrderId).pipe(
            tap((value) => {
                this.emitChange(value);
            }),
        );
    }

    search(data: SearchParams): any {
        const params = this.getHttpParams(data);
        return this.http.get<IRecipientSalesOrder[]>(`/recipient-sales-orders/_search`, { observe: 'response', params: params });
    }

    linkDonorToSalesOrder(salesOrderId: number, donorEggInventory: IVwDonorAvailableInventory): Observable<IRecipientSalesOrder> {
        return this.http.put<IRecipientSalesOrder>(`/recipient-sales-orders/${salesOrderId}/link-donor`, donorEggInventory);
    }

    unlinkDonorFromSalesOrder(salesOrderId: number, salesOrderDonorId: number): Observable<IRecipientSalesOrder> {
        return this.http.delete<IRecipientSalesOrder>(`/recipient-sales-orders/${salesOrderId}/unlink-donor/${salesOrderDonorId}`);
    }

    getSHEBDF1Pdf(salesOrderId: number): Observable<any> {
        return this.http.get<any>(`/recipient-sales-orders/${salesOrderId}/shebdf1-pdf`);
    }

    getSHEBDF7Pdf(salesOrderId: number): Observable<any> {
        return this.http.get<any>(`/recipient-sales-orders/${salesOrderId}/shebdf7-pdf`);
    }

    getSHEBDF8Pdf(salesOrderId: number): Observable<any> {
        return this.http.get<any>(`/recipient-sales-orders/${salesOrderId}/shebdf8-pdf`);
    }

    downloadSHEBDF3(salesOrderId: number): Observable<any> {
        return this.http.get<any>(`/recipient-sales-orders/${salesOrderId}/shebdf3-pdf`);
    }

    downloadSHEBDF2(salesOrderId: number): Observable<any> {
        return this.http.get<any>(`/recipient-sales-orders/${salesOrderId}/shebdf2-pdf`);
    }

    getInventoryDetails(donorId: number, salesOrderId: number): Observable<IInventory[]> {
        return this.http.get<IInventory[]>(`/recipient-sales-orders/${salesOrderId}/inventory/${donorId}`);
    }
}
