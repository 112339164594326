import { OnInit, Input, Inject, Component, ContentChild, TemplateRef } from '@angular/core';

import { AuditLogService } from '../../services/audit-logs.service';
import { IAuditConfiguration } from '../../interfaces/audit-configuration';
import { IEventType } from '../../interfaces/event-type';
import { EventTypeService } from '../../services/event-types.service';
import {
    IAuditLogUser,
    IAuditLogUserService,
    IAuditLogModuleConfig,
    AuditLoggingModuleUserServiceToken,
    AuditLoggingModuleConfigToken,
    IAuditLog,
    IAuditModalTemplateApi,
} from '@mt-ng2/audit-logging-module-config';
import { IModalWrapperApi, IModalOptions } from '@mt-ng2/modal-module';

@Component({
    selector: 'mt-audit-logs-for-record',
    styleUrls: ['./audit-logs-for-record.component.less'],
    templateUrl: './audit-logs-for-record.component.html',
})
export class AuditLogsForRecordComponent implements OnInit {
    auditLogs: IAuditLog[] = [];
    auditLogUsers: IAuditLogUser[] = [];
    auditConfigurations: IAuditConfiguration[] = [];
    auditLogsEnabled: boolean;

    @Input() recordId: number;
    @Input() entity: string;
    @ContentChild('auditModalTriggerTemplate', { read: TemplateRef })
    auditModalTriggerTemplate;
    auditModalTemplateApi: IAuditModalTemplateApi = {
        showLogsModal: this.showLogsModal.bind(this),
    };

    eventTypes: IEventType[];

    modalApi: IModalWrapperApi;

    modalOptions: IModalOptions = {
        title: '<h2>Audit Logs</h2>',
        width: 1000,
    };

    constructor(
        private readonly auditLogService: AuditLogService,
        private eventTypeService: EventTypeService,
        @Inject(AuditLoggingModuleUserServiceToken) private auditLogUserService: IAuditLogUserService,
        @Inject(AuditLoggingModuleConfigToken) private auditLogModuleConfig: IAuditLogModuleConfig,
    ) {}

    ngOnInit(): void {
        this.auditLogsEnabled = this.auditLogModuleConfig.enabled;
        if (this.auditLogsEnabled) {
            this.getAuditConfigurations();
            this.getAuditLogUsers();
            this.eventTypeService.getItems().subscribe((eventTypes: IEventType[]) => {
                this.eventTypes = eventTypes;
            });
        }
    }

    getAuditLogsForRecord(): void {
        this.auditLogService.getForRecord(this.recordId, this.entity).subscribe((answer) => {
            this.auditLogs = answer;
        });
    }

    getAuditConfigurations(): void {
        this.auditLogService.getEntities().subscribe((entities: IAuditConfiguration[]) => {
            this.auditConfigurations = entities;
        });
    }

    getAuditLogUsers(): void {
        this.auditLogUserService.getUsers().subscribe((users: IAuditLogUser[]) => {
            this.auditLogUsers = users;
        });
    }

    showLogsModal(event: Event): void {
        event.stopPropagation();
        this.getAuditLogsForRecord();
        this.modalApi.show();
    }

    cancel(): void {
        this.modalApi.close();
    }
}
