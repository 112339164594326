import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IEventType } from '../interfaces/event-type';

@Injectable({
    providedIn: 'root',
})
export class EventTypeService extends StaticMetaItemService<IEventType> {
    constructor(public http: HttpClient) {
        super('EventTypeService', 'Source', 'SourceIds', '/changeLogs/eventTypes', http);
    }
}
