import { NgModule } from '@angular/core';
import { SharedModule } from '@common/shared.module';
import { ModalModule } from '@mt-ng2/modal-module';

import { SalesOrderReservationAddComponent } from './sales-order-reservation-add/sales-order-reservation-add.component';

import { SalesOrderReservationHeaderComponent } from './sales-order-reservation-header/sales-order-reservation-header.component';
import { SalesOrderReservationsComponent } from './sales-order-reservation-list/sales-order-reservations.component';
import { SalesOrderReservationRoutingModule } from './sales-order-reservation-routing.module';
import { DonorEditQuantityComponent } from './update-sales-order-reservation/donor-edit-quantity.component';
import { UpdateSalesOrderReservationComponent } from './update-sales-order-reservation/update-sales-order-reservation.component';

@NgModule({
    declarations: [
        SalesOrderReservationsComponent,
        SalesOrderReservationHeaderComponent,
        SalesOrderReservationAddComponent,
        UpdateSalesOrderReservationComponent,
        DonorEditQuantityComponent,
    ],
    imports: [SharedModule, SalesOrderReservationRoutingModule, ModalModule],
})
export class SalesOrderReservationModule {}
