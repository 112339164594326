import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { FormSections } from '@model/enums/form-sections.enum';
import { ICurrentSection, ApplicationDataService } from '../../services/application-data.service';
import { MedicalIssuesDynamicConfig } from './medical-issues.dynamic-config';
import { IMedicalIssueType } from '@model/interfaces/medical-issue-type';
import { IMedicalIssueRelationshipType } from '@model/interfaces/medical-issue-relationship-type';
import { MedicalIssueRelationshipTypesService } from '@common/services/medical-issue-relationship-types.service';
import { MedicalIssueTypesService } from '@common/services/medical-issue-types.service';
import { MedicalIssueOptionsService } from '@common/services/medical-issue-options.service';
import { forkJoin } from 'rxjs';
import { IApplicationIssue } from '@model/interfaces/application-issue';
import { IApplicationIssuesMedicalIssue } from '@model/interfaces/application-issues-medical-issue';
import { IApplication } from '@model/interfaces/application';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FormBuilder, FormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { ApplicationIssueDynamicControlsPartial } from '@model/partials/application-issue-partial.form-controls';
import { ApplicationIssueService } from '../../services/application-issue.service';
import { ApplicationService } from '../../../services/application.service';
import { DonorService } from '../../../../donors/services/donor.service';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { BirthControlTypes } from '@model/enums/birth-control-types.enum';
import { IApplicationIssueDynamicControlsParameters } from '@model/form-controls/application-issue.form-controls';

@Component({
    selector: 'medical-issues',
    templateUrl: './medical-issues.component.html',
})
export class AppMedicalIssuesComponent implements OnInit {
    @Input() medicalIssues: IApplicationIssue;
    @Input() application: IApplication;

    abstractApplicationIssueControls: any;

    applicationIssuesForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: MedicalIssuesDynamicConfig<IApplicationIssue>;

    medicalIssueTypes: IMedicalIssueType[];
    applicationIssueMedicalIssues: IApplicationIssuesMedicalIssue[];
    medicalIssueRelationshipTypes: IMedicalIssueRelationshipType[];
    medicalIssueOptions: IMedicalIssueType[];
    medicalSubIssues: IApplicationIssuesMedicalIssue[] = [];
    selectedBirthControlType: string;
    showOtherBirthControlMethod: boolean;
    medicalIssueMetaItems: IPersonalInformationMetaData;
    controlsAdditionalParams: IApplicationIssueDynamicControlsParameters;
    isEditing = false;
    isTakingMedication: boolean;
    hasSurgeries: boolean;

    currentSection: ICurrentSection = {
        formGroup: null,
        nextTab: 'tab-whoAreYou',
        nextTabLabel: 'Who Are You?',
        previousTab: 'tab-preliminaryHistory',
        previousTabLabel: 'Preliminary History',
        routerLink: null,
        sectionId: FormSections.MedicalIssues,
    };

    constructor(
        private applicationService: ApplicationDataService,
        private medicalIssueOptionsService: MedicalIssueOptionsService,
        private medicalIssueTypesService: MedicalIssueTypesService,
        private medicalIssueRelationshipTypesService: MedicalIssueRelationshipTypesService,
        private notificationsService: NotificationsService,
        private fb: FormBuilder,
        private applicationIssueService: ApplicationIssueService,
        private cdr: ChangeDetectorRef,
        private appService: ApplicationService,
        private donorService: DonorService,
    ) { }

    ngOnInit(): void {
        forkJoin([
            this.donorService.getPersonalInformationMetaData(),
            this.medicalIssueRelationshipTypesService.getAll(),
            this.medicalIssueOptionsService.getItems(),
            this.medicalIssueTypesService.getItems(),
        ]).subscribe((forkJoinResponses) => {
            const [metaData, relationshipTypes] = forkJoinResponses;
            this.medicalIssueMetaItems = metaData as IPersonalInformationMetaData;
            this.medicalIssueRelationshipTypes = relationshipTypes as IMedicalIssueRelationshipType[];
            this.medicalIssueOptions = this.medicalIssueOptionsService.items;
            this.medicalIssueTypes = this.medicalIssueTypesService.items;
            this.applicationIssueMedicalIssues = this.medicalIssues.ApplicationIssuesMedicalIssues;

            this.controlsAdditionalParams = {
                formGroup: 'ApplicationIssue',
                birthControlTypes: this.medicalIssueMetaItems.BirthControlTypes,
            };

            if (this.medicalIssues.BirthControlTypeId) {
                const birthContolType = this.medicalIssueMetaItems.BirthControlTypes.find((t) => t.Id === this.medicalIssues.BirthControlTypeId);

                if (birthContolType) {
                    this.selectedBirthControlType = birthContolType.Name;
                }

                this.showOtherBirthControlMethod = (this.medicalIssues.BirthControlTypeId === BirthControlTypes.Other);
            }

            this.setConfig();
            this.createForm();

            this.isTakingMedication = this.medicalIssues.IsTakingMedication;
        });
    }

    createForm(): void {
        this.getControls();
        this.applicationIssuesForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractApplicationIssueControls = new ApplicationIssueDynamicControlsPartial(this.medicalIssues, this.controlsAdditionalParams).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationIssue: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.applicationIssuesForm.valid) {
            Object.assign(this.medicalIssues, {
                ...this.applicationIssuesForm.value.ApplicationIssue,
                ApplicationIssuesMedicalIssues: this.applicationIssueMedicalIssues,
            });
            this.assignSubIssueValues();
            this.applicationIssueService.updateWithFks(this.medicalIssues).subscribe((updatedIssues) => {
                this.medicalIssues = updatedIssues;
                this.applicationIssueService.emitChange(this.medicalIssues);
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.applicationIssuesForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
        this.setConfig();
        this.enableDoubleClick();
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
        this.enableDoubleClick();
    }

    success(): void {
        this.notificationsService.success('Medical issues saved successfully.');
        this.setConfig();
        this.getControls();
        this.enableDoubleClick();
        this.isEditing = false;
        this.appService.emitChange(this.application);
    }

    setConfig(): void {
        this.formFactory = new MedicalIssuesDynamicConfig<IApplicationIssue>(this.medicalIssues, null, this.controlsAdditionalParams);
        this.config = this.formFactory.getForUpdate();
        this.applicationService.currentSection.next(this.currentSection);
    }

    onBirthControlTypeIdChanging(value: number): void {
        this.showOtherBirthControlMethod = (value === BirthControlTypes.Other);
    }

    getIssuesByTypeId(typeId: number): IApplicationIssuesMedicalIssue[] {
        return this.applicationIssueMedicalIssues.filter(
            (issue) => issue.MedicalIssue.MedicalIssueTypeId === typeId && !issue.MedicalIssue.ParentMedicalIssueId,
        );
    }

    onTakingMedicationValueChange(value: boolean): void {
        this.isTakingMedication = value;
    }

    onHasSurgeriesValueChange(value: boolean): void {
        this.hasSurgeries = value;
    }

    edit(): void {
        this.isEditing = true;
        this.setConfig();
    }

    cloneArray(array: any[]): any[] {
        return JSON.parse(JSON.stringify(array));
    }

    subIssueChanged(issueSelectDto: any): void {
        let medicalIssue = this.medicalIssues?.ApplicationIssuesMedicalIssues?.find((x) => x.MedicalIssue?.Id === issueSelectDto.issueId);
        if (medicalIssue) {
            this.medicalIssueRelationshipTypes.forEach((item) => {
                medicalIssue[item.Name] = false;
            });
            issueSelectDto.selectedItems.forEach((item) => {
                medicalIssue[item.Name] = true;
            });
        }
        let medicalIssueIndex = this.findIndexByProperty(this.medicalSubIssues, 'Id', medicalIssue.Id);
        if (medicalIssueIndex > -1) {
            this.medicalSubIssues[medicalIssueIndex] = medicalIssue;
        } else {
            this.medicalSubIssues.push(medicalIssue);
        }
    }

    findIndexByProperty(data: any[], key: string, value: number): number {
        for (let i = 0; i < data.length; i++) {
            if (data[i][key] === value) {
                return i;
            }
        }
        return -1;
    }

    assignSubIssueValues(): void {
        this.medicalSubIssues.forEach((subissue) => {
            let medicalIssue = this.medicalIssues.ApplicationIssuesMedicalIssues.find((x) => x.Id === subissue.Id);
            if (medicalIssue) {
                this.medicalIssueRelationshipTypes.forEach((relationshipType) => {
                    medicalIssue[relationshipType.Name] = subissue[relationshipType.Name];
                });
            }
        });
    }
}
