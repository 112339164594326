<div *ngIf="myProfile" class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="My Profile"></mt-page-title>
        </h2>
    </div>
</div>
<div *ngIf="user && user.Id > 0">
    <div class="row">
        <div class="col-md-6">
            <app-user-basic-info [user]="user" [canEdit]="canEdit"></app-user-basic-info>
            <br />
            <app-auth-user-portal-access
                *ngIf="user.Id !== currentUser.Id"
                [AuthUser]="user.AuthUser"
                [canEdit]="canEdit"
                (updateVersion)="updateVersion($event)"
            ></app-auth-user-portal-access>
            <br />
            <div *ngIf="showUserRegionsPanel">
                <app-user-regions [canEdit]="canEdit" [user]="user"></app-user-regions>
                <br />
            </div>
            <app-auth-user-password [AuthUser]="user.AuthUser" [canEdit]="canEdit" (updateVersion)="updateVersion($event)"></app-auth-user-password>
            <br />
            <app-upload-documents-detail [entityId]="user.Id" [entityType]="documentEntityType" [claimType]="claimType"></app-upload-documents-detail>
            <br />
        </div>
        <div class="col-md-6">
            <app-user-photo [user]="user" [canEdit]="canEdit"></app-user-photo>
            <br />
            <app-common-addresses
                [canEdit]="canEdit"
                [address]="userAddress"
                (onSave)="saveAddress($event)"
                (onDelete)="deleteAddress($event)"
                [allowInternationalAddresses]="true"
            ></app-common-addresses>
            <br />
            <app-common-phone [PhoneArray]="user.UserPhones" (onSave)="savePhones($event)" [canEdit]="canEdit"> </app-common-phone>
            <br />
            <div *ngIf="isEmbryologist">
                <app-embryologist-details [userId]="user.Id" [canEdit]="canEdit"></app-embryologist-details>
                <br />
                <app-embryologist-clinics [user]="user" [canEdit]="canEdit"></app-embryologist-clinics>
            </div>
        </div>
    </div>
    <a routerLink="/users" class="btn btn-flat btn-default">Close</a>
    <button class="btn btn-flat btn-success" *ngIf="accountLocked" (click)="unlock()">Unlock</button>
</div>
<div *ngIf="user && user.Id === 0">
    <div class="row">
        <div class="col-md-6">
            <app-user-basic-info [user]="user"></app-user-basic-info>
        </div>
    </div>
</div>
