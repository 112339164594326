import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { AggregateTypesService } from '../services/aggregate-types.service';
import { sortReportColumnsByOrder } from '../services/report-columns.service';
import { IAggregateType } from '../model/interfaces/aggregate-type';
import { IReportColumn } from '../model/interfaces/report-column';
import { ReportAggregatesService } from '../services/report-aggregates.service';
import { IReportAggregate } from '../model/interfaces/report-aggregate';

@Component({
    animations: [
        trigger('slideDown', [
            state(
                'down',
                style({
                    height: '*',
                    opacity: '1',
                    visibility: 'visible',
                }),
            ),
            state(
                'up',
                style({
                    height: '0px',
                    opacity: '0',
                    visibility: 'hidden',
                }),
            ),
            transition('down <=> up', animate('300ms ease-in')),
        ]),
    ],
    selector: 'report-aggregates',
    styles: [
        `
            .select-records-where {
                height: 27px;
            }
            .aggregate-row {
                height: 27px;
            }
            .aggregate-header {
                width: 100%;
                background-color: gray;
            }
            .aggregate-header .aggregates-as-text {
                color: white;
                padding-left: 8px;
            }
            .aggregate-header .aggregates-as-text:hover {
                text-decoration: underline;
            }
            .aggregate-header .no-underline.aggregates-as-text:hover {
                text-decoration: none;
            }
            .configuration-section {
                padding-top: 5px;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 8px;
                margin-bottom: 10px;
            }
        `,
    ],
    templateUrl: './report-aggregates.component.html',
})
export class ReportAggregatesComponent implements OnInit {
    private _reportColumns: IReportColumn[];
    @Input('reportColumns')
    set reportColumns(value: IReportColumn[]) {
        this._reportColumns = value;
        this.setAggregates();
    }
    get reportColumns(): IReportColumn[] {
        return this._reportColumns;
    }

    @Input() canEdit: boolean;

    aggregateTypes: IAggregateType[];

    aggregates: IReportColumn[];
    aggregateTypeIds: { columnId: number; typeId: number }[];
    aggregatesAsText = '';
    noAggregatesSetMessage = 'No aggregates set';
    showConfigurationSection: 'up' | 'down' = 'up';

    constructor(private aggregateTypesService: AggregateTypesService, private reportAggregatesService: ReportAggregatesService) {}

    ngOnInit(): void {
        this.aggregateTypesService.getItems().subscribe((items) => (this.aggregateTypes = items));
    }

    toggleConfigurationSection(): void {
        if (this.canEdit) {
            this.showConfigurationSection = this.showConfigurationSection === 'up' ? 'down' : 'up';
        }
    }

    setAggregates(): void {
        if (!this.reportColumns) {
            this.aggregates = [];
            return;
        }

        this.aggregates = [...this.reportColumns];
        sortReportColumnsByOrder(this.aggregates);
        this.aggregateTypeIds = this.aggregates.map((c) => {
            return {
                columnId: c.Id,
                typeId: c.ReportAggregate?.AggregateTypeId || 0,
            };
        });

        let columnsWithAggregate = this.aggregates.filter((a) => a.ReportAggregate);
        if (columnsWithAggregate?.length) {
            this.aggregatesAsText = columnsWithAggregate.map((a) => `${a.ReportAggregate.AggregateType.Name} : ${a.DisplayAs}`).join(', ');
        } else {
            this.aggregatesAsText = this.noAggregatesSetMessage;
        }
    }

    aggregateTypeIdsChanged(index: number, typeId: number): void {
        const columnWithAggregate = { ...this._reportColumns[index] };
        if (typeId) {
            let reportAggregate: IReportAggregate = {
                AggregateTypeId: typeId,
                Id: columnWithAggregate.Id,
            };
            let currentAggregateTypeId = columnWithAggregate.ReportAggregate?.AggregateTypeId;
            if (currentAggregateTypeId) {
                this.reportAggregatesService.update(reportAggregate).subscribe((answer) => {
                    this._reportColumns[index].ReportAggregate = {
                        AggregateType: this.aggregateTypes.find((t) => t.Id === typeId),
                        AggregateTypeId: typeId,
                        Id: reportAggregate.Id,
                    };
                    this.setAggregates();
                });
            } else {
                this.reportAggregatesService.create(reportAggregate).subscribe((newId) => {
                    this._reportColumns[index].ReportAggregate = {
                        AggregateType: this.aggregateTypes.find((t) => t.Id === typeId),
                        AggregateTypeId: typeId,
                        Id: newId,
                    };
                    this.setAggregates();
                });
            }
        } else {
            this.reportAggregatesService.delete(columnWithAggregate.Id).subscribe(() => {
                this._reportColumns[index].ReportAggregate = null;
                this.setAggregates();
            });
        }
    }
}
