import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IReportChanged } from '../model/interfaces/report-changed';

@Injectable()
export class ReportChangedService {
    public reportChanged = new Subject<IReportChanged>();

    constructor() {}

    columnDeleted(value: number): void {
        this.reportChanged.next({
            eventType: ReportChangeType.columnDeleted,
            value: value,
        });
    }

    columnHeaderUpdated(value: { id: number; name: string }): void {
        this.reportChanged.next({
            eventType: ReportChangeType.columnHeaderUpdated,
            value: value,
        });
    }
}
// UPDATE THIS SERVICE CALL TO PUSH THE OBSERVABLE FROM THE CONFIGURATION SERVICE
// ADD A .DO TO THE HTTP CALL TO EMIT
export enum ReportChangeType {
    columnDeleted = 1,
    columnHeaderUpdated = 2,
}
