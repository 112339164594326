import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ISalesOrderReservation } from '@model/interfaces/sales-order-reservation';
import { Observable } from 'rxjs';
import { IReservationConversionDto } from '@model/interfaces/custom/reservation-conversion-dto';

export const emptySalesOrderReservation: ISalesOrderReservation = {
    Canceled: false,
    CreatedById: 0,
    CreatedDate: null,
    Id: 0,
    RecipientId: 0,
    SalesOrderCreated: false,
};

@Injectable({
    providedIn: 'root',
})
export class SalesOrderReservationService extends BaseService<ISalesOrderReservation> {
    constructor(public http: HttpClient) {
        super('/reservations', http);
    }

    getEmptySalesOrderReservation(): ISalesOrderReservation {
        return { ...emptySalesOrderReservation };
    }

    convertReservationToSalesOrder(data: IReservationConversionDto): Observable<number> {
        return this.http.post<number>(`/reservations/convert`, data);
    }
}
