<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Search Donors"></mt-page-title>
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <br />
</div>
<div style="zoom: 80%" *ngIf="donors && donors.length">
    <entity-list
        [entities]="donors"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (onPageChanged)="getDonors()"
        (onItemSelected)="donorSelected($event)"
        [entityListConfig]="entityListConfig"
    >
    </entity-list>
</div>
