import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { AuthEntityService } from '../../auth-entity.service';
import { AuthUserDynamicConfig } from '../auth-user.dynamic-config';
import { IAuthUser } from '@model/interfaces/auth-user';

@Component({
    selector: 'app-auth-user-password',
    templateUrl: './auth-user-password.component.html',
})
export class AuthUserPasswordComponent implements OnInit {
    @Input('AuthUser') authUser: IAuthUser;
    @Input('canEdit') canEdit: boolean;
    @Output('updateVersion') updateVersion: EventEmitter<string> = new EventEmitter<string>();
    config: any = {};
    formFactory: AuthUserDynamicConfig<IAuthUser>;
    doubleClickIsDisabled = false;
    isEditing: boolean;
   /*
     This form was originally designed to request the current password from
     the user.  However, since the form is ONLY used in the user admin form
     (and the most common scenario for that would be a user has forgotten
     their password), the 'CurrentPassword' control has been removed - and
     the back-end code adjusted to allow the password to be updated without
     this information.

     CMB - 2023-08-17
   */
    configControls: string[] = ['Password', 'ConfirmPassword'];

    constructor(private notificationsService: NotificationsService, private authEntityService: AuthEntityService, private authService: AuthService) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new AuthUserDynamicConfig<IAuthUser>(this.authUser, null, this.configControls);
        this.config = this.formFactory.getForUpdate();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancel(): void {
        this.isEditing = false;
    }

    formSubmitted(form: any): void {
        if (this.authService.matchPassword(form)) {
            if (form.valid) {
                this.authEntityService
                    .savePassword(
                        this.authUser.Id,
                        form.value.AuthUser.Password,
                        form.value.AuthUser.CurrentPassword,
                        form.value.AuthUser.ConfirmPassword,
                    )
                    .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                    .subscribe((answer) => {
                        this.success();
                        this.isEditing = false;
                        this.updateVersion.emit(answer);
                    });
            } else {
                markAllFormFieldsAsTouched(form);
                this.error();
                this.enableDoubleClick();
            }
        } else {
            this.error('Passwords do not match.');
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Password save failed.  Please check the form and try again.');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Password Updated Successfully');
    }
}
