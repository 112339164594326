import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IRecipientCycle } from '../interfaces/recipient-cycle';
import { IRecipient } from '../interfaces/recipient';
import { IRecipientCycleStatus } from '../interfaces/recipient-cycle-status';

export interface IRecipientCycleDynamicControlsParameters {
    formGroup?: string;
    statuses?: IRecipientCycleStatus[];
    recipients?: IRecipient[];
}

export class RecipientCycleDynamicControls {

    formGroup: string;
    statuses: IRecipientCycleStatus[];
    recipients: IRecipient[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private recipientcycle?: IRecipientCycle, additionalParameters?: IRecipientCycleDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'RecipientCycle';
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;
        this.recipients = additionalParameters && additionalParameters.recipients || undefined;

        this.Form = {
            CreatedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created Date',
                name: 'CreatedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipientcycle && this.recipientcycle.CreatedDate || null,
            }),
            RecipientId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Recipient',
                name: 'RecipientId',
                options: this.recipients,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.recipientcycle && this.recipientcycle.RecipientId || null,
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.recipientcycle && this.recipientcycle.StatusId || null,
            }),
        };

        this.View = {
            CreatedDate: new DynamicLabel(
                'Created Date',
                this.recipientcycle && this.recipientcycle.CreatedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            RecipientId: new DynamicLabel(
                'Recipient',
                getMetaItemValue(this.recipients, this.recipientcycle && this.recipientcycle.hasOwnProperty('RecipientId') && this.recipientcycle.RecipientId !== null ? this.recipientcycle.RecipientId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            StatusId: new DynamicLabel(
                'Status',
                getMetaItemValue(this.statuses, this.recipientcycle && this.recipientcycle.hasOwnProperty('StatusId') && this.recipientcycle.StatusId !== null ? this.recipientcycle.StatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
