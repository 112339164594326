import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IVitMethod } from '@model/interfaces/vit-method';

@Injectable({
    providedIn: 'root',
})
export class VitMethodService extends MetaItemService<IVitMethod> {
    constructor(public http: HttpClient) {
        super('VitMethodService', 'Method', 'MethodIds', '/vit-methods', http);
    }
}
