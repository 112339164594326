import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { forkJoin, Subscription } from 'rxjs';

import { IModalOptions } from '@mt-ng2/modal-module';
import { DonorReviewsDynamicConfiguration} from './donor-reviews.dynamic-config';
import { IReviewType } from '@model/interfaces/review-type';
import { IReviewOutcome } from '@model/interfaces/review-outcome';
import { IDonorReview } from '@model/interfaces/donor-review';

import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ReviewTypeService } from '@common/services/review-type-service';
import { ReviewOutcomeService } from '@model/../donors/services/review-outcome.service';
import { DonorReviewService } from '@common/services/donor-review.service';
import { DonorIssueService } from '@model/../donors/donor-data/services/donor-issue.service';
import { DonorBasicInfoService } from '@model/../donors/donor-data/services/donor-basic-info.service';

@Component({
    selector: 'app-donor-reviews',
    templateUrl: './donor-reviews.component.html',
})
export class DonorReviewsComponent implements OnInit {
    @Input() donorId: number;
    isEditing = false;
    isViewing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: DonorReviewsDynamicConfiguration<IDonorReview>;
    doubleClickIsDisabled = false;
    reviewTypes?: IReviewType[];
    outcomes?: IReviewOutcome[];
    currentUser: ILoggedIn;
    selectedReview: IDonorReview;
    donorReviews: IDonorReview[];

    confirmOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };
    subs = new Subscription();

    constructor(
        private donorReviewService: DonorReviewService,
        private reviewTypeService: ReviewTypeService,
        private authService: AuthService,
        private outcomesService: ReviewOutcomeService,
        private notificationsService: NotificationsService,
        private donorIssueService: DonorIssueService,
        private donorBasicInfoService: DonorBasicInfoService,
         ) {}

    ngOnInit(): void {
        forkJoin([this.reviewTypeService.getItems(), this.outcomesService.getItems(), this.donorReviewService.getReviews(this.donorId)]).subscribe(
            ([reviewTypes, outcomes, serviceResonse ]) => {
                this.reviewTypes = reviewTypes;
                this.outcomes = outcomes;
                this.donorReviews = serviceResonse.body;
            },
        );

        // Review status may have changed from an update to medical issues or basics complete info, refresh data
        this.subs.add(
            this.donorIssueService.changeEmitted$.subscribe(() => {
                this.donorReviewService.getReviews(this.donorId).subscribe((serviceResonse) => {
                    this.donorReviews = serviceResonse.body;
                });
            }).add(
            this.donorBasicInfoService.changeEmitted$.subscribe(() => {
                this.donorReviewService.getReviews(this.donorId).subscribe((serviceResonse) => {
                    this.donorReviews = serviceResonse.body;
                });
            })),
        );

        this.authService.currentUser.subscribe((user) => (this.currentUser = user));
    }

    showThisReview(donorReview: IDonorReview): boolean {
        return !this.isEditing ||  this.selectedReview.Id !== donorReview.Id;
    }

    isSelectedForEdit(donorReview: IDonorReview): boolean {
        return this.isEditing && this.selectedReview.Id === donorReview.Id;
    }

    showDonorReview(donorReview: IDonorReview): void {
        this.selectedReview = donorReview;
        this.setConfig();
        this.isEditing = true;
    }

    setConfig(configControls?: string[]): void {
        const users = null;
        this.formFactory = new DonorReviewsDynamicConfiguration<IDonorReview>(this.selectedReview, this.reviewTypes, this.outcomes, users, configControls);
        this.config = this.formFactory.getForUpdate();
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.selectedReview, form.value.DonorReview);
            this.selectedReview.ReviewModifiedById = this.currentUser.Id;
            this.selectedReview.ReviewModifiedDate = new Date();
            this.saveDonorReview();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed. Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    getReviewStatus(reviewStatusId: number): string {
        let result = '';
        let reviewStatus = this.outcomes.find((t) => t.Id === reviewStatusId);
        if (reviewStatus) {
            result = reviewStatus.Name;
        }
        return result;
    }

    private saveDonorReview(): void {
        this.donorReviewService.updateReview(this.donorId, this.selectedReview)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe(() => {
                this.success();
            });
    }

    private success(): void {
        this.isEditing = false;
        this.setConfig();
        this.notificationsService.success('Review status saved successfully.');
    }
}
