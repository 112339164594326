import { TemplateRef, Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';

import { ReportColumnsService, sortReportColumnsByOrder } from '../services/report-columns.service';
import { IReport } from '../model/interfaces/report';
import { IReportColumn } from '../model/interfaces/report-column';
import { IColumnDefinition } from '../model/interfaces/column-definition';
import { getColumnClass } from '../libraries/datapoints.library';
import { ReportsService } from '../services/reports.service';
import { ReportChangedService, ReportChangeType } from '../services/report-changed.service';
import { IReportChanged } from '../model/interfaces/report-changed';
import { IDataFormatOption } from '../model/interfaces/data-format-option';

@Injectable()
export abstract class GridComponentBase {
    report: IReport;
    subscriptions: Subscription = new Subscription();
    columnDefs: IColumnDefinition[];
    groupRowsBy: string[];
    canEdit: boolean;
    defaultSort: { dir: string; prop: string }[];
    hasSummaryRow: boolean;

    reportsService: ReportsService;
    reportColumnsService: ReportColumnsService;
    reportChangedService: ReportChangedService;
    notificationsService: NotificationsService;

    abstract grid: any;
    abstract headerTemplate: TemplateRef<any>;

    constructor(injector: Injector) {
        this.reportChangedService = injector.get(ReportChangedService);
        this.reportColumnsService = injector.get(ReportColumnsService);
        this.notificationsService = injector.get(NotificationsService);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    emitColumnsChanged(): void {
        this.reportColumnsService.reportColumnsChanged.next(this.report.ReportColumns);
    }

    getColumnClass(format: IDataFormatOption): string | null {
        return getColumnClass(format);
    }

    abstract setColumnDefs(totalRecordCount?: number): void;

    isColumnGrouping(column: IReportColumn): boolean {
        return !column.ReportGroup;
    }

    subscribeToDeletionEvent(): void {
        this.subscriptions.add(
            this.reportChangedService.reportChanged
                .pipe(filter((rc) => rc.eventType === ReportChangeType.columnDeleted))
                .subscribe((reportChangedEvent: IReportChanged) => {
                    let index = this.report.ReportColumns.findIndex((rc) => rc.Id === reportChangedEvent.value);
                    if (index >= 0) {
                        this.deleteColumn(index, reportChangedEvent.value as number);
                    }
                }),
        );
    }

    saveReportColumn(reportColumn: IReportColumn): void {
        if (reportColumn.Id) {
            this.reportColumnsService.update(reportColumn).subscribe(() => {
                this.notificationsService.success('Column Updated Successfully');
                let index = this.report.ReportColumns.findIndex((rc) => rc.Id === reportColumn.Id);
                this.report.ReportColumns[index] = reportColumn;
                this.emitColumnsChanged(); // column resized
                this.setColumnDefs();
            });
        } else if (reportColumn.Id === 0) {
            this.reportColumnsService.create(reportColumn).subscribe((newId: number) => {
                this.notificationsService.success('Column Created Successfully');
                reportColumn.Id = newId;
                this.report.ReportColumns.push(reportColumn);
                this.emitColumnsChanged(); // column added
                this.setColumnDefs();
            });
        }
    }

    /**
     * This handler is supposed to watch for when the user has dragged the column's width slider
     * and changed the width of the column.  So we want to capture that action and save the change.
     */
    onColumnResized(event: any): void {
        let ngxGridColumn = event.column;
        if (!ngxGridColumn) {
            // the resize event did not come from a column width slider so just return
            // i.e. this happens when the window is resized
            return;
        }
        let reportColumn = this.report.ReportColumns.find((rc) => {
            return rc.Id === ngxGridColumn.reportColumnId;
        });
        reportColumn.Width = event.newValue;
        this.saveReportColumn(reportColumn);
    }

    onColumnReordered(event: any): void {
        this.updateColumnOrder(false);
    }

    deleteColumn(reportColumnToDeleteIndex: number, rcId?: number): void {
        let reportColumnId: number = rcId ? rcId : this.report.ReportColumns[reportColumnToDeleteIndex].Id;
        let columnDefinitionToDeleteIndex = this.columnDefs.findIndex((cd) => cd.reportColumnId === reportColumnId);
        this.columnDefs.splice(columnDefinitionToDeleteIndex, 1);
        this.reportColumnsService.delete(reportColumnId).subscribe(() => {
            this.notificationsService.success('Column Deleted');
            let deletedColumn = this.removeAndSelectReportColumn(reportColumnToDeleteIndex);
            this.checkIfDeletedColumnWasDefaultSort(deletedColumn);
            this.updateColumnOrder(true);
        });
    }

    updateColumnOrder(wasColumnDeleted: boolean): void {
        if (this.grid._internalColumns) {
            this.grid._internalColumns = this.grid._internalColumns.filter((gc) => {
                return this.report.ReportColumns.some((rc) => rc.Id === gc.reportColumnId);
            });
            let gridColumns: IColumnDefinition[] = this.grid._internalColumns.map((gridColumn) => {
                return {
                    name: gridColumn.name,
                    prop: gridColumn.prop,
                    reportColumnId: gridColumn.reportColumnId,
                    width: gridColumn.width,
                } as IColumnDefinition;
            });
            gridColumns.forEach((gc, index) => {
                let reportColumnIndex = this.report.ReportColumns.findIndex((rc) => rc.Id === gc.reportColumnId);
                this.report.ReportColumns[reportColumnIndex].Order = index + 1;
                this.report.ReportColumns[reportColumnIndex].Width = gc.width;
            });
            sortReportColumnsByOrder(this.report.ReportColumns);
            this.columnDefs.sort((a, b) => {
                return a.reportColumn.Order - b.reportColumn.Order;
            });
            this.reportColumnsService.updateList(this.report.ReportColumns).subscribe(() => {
                if (!wasColumnDeleted) {
                    // if we are only reordering the columns, not deleting
                    this.notificationsService.success('Column Order Updated Successfully');
                }
                this.columnDefs = [...this.columnDefs]; // triggers onPush change detection
                this.emitColumnsChanged(); // columns reordered
            });
        }
    }

    getFooterHeight(): number | null {
        return this.report?.FooterHeight ?? null;
    }

    getGridWidth(): number {
        let totalWidth = 50; // add buffer of 50px;
        if (this.columnDefs) {
            this.columnDefs.forEach((col) => {
                totalWidth += col.width;
            });
        }
        totalWidth = Math.min(Math.max(totalWidth, 500), 1300); // set a min and max width
        if (this.grid) {
            this.grid._innerWidth = totalWidth;
        }
        return totalWidth;
    }

    getSorts(): void {
        if (this.report) {
            let sortObj = {
                dir: this.report.SortDirection,
                prop: this.report.SortProperty,
            };
            this.defaultSort = [sortObj];
        }
    }

    checkIfDeletedColumnWasDefaultSort(deletedColumn: IReportColumn): void {
        if (this.report.SortProperty === deletedColumn.DatapointPropertyName) {
            // check to see if report columns still contain a column with the prop name being deleted
            // since we are goign to disallow duplicate columns, this will be unneccessary
            // TODO: remove duplicate column logic
            if (!this.report.ReportColumns.some((rc) => rc.DatapointPropertyName === this.report.SortProperty)) {
                this.report.SortDirection = null;
                this.report.SortProperty = null;
                this.reportsService.update(this.report).subscribe();
            }
        }
    }

    removeAndSelectReportColumn(index: number): IReportColumn {
        let reportColumn = this.report.ReportColumns[index];
        this.report.ReportColumns.splice(index, 1);
        return reportColumn;
    }
}
