import { NgModule } from '@angular/core';

import { UserRoutingModule } from './user-routing.module';

import { UserBasicInfoComponent } from './user-basic-info/user-basic-info.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UsersComponent } from './user-list/users.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { UserPhotoComponent } from './user-photo/user-photo.component';

import { SharedModule } from '@common/shared.module';
import { AuthEntityModule } from '../auth-entity/auth-entity.module';
import { MtPhotoControlModule } from '@mt-ng2/photo-control';
import { UserRegionsComponent } from './user-regions/user-regions.component';
import { EmbryologistDetailComponent } from './embryologist-detail/embryologist-detail.component';
import { EmbryologistClinicsComponent } from './embryologist-clinics/embryologist-clinics.component';

@NgModule({
    declarations: [
        UsersComponent,
        UserHeaderComponent,
        UserDetailComponent,
        UserBasicInfoComponent,
        UserPhotoComponent,
        UserRegionsComponent,
        EmbryologistDetailComponent,
        EmbryologistClinicsComponent,
    ],
    imports: [SharedModule, UserRoutingModule, AuthEntityModule, MtPhotoControlModule],
})
export class UserModule {}
