import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { HttpClient } from '@angular/common/http';
import { IReportGroup } from '../model/interfaces/report-group';
import { Observable } from 'rxjs';

@Injectable()
export class ReportGroupsService extends BaseService<IReportGroup> {
    constructor(public http: HttpClient) {
        super('/reports/groups', http);
    }

    delete(id: number): Observable<any> {
        return this.http.delete('/reports/groups/' + id);
    }
}
