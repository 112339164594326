import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { INoteCategory } from '@model/interfaces/note-category';

@Injectable({
    providedIn: 'root',
})
export class NoteCategoryService extends StaticMetaItemService<INoteCategory> {
    constructor(public http: HttpClient) {
        super('NoteCategoryService', 'Category', 'CategoryIds', '/options/noteCategories', http);
    }
}
