import { ApplicationStatuses } from '@model/enums/application-statuses.enum';
import { IApplication } from '@model/interfaces/application';
import { IVwApplicationInformation } from '@model/interfaces/vw-application-information';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class ApplicationsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['DonorId'],
                    name: 'Donor Id',
                }),
                new EntityListColumn({
                    accessorFunction: function (application: IVwApplicationInformation): string {
                        let val = `
                            <strong>Pre-screening:</strong> ${application.ApplicationPreScreeningComplete ? 'Complete' : 'Incomplete'}<br>
                            <strong>Medical Issues:</strong> ${application.ApplicationIssueComplete ? 'Complete' : 'Incomplete'}<br>
                            <strong>Who Are You:</strong> ${application.ApplicationWhoAreYouComplete ? 'Complete' : 'Incomplete'}<br>
                            <strong>Family History:</strong> ${application.ApplicationFamilyHistoryComplete ? 'Complete' : 'Incomplete'}<br>
                            <strong>Waiver/Photos:</strong> ${application.ApplicationWaiverPhotosComplete ? 'Complete' : 'Incomplete'}
                            `;
                        return val;
                    },
                    bindToInnerHtml: true,
                    name: 'Status',
                }),
                new EntityListColumn({
                    accessors: ['FirstName'],
                    name: 'First Name',
                }),
                new EntityListColumn({
                    accessors: ['LastName'],
                    name: 'Last Name',
                }),
                new EntityListColumn({
                    accessors: ['Email'],
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessors: ['City'],
                    name: 'City',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (application: IVwApplicationInformation): string {
                        return application.State ? `${application.State}` : ``;
                    },
                    name: 'State',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['Zip'],
                    name: 'Zip',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['DateCreated'],
                    name: 'Date Created',
                    pipes: ['date:mediumDate'],
                }),
                new EntityListColumn({
                    accessors: ['DenialReason'],
                    name: 'Denial Reason',
                }),
            ],
            rowClass: (entity: IVwApplicationInformation) => (entity.ApplicationStatusId === ApplicationStatuses.Denied || entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
