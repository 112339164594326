import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

import { ClinicService } from '../services/clinic.service';
import { ClinicDynamicConfig } from '../clinic.dynamic-config';

import { IClinic } from '@model/interfaces/clinic';

@Component({
    selector: 'app-clinic-basic-info',
    templateUrl: './clinic-basic-info.component.html',
})
export class ClinicBasicInfoComponent implements OnInit {
    @Input() clinic: IClinic;
    @Input() canEdit: boolean;

    isEditing = false;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: ClinicDynamicConfig<IClinic>;
    doubleClickIsDisabled = false;

    get isNewClinic(): boolean {
        return this.clinic && this.clinic.Id ? false : true;
    }

    constructor(private clinicService: ClinicService, private notificationsService: NotificationsService, private router: Router) {}

    ngOnInit(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new ClinicDynamicConfig<IClinic>(this.clinic);

        if (this.isNewClinic) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewClinic) {
            this.router.navigate(['/clinics']);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.clinic, form.value.Clinic);
            this.saveClinic();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveClinic(): void {
        if (this.isNewClinic) {
            this.clinicService
                .create(this.clinic)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.clinic.Id = answer;
                    this.success(true);
                });
        } else {
            this.clinicService
                .update(this.clinic)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    private success(newClinicSave?: boolean): void {
        if (newClinicSave) {
            this.router.navigate([`/clinics/${this.clinic.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.clinicService.emitChange(this.clinic);
        this.notificationsService.success('Clinic saved successfully.');
    }
}
