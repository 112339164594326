import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IApplicationIntroduceYourself } from '@model/interfaces/application-introduce-yourself';
import { ApplicationIntroduceYourselfService } from '@common/services/application-introduce-yourself.service';
import { AdminIntroduceYourselfDynamicControlsPartial } from '@model/partials/admin-introduce-yourself-partial.form-controls';
import { IApplication } from '@model/interfaces/application';
import { ApplicationStatuses } from '@model/enums/application-statuses.enum';

@Component({
    selector: 'application-introduce-yourself-form',
    templateUrl: './application-introduce-yourself-form.component.html',
})
export class ApplicationIntroduceYourselfFormComponent implements OnInit {
    @Input() introduceYourself: IApplicationIntroduceYourself;
    @Input() application: IApplication;
    @Output() onFinish: EventEmitter<IApplicationIntroduceYourself> = new EventEmitter<IApplicationIntroduceYourself>();

    // abstract controls
    abstractApplicationIntroduceYourselfControls: any;

    introduceYourselfForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    // metadata: IPersonalInformationMetaData;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private introduceYourselfService: ApplicationIntroduceYourselfService,
    ) { }

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.introduceYourselfForm = this.assignFormGroups();
        this.cdr.detectChanges();
        this.formCreated = true;
    }

    getControls(): void {
        this.abstractApplicationIntroduceYourselfControls = new AdminIntroduceYourselfDynamicControlsPartial(this.introduceYourself).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationIntroduceYourself: this.fb.group({}),
        });
    }

    formSubmitted(): void {
        if (this.introduceYourselfForm.valid) {
            Object.assign(this.introduceYourself, this.introduceYourselfForm.value.ApplicationIntroduceYourself);
            this.introduceYourselfService.updateIntroduceYourself(this.introduceYourself).subscribe((resp) => {
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.introduceYourselfForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.onFinish.emit(null);
    }

    approveClick(): void {
        if (this.introduceYourselfForm.valid) {
            let approved = confirm('Are you sure that you want to approve this donor information?');
            if (approved) {
                this.introduceYourselfService.approveIntroduceYourself(this.application.DonorId, this.introduceYourself).subscribe((result) => {
                    if (result) {
                        this.introduceYourself.Approved = true;
                        this.notificationsService.success('Introduce Yourself section was sucesfully approved.');
                    } else {
                        this.notificationsService.error('There was a problem approving the Introduce Yourself section');
                    }
                });
            }
        } else {
            markAllFormFieldsAsTouched(this.introduceYourselfForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Introduce yourself saved successfully.');
        this.enableDoubleClick();
        this.onFinish.emit(this.introduceYourself);
    }

    get ApplicationIntroduceYourself(): FormGroup {
        return this.introduceYourselfForm.get('ApplicationIntroduceYourself') as FormGroup;
    }

    get showApprovalButton(): boolean {
        let complete = false;

        if (!this.introduceYourself.Approved && this.application && this.application.ApplicationStatusId === ApplicationStatuses.Complete) {
            complete = (true);
        }

        return complete;
    }
}
