<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && embryologistDetail?.Id > 0"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Embryologist Detail
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Embryologist Detail</h4>
    <form *ngIf="formCreated" [formGroup]="form">
        <dynamic-field [form]="form" [field]="abstractEmbryologistDetailControls.IsActive"></dynamic-field>
        <dynamic-field [form]="form" (valueChanges)="isVerifiedChanged($event)" [field]="abstractEmbryologistDetailControls.IsVerified"></dynamic-field>
        <dynamic-field *ngIf="embryologistIsVerified" [form]="form" [field]="abstractEmbryologistDetailControls.DateVerified"></dynamic-field>
        <dynamic-field [form]="form" [field]="abstractEmbryologistDetailControls.IsTrainer"></dynamic-field>
        <dynamic-field [form]="form" [field]="abstractEmbryologistDetailControls.TestCompleted"></dynamic-field>
        <dynamic-field [form]="form" [field]="abstractEmbryologistDetailControls.CertifiedTechnologies"></dynamic-field>
        <button class="btn btn-flat btn-primary" (click)="formSubmitted()">Save</button>
        <button class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </form>
</div>
