import {
    ApplicationIntroduceYourselfDynamicControls,
    IApplicationIntroduceYourselfDynamicControlsParameters,
} from '../form-controls/application-introduce-yourself.form-controls';
import { IApplicationIntroduceYourself } from '../interfaces/application-introduce-yourself';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
} from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';

export class AdminIntroduceYourselfDynamicControlsPartial extends ApplicationIntroduceYourselfDynamicControls {
    constructor(
        applicationIntroduceYourself?: IApplicationIntroduceYourself,
        additionalParameters?: IApplicationIntroduceYourselfDynamicControlsParameters,
    ) {
        super(applicationIntroduceYourself, additionalParameters);
        (<DynamicField>this.Form.CharacterDescription) = new DynamicField({
            formGroup: this.formGroup,
            label: 'How would a family member or friend describe your character?',
            name: 'CharacterDescription',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationIntroduceYourself &&
                    applicationIntroduceYourself.hasOwnProperty('CharacterDescription') &&
                    applicationIntroduceYourself.CharacterDescription !== null
                    ? applicationIntroduceYourself.CharacterDescription.toString()
                    : '',
        });
        (<DynamicField>this.Form.ExerciseRoutine) = new DynamicField({
            formGroup: this.formGroup,
            label: 'What is your exercise routine?',
            name: 'ExerciseRoutine',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationIntroduceYourself &&
                    applicationIntroduceYourself.hasOwnProperty('ExerciseRoutine') &&
                    applicationIntroduceYourself.ExerciseRoutine !== null
                    ? applicationIntroduceYourself.ExerciseRoutine.toString()
                    : '',
        });
        (<DynamicField>this.Form.Favorites) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Favorite (Books, Food, Music)',
            name: 'Favorites',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationIntroduceYourself &&
                    applicationIntroduceYourself.hasOwnProperty('Favorites') &&
                    applicationIntroduceYourself.Favorites !== null
                    ? applicationIntroduceYourself.Favorites.toString()
                    : '',
        });
        (<DynamicField>this.Form.Goals) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Goals (Personal / Career)',
            name: 'Goals',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationIntroduceYourself && applicationIntroduceYourself.hasOwnProperty('Goals') && applicationIntroduceYourself.Goals !== null
                    ? applicationIntroduceYourself.Goals.toString()
                    : '',
        });
        (<DynamicField>this.Form.LanguagesSpoken) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Languages Spoken',
            name: 'LanguagesSpoken',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationIntroduceYourself && applicationIntroduceYourself.hasOwnProperty('LanguagesSpoken') && applicationIntroduceYourself.LanguagesSpoken !== null
                    ? applicationIntroduceYourself.LanguagesSpoken.toString()
                    : '',
        });

        (<DynamicField>this.Form.TalentsAbilities) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Talents/Abilities',
            name: 'TalentsAbilities',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationIntroduceYourself &&
                    applicationIntroduceYourself.hasOwnProperty('TalentsAbilities') &&
                    applicationIntroduceYourself.TalentsAbilities !== null
                    ? applicationIntroduceYourself.TalentsAbilities.toString()
                    : '',
        });
        (<DynamicField>this.Form.WhyInterestedInBecomingEggDonor) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Why are you interested in becoming an egg donor?',
            name: 'WhyInterestedInBecomingEggDonor',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(3000)],
            validators: { maxlength: 3000, required: true },
            value:
                applicationIntroduceYourself &&
                    applicationIntroduceYourself.hasOwnProperty('WhyInterestedInBecomingEggDonor') &&
                    applicationIntroduceYourself.WhyInterestedInBecomingEggDonor !== null
                    ? applicationIntroduceYourself.WhyInterestedInBecomingEggDonor.toString()
                    : '',
        });
    }
}
