import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IEmbryologistDetail } from '@model/interfaces/embryologist-detail';
import { Observable } from 'rxjs';

export const emptyEmbryologistDetail: IEmbryologistDetail = {
    Id: 0,
    IsActive: null,
    IsTrainer: null,
    IsVerified: false,
    TestCompleted: null,
    UserId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class EmbryologistDetailService extends BaseService<IEmbryologistDetail> {
    constructor(public http: HttpClient) {
        super('/embryologist-details', http);
    }

    getEmptyEmbryologistDetail(userId: number): IEmbryologistDetail {
        const newDetails = { ...emptyEmbryologistDetail };
        newDetails.UserId = userId;
        return newDetails;
    }

    getEmbryologistDetailsByUserId(userId: number): Observable<IEmbryologistDetail> {
        return this.http.get<IEmbryologistDetail>(`/embryologist-details/user/${userId}`);
    }
}
