import { Validators } from '@angular/forms';
import { AddressDynamicControls as base, IAddressDynamicControlsParameters as ibase } from '../form-controls/address.form-controls';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, LabelPositions, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { FormYesNoButtonComponent } from '@common/components/form-yes-no-button.component';
import { IAddress } from '../interfaces/address';
import { SharedFunctionsService } from '@common/services/shared-functions-service';
import { ICountryMetaItem } from '../interfaces/custom/country-meta-item';
import { IStateMetaItem } from '../interfaces/custom/state-meta-item';

export interface IAddressDynamicControlsParameters extends ibase {
    addressTypeId?: number;
    countries: ICountryMetaItem[];
    formGroup?: string;
    states: IStateMetaItem[];
}

export class AddressDynamicControls extends base {

    sharedFunctionsService: SharedFunctionsService;

    constructor(public value?: IAddress, public additionalParameters?: IAddressDynamicControlsParameters) {
        super(value, additionalParameters);

        this.sharedFunctionsService = new SharedFunctionsService();

        (<DynamicField>this.Form.StateCode) = new DynamicField({
            formGroup: this.formGroup,
            label: 'State/Province',
            name: 'StateCode',
            labelPosition: { position: LabelPositions.Top, colsForLabel: 1},
            options: additionalParameters.states.filter( (s) => s.CountryCode === value?.CountryCode),
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: this.sharedFunctionsService.getStateId(value.CountryCode, additionalParameters.countries, value.StateCode, additionalParameters.states),
        });

        (<DynamicField>this.Form.CountryCode) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Country',
            name: 'CountryCode',
            options: additionalParameters.countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: this.sharedFunctionsService.getCountryIdByCode(value.CountryCode, additionalParameters.countries),
        });

        (<DynamicField>this.Form.IsCurrentAddress) = new DynamicField({
            component: FormYesNoButtonComponent,
            formGroup: this.formGroup,
            label: 'Is This Your Current Address?',
            labelHtml: this.BuildDonorFormLabel(`Is this your current address?`),
            name: 'IsCurrentAddress',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: additionalParameters.addressTypeId ? additionalParameters.addressTypeId === 2 : true,
        });

        (<DynamicField>this.Form.City).validation = [Validators.required];
        (<DynamicField>this.Form.City).validators = { required: true};

        (<DynamicField>this.Form.Zip).validation = [Validators.required];
        (<DynamicField>this.Form.Zip).validators = { required: true};

        (<DynamicField>this.Form.Address1).validation = [Validators.required];
        (<DynamicField>this.Form.Address1).validators = { required: true};
    }
    BuildDonorFormLabel(questionTitle: string): string {
        return `<label class="input-label">
        <span class="input-scaffold__label">${questionTitle} <span class="input-scaffold__mock-required">*</span></span>
    </label>`;
    }
}
