import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IDonorIssue } from '@model/interfaces/donor-issue';

export const emptyDonorIssue: IDonorIssue = {
    CurrentMedications: null,
    DateCreated: new Date(),
    HasSurgeries: null,
    Id: 0,
    IsTakingMedication: null,
    OtherIssues: null,
    Surgeries: null,
};

@Injectable({
    providedIn: 'root',
})
export class DonorIssueService extends BaseService<IDonorIssue> {
    constructor(public http: HttpClient) {
        super('/donor-medical-issues', http);
    }

    getEmptyDonorIssue(): IDonorIssue {
        return { ...emptyDonorIssue };
    }
}
