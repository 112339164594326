import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IApplication } from '@model/interfaces/application';
import { ApplicationService } from '../services/application.service';

@Component({
    templateUrl: './application-header.component.html',
})
export class ApplicationHeaderComponent implements OnInit, OnDestroy {
    application: IApplication;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private applicationService: ApplicationService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('applicationId');
        // set the header based on the id
        if (id > 0) {
            this.applicationService.getById(id).subscribe((application) => {
                this.setHeader(application);
            });
        } else {
            this.setHeader(this.applicationService.getEmptyApplication());
        }
        // subscribe to any changes in the application service
        // which should update the header accordingly
        this.subscriptions.add(
            this.applicationService.changeEmitted$.subscribe((application) => {
                this.setHeader(application);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(application: IApplication): void {
        this.application = application;
        this.header = `Application: ${this.application.User.FirstName + ' ' + this.application.User.LastName}`;
    }
}
