import { Component, Input, OnInit } from '@angular/core';
import { FormSections } from '@model/enums/form-sections.enum';
import { WhoAreYouDynamicConfig } from './who-are-you.dynamic-config';
import { IBirthControlType } from '@model/interfaces/birth-control-type';
import { IBodyStructure } from '@model/interfaces/body-structure';
import { IGpa } from '@model/interfaces/gpa';
import { IEyeColor } from '@model/interfaces/eye-color';
import { IHairTexture } from '@model/interfaces/hair-texture';
import { INaturalHairColor } from '@model/interfaces/natural-hair-color';
import { IRace } from '@model/interfaces/race';
import { ISkinType } from '@model/interfaces/skin-type';
import { IAncestry } from '@model/interfaces/ancestry';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import { IApplication } from '@model/interfaces/application';
import { ICurrentSection, ApplicationDataService } from '../../services/application-data.service';
import { BirthControlTypes } from '@model/enums/birth-control-types.enum';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { ApplicationService } from '../../../services/application.service';
import { ISeriousTraumaticEvent } from '@model/interfaces/serious-traumatic-event';
import { IApplicationWhoAreYouChildren } from '@model/interfaces/application-who-are-you-children';
import { forkJoin } from 'rxjs';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { CommonService } from '../../../../../admin-portal/common/services/common.service';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { IPreScreening } from '@model/interfaces/pre-screening';

const CONFIG_CONTROLS = [
    'HasAcknowledgedWaiver',
    'FirstName',
    'MiddleInitial',
    'LastName',
    'Address1',
    'Address2',
    'City',
    'StateCode',
    'Province',
    'Zip',
    'CountryCode',
    'Dob',
    'PlaceOfBirth',
    'ApplicantPhoneNumber',
    'HasTextCommunication',
    'EmergencyContactName',
    'EmergencyContactNumber',
    'BodyStructureId',
    'EyeColorId',
    'NaturalHairColorId',
    'HairTextureId',
    'SkinTypeId',
    'RaceId',
    'HighSchoolGpaId',
    'CollegeMajor',
    'CollegeGpaId',
    'CurrentOccupation',
];

@Component({
    selector: 'who-are-you',
    templateUrl: './who-are-you.component.html',
})
export class AppWhoAreYouComponent implements OnInit {
    @Input() whoAreYou: IApplicationWhoAreYou;
    @Input() preScreening: IPreScreening;
    @Input() donor: ILoggedIn;
    @Input() application: IApplication;

    isEditing = false;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: WhoAreYouDynamicConfig<IApplicationWhoAreYou>;
    doubleClickIsDisabled = false;

    fatherAncestries: IAncestry[];
    motherAncestries: IAncestry[];

    applicationChildrenDetails: IApplicationWhoAreYouChildren[];

    whoAreYouMetaItems: IPersonalInformationMetaData;

    currentSection: ICurrentSection = {
        formGroup: null,
        nextTab: 'tab-familyHistory',
        nextTabLabel: 'Family History',
        previousTab: 'tab-medicalIssues',
        previousTabLabel: 'Medical Issues',
        routerLink: null,
        sectionId: FormSections.WhoAreYou,
    };

    states?: IStateMetaItem[];
    countries?: ICountryMetaItem[];
    private configControls: string[];

    constructor(private applicationService: ApplicationDataService, private appService: ApplicationService, private commonService: CommonService) { }

    ngOnInit(): void {
        forkJoin([this.commonService.getStates(),
        this.commonService.getCountries(),
        this.applicationService.getPersonalInformationMetaData()]).subscribe(
            ([states, countries, metaData]) => {
                this.states = this.commonService.getStateMetaItems();
                this.states = this.commonService.getStateMetaItems();
                this.countries = this.commonService.getCountryMetaItems();
                this.whoAreYouMetaItems = metaData;
                this.fatherAncestries = this.whoAreYou.FatherAncestries;
                this.motherAncestries = this.whoAreYou.MotherAncestries;
                this.setConfig();
            },
        );

    }

    setConfig(): void {
        this.configControls = [...CONFIG_CONTROLS];
        this.setViewFields();
        this.formFactory = new WhoAreYouDynamicConfig<IApplicationWhoAreYou>(
            this.whoAreYou,
            this.donor,
            this.application.HasAcknowledgedWaiver,
            this.whoAreYouMetaItems,
            this.states,
            this.countries,
            this.configControls,
        );
        this.config = this.formFactory.getForUpdate();
        this.applicationService.currentSection.next(this.currentSection);
    }

    setViewFields(): void {
        this.fatherAncestries = this.whoAreYou.FatherAncestries;
        this.motherAncestries = this.whoAreYou.MotherAncestries;
    }

    private findAndRemoveField(name: string): void {
        let index = this.configControls.findIndex((v) => v === name);
        this.configControls.splice(index, 1);
    }

    onEditFinished(updatedValue?: IApplicationWhoAreYou): void {
        if (updatedValue) {
            this.whoAreYou = updatedValue;
            this.whoAreYou.ConfirmPhoneNumber = this.whoAreYou.ApplicantPhoneNumber;
            this.setConfig();
            this.isEditing = false;
        }
        this.isEditing = false;
        this.appService.emitChange(this.application);
    }

    edit(): void {
        this.isEditing = true;
    }
}
