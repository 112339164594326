import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IVwDonorCycleReport } from '@model/interfaces/vw-donor-cycle-report';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DonorCycleReportService extends BaseService<IVwDonorCycleReport> {
    constructor(public http: HttpClient) {
        super('/donor-cycle-report', http);
    }

    get(searchParams: SearchParams): Observable<HttpResponse<IVwDonorCycleReport[]>> {
        let params = this.getHttpParams(searchParams);
        return this.http
            .get<IVwDonorCycleReport[]>(`/donor-cycle-report/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }
}
