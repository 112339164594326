import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IDonorFamilyMemberHistory } from '@model/interfaces/donor-family-member-history';
import {
    IDonorFamilyMemberHistoryDynamicControlsParameters,
    DonorFamilyMemberHistoryDynamicControls,
} from '@model/form-controls/donor-family-member-history.form-controls';
import { IEducationCompletedType } from '@model/interfaces/education-completed-type';
import { IEyeColor } from '@model/interfaces/eye-color';
import { IHairTexture } from '@model/interfaces/hair-texture';
import { INaturalHairColor } from '@model/interfaces/natural-hair-color';
import { IRace } from '@model/interfaces/race';
import { ISkinType } from '@model/interfaces/skin-type';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import {
    DonorFamilyMemberHistoryDynamicControlsPartial,
    IDonorFamilyMemberHistoryDynamicControlsParametersExtended,
} from '@model/partials/donor-family-member-history-partial.form-controls';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';

export class FamilyMemberHistoryDynamicConfig<T extends IDonorFamilyMemberHistory> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private donorFamilyMemberHistory: T,
        private educationCompletedTypes?: IEducationCompletedType[],
        private eyeColors?: IEyeColor[],
        private hairColors?: INaturalHairColor[],
        private hairTextures?: IHairTexture[],
        private heightUnits?: IUnitsOfMeasurement[],
        private races?: IRace[],
        private skinTypes?: ISkinType[],
        private weightUnits?: IUnitsOfMeasurement[],
        private countries?: ICountryMetaItem[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IDonorFamilyMemberHistoryDynamicControlsParametersExtended = {
            countries: this.countries,
            educationCompletedTypes: educationCompletedTypes,
            eyeColors: eyeColors,
            hairColors: hairColors,
            hairTextures: hairTextures,
            heightUnits: heightUnits,
            races: races,
            skinTypes: skinTypes,
            weightUnits: weightUnits,
        };

        const dynamicControls = new DonorFamilyMemberHistoryDynamicControlsPartial(this.donorFamilyMemberHistory, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'Age',
                'AgeAtDeath',
                'CauseOfDeath',
                'Deceased',
                'EducationCompletedTypeId',
                'EyeColorId',
                'HairColorId',
                'HairTextureId',
                'Height',
                'HeightUnitId',
                'IsFullSibling',
                'IsHalfSiblingFather',
                'IsHalfSiblingMother',
                'IsSibling',
                'Occupation',
                'RaceId',
                'Skills',
                'SkinTypeId',
                'Weight',
                'WeightUnitId',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
