import { RecipientCycleDynamicControls, IRecipientCycleDynamicControlsParameters } from '../form-controls/recipient-cycle.form-controls';
import { INewRecipientCycleDto } from '@model/interfaces/custom/new-recipient-cycle-dto';
import { IProductType } from '@model/interfaces/product-type';
import { IRecipientCycleStatus } from '@model/interfaces/recipient-cycle-status';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, noZeroRequiredValidator } from '@mt-ng2/dynamic-form';

export interface IRecipientCycleAddDynamicControlsParameters {
    formGroup?: string;
    productTypes: IProductType[];
    statuses?: IRecipientCycleStatus[];
}

export class RecipientCycleAddDynamicControlsPartial extends RecipientCycleDynamicControls {
    recipientsalesorder: any;
    constructor(recipientcyclePartial?: INewRecipientCycleDto, additionalParameters?: IRecipientCycleAddDynamicControlsParameters) {
        super(recipientcyclePartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.ProductTypeId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Product Type',
            name: 'ProductTypeId',
            options: additionalParameters.productTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [noZeroRequiredValidator],
            validators: { required: true },
            value: (recipientcyclePartial && recipientcyclePartial.ProductTypeId) || null,
        });
        // (<DynamicField>this.Form.StatusId).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.RecipientId).label = 'Some other Label';
        // (<DynamicLabel>this.View.StatusId).label = 'Some other Label';
    }
}
