import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDatapoint } from '../model/interfaces/datapoint';
import { IColumnDefinition } from '../model/interfaces/column-definition';
import { NotificationsService } from '@mt-ng2/notifications-module';

let dateDisplayOptions = { year: 'numeric', month: 'long', day: 'numeric' };
let today = new Date(); // bad to have these as "global" variables?

export function flattenDataPoints(datapoints: IDatapoint[], collection?: IDatapoint[], datapointPrefix = '', displayAsPrefix = ''): IDatapoint[] {
    if (!collection) {
        collection = [];
    }

    datapoints.forEach((dp) => {
        const nested = dp.Type === 'class' && dp.definition?.length;
        const name = datapointPrefix ? `${datapointPrefix}_${dp.Name}` : `${dp.Name}`;
        const displayAs = displayAsPrefix ? `${displayAsPrefix}:${dp.DisplayAs}` : `${dp.DisplayAs}`;
        if (nested) {
            flattenDataPoints(dp.definition, collection, name, displayAs);
            return;
        }
        if (dp.Type === 'class') {
            return;
        }
        let items = dp.items;
        if (items?.length && items[0].hasOwnProperty('Sort')) {
            items = items.sort((a, b) => (a.Sort > b.Sort ? 1 : b.Sort > a.Sort ? -1 : 0));
        }
        collection.push({
            DisplayAs: displayAs,
            FrontEndFormat: dp.FrontEndFormat,
            items: items,
            Name: name,
            Type: dp.Type,
        } as IDatapoint);
    });

    return collection;
}

@Injectable()
export class DatasetDatapointsService {
    constructor(public http: HttpClient, private notificationsService: NotificationsService) {}

    getDatapoints(datasetId: number): Observable<IDatapoint[]> {
        return this.http.get<IDatapoint[]>(`/reports/datasets/datapoints/${datasetId}`);
    }

    generateDummyData(dataPoints: IDatapoint[], columnDefinitions: IColumnDefinition[]): any[] {
        let data = [];
        let metaDataHasNoItemsError = {
            property: '',
            throwError: false,
        };
        for (let i = 0; i < 6; i++) {
            let datum = {};
            columnDefinitions.forEach((columnDefinition) => {
                let dataPoint: IDatapoint = dataPoints.find((dp) => {
                    if (dp.Type === 'metadata') {
                        return columnDefinition.prop === dp.Name;
                    } else {
                        return dp.Name === columnDefinition.prop;
                    }
                });
                if (dataPoint.Type !== 'metadata') {
                    datum = this.flattenData(dataPoint.Name, datum, dataPoint);
                } else if (dataPoint.Type === 'metadata') {
                    let item = dataPoint.items[Math.floor(Math.random() * dataPoint.items.length)];
                    if (item) {
                        datum[dataPoint.Name] = item.Name;
                    } else {
                        metaDataHasNoItemsError.throwError = true;
                        metaDataHasNoItemsError.property = dataPoint.DisplayAs;
                    }
                }
            });
            data.push(datum);
        }
        if (metaDataHasNoItemsError?.throwError) {
            this.notificationsService.error('No items for metadata ' + metaDataHasNoItemsError.property);
        }
        return data;
    }

    private flattenData(dataPointName: string, datum: any, dataPoint: IDatapoint): any {
        if (dataPointName.includes('_')) {
            let stringIndexOfUnderscore = dataPointName.indexOf('_');
            let leftKey = dataPointName.slice(0, stringIndexOfUnderscore);
            let rightKey = dataPointName.slice(stringIndexOfUnderscore + 1);
            if (!datum[leftKey]) {
                datum[leftKey] = {};
            }
            datum[leftKey] = this.flattenData(rightKey, datum[leftKey], dataPoint);
        } else {
            switch (dataPoint.Type) {
                case 'number':
                    datum[dataPointName] = '0000';
                    break;
                case 'string':
                    datum[dataPointName] = 'XXXX';
                    break;
                case 'boolean':
                    datum[dataPointName] = !!Math.round(Math.random()) as boolean;
                    break;
                case 'Date':
                    datum[dataPointName] = today.toLocaleDateString('en-US', dateDisplayOptions as any);
                    break;
                default:
                    datum[dataPointName] = ''; // currently no dummy data for class dataPoints
                    break;
            }
        }
        return datum;
    }
}
