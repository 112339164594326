import { DynamicField, InputTypes } from '@mt-ng2/dynamic-form';
import { DeviceLotNumberDynamicControls, IDeviceLotNumberDynamicControlsParameters } from '../form-controls/device-lot-number.form-controls';
import { IDeviceLotNumber } from '../interfaces/device-lot-number';

export class DeviceLotNumberDynamicControlsPartial extends DeviceLotNumberDynamicControls {
    constructor(devicelotnumberPartial?: IDeviceLotNumber, additionalParameters?: IDeviceLotNumberDynamicControlsParameters) {
        super(devicelotnumberPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.ExpirationDate).type.inputType = InputTypes.DateInput;
        // (<DynamicField>this.Form.ExpirationDate).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.Name).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.OutOfUse).type.fieldType = DynamicFieldTypes.Input;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.ExpirationDate).label = 'Some other Label';
        // (<DynamicLabel>this.View.Name).label = 'Some other Label';
        // (<DynamicLabel>this.View.OutOfUse).label = 'Some other Label';
    }
}
