import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { setMax } from '@common/libraries/dynamic-field.library';
import { SalesOrderReservationDonorDynamicControls } from '@model/form-controls/sales-order-reservation-donor.form-controls';
import { ISalesOrderReservationDonor } from '@model/interfaces/sales-order-reservation-donor';
import { IVwDonorAvailableInventory } from '@model/interfaces/vw-donor-available-inventory';
import { DonorService } from '../../donors/services/donor.service';

@Component({
    selector: 'donor-edit-quantity',
    template: `
        <div>
            <div>
                <label>Donor:</label>
                {{ donor.Donor.User.FirstName }} {{ donor.Donor.User.LastName }}
            </div>
            <div>
                <label>Donor Id:</label>
                {{ donor.Donor.DonorId }}
            </div>
            <div>
                <label>Email:</label>
                {{ donor.Donor.User.Email }}
            </div>
            <div *ngIf="maxInventory">
                <label>Available Inventory:</label>
                {{ maxInventory }}
            </div>
            <div *ngIf="abstractSalesOrderReservationDonorControls">
                <dynamic-field
                    [field]="abstractSalesOrderReservationDonorControls.EggCountReserved"
                    [form]="form"
                    [overrideForm]="true"
                    (valueChanges)="onChange($event)"
                ></dynamic-field>
            </div>
        </div>
    `,
})
export class DonorEditQuantityComponent implements OnInit {
    @Input() donor: ISalesOrderReservationDonor;
    @Input() form: FormGroup;

    abstractSalesOrderReservationDonorControls: any;
    availableInventory: IVwDonorAvailableInventory;

    maxInventory: number;

    constructor(private donorService: DonorService) {}

    ngOnInit(): void {
        this.donorService.getAvailableInventoryByDonor(this.donor.DonorId).subscribe((inv) => {
            this.availableInventory = inv;
            this.getControls();
            this.setValidation();
        });
    }

    getControls(): void {
        this.abstractSalesOrderReservationDonorControls = new SalesOrderReservationDonorDynamicControls(this.donor, {
            formGroup: 'SalesOrderReservationDonor',
        }).Form;
    }

    setValidation(): void {
        if (this.availableInventory) {
            // Max should include amount already reserved
            this.maxInventory = this.availableInventory.AvailableInventory + this.donor.EggCountReserved;
            setMax(this.abstractSalesOrderReservationDonorControls.EggCountReserved, this.maxInventory);
        }
    }
}
