<div *ngIf="clinic">
    <div class="row">
        <div class="col-md-6">
            <app-clinic-basic-info [clinic]="clinic" [canEdit]="canEdit"></app-clinic-basic-info>
            <br />
            <app-clinic-donor-monitoring [clinic]="clinic" [canEdit]="canEdit"></app-clinic-donor-monitoring>
            <br />
            <app-clinic-verification-basic-info [canEdit]="canEdit" [clinic]="clinic"></app-clinic-verification-basic-info>
            <br />
            <app-notes [entityId]="clinic.Id" [entityType]="clinicNoteType" [canEdit]="canEdit"></app-notes>
            <br />
            <app-clinic-embryologists [clinic]="clinic" [canEdit]="canEdit"></app-clinic-embryologists>
            <br />
        </div>
        <div class="col-md-6">
            <div *ngIf="clinicEntityTypes?.length">
                <div *ngFor="let type of clinicEntityTypes">
                    <clinic-entity [clinicId]="clinic.Id" [clinicEntityType]="type" [canEdit]="canEdit"></clinic-entity>
                    <br />
                </div>
            </div>
            <app-upload-documents-detail
                [entityId]="clinic.Id"
                [entityType]="documentEntityType"
                [claimType]="claimType"
            ></app-upload-documents-detail>
            <br />
        </div>
    </div>
    <a routerLink="/clinics" class="btn btn-default">Close</a>
</div>
