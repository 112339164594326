<div *ngIf="controlInitialized">
    <dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="wrapperForm">
        <div>
            <div class="row">
                <div class="col-xs-12 pad">
                    <dynamic-field [form]="wrapperForm" [field]="datetimeField" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                </div>
                <div class="col-xs-12 pad">
                    <dynamic-field [form]="wrapperForm" [field]="triggerTypeField" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                </div>
                <div class="col-xs-12 pad">
                    <dynamic-field [form]="wrapperForm" [field]="triggerAmountField" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                </div>
                <div class="col-xs-12 pad">
                    <dynamic-field [form]="wrapperForm" [field]="dualTriggerField" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                </div>
            </div>
            <div *ngIf="showSecondTrigger" class="row">
                <div class="col-xs-12 pad">
                    <dynamic-field [form]="wrapperForm" [field]="datetimeField2" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                </div>
                <div class="col-xs-12 pad">
                    <dynamic-field [form]="wrapperForm" [field]="triggerTypeField2" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                </div>
                <div class="col-xs-12 pad">
                    <dynamic-field [form]="wrapperForm" [field]="triggerAmountField2" (blur)="onBlur()" (focus)="onFocus()"></dynamic-field>
                </div>
            </div>
        </div>
    </dynamic-form-input-wrapper>
</div>
