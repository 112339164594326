import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IUser } from '@model/interfaces/user';
import { IAuditLogUserService, IAuditLogUser } from '@mt-ng2/audit-logging-module-config';

@Injectable()
export class AuditLogUserService implements IAuditLogUserService {
    constructor(public http: HttpClient) {}

    getUsers(): Observable<IAuditLogUser[]> {
        return this.http.get<IAuditLogUser[]>('/api/v1/users/audit-log-users');
    }
}
