import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimValues } from '@mt-ng2/auth-module';
import { AuditLogsListComponent } from '../components/audit-logs-list/audit-logs-list.component';
import { AuditLogAuthGuard } from './audit-logs-auth.guard';

const listRoleGuard: any = {
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Audit Logs',
};

/**
 * define routes for audit logs below
 */

const eagerLoadedRoutes: Routes = [
    {
        canActivate: [AuditLogAuthGuard],
        component: AuditLogsListComponent,
        data: listRoleGuard,
        path: 'audit-logs',
    },
];

const lazyLoadedRoutes: Routes = [
    {
        canActivate: [AuditLogAuthGuard],
        component: AuditLogsListComponent,
        data: listRoleGuard,
        path: '',
    },
];

/**
 * eager loaded module
 */

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(eagerLoadedRoutes)],
})
export class AuditLogEagerLoadedRoutingModule {}

/**
 * lazy loaded module
 */

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(lazyLoadedRoutes)],
})
export class AuditLogRoutingModule {}
