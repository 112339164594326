import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IDeviceLotNumber } from '@model/interfaces/device-lot-number';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DeviceLotNumberService extends MetaItemService<IDeviceLotNumber> {
    constructor(public http: HttpClient) {
        super('DeviceLotNumberService', 'Number', 'NumberIds', '/device-lot-numbers', http);
    }

    updateItems(items: IDeviceLotNumber[]): Observable<object> {
        // mt managed list defaults value to '' for new entries, which results in a failure to parse on the BE
        items.forEach((i) => (i.OutOfUse = i.OutOfUse ? true : false));
        return super.updateItems(items);
    }
}
