<div *ngIf="formCreated">
    <div class="form-section-border">
        <h3>Who Are You</h3>
    </div>
    <form [formGroup]="whoAreYouForm" (ngSubmit)="formSubmitted()">
        <h4>Personal Information</h4>
        <custom-ssn [ssn]="whoAreYou.Ssn" [id]="whoAreYou.Id" type="app" [form]="whoAreYouForm"></custom-ssn>
        <br/>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasAcknowledgedWaiver" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.FirstName" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.MiddleInitial"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.LastName" [form]="whoAreYouForm"></dynamic-field>
        <label class="input-label">
            <span class="input-scaffold__label">Phone Number</span>
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.ApplicantPhoneNumber"
                [form]="whoAreYouForm"></dynamic-field>
        </label>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasTextCommunication"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.EmergencyContactName"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.EmergencyContactNumber"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.Dob" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.PlaceOfBirth"
            [form]="whoAreYouForm"></dynamic-field>
        <h4>Address</h4>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.CountryCode" [form]="whoAreYouForm"
            (valueChanges)="selectedCountryId = $event"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.Address1" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.Address2" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.City" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field *ngIf="!countryHasStateCodes" [field]="abstractApplicationWhoAreYouControls.Province"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field *ngIf="countryHasStateCodes" [field]="abstractApplicationWhoAreYouControls.StateCode"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.Zip" [form]="whoAreYouForm"></dynamic-field>

        <h4>Race/Ancestry</h4>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.RaceId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.ApplicationFatherAncestries" [form]="whoAreYouForm"
            (valueChanges)="onFatherAncestryValueChange($event)"></dynamic-field>
        <dynamic-field *ngIf="showOtherFatherAncestries"
            [field]="abstractApplicationWhoAreYouControls.OtherAncestryFather" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.ApplicationMotherAncestries" [form]="whoAreYouForm"
            (valueChanges)="onMotherAncestryValueChange($event)"></dynamic-field>
        <dynamic-field *ngIf="showOtherMotherAncestries"
            [field]="abstractApplicationWhoAreYouControls.OtherAncestryMother" [form]="whoAreYouForm"></dynamic-field>
        <h4>Physical Attributes</h4>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.BodyStructureId"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.EyeColorId" [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.NaturalHairColorId"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HairTextureId"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.SkinTypeId" [form]="whoAreYouForm"></dynamic-field>
        <h4>Education</h4>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.HighSchoolGpaId"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.CollegeMajor"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.CollegeGpaId"
            [form]="whoAreYouForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationWhoAreYouControls.CurrentOccupation"
            [form]="whoAreYouForm"></dynamic-field>
        <div class="row col-md-12">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </div>
    </form>
</div>