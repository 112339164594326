import { Component, OnInit } from '@angular/core';
import { IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { IClinicDto } from '@model/interfaces/custom/clinic-dto';
import { ClinicService } from '../services/clinic.service';

@Component({
    selector: 'app-delete-clinic',
    template: `
        <i *ngIf="entity.CanBeDeleted" class="fa fa-2x fa-fw fa-trash" (click)="deleteClinic($event)"></i>
    `,
})

export class DeleteClinicComponent implements OnInit, IEntityListDynamicCellComponentGeneric<IClinicDto> {
    entity: IClinicDto;
    entityListComponentMembers: IEntityListComponentMembers;

    constructor() { }

    ngOnInit(): void { }

    deleteClinic(e: Event): void {
        e.stopPropagation();
        this.entityListComponentMembers.itemDeleted(this.entity, e);
    }
}
