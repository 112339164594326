import { IDataFormatOption } from '../model/interfaces/data-format-option';
import { FrontEndFormats } from './datapoints.library';

export function convertNumberString(value: string, format: FrontEndFormats): number {
    let regex: RegExp = null;
    switch (format) {
        case FrontEndFormats.Currency:
            regex = /[$,]+/g;
            break;
        case FrontEndFormats.Percentage:
            regex = /%+/g;
            break;
        default:
            break;
    }
    if (regex) {
        value = value.replace(regex, '');
    }
    return Number(value);
}

export function getComparatorForDataFormat(format: IDataFormatOption): (valueA: any, valueB: any) => number {
    return function (valueA: any, valueB: any): number {
        if (format.Type === 'Date') {
            valueA = new Date(valueA);
            valueB = new Date(valueB);
        } else if (format.Type === 'number') {
            valueA = convertNumberString(valueA, format.FrontEndFormat);
            valueB = convertNumberString(valueB, format.FrontEndFormat);
        }
        return valueA < valueB ? -1 : 1;
    };
}
