import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ISalesOrderReservation } from '@model/interfaces/sales-order-reservation';
import { SalesOrderReservationService } from '../services/sales-order-reservation.service';

@Component({
    templateUrl: './sales-order-reservation-header.component.html',
})
export class SalesOrderReservationHeaderComponent implements OnInit, OnDestroy {
    salesOrderReservation: ISalesOrderReservation;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private salesOrderReservationService: SalesOrderReservationService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('salesOrderReservationId');
        // set the header based on the id
        if (id > 0) {
            this.salesOrderReservationService.getById(id).subscribe((salesOrderReservation) => {
                this.setHeader(salesOrderReservation);
            });
        } else {
            this.setHeader(this.salesOrderReservationService.getEmptySalesOrderReservation());
        }
        // subscribe to any changes in the salesOrderReservation service
        // which should update the header accordingly
        this.subscriptions.add(
            this.salesOrderReservationService.changeEmitted$.subscribe((salesOrderReservation) => {
                this.setHeader(salesOrderReservation);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(salesOrderReservation: ISalesOrderReservation): void {
        this.salesOrderReservation = salesOrderReservation;
        this.header = 'Add Sales Order Reservation';
    }
}
