<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Search Recipients"></mt-page-title>
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <br />
</div>
<div style="zoom: 80%" *ngIf="recipients && recipients.length">
    <entity-list
        [entities]="recipients"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (onPageChanged)="getRecipients()"
        (onItemSelected)="recipientSelected($event)"
        [entityListConfig]="entityListConfig"
    >
    </entity-list>
</div>
