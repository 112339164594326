import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IAppointment } from '@model/interfaces/appointment';

export const emptyAppointment: IAppointment = {
    Cancelled: null,
    DateCreated: null,
    DonorId: null,
    EndTimeDonor: null,
    EndTimeUtc: null,
    EventUuid: null,
    Id: 0,
    PhoneNumber: null,
    StartTimeDonor: null,
    StartTimeUtc: null,
    Timezone: null,
    TypeId: 1,
};

@Injectable({
    providedIn: 'root',
})
export class AppointmentService extends BaseService<IAppointment> {
    constructor(public http: HttpClient) {
        super('/appointments', http);
    }

    getEmptyAppointment(): IAppointment {
        return { ...emptyAppointment };
    }
}
