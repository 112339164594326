import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MetaItemService } from '@mt-ng2/base-service';
import { IRetrievalPhysician } from '@model/interfaces/retrieval-physician';

@Injectable({
    providedIn: 'root',
})
export class RetrievalPhysicianService extends MetaItemService<IRetrievalPhysician> {
    constructor(public http: HttpClient) {
        super('RetrievalPhysicianService', 'Physician', 'PhysicianIds', '/retrieval-physicians', http);
    }

    getActiveRetrievalPhysicans(cycleId: number): Observable<IRetrievalPhysician[]> {
        return this.http.get<IRetrievalPhysician[]>(`/retrieval-physicians/active/${cycleId}`);
    }
}
