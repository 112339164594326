/**
 * Enum for shape of cropping tool
 */
export enum Shape {
    Rectangle = 1,
    Circle = 2,
}

/**
 * Interface defining MT's version of cropper settings
 * @property {number} croppedHeight - resulting image height
 * @property {number} croppedWidth - resulting image width
 * @property {number} height - crop height
 * @property {number} width - crop width
 * @property {number} canvasHeight - canvas DOM element height
 * @property {number} canvasWidth - canvas DOM element width
 * @property {number} minHeight - minimum crop height
 * @property {number} minWidth - minimum crop width
 * @property {Shape} shape - of the cropper tool
 * @property {boolean} cropFromOriginalResolution - flag indicating whether resulting image will be cropped from the image's original resolution : default is true
 * @property {boolean} hideFileInput - flag hides file input element from cropper canvas
 */
export interface IMtCropperSettings {
    croppedHeight?: number;
    croppedWidth?: number;
    height?: number;
    width?: number;
    canvasHeight?: number;
    canvasWidth?: number;
    minHeight?: number;
    minWidth?: number;
    shape?: Shape;
    cropFromOriginalResolution?: boolean;
    hideFileInput?: boolean;
}
