<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Recipient Sales Order Report"></mt-page-title>
        </h2>
    </div>
</div>
<div id="search-panel" [formGroup]="searchForm">
    <div class="row">
        <div class="col-md-2">
            <label>Contract Date</label><br />
            <mt-search-filter-daterange
                [startDate]="startDate"
                [endDate]="endDate"
                entity="Contract Date"
                (onSelectionChanged)="dateSelectionChanged($event)"
            ></mt-search-filter-daterange>
        </div>
        <div class="col-md-2">
            <label>Clinic</label><br />
            <mt-search-filter-select [items]="clinics" entity="Clinic" (onSelectionChanged)="search()"> </mt-search-filter-select>
        </div>
        <div class="col-md-1">
            <label>Recipient ID</label>
            <input
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                type="number"
                class="form-control"
                formControlName="recipientId"
            />
        </div>
        <div class="col-md-2">
            <label>Recipient Name</label>
            <input type="text" class="form-control" formControlName="recipientName" />
        </div>
        <div class="col-md-2">
            <label>Recipient Email</label>
            <input type="text" class="form-control" formControlName="recipientEmail" />
        </div>
        <div class="col-md-1">
            <label>Donor ID</label>
            <input onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" class="form-control" formControlName="donorId" />
        </div>
        <div class="col-md-2">
            <results-per-page (onSizeChange)="onPageSizeChange($event)"></results-per-page>
        </div>
    </div>
    <br />
</div>

<entity-list
    [entities]="recipientSales"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (onPageChanged)="search()"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    [showItemCounts]="true"
>
</entity-list>
