import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SearchParams } from '@mt-ng2/common-classes';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppError, NotFoundError } from '@mt-ng2/errors-module';

@Injectable({providedIn: 'root'})
export class QualitySummaryReportService {
    constructor(public http: HttpClient) {}

     get(searchParams: SearchParams): Observable<any> {
        let params = this.getHttpParams(searchParams);
        return this.http
            .get<any>(`/quality-summary-report/generate`, {
                observe: 'response',
                params: params,
            }).pipe(map((resp) => resp.body));
    }

    protected getHttpParams(searchparameters: SearchParams): HttpParams {
        let params = new HttpParams();
        if (searchparameters.extraParams && searchparameters.extraParams.length > 0) {
            let extraparams = new HttpParams();
            searchparameters.extraParams.forEach((param) => {
                if (param.valueArray) {
                    if (param.valueArray.length > 0) {
                        extraparams = extraparams.append(param.name, param.valueArray.toString());
                    }
                } else {
                    if (param.value.length > 0) {
                        extraparams = extraparams.set(param.name, param.value);
                    }
                }
            });
            if (extraparams.keys().length > 0) {
                params = params.append('extraparams', extraparams.toString());
            }
        }
        return params;
    }
}
