import { IReportColumn } from '../model/interfaces/report-column';
import { FrontEndFormats } from './datapoints.library';
import { formatCurrency, numberWithCommas } from './formats.library';

export enum AggregateTypes {
    Count = 1,
    Sum = 2,
    GroupedCount = 3,
    Min = 4,
    Max = 5,
    Avg = 6,
}

export type AggregateSummaryFunction = (cellData: any[]) => string;

/**
 * returns a function to be used in the datatable for getting aggregates.  Uses the SummaryFunctionBuilder
 * to create the functions to be returned.
 * @param column
 * @param totalRecordCount
 * @returns
 */
export function getSummaryFunc(column: IReportColumn, totalRecordCount: number): AggregateSummaryFunction {
    const summaryFunctionBuilder = new SummaryFunctionBuilder(column, totalRecordCount);
    const aggregateTypeId: AggregateTypes = column?.ReportAggregate?.AggregateTypeId ?? 0;
    switch (aggregateTypeId) {
        case AggregateTypes.Count:
            return summaryFunctionBuilder.Count;
        case AggregateTypes.Sum:
            return summaryFunctionBuilder.Sum;
        case AggregateTypes.GroupedCount:
            return summaryFunctionBuilder.GroupedCount;
        case AggregateTypes.Min:
            return summaryFunctionBuilder.Min;
        case AggregateTypes.Max:
            return summaryFunctionBuilder.Max;
        case AggregateTypes.Avg:
            return summaryFunctionBuilder.Avg;
        default:
            return () => null;
    }
}

/**
 * Helper class to build AggregateSummaryFunction.
 *
 * The AggregateSummaryFunction only has cellData passed in as a parameter, but most functions require more
 * information about the report column to create the aggregate.  This class is responsible for containing
 * any and all data needed for the summary function and returning a built out summary function that is
 * bound to the instance so that it knows how to build the aggregate with compounded data (i.e. the cell
 * data along with the report column, etc.)
 *
 * The only public interface from this class are the properties of functions (i.e. Count, Max, Min, etc.).
 * So the use of this class would be
 *
 * `new SummaryFunctionBuilder(column, count).Max`
 */
class SummaryFunctionBuilder {
    private formatType: FrontEndFormats;
    private dataFormatTypeIsNumber: boolean;

    constructor(column: IReportColumn, private totalRecordCount: number) {
        this.formatType = column.DataFormatOptions.FrontEndFormat;
        this.dataFormatTypeIsNumber = column.DataFormatOptions.Type === 'number';
    }

    public get Count(): AggregateSummaryFunction {
        return ((cellData: any[]): string => {
            let count = cellData.length;
            return `<span class="small">${this.getAggregateLabel(cellData.length, 'Count')} ${count}</span>`;
        }).bind(this);
    }

    public get Sum(): AggregateSummaryFunction {
        return ((cellData: any[]): string => {
            let sum: number = null;
            cellData.forEach((cell) => {
                cell = this.getCellValueAsNumber(cell);
                if (this.isNumeric(cell)) {
                    if (sum === null) {
                        sum = 0;
                    }
                    sum += cell;
                }
            });
            return `<span class="small">${this.getAggregateLabel(cellData.length, 'Sum')} ${sum !== null ? this.applyFormat(sum) : ''}</span>`;
        }).bind(this);
    }

    public get GroupedCount(): AggregateSummaryFunction {
        return ((cellData: any[]): string => {
            const counts: { name: string; count: number }[] = [];
            cellData.forEach((cell) => {
                const count = counts.find((c) => c.name === cell);
                if (count) {
                    count.count += 1;
                } else {
                    counts.push({ name: cell, count: 1 });
                }
            });
            let summary = [];
            counts.forEach((count) => {
                summary.push(`${count.name}: ${count.count}`);
            });
            return `<span class="small">${summary.join('<br>')}</span>`;
        }).bind(this);
    }

    public get Min(): AggregateSummaryFunction {
        return ((cellData: any[]): string => {
            let min: any = null;
            if (cellData?.length) {
                if (this.cellDataCanBeConvertedToNumber(cellData)) {
                    cellData.forEach((n: number) => {
                        let num = this.getCellValueAsNumber(n);
                        if (min === null || num < min) {
                            min = num;
                        }
                    });
                } else if (this.cellDataCanBeConvertedToDate(cellData)) {
                    let minDate: Date = null;
                    cellData.forEach((dateAsString: string) => {
                        const d = new Date(dateAsString);
                        if (minDate === null || d < minDate) {
                            minDate = d;
                            min = dateAsString;
                        }
                    });
                }
            }
            return `<span class="small">Min: ${min !== null ? this.applyFormat(min) : ''}</span>`;
        }).bind(this);
    }

    public get Max(): AggregateSummaryFunction {
        return ((cellData: any[]): string => {
            let max: any = null;
            if (cellData?.length) {
                if (this.cellDataCanBeConvertedToNumber(cellData)) {
                    cellData.forEach((n: number) => {
                        let num = this.getCellValueAsNumber(n);
                        if (max === null || num > max) {
                            max = num;
                        }
                    });
                } else if (this.cellDataCanBeConvertedToDate(cellData)) {
                    let maxDate: Date = null;
                    cellData.forEach((dateAsString: string) => {
                        const d = new Date(dateAsString);
                        if (maxDate === null || d > maxDate) {
                            maxDate = d;
                            max = dateAsString;
                        }
                    });
                }
            }
            return `<span class="small">Max: ${max !== null ? this.applyFormat(max) : ''}</span>`;
        }).bind(this);
    }

    public get Avg(): AggregateSummaryFunction {
        return ((cellData: any[]): string => {
            let avg: any = null;
            if (cellData?.length) {
                let avgDivisor = cellData.length;
                avg = this.calculateAvg(cellData, avgDivisor, avg);
            }
            return `<span class="small">${this.getAggregateLabel(cellData.length, 'Avg')} ${avg !== null ? this.applyFormat(avg) : ''}</span>`;
        }).bind(this);
    }

    private isNumeric(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    private tryAddDecimals(value: number): string {
        return value % 1 !== 0 ? value.toFixed(2) : value.toString();
    }

    private getAggregateLabel(cellCount: number, label: string): string {
        const isSubset = cellCount !== this.totalRecordCount;
        return isSubset ? `${label}:` : `Report Total ${label}:`;
    }

    private calculateAvg(cellData: any[], avgDivisor: number, avg: any): any {
        if (this.cellDataCanBeConvertedToNumber(cellData)) {
            let sum = 0;
            cellData.forEach((value: string | number) => {
                let valueAsNumber = this.getCellValueAsNumber(value);
                if (!valueAsNumber) {
                    avgDivisor--;
                    valueAsNumber = 0;
                }
                sum += valueAsNumber;
            });
            avg = sum / avgDivisor;
        }
        return avg || 0;
    }

    private getCellValueAsNumber(value: string | number): number {
        if (typeof value === 'number') {
            return value;
        } else if (typeof value === 'string' && value) {
            return parseFloat(value.toString().replace(/[^0-9.-]+/g, ''));
        }
        if (!value && this.dataFormatTypeIsNumber) {
            return 0;
        }
        return null;
    }

    private cellDataCanBeConvertedToNumber(cellData: any[]): boolean {
        const firstCellDataAsNumber = this.getCellValueAsNumber(cellData[0]);
        return this.isNumeric(firstCellDataAsNumber);
    }

    private cellDataCanBeConvertedToDate(cellData: any[]): boolean {
        const firstCellDataAsDateParse = Date.parse(cellData[0]);
        return !isNaN(firstCellDataAsDateParse);
    }

    private applyFormat(value: string | number): string {
        if (this.dataFormatTypeIsNumber) {
            value = this.tryAddDecimals(<number>value);
        }
        switch (this.formatType) {
            case FrontEndFormats.Currency:
                value = formatCurrency(value.toString());
                return value;
            case FrontEndFormats.Decimal:
                return numberWithCommas(this.tryAddDecimals(<number>value));
            case FrontEndFormats.Percentage:
                value = `${value.toString()}%`;
                value = numberWithCommas(value);
                return value;
            default:
                return numberWithCommas(value.toString());
        }
    }
}
