import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { HttpClient } from '@angular/common/http';
import { IReportAggregate } from '../model/interfaces/report-aggregate';
import { Observable } from 'rxjs';

@Injectable()
export class ReportAggregatesService extends BaseService<IReportAggregate> {
    constructor(public http: HttpClient) {
        super('/reports/aggregates', http);
    }

    delete(id: number): Observable<any> {
        return this.http.delete('/reports/aggregates/' + id);
    }
}
