import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { SalesOrderReservationAddComponent } from './sales-order-reservation-add/sales-order-reservation-add.component';
import { SalesOrderReservationsComponent } from './sales-order-reservation-list/sales-order-reservations.component';
import { SalesOrderReservationHeaderComponent } from './sales-order-reservation-header/sales-order-reservation-header.component';

import { ClaimTypes } from '@model/ClaimTypes';

const salesOrderReservationListRoleGuard: any = {
    claimType: ClaimTypes.Reservations,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'SalesOrderReservations',
};

const salesOrderReservationAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Reservations,
    claimValues: [ClaimValues.FullAccess],
};

export const salesOrderReservationPaths = {
    salesOrderReservations: 'reservations',
    salesOrderReservationsAdd: 'reservations/add',
};

const salesOrderReservationRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: SalesOrderReservationsComponent,
        data: salesOrderReservationListRoleGuard,
        path: salesOrderReservationPaths.salesOrderReservations,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: SalesOrderReservationAddComponent, path: '', pathMatch: 'full' }],
        component: SalesOrderReservationHeaderComponent,
        data: salesOrderReservationAddRoleGuard,
        path: salesOrderReservationPaths.salesOrderReservationsAdd,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(salesOrderReservationRoutes)],
})
export class SalesOrderReservationRoutingModule {}
