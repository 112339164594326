import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';

@Component({
    selector: 'app-attachment-upload',
    template: `
        <div class="row">
            <div class="col-md-4">
                <div (click)="uploadSelect.click()" ng2FileDrop [uploader]="uploader" class="drop-box" multiple>
                    Drop files here
                    <br />
                    - OR -
                    <br />
                    Click to upload
                </div>
                <span style="visibility: hidden">
                    <input #uploadSelect id="chooseFileInput" multiple type="file" ng2FileSelect [uploader]="uploader" />
                </span>
                <div class="buttons">
                    <button type="button" class="btn btn-flat btn-default" (click)="cancelAttachment()">Cancel Attaching</button>
                </div>
            </div>
            <div class="col-md-8" style="margin-bottom: 40px" *ngIf="uploader.queue.length">
                <table class="table" style="font-size:70%">
                    <thead>
                        <tr>
                            <th width="80%">File Name</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of uploader.queue; let i = index">
                            <th>{{ item?.file?.name }}</th>
                            <th><button type="button" class="btn btn-sm btn-danger fa fa-times pull-right" (click)="removeFile(i)"></button></th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    `,
})
export class EmailAttachmentUploadComponent implements OnInit {
    @Output('onQueueChange') onQueueChange: EventEmitter<FileItem[]> = new EventEmitter<FileItem[]>();
    @Output('onFinishedAttaching') onFinishedAttaching: EventEmitter<void> = new EventEmitter<void>();
    @Output('onAddingFileFailed') onAddingFileFailed: EventEmitter<string> = new EventEmitter<string>();

    maxFileSize = 4 * 1024 * 1024;
    uploader: FileUploader;

    constructor() {}

    ngOnInit(): void {
        this.uploader = new FileUploader({
            maxFileSize: this.maxFileSize,
        });
        this.uploader.onAfterAddingFile = (file) => {
            this.onQueueChange.emit(this.uploader.queue);
        };
        this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.addingFileFailed(item, filter, options);
    }

    removeFile(index: number): void {
        this.uploader.queue = this.uploader.queue.filter((f, i) => i !== index);
        this.onQueueChange.emit(this.uploader.queue);
    }

    cancelAttachment(): void {
        this.onQueueChange.emit(null);
        this.onFinishedAttaching.emit();
    }

    addingFileFailed(item: FileLikeObject, filter: any, options: any): void {
        let errorMessage = `Error: ${filter.name})`;
        this.onAddingFileFailed.emit(errorMessage);
    }
}
