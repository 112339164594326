import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IIncubatorNumber } from '@model/interfaces/incubator-number';

@Injectable({
    providedIn: 'root',
})
export class IncubatorNumberService extends MetaItemService<IIncubatorNumber> {
    constructor(public http: HttpClient) {
        super('IncubatorNumberService', 'Number', 'NumberIds', '/incubator-numbers', http);
    }
}
