import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { IAppointment } from '@model/interfaces/appointment';
import { ILabelGenerator } from '@model/interfaces/custom/ILabelGenerator';
import { PhonePipe } from '@mt-ng2/phone-control';

@Injectable({ providedIn: 'root' })
export class AppointmentLabelGenerator implements ILabelGenerator {

    constructor(private datePipe: DatePipe, private phonePipe: PhonePipe) {
    }

    GetLabel(entity: IAppointment): string {
        const donor = entity.Donors[0].User;
        return `<label>Donor: </label> <span>${donor.FirstName} ${donor.LastName}</span>
                <br>
                <label>Start Time: </label> <span>${this.datePipe.transform(entity.StartTimeUtc, 'short')}</span>
                <br>
                <label>Phone number: </label> <span>${this.phonePipe.transform(entity.PhoneNumber)} </span>
                <br>
                <label>Cancelled: </label> <span>${entity.Cancelled ? 'Yes' : 'No'} </span>`;
    }
}
