import { DynamicField, DynamicFieldTypes, DynamicLabel } from '@mt-ng2/dynamic-form';
import { InventoryDynamicControls, IInventoryDynamicControlsParameters } from '../form-controls/inventory.form-controls';
import { IInventory } from '../interfaces/inventory';
import { IInventoryType } from '../interfaces/inventory-type';

export class InventoryDynamicControlsPartial extends InventoryDynamicControls {
    constructor(inventoryPartial?: IInventory, additionalParameters?: IInventoryDynamicControlsParameters) {
        super(inventoryPartial, additionalParameters);

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        // (<DynamicField>this.Form.Comments).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.CountPerStraw).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.GobletId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.InventoryTypeId).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.ReserveForResearch).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.RetrievalDate).type.fieldType = DynamicFieldTypes.Input;
        // (<DynamicField>this.Form.StrawCount).type.fieldType = DynamicFieldTypes.Input;
        (<DynamicField>this.Form.StrawNumber).doNotCreateControl = true;

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        // (<DynamicLabel>this.View.Comments).label = 'Some other Label';
        // (<DynamicLabel>this.View.CountPerStraw).label = 'Some other Label';
        // (<DynamicLabel>this.View.GobletId).label = 'Some other Label';
        // (<DynamicLabel>this.View.InventoryTypeId).label = 'Some other Label';
        // (<DynamicLabel>this.View.ReserveForResearch).label = 'Some other Label';
        // (<DynamicLabel>this.View.RetrievalDate).label = 'Some other Label';
        // (<DynamicLabel>this.View.StrawCount).label = 'Some other Label';
        // (<DynamicLabel>this.View.StrawNumber).label = 'Straw Number';
    }
}
