import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IReferralType } from '@model/interfaces/referral-type';

import { MetaItemService } from '@mt-ng2/base-service';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReferralTypeService extends MetaItemService<IReferralType> {
    constructor(public http: HttpClient) {
        super('ReferralTypeService', 'ReferralType', 'ReferralTypeIds', '/referral-types', http);
    }

    updateItems(items: IReferralType[]): Observable<object> {
        items.forEach((i) => (i.OutOfUse = i.OutOfUse ? true : false));
        items.forEach((i) => (i.HasComments = i.HasComments ? true : false));
        return super.updateItems(items);
    }
}
