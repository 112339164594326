import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IApplication } from '@model/interfaces/application';
import { IApplicationStatus } from '@model/interfaces/application-status';
import {
    ApplicationReivewDynamicControlsPartial,
    IApplicationReivewDynamicControlsPartialParameters,
} from '@model/partials/application-review-partial.form-controls';

export class ApplicationDynamicConfig<T extends IApplication> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private application: T,
        private applicationStatus: IApplicationStatus,
        private applicationStatuses?: IApplicationStatus[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IApplicationReivewDynamicControlsPartialParameters = {
            applicationStatus: this.applicationStatus,
            applicationStatuses: this.applicationStatuses,
        };
        const dynamicControls = new ApplicationReivewDynamicControlsPartial(this.application, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['DonorId', 'ApplicationStatusId', 'DenialReasonId'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
