import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IClinic } from '@model/interfaces/clinic';
import { IAddress } from '@mt-ng2/dynamic-form';
import { Observable } from 'rxjs';
import { IAddressResponse } from '@model/interfaces/custom/address-response';
import { IUser } from '@model/interfaces/user';
import { IMetaItem } from '@model/interfaces/base';
import { EmbryologistDto } from '@model/partials/embryologist-dto';
import { SearchParams } from '@mt-ng2/common-classes';
import { IClinicDto } from '@model/interfaces/custom/clinic-dto';
import { catchError } from 'rxjs/operators';

export const emptyClinic: IClinic = {
    ApprovedForRetrievals: null,
    ApprovedMonitoringClinic: false,
    Id: 0,
    Name: null,
};

@Injectable({
    providedIn: 'root',
})
export class ClinicService extends BaseService<IClinic> {
    constructor(public http: HttpClient) {
        super('/clinics', http);
    }

    search(searchparams: SearchParams): Observable<HttpResponse<IClinicDto[]>> {
        let params = this.getHttpParams(searchparams);
        return this.http
            .get<IClinicDto[]>('/clinics/_search', {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }

    getClinicMetaItems(): Observable<IMetaItem[]> {
        return this.http.get<IMetaItem[]>(`/clinics/meta-items`);
    }

    getEmptyClinic(): IClinic {
        return { ...emptyClinic };
    }

    addVerification(clinic: IClinic): Observable<void> {
        return this.http.post<void>(`/clinics/${clinic.Id}/verify`, clinic);
    }

    linkUsersToClinic(clinicId: number, embryologists: IUser[] | EmbryologistDto[]): Observable<IClinic> {
        return this.http.put<IClinic>(`/clinics/${clinicId}/embryologists`, embryologists);
    }

    removeClinicUserLink(clinicId: number, embryologistId: number): Observable<IClinic> {
        return this.http.delete<IClinic>(`/clinics/${clinicId}/embryologists/${embryologistId}`);
    }

    getEmbryologistsByClinicId(clinicId: number): Observable<IUser[]> {
        return this.http.get<IUser[]>(`/clinics/${clinicId}/embryologists`);
    }

    getEmbryologistsApprovedForRetrievals(cycleId: number): Observable<IMetaItem[]> {
        return this.http.get<IMetaItem[]>(`/clinics/embryologists-approved-for-retrievals/${cycleId}`);
    }
}
