import { IRecipientSalesOrder } from '@model/interfaces/recipient-sales-order';
import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class RecipientsSalesReportEntityListConfig extends EntityListConfig {
    constructor(donorId: number) {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['ContractSent'],
                    name: 'Contract Date',
                    pipes: ['date'],
                }),
                new EntityListColumn({
                    accessorFunction: function (rso: IRecipientSalesOrder): string {
                        return `${rso.RecipientCycle?.Recipient?.FirstName} ${
                            rso.RecipientCycle?.Recipient?.MiddleInitial ? rso.RecipientCycle?.Recipient?.MiddleInitial : ''
                        } ${rso.RecipientCycle?.Recipient?.LastName}`;
                    },
                    linkFunction: function (rso: IRecipientSalesOrder): void {
                        window.location.assign(`#/recipients/${rso.RecipientCycle?.Recipient?.Id}`);
                    },
                    name: 'Recipient Name',
                    sort: {
                        sortProperty: 'RecipientCycle.Recipient.LastName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (rso: IRecipientSalesOrder): string {
                        let shippingToString = '';
                        if (rso.Shippings.length) {
                            let rsoClinicArr = [];
                            rso.Shippings.forEach((s) => {
                                if (s.Clinic) {
                                    rsoClinicArr.push(s.Clinic);
                                }
                            });
                            const distinctClinics = Array.from(new Set(rsoClinicArr.map((c) => c.Id))).map((Id) => {
                                return rsoClinicArr.find((c) => c.Id === Id);
                            });
                            shippingToString = distinctClinics.map((c) => c.Name).join(', ');
                        }
                        return `${shippingToString}`;
                    },
                    name: 'Recipient Clinic',
                    sort: {
                        sortProperty: 'Shippings.FirstOrDefault().Clinic.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (rso: IRecipientSalesOrder): string {
                        return `${rso.ProductType?.Name}`;
                    },
                    name: 'Product Type',
                    sort: {
                        sortProperty: 'ProductType.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (rso: IRecipientSalesOrder): string {
                        let quantity = 0;
                        if (donorId) {
                            rso.RecipientSalesOrderDonors.forEach((rsod) => {
                                if (rsod.Donor.DonorId.toString().includes(donorId.toString())) {
                                    quantity += rsod.EggsPurchased;
                                }
                            });
                        } else {
                            rso.RecipientSalesOrderDonors.forEach((rsod) => {
                                quantity += rsod.EggsPurchased;
                            });
                        }
                        return `${quantity}`;
                    },
                    name: 'Qty',
                    sort: {
                        sortProperty: 'RecipientSalesOrderDonors.Sum(x => x.EggsPurchased)',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (rso: IRecipientSalesOrder): string {
                        return `${rso.RecipientCycle?.RecipientCycleStatus?.Name}`;
                    },
                    name: 'Status',
                    sort: {
                        sortProperty: 'RecipientCycle.RecipientCycleStatus.Name',
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
