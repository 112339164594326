import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IEmbryologistDetail } from '@model/interfaces/embryologist-detail';
import { EmbryologistDetailDynamicControls } from '@model/form-controls/embryologist-detail.form-controls';

export class EmbryologistDetailDynamicConfig<T extends IEmbryologistDetail> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private details: T, private configControls?: string[]) {
        super();
        const dynamicControls = new EmbryologistDetailDynamicControls(this.details);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['IsActive', 'IsVerified', 'DateVerified', 'IsTrainer', 'TestCompleted', 'CertifiedTechnologies'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
