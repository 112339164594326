import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { IMetaItem } from '@mt-ng2/base-service';

import { ReportsService } from '../services/reports.service';
import { IReport } from '../model/interfaces/report';
import { IDatapoint } from '../model/interfaces/datapoint';
import { flattenDataPoints } from '../services/dataset-datapoints.service';
import {
    getConjunctionFromFilter,
    getFiltersAsText,
    emptyReportFilter,
    FilterTypesEnum,
    ConjunctionTypesEnum,
    getGroupsWithValidFilters,
} from '../libraries/report-filters.library';
import { IReportFilterGroup } from '../model/interfaces/report-filter-group';

const ConjunctionTypes: IMetaItem[] = [
    { Id: 1, Name: 'and' },
    { Id: 2, Name: 'or' },
];

@Component({
    animations: [
        trigger('slideDown', [
            state(
                'down',
                style({
                    height: '*',
                    opacity: '1',
                    visibility: 'visible',
                }),
            ),
            state(
                'up',
                style({
                    height: '0px',
                    opacity: '0',
                    visibility: 'hidden',
                }),
            ),
            transition('down <=> up', animate('300ms ease-in')),
        ]),
    ],
    selector: 'report-filters',
    styles: [
        `
            .select-records-where {
                height: 27px;
            }
            .filter-row {
                height: 27px;
            }
            .filter-row:hover {
                background-color: #f1f1f1;
            }
            .remove-button {
                display: none;
                position: relative;
                margin-top: 2px;
                margin-right: 2px;
                padding: 0px;
            }
            .filter-row:hover .remove-button {
                display: block;
            }
            .group-filters {
                padding: 4px;
                border: 2px solid;
                border-radius: 5px;
                margin-bottom: 5px;
                padding-left: 20px;
                border-color: rgb(146, 186, 189, 0.8);
            }
            .group-filters .add-filter-in-group {
                margin-top: -8px;
                zoom: 0.7;
            }
            .filter-header {
                width: 100%;
                background-color: gray;
            }
            .filter-header .filters-as-text {
                color: white;
                padding-left: 8px;
            }
            .filter-header .filters-as-text:hover {
                text-decoration: underline;
            }
            .filter-header .no-underline.filters-as-text:hover {
                text-decoration: none;
            }
            .configuration-section {
                padding-top: 5px;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 8px;
                margin-bottom: 10px;
            }
            .clickable-text {
                border-bottom: 1px dotted #aeaeae;
                color: #0099cc;
                display: inline-block;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                padding-left: 2px;
                padding-right: 2px;
            }
            .fa.disabled,
            .fa[disabled],
            .disabled > .fa,
            [disabled] > .fa {
                color: lightgrey;
                opacity: 0.5;
                pointer-events: none;
            }
        `,
    ],
    templateUrl: './report-filters.component.html',
})
export class ReportFiltersComponent implements OnInit {
    private _report: IReport;
    @Input()
    set report(value: IReport) {
        this._report = value;
        if (value?.Filters) {
            this.filters = JSON.parse(value.Filters);
        } else {
            this.filters = [];
        }
    }
    get report(): IReport {
        return this._report;
    }

    @Input() canEdit: boolean;

    @Input()
    set dataPoints(value: IDatapoint[]) {
        if (value?.length) {
            this.datapointOptions = flattenDataPoints(value);
        } else {
            this.datapointOptions = null;
        }
    }

    datapointOptions: IDatapoint[] = [];
    conjunctionOptions = ConjunctionTypes;
    filters: IReportFilterGroup[] = [];

    showConfigurationSection: 'up' | 'down' = 'up';

    constructor(private reportsService: ReportsService) {}

    getConjunction(group: IReportFilterGroup): string {
        return getConjunctionFromFilter(group);
    }

    filtersAsText(): string {
        this.autoSaveCheck();

        const noFiltersMessage = `No filter defined.${this.canEdit ? ' Click to add a new condition' : ''}`;
        if (!this.filters || !this.filters.length) {
            return noFiltersMessage;
        }
        let filtersText = getFiltersAsText(this.filters, this.datapointOptions);
        return filtersText ? filtersText : noFiltersMessage;
    }

    toggleConfigurationSection(): void {
        if (this.canEdit) {
            this.showConfigurationSection = this.showConfigurationSection === 'up' ? 'down' : 'up';
        }
    }

    ngOnInit(): void {}

    create(group?: IReportFilterGroup): void {
        if (!group) {
            group = { ConjunctionId: 1, Filters: [] };
            this.filters.push(group);
        }
        const filter = { ...emptyReportFilter };
        const datapoint = { ...this.datapointOptions[0] };
        filter.DatapointName = datapoint.Name;
        filter.DatapointType = datapoint.Type;
        filter.FilterTypeId = datapoint.Type === 'metadata' ? FilterTypesEnum.In : emptyReportFilter.FilterTypeId;
        group.Filters.push(filter);
    }

    delete(group: IReportFilterGroup, filterIndex: number, groupIndex: number): void {
        group.Filters.splice(filterIndex, 1);
        if (!group.Filters.length) {
            this.filters.splice(groupIndex, 1);
        }
        if (group.Filters.length === 1) {
            group.Filters[0].ConjunctionId = ConjunctionTypesEnum.And;
        }
    }

    clear(): void {
        this.filters = [];
    }

    getFiltersToSave(): string {
        const groupsWithValidFilters = getGroupsWithValidFilters(this.filters, this.datapointOptions);
        return JSON.stringify(groupsWithValidFilters);
    }

    // tslint:disable-next-line:member-ordering
    attemptingSave = false; // set to true while saving, so the save is not continually retried
    // this function is run all the time through template binding
    // so it is always up to date
    // it performs a check to see if filters need to be saved
    // and handles the save if needed
    autoSaveCheck(): void {
        if (this.filtersNeedToBeSaved && !this.attemptingSave) {
            this.attemptingSave = true;
            const reportToSave = { ...this.report };
            reportToSave.Filters = this.getFiltersToSave();
            this.reportsService.update(reportToSave).subscribe((success) => {
                this.report.Filters = reportToSave.Filters;
                this.attemptingSave = false;
            });
        }
    }

    get filtersNeedToBeSaved(): boolean {
        const filtersToSave = this.getFiltersToSave();
        if (!this.report.Filters) {
            this.report.Filters = '[]';
        }
        return this.report.Filters !== filtersToSave;
    }
}
