import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';

import { ExportService } from './export.service';
import { IReport } from '../model/interfaces/report';
import { IExportOptions } from '../model/interfaces/export-options';
import { sortReportColumnsByOrder } from './report-columns.service';
import { IReportColumn } from '../model/interfaces/report-column';
import { AdvancedReportingModuleConfig } from './advanced-reporting-module-config.service';

const emptyReport: IReport = {
    Archived: false,
    DatasetId: null,
    Filters: null,
    Id: 0,
    Name: '',
    OwnerId: 0,
};
export interface IEmailReportParams {
    ReportId: Number;
    UserName: String;
    Email: String;
}

@Injectable()
export class ReportsService extends BaseService<IReport> {
    constructor(public http: HttpClient, private reportingModuleConfig: AdvancedReportingModuleConfig, private exportService: ExportService) {
        super('/reports', http);
    }

    getEmptyReport(): IReport {
        const newReport = { ...emptyReport };
        const user = this.reportingModuleConfig.reportUsersService.currentReportUser.getValue();
        newReport.OwnerId = user?.Id ?? 0;
        return newReport;
    }

    renderReport(reportId: number): Observable<any> {
        const now = new Date();
        const zone = now.toTimeString().match(/([\+|-]\d+)/)[1];
        return this.http.get<any>('/reports/renderreport/' + reportId, { params: { timeZone: zone } });
    }

    exportReportDataToCsv(data: any, report: IReport): void {
        sortReportColumnsByOrder(report.ReportColumns);
        const columns = report.ReportColumns.map((column: IReportColumn) => {
            return {
                name: column.DisplayAs,
                prop: column.DatapointPropertyName,
            };
        });
        data = data.map((datum) => {
            /* only display columns that are part of the report.
            Right now we are returning all datapoints from the backend
            regardless of whether or not the columns are in the report */
            let copy = {};
            columns.forEach((column) => {
                copy[column.prop] = datum[column.prop] ? datum[column.prop] : '';
            });
            return copy;
        });
        const options: IExportOptions = {
            headers: columns.map((c) => c.name),
            showTitle: false,
        };
        this.exportService.exportToCsv(data, report.Name, options);
    }

    toggleArchived(reportId: number): Observable<any> {
        return this.http.put<any>(`/reports/${reportId}/toggle-archived`, null);
    }

    copy(reportId: number): Observable<number> {
        return this.http.post<number>(`/reports/${reportId}/copy`, null);
    }

    emailReport(emailReportParams: IEmailReportParams): Promise<any> {
        const now = new Date();
        const zone = now.toTimeString().match(/([\+|-]\d+)/)[1];
        return this.http
            .get<any>(`/reports/emailreport/${emailReportParams.ReportId}/${emailReportParams.Email}/${emailReportParams.UserName}`, {
                params: { timeZone: zone },
            })
            .toPromise();
    }
}
