import { NgModule } from '@angular/core';

import { SharedModule } from '@common/shared.module';
import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationAddComponent } from './notification-add/notification-add.component';
import { NotificationBasicInfoComponent } from './notification-basic-info/notification-basic-info.component';
import { NotificationDetailComponent } from './notification-detail/notification-detail.component';
import { NotificationsComponent } from './notification-list/notifications.component';
import { NotificationHeaderComponent } from './notification-header/notification-header.component';

@NgModule({
    declarations: [
        NotificationsComponent,
        NotificationHeaderComponent,
        NotificationAddComponent,
        NotificationDetailComponent,
        NotificationBasicInfoComponent,
    ],
    imports: [SharedModule, NotificationRoutingModule],
})
export class NotificationModule {}
