<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Clinics"></mt-page-title>
        </h2>
    </div>
</div>
<div class="row col-md-12">
    <mt-search-bar (onSearch)="search($event)" (ready)="setStickySearch($event)"></mt-search-bar>
</div>
<div class="row col-md-11">
    <results-per-page (onSizeChange)="onPageSizeChange($event)"></results-per-page>
</div>
<div class="row col-md-12">
    <entity-list
        [entities]="clinics"
        [total]="total"
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        (onPageChanged)="getClinics()"
        (onItemSelected)="clinicSelected($event)"
        (onColumnSorted)="columnSorted($event)"
        [entityListConfig]="entityListConfig"
        (onItemDeleted)="clinicDeleted($event)"
    >
    </entity-list>
</div>

<div *ngIf="canAddClinic" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/clinics', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>

<modal-wrapper [options]="deleteModalOptions" *ngIf="showDeleteModal">
    <h3>Are you sure you want to delete{{ clinicToDelete ? ' ' + clinicToDelete : '' }}? This cannot be undone.</h3>
    <br />
    <div class="row">
        <button class="btn btn-danger" (click)="confirmDelete()">Delete</button>
        <button class="btn btn-default" (click)="showDeleteModal = false">Cancel</button>
    </div>
</modal-wrapper>
