import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { ApplicationWhoAreYouService } from '../../../services/application-who-are-you.service';
import { IAncestry } from '@model/interfaces/ancestry';
import { AncestryTypes } from '@model/enums/ancestry-type.enum';
import { AdminApplicationWhoAreYouDynamicControlsPartial } from '@model/partials/admin-application-who-are-you-partial.form-controls';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { CommonService } from '../../../../../../admin-portal/common/services/common.service';
import { forkJoin } from 'rxjs';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { IPreScreening } from '@model/interfaces/pre-screening';
import { IUser } from '@model/interfaces/user';
import { IApplication } from '@model/interfaces/application';

@Component({
    selector: 'application-who-are-you-form',
    templateUrl: './who-are-you-form.component.html',
})
export class ApplicationWhoAreYouFormComponent implements OnInit {
    @Input() whoAreYouMetaItems: IPersonalInformationMetaData;
    @Input() whoAreYou: IApplicationWhoAreYou;
    @Input() preScreening: IPreScreening;
    @Input() donor: ILoggedIn;
    @Input() application: IApplication;

    @Output() onFinish: EventEmitter<IApplicationWhoAreYou> = new EventEmitter<IApplicationWhoAreYou>();
    // abstract controls
    abstractApplicationWhoAreYouControls: any;

    whoAreYouForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    // UI vars
    showOtherMotherAncestries = false;
    showOtherFatherAncestries = false;

    states?: IStateMetaItem[];
    countries?: ICountryMetaItem[];
    selectedCountryId: number;

    // metadata: IPersonalInformationMetaData;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private whoAreYouService: ApplicationWhoAreYouService,
        private commonService: CommonService,
    ) { }

    ngOnInit(): void {
        forkJoin([
            this.commonService.getStates(),
            this.commonService.getCountries(),
        ]).subscribe(([states, countries]) => {
            this.states = this.commonService.getStateMetaItems();
            this.countries = this.commonService.getCountryMetaItems();
            this.createForm();
        });
    }

    createForm(): void {
        this.setFormFieldUi();
        this.getControls();
        this.whoAreYouForm = this.assignFormGroups();
        this.cdr.detectChanges();
        this.formCreated = true;
    }

    getControls(): void {
        this.abstractApplicationWhoAreYouControls = new AdminApplicationWhoAreYouDynamicControlsPartial(
            this.whoAreYou,
            this.donor,
            {
                formGroup: 'ApplicationWhoAreYou',
                metaData : this.whoAreYouMetaItems,
                states: this.states,
                countries: this.countries,
                hasAcknowledgedWaiver: this.application.HasAcknowledgedWaiver,
            },
        ).Form;
    }

    setFormFieldUi(): void {
         const otherAncestry = this.whoAreYouMetaItems.Ancestries.find((a) => a.Id === AncestryTypes.Other);
         this.showOtherMotherAncestries = this.whoAreYou.FatherAncestries.includes(otherAncestry);
         this.showOtherFatherAncestries = this.whoAreYou.MotherAncestries.includes(otherAncestry);
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationWhoAreYou: this.fb.group({}),
        });
    }

    onMotherAncestryValueChange(value: number[]): void {
        this.showOtherMotherAncestries = value.includes(AncestryTypes.Other);
    }

    onFatherAncestryValueChange(value: number[]): void {
        this.showOtherFatherAncestries = value.includes(AncestryTypes.Other);
    }

    formSubmitted(): void {
        if (this.whoAreYouForm.valid) {
            Object.assign(this.whoAreYou, this.whoAreYouForm.value.ApplicationWhoAreYou);

            let country = this.whoAreYouForm.get('ApplicationWhoAreYou.CountryCode').value;
            this.whoAreYou.CountryCode =
                country !== null
                    ? this.countries.find((c) => c.Id === Number(country)).CountryCode
                    : null;
            if (this.countryHasStateCodes) {
                const state = this.states.find((state) => state.Id === Number(this.whoAreYouForm.get('ApplicationWhoAreYou.StateCode').value));
                this.whoAreYou.StateCode = state ? state.StateCode : null;
                this.whoAreYou.Province = '';
            } else {
                this.whoAreYou.Province = this.whoAreYouForm.get('ApplicationWhoAreYou.Province').value;
                this.whoAreYou.StateCode = '';
            }
            country = this.whoAreYouForm.get('ApplicationWhoAreYou.PlaceOfBirth').value;
            this.whoAreYou.PlaceOfBirth  =
             country !== null
                 ? this.countries.find((c) => c.Id === Number(country)).CountryCode
                 : null;

            this.whoAreYou.FatherAncestries = this.parseAncestries(this.whoAreYouForm.value.ApplicationWhoAreYou.ApplicationFatherAncestries);
            this.whoAreYou.MotherAncestries = this.parseAncestries(this.whoAreYouForm.value.ApplicationWhoAreYou.ApplicationMotherAncestries);

            const user = {
                Id: this.donor.Id,
                AuthUserId: this.donor.AuthId,
                Email: this.donor.Email,
                FirstName: this.whoAreYouForm.get('ApplicationWhoAreYou.FirstName').value,
                MiddleInitial: this.whoAreYouForm.get('ApplicationWhoAreYou.MiddleInitial').value,
                LastName: this.whoAreYouForm.get('ApplicationWhoAreYou.LastName').value,
            } as IUser;

            const hasAcknowldegedWaiver  = this.whoAreYouForm.value.ApplicationWhoAreYou.HasAcknowledgedWaiver;

            this.whoAreYouService.updateWhoAreYouEtc(this.whoAreYou, user, hasAcknowldegedWaiver).subscribe((resp) => {
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.whoAreYouForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    parseAncestries(ancestryIds: number[]): IAncestry[] {
        return this.whoAreYouMetaItems.Ancestries.filter((a) => ancestryIds.includes(a.Id));
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.onFinish.emit(null);
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Who are you saved successfully.');
        this.enableDoubleClick();
        this.onFinish.emit(this.whoAreYou);
    }

    get ApplicationWhoAreYou(): FormGroup {
        return this.whoAreYouForm.get('ApplicationWhoAreYou') as FormGroup;
    }

    get countryHasStateCodes(): boolean {
        const hasStates =
            (
                !this.selectedCountryId ||
                this.selectedCountryId === this.countries.find((country) => country.CountryCode === 'AU').Id ||
                this.selectedCountryId === this.countries.find((country) => country.CountryCode === 'CA').Id ||
                this.selectedCountryId === this.countries.find((country) => country.CountryCode === 'US').Id
            );
        return hasStates;
    }
}
