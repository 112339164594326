import { CommonService } from './../../../donor-portal/common/services/common.service';
import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IColumnSortedEvent, SortDirection, EntityListExportConfig } from '@mt-ng2/entity-list-module';

import { entityListModuleConfig } from '@common/shared.module';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';
import { InventoryDonorReportEntityListConfig } from './inventory-donor-report.entity-list-config';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { InventoryDonorReportService } from '../services/inventory-donor-report.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IVwDonorInventoryReport } from '@model/interfaces/vw-donor-inventory-report';

@Component({
    selector: 'app-donor-cycles',
    templateUrl: './inventory-donor-report.component.html',
})
export class InventoryDonorReportComponent implements OnInit {
    searchForm: FormGroup = new FormGroup({
        clinicName: new FormControl(),
        donorId: new FormControl(),
        recipientEmail: new FormControl(),
        recipientId: new FormControl(),
        recipientName: new FormControl(),
        saleDateEnd: new FormControl(),
        saleDateStart: new FormControl(),
    });

    searchFormValueChangesSub: Subscription;

    startDate: Date;
    endDate: Date;

    donorInventory: IVwDonorInventoryReport[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new InventoryDonorReportEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection = 'desc';

    canAddDonorCycle = false;
    months: MtSearchFilterItem[];
    countries: MtSearchFilterItem[] = [];

    constructor(private donorInventoryService: InventoryDonorReportService, private commonService: CommonService) {}

    ngOnInit(): void {
        this.initializeEntityListConfig();
        this.commonService.getCountries().subscribe((answer) => (this.countries = answer.map((answer) => new MtSearchFilterItem(answer, false))));
        this.setSearchFormValueChangesSub();
        this.search();
    }

    ngOnDestroy(): void {
        this.searchFormValueChangesSub.unsubscribe();
    }

    initializeEntityListConfig(): void {
        this.entityListConfig = new InventoryDonorReportEntityListConfig();
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Donor Inventory Report',
            getDataForExport: this.getDonorCyclesForExport.bind(this),
        });
    }

    getDonorCyclesCall(options = { forExport: false }): Observable<HttpResponse<IVwDonorInventoryReport[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: options.forExport ? 0 : (this.currentPage - 1) * this.itemsPerPage,
            take: options.forExport ? this.total : this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorInventoryService.get(searchparams);
    }

    getDonorCycles(): void {
        this.getDonorCyclesCall().subscribe((answer) => {
            this.donorInventory = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getDonorCyclesForExport(): Observable<IVwDonorInventoryReport[]> {
        return this.getDonorCyclesCall({ forExport: true }).pipe(map((answer) => answer.body));
    }

    search(query?: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getDonorCycles();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getDonorCycles();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDonorCycles();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.CountryCode);
    }

    private setSearchFormValueChangesSub(): void {
        if (this.searchFormValueChangesSub) {
            this.searchFormValueChangesSub.unsubscribe();
        }
        let valueChanges$ = this.searchForm.valueChanges;

        this.searchFormValueChangesSub = valueChanges$.pipe(debounceTime(350)).subscribe((response) => {
            this.initializeEntityListConfig();
            this.search();
        });
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCountryIds: number[] = this.getSelectedFilters(this.countries);
        const _extraSearchParams: ExtraSearchParams[] = [];

        if (selectedCountryIds.length > 0) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'countryCodes',
                    valueArray: selectedCountryIds,
                }),
            );
        }

        if (this.searchForm?.get('clinicName')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'clinicName',
                    value: this.searchForm.get('clinicName').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('recipientId')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recipientId',
                    value: this.searchForm.get('recipientId').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('donorId')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'donorId',
                    value: this.searchForm.get('donorId').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('recipientEmail')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recipientEmail',
                    value: this.searchForm.get('recipientEmail').value.toString(),
                }),
            );
        }

        if (this.searchForm?.get('recipientName')?.value != null) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'recipientName',
                    value: this.searchForm.get('recipientName').value.toString(),
                }),
            );
        }
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'SaleDateStart',
                value: this.startDate ? this.getDateValueForParams(this.startDate) : '',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'SaleDateEnd',
                value: this.endDate ? this.getDateValueForParams(this.endDate) : '',
            }),
        );

        return _extraSearchParams;
    }

    getDateValueForParams(date: Date): string {
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return `${month}-${day}-${year}`;
    }

    dateSelectionChanged(value: ISearchFilterDaterangeValue): void {
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.searchForm.get('saleDateStart').setValue(value.startDate);
        this.searchForm.get('saleDateEnd').setValue(value.endDate);
        this.searchForm.updateValueAndValidity();
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.getDonorCycles();
    }
}
