import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IReportAccessShare } from '../model/interfaces/report-access-share';

@Injectable()
export class ReportAccessSharesService {
    constructor(private http: HttpClient) {}

    get(reportId: number): Observable<IReportAccessShare[]> {
        return this.http.get<IReportAccessShare[]>(`/reports/${reportId}/report-access-shares`);
    }

    save(reportId: number, reportAccessShares: IReportAccessShare[], skipUserAccessWarning: boolean): Observable<IReportAccessShare[]> {
        return this.http.put<IReportAccessShare[]>(`/reports/${reportId}/report-access-shares/${skipUserAccessWarning}`, reportAccessShares);
    }
}
