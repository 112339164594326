<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Quality Summary Report"></mt-page-title>
        </h2>
    </div>
</div>
<form id="search-panel" [formGroup]="searchForm">
    <div class="row">
        <div class="col-md-2">
            <label>Recipient ID</label>
            <input type="text" class="form-control" formControlName="recipientId" />
        </div>
        <div class="col-md-2">
            <label>Donor ID</label>
            <input type="text" class="form-control" formControlName="donorId" />
        </div>
        <div class="col-md-2">
            <label>Email</label>
            <input type="text" class="form-control" formControlName="email" />
        </div>
        <div class="col-md-2">
            <label>Name</label>
            <input type="text" class="form-control" formControlName="name" />
        </div>
        <div class="col-md-2">
            <label>Clinic</label>
            <mt-type-ahead [items]="clinics" (selectionChanged)="selectionChanged($event)"></mt-type-ahead>
        </div>
    </div>
</form>
<br />
<div class="row">
    <div class="col-md-12">
        <button class="btn btn-primary pull-left" (click)="onGenerateReport()" type="button">Generate</button>
    </div>
</div>
