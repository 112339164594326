import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { ReportsService } from '../services/reports.service';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    selector: 'copy-report-button',
    styles: [
        `
            .copy-icon {
                color: dodgerblue;
            }
        `,
    ],
    template: `
        <span *ngIf="reportId" (click)="copyReport($event)" class="btn btn-xs btn-transparent" title="copy">
            <i class="fa fa-fw fa-lg fa-copy copy-icon"></i>
        </span>
    `,
})
export class CopyReportButtonComponent {
    @Input() reportId: number;

    @Output() onCopy: EventEmitter<any> = new EventEmitter<any>();

    constructor(private router: Router, private reportsService: ReportsService, private notificationsService: NotificationsService) {}

    copyReport(event: Event): void {
        event?.stopPropagation();
        this.reportsService.copy(this.reportId).subscribe((newReportId) => {
            this.reportsService.getById(newReportId).subscribe((report) => this.reportsService.emitChange(report));
            this.router.navigate([`/my-reports/${newReportId}/edit`]);
            this.notificationsService.success('Report copied successfully.');
            this.onCopy.emit(null);
        });
    }
}
