import { Component, OnDestroy, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICurrentReportUser, IReportUser, IReportUserRole } from '@mt-ng2/advanced-reporting-module-config';
import { ISelectionChangedEvent, TypeAheadComponent } from '@mt-ng2/type-ahead-control';
import { IModalWrapperApi } from '@mt-ng2/modal-module';

import { ReportAccessSharesService } from '../services/report-access-shares.service';
import { IReport } from '../model/interfaces/report';
import { IReportAccessShare } from '../model/interfaces/report-access-share';
import { reorderReportAccessShares, getNewShareForUserRole, getNewShareForUser, unselectedNewShare } from '../libraries/report-access-shares.library';
import { getCanEdit } from '../libraries/reports.library';
import { AdvancedReportingModuleConfig } from '../services/advanced-reporting-module-config.service';

@Component({
    selector: 'report-access-shares',
    styleUrls: ['./report-access-shares.component.less'],
    templateUrl: './report-access-shares.component.html',
})
export class ReportAccessSharesComponent implements OnDestroy {
    public reportObject: IReport;
    @Input('report')
    set report(value: IReport) {
        this.reportObject = { ...value };
    }

    // using the set here so that it will reevaluate whenever the
    // viewChild item is triggered
    mtTypeAhead: TypeAheadComponent;
    @ViewChild('mtTypeAhead', { static: false }) set setMtTypeAhead(content: TypeAheadComponent) {
        this.mtTypeAhead = content;
    }

    users: IReportUser[];
    userRoles: IReportUserRole[];
    reportAccessShares: IReportAccessShare[];
    sharesWithoutAccess: IReportAccessShare[] = [];
    canEdit = false;

    modalTitle: string;

    modalOptions = {
        customContainerClass: 'report-access-shares-modal',
        title: `<h2 class="zoom-out">Sharing with</h2>`,
    };
    modalApi: IModalWrapperApi;

    availableShares: IReportAccessShare[];

    selectedNewShare: IReportAccessShare;

    subscriptions: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private reportAccessShareService: ReportAccessSharesService,
        private notificationsService: NotificationsService,
        private reportingModuleConfig: AdvancedReportingModuleConfig,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    // *** MODAL BUTTONS

    showShareModal(event: Event): void {
        event.stopPropagation();
        this.initModal();
    }

    save(): void {
        this.reportAccessShareService
            .save(this.reportObject.Id, this.reportAccessShares, this.sharesWithoutAccess?.length > 0)
            .subscribe((sharesWithoutAccess) => {
                if (!sharesWithoutAccess?.length) {
                    // user has already been warned or there are no user's w/o access
                    this.modalApi.close();
                    this.notificationsService.success('Report Sharing saved successfully.');
                    this.sharesWithoutAccess = [];
                } else {
                    this.sharesWithoutAccess = sharesWithoutAccess;
                    this.cdr.detectChanges();
                }
            });
    }

    cancel(): void {
        this.modalApi.close();
        this.sharesWithoutAccess = [];
    }

    // *** INIT FUNCTIONS

    // called every time the modal is shown
    initModal(): void {
        this.reportAccessShares = null;
        forkJoin(
            this.reportAccessShareService.get(this.reportObject.Id), // we get the shares every time in case they were changed by another user
            this.getUsers(),
            this.getUserRoles(),
        ).subscribe((responses) => {
            const [reportAccessShares] = responses;
            this.reportAccessShares = reorderReportAccessShares(reportAccessShares, this.users, this.userRoles);
            this.reportObject.ReportAccessShares = this.reportAccessShares;
            this.setCanEdit();
            this.setAvailableShares();
            this.cdr.markForCheck();
            this.modalApi.show();
        });
    }

    getUsers(): Observable<IReportUser[]> {
        if (this.users) {
            return of(this.users);
        }
        const user = this.getCurrentReportUser();
        const currentUserId = user?.Id ?? 0;
        return this.reportingModuleConfig.reportUsersService
            .getUsers()
            .pipe(tap((users) => (this.users = users.filter((user) => user.Id !== currentUserId))));
    }

    private getCurrentReportUser(): ICurrentReportUser {
        return this.route.snapshot.data.currentUser;
    }

    getUserRoles(): Observable<IReportUserRole[]> {
        if (this.userRoles) {
            return of(this.userRoles);
        }
        return this.reportingModuleConfig.reportUsersService.getUserRoles().pipe(tap((roles) => (this.userRoles = roles)));
    }

    setCanEdit(): void {
        const user = this.getCurrentReportUser();
        this.canEdit = getCanEdit(this.reportObject, user.Id, user.RoleId);
    }

    setAvailableShares(): void {
        const availableUserRoleShares = this.userRoles
            .filter((role) => !this.reportAccessShares.some((ras) => ras.UserRoleId === role.Id))
            .map((role) => {
                return getNewShareForUserRole(role, this.reportObject.Id);
            });

        const availableUserShares: IReportAccessShare[] = this.users
            .filter((user) => !this.reportAccessShares.some((ras) => ras.UserId === user.Id))
            .map((user) => {
                return getNewShareForUser(user, this.reportObject.Id);
            });

        let availableShares: IReportAccessShare[] = [unselectedNewShare];
        availableShares = availableShares.concat(availableUserRoleShares).concat(availableUserShares);
        this.availableShares = availableShares;

        this.selectedNewShare = unselectedNewShare;
    }

    // *** GRID and DROPDOWN FUNCTIONS

    newShareSelected(event: ISelectionChangedEvent): void {
        let selectedItem: IReportAccessShare = event.selection;
        if (!selectedItem || (selectedItem.UserId === 0 && selectedItem.UserRoleId === 0)) {
            return;
        }
        this.reportAccessShares.push(selectedItem);
        this.reportAccessShares = reorderReportAccessShares(this.reportAccessShares, this.users, this.userRoles);
        this.setAvailableShares();
        setTimeout(() => {
            this.mtTypeAhead.selectedItem = null;
            this.cdr.detectChanges();
        }, 0); // the setTimeout is needed so that we wait long enough for a current version of the mtTypeAhead to be evaluated
        this.cdr.detectChanges();
    }

    delete(index: number): void {
        this.reportAccessShares.splice(index, 1);
        this.setAvailableShares();
        this.cdr.detectChanges();
    }

    getName(share: IReportAccessShare): string {
        if (share === unselectedNewShare) {
            return 'select User or User Role';
        }
        if (share.UserId) {
            const user = this.users.find((user) => user.Id === share.UserId);
            return user?.Name ?? '';
        }
        const role = this.userRoles.find((role) => role.Id === share.UserRoleId);
        return role?.Name ? `User Role: ${role.Name}` : '';
    }
}
