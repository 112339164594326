import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUser } from '@model/interfaces/user';
import { forkJoin } from 'rxjs';
import { UserService } from '../user.service';
import { IClinic } from '@model/interfaces/clinic';
import { IMetaItem } from '@model/interfaces/base';
import { ClinicService } from '../../clinics/services/clinic.service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-embryologist-clinics',
    templateUrl: './embryologist-clinics.component.html',
})
export class EmbryologistClinicsComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() user: IUser;

    embryologistClinicsControls: DynamicField;
    clinicsForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    clinics: IClinic[];
    clinicMetaItem: IMetaItem[];

    isEditing: boolean;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private clinicService: ClinicService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.clinicService.getAll()]).subscribe(([clinics]) => {
            this.clinics = clinics;
            this.clinicMetaItem = this.generateMetaItems();
            this.createForm();
        });
    }

    generateMetaItems(): IMetaItem[] {
        return this.clinics.map((c) => {
            return {
                Id: c.Id,
                Name: c.Name,
            };
        });
    }

    createForm(): void {
        this.getControls();
        this.clinicsForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.embryologistClinicsControls = new DynamicField({
            formGroup: 'Clinics',
            label: 'Clinics',
            name: 'Clinics',
            options: this.clinicMetaItem,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
            }),
            validation: [],
            validators: {},
            value: this.getClinicsMultiselectValue(this.user.Clinics, this.clinics),
        });
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            Clinics: this.fb.group({}),
        });
    }

    getClinicsMultiselectValue(value: IClinic[], options: any[]): number[] {
        const clinicIds = value.map((itm) => itm.Id);
        return options.filter((itm) => clinicIds.includes(itm.Id)).map((itm) => itm.Id);
    }

    edit(): void {
        this.isEditing = true;
    }

    formSubmitted(): void {
        if (this.clinicsForm.valid) {
            const clinicIds: number[] = this.clinicsForm.value.Clinics.Clinics;
            if (clinicIds?.length) {
                this.user.Clinics = this.clinics.filter((c) => clinicIds.includes(c.Id));
            } else {
                this.user.Clinics = [];
            }
            this.userService.linkUserToClinics(this.user.Id, this.user.Clinics).subscribe((answer) => {
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.clinicsForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Clinics saved successfully.');
        this.enableDoubleClick();
        this.isEditing = false;
    }
}
