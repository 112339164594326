import { Component, Input } from '@angular/core';

import { IMetaItem } from '@mt-ng2/base-service';

import { ReportsService } from '../services/reports.service';
import { IReportColumn } from '../model/interfaces/report-column';
import { IReport } from '../model/interfaces/report';

export const SortDirectionTypes: IMetaItem[] = [
    { Id: 1, Name: 'asc' },
    { Id: 2, Name: 'desc' },
];

@Component({
    selector: `report-sort`,
    styles: [
        `
            .sort-header {
                width: 100%;
                background-color: gray;
            }
            .sort-header .sort-as-text {
                color: white;
                padding-left: 8px;
            }
            .fa.disabled,
            .fa[disabled],
            .disabled > .fa,
            [disabled] > .fa {
                color: lightgrey;
                opacity: 0.5;
                pointer-events: none;
            }
        `,
    ],
    template: `
        <span ngbDropdown class="dropdown">
            <div class="sort-header">
                <span
                    *ngIf="reportColumns?.length !== 0 && canEdit"
                    ngbDropdownToggle
                    [style.cursor]="canEdit ? 'pointer' : 'default'"
                    class="btn btn-primary btn-flat"
                >
                    <i class="fa fa-sort"></i>
                    <ul ngbDropdownMenu class="dropdown-menu">
                        <li *ngFor="let column of reportColumns">
                            <a (click)="selectSort(column)">
                                <i class="fa fa-fw" [ngClass]="{ 'fa-check': report.SortProperty == column.DatapointPropertyName }"></i>
                                {{ column.DisplayAs }}
                            </a>
                        </li>
                    </ul>
                </span>
                <span
                    *ngIf="reportColumns?.length === 0 || !canEdit"
                    ngbDropdownToggle
                    [style.cursor]="'not-allowed'"
                    class="btn btn-primary btn-flat"
                >
                    <i class="fa fa-sort disabled"></i>
                </span>
                <span class="sort-as-text">
                    <span [innerHtml]="getSortProp()"></span>
                </span>
                <span ngbDropdown class="dropdown" *ngIf="report.SortDirection">
                    <span ngbDropdownToggle class="sort-as-text">{{ report.SortDirection }}</span>
                    <ul ngbDropdownMenu class="dropdown-menu">
                        <li *ngFor="let option of sortDirectionOptions">
                            <a (click)="setDirection(option.Name)">
                                <i class="fa fa-fw" [ngClass]="{ 'fa-check': report.SortDirection === option.Name }"></i>
                                {{ option.Name }}
                            </a>
                        </li>
                    </ul>
                </span>
                <span *ngIf="canEdit && report.SortProperty" class="sort-as-text" [style.cursor]="'pointer'" (click)="deleteSort()">
                    <i class="fa fa-window-close"></i>
                </span>
            </div>
        </span>
    `,
})
export class ReportSortComponent {
    @Input('report') report: IReport;
    @Input('canEdit') canEdit: boolean;
    @Input('reportColumns') reportColumns: IReportColumn[] = [];
    sortDirectionOptions = SortDirectionTypes;

    constructor(private reportsService: ReportsService) {}

    selectSort(reportColumn: IReportColumn): void {
        this.report.SortProperty = reportColumn.DatapointPropertyName;
        if (!this.report.SortDirection) {
            this.report.SortDirection = SortDirectionTypes[0].Name;
        }
        this.saveReport();
    }

    getSortProp(): string {
        let noDefaultSortText = `No default sort set`;
        if (!this.report || !this.report.SortProperty) {
            return noDefaultSortText;
        }
        return this.report.SortProperty;
    }

    setDirection(direction: string): void {
        this.report.SortDirection = direction;
        this.saveReport();
    }

    deleteSort(): void {
        this.report.SortDirection = null;
        this.report.SortProperty = null;
        this.saveReport();
    }

    saveReport(): void {
        this.reportsService.update(this.report).subscribe();
    }
}
