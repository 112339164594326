import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDonorInventoryDto } from '@model/interfaces/custom/donor-inventory-dto';
import { IInventoryPositionDto } from '@model/interfaces/custom/inventory-position-dto';
import { IInventory } from '@model/interfaces/inventory';
import { IInventoryRemoved } from '@model/interfaces/inventory-removed';
import { IInventoryVessel } from '@model/interfaces/inventory-vessel';
import { IVwDonorEggInventory } from '@model/interfaces/vw-donor-egg-inventory';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TreatDateAsLocal } from '@common/libraries/treat-date-as-local.library';

export const emptyInventoryVessel: IInventoryVessel = {
    Id: 0,
    Level: null,
    Name: null,
    Number: null,
};

@Injectable({
    providedIn: 'root',
})
export class InventoryVesselService {
    constructor(public http: HttpClient) {}

    getEmptyInventoryVessel(): IInventoryVessel {
        return { ...emptyInventoryVessel };
    }

    getTanks(): Observable<IInventoryVessel[]> {
        return this.http.get<IInventoryVessel[]>('/inventory-vessels/tanks');
    }

    getDirectChildren(parentNodeId: number): Observable<IInventoryVessel[]> {
        return this.http.get<IInventoryVessel[]>(`/inventory-vessels/${parentNodeId}/children`);
    }

    create(vessel: IInventoryVessel, parentNodeId?: number): Observable<number> {
        if (!parentNodeId) {
            return this.http.post<number>('/inventory-vessels', vessel);
        } else {
            return this.http.post<number>(`/inventory-vessels/${parentNodeId}/children`, vessel);
        }
    }

    update(vessel: IInventoryVessel): Observable<number> {
        return this.http.put<number>(`/inventory-vessels/${vessel.Id}`, vessel);
    }

    getInventoryPositionByGoblet(gobletId: number): Observable<IInventoryPositionDto> {
        return this.http.get<IInventoryPositionDto>(`/inventory-vessels/position/${gobletId}`);
    }

    getDonorInventoryLocationsByDonorId(donorId: number): Observable<IDonorInventoryDto[]> {
        if (!donorId) {
            return of(null);
        }
        return this.http.get<IDonorInventoryDto[]>(`/inventory-vessels/donor/${donorId}`).pipe(
            map((data: IDonorInventoryDto[]) => {
                return data.map((dto: IDonorInventoryDto) => {
                    return {
                        ...dto,
                        RetrievalDate: TreatDateAsLocal(dto.RetrievalDate),
                    };
                });
            }),
        );
    }

    getDonorPotentialFamiliesByDonorId(donorId: number, countryStateCombination: any): Observable<IDonorInventoryDto[]> {
        if (!donorId) {
            return of(null);
        }
        return this.http.post<IDonorInventoryDto[]>(`/potential-families/donor/${donorId}`, countryStateCombination);
    }

    setInventoryAsRemoved(salesOrderId: number, data: IInventoryRemoved[]): Observable<IVwDonorEggInventory[]> {
        return this.http.put<IVwDonorEggInventory[]>(`/inventory-vessels/sales-order/${salesOrderId}/removed`, data);
    }

    setInventoryAsShipped(salesOrderId: number, data: IInventoryRemoved[]): Observable<IVwDonorEggInventory[]> {
        return this.http.put<IVwDonorEggInventory[]>(`/inventory-vessels/sales-order/${salesOrderId}/shipped`, data);
    }

    getSalesOrderInventory(salesOrderId: number): Observable<IVwDonorEggInventory[]> {
        return this.http.get<IVwDonorEggInventory[]>(`/inventory-vessels/sales-order/${salesOrderId}`).pipe(
            map((data: IVwDonorEggInventory[]) => {
                return data.map((dei: IVwDonorEggInventory) => {
                    return {
                        ...dei,
                        RetrievalDate: TreatDateAsLocal(dei.RetrievalDate),
                    };
                });
            }),
        );
    }

    getSalesOrderRemovedInventory(salesOrderId: number): Observable<IVwDonorEggInventory[]> {
        return this.http.get<IVwDonorEggInventory[]>(`/inventory-vessels/sales-order/${salesOrderId}/removed`).pipe(
            map((data: IVwDonorEggInventory[]) => {
                return data.map((dei: IVwDonorEggInventory) => {
                    return {
                        ...dei,
                        RetrievalDate: TreatDateAsLocal(dei.RetrievalDate),
                    };
                });
            }),
        );
    }

    getInventoryLocationsForDonors(salesOrderId: number, donorIds: number[]): Observable<IVwDonorEggInventory[]> {
        if (!donorIds?.length) {
            return of(null);
        }
        return this.http.post<IVwDonorEggInventory[]>(`/inventory-vessels/donors/${salesOrderId}`, donorIds).pipe(
            map((data: IVwDonorEggInventory[]) => {
                return data.map((dei: IVwDonorEggInventory) => {
                    return {
                        ...dei,
                        RetrievalDate: TreatDateAsLocal(dei.RetrievalDate),
                    };
                });
            }),
        );
    }

    saveInventoryLocation(donorId: number, parentId: number, inventoryData: IInventory): Observable<IInventory> {
        return this.http.post<IInventory>(`/inventory-vessels/donors/${donorId}/parent/${parentId}/inventory`, inventoryData);
    }

    saveMultipleInventoryLocations(
        donorId: number,
        parentId: number,
        inventoryData: IInventory,
        numberOfInventories: number,
    ): Observable<IInventory[]> {
        let inventoryPayload = [];
        for (let i = 1; i <= numberOfInventories; i++) {
            inventoryPayload.push(Object.assign({}, inventoryData, { StrawNumber: i }));
        }
        return this.http.post<IInventory[]>(`/inventory-vessels/donors/${donorId}/parent/${parentId}/multiple-inventories`, inventoryPayload);
    }
}
