<div *ngIf="introduceYourself.Id > 0 && !isEditing">
    <div class="form-section-border">
        <h3>
            Introduce Yourself
            <span class="pull-right" (click)="edit()">
                <i class="fa fa-lg fa-edit"></i>
            </span>
        </h3>
    </div>
    <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
</div>
<div *ngIf="isEditing">
    <application-introduce-yourself-form
        [introduceYourself]="introduceYourself"
        (onFinish)="onEditFinished($event)"
        [application]="application"
    ></application-introduce-yourself-form>
</div>
