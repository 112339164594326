import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IInventoryPositionDto } from '@model/interfaces/custom/inventory-position-dto';
import { IInventoryVessel } from '@model/interfaces/inventory-vessel';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { forkJoin } from 'rxjs';
import { IVesselFormOptions } from './inventory-vessel-form/inventory-vessel-form.component';
import { InventoryVesselService } from './inventory-vessel.service';

export enum VesselTypes {
    Tank = 1,
    Can = 2,
    Goblet = 3,
}

@Component({
    selector: 'app-inventory-vessel',
    styles: [
        `
            .pad-buttons {
                padding-top: 25px;
            }
        `,
    ],
    templateUrl: 'inventory-vessel.component.html',
})
export class InventoryVesselComponent implements OnInit {
    @Input() position: IInventoryPositionDto;
    @Output() onGobletSelected = new EventEmitter<IInventoryPositionDto>();

    isReady = false;
    vesselTypes = VesselTypes;

    tanks: IInventoryVessel[] = [];
    selectedTank: IInventoryVessel;
    cans: IInventoryVessel[] = [];
    selectedCan: IInventoryVessel;
    goblets: IInventoryVessel[] = [];
    selectedGoblet: IInventoryVessel;

    modalOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: false,
        showConfirmButton: false,
    };
    formOptions: IVesselFormOptions;
    showModal = false;

    constructor(private inventoryVesselService: InventoryVesselService, private notificationsService: NotificationsService) {}

    ngOnInit(): void {
        if (this.position) {
            // get tanks, cans, and goblets
            forkJoin([
                this.inventoryVesselService.getTanks(),
                this.inventoryVesselService.getDirectChildren(this.position.Tank.Id),
                this.inventoryVesselService.getDirectChildren(this.position.Can.Id),
            ]).subscribe(([tanks, cans, goblets]) => {
                this.tanks = tanks;
                this.cans = cans;
                this.goblets = goblets;
                this.selectedTank = this.position.Tank;
                this.selectedCan = this.position.Can;
                this.selectedGoblet = this.position.Goblet;
                this.isReady = true;
            });
        } else {
            this.inventoryVesselService.getTanks().subscribe((tanks) => {
                this.tanks = tanks;
                this.isReady = true;
            });
        }
    }

    tankSelectionChanged(vessel: IInventoryVessel): void {
        if (this.selectedTank && this.selectedTank.Id !== vessel.Id) {
            this.selectedCan = null;
            this.selectedGoblet = null;
        }
        this.inventoryVesselService.getDirectChildren(vessel.Id).subscribe((cans) => {
            this.cans = cans;
            this.selectedTank = vessel;
        });
    }

    canSelectionChanged(vessel: IInventoryVessel): void {
        if (this.selectedCan && this.selectedCan.Id !== vessel.Id) {
            this.selectedGoblet = null;
        }
        this.inventoryVesselService.getDirectChildren(vessel.Id).subscribe((goblets) => {
            this.goblets = goblets;
            this.selectedCan = vessel;
        });
    }

    gobletSelectionChanged(vessel: IInventoryVessel): void {
        this.selectedGoblet = vessel;
        // emit Goblet Id
        this.onGobletSelected.emit({
            Can: this.selectedCan,
            Goblet: this.selectedGoblet,
            Tank: this.selectedTank,
        });
    }

    editVessel(type: VesselTypes): void {
        const selectedVessel = this.getSelectedVesselByType(type);
        if (selectedVessel == null) {
            return this.notificationsService.error('Make a selection to edit');
        }
        this.formOptions = {
            type: type,
            vessel: selectedVessel,
        };
        this.showModal = true;
    }

    getSelectedVesselByType(type: VesselTypes): IInventoryVessel {
        switch (type) {
            case VesselTypes.Tank:
                return this.selectedTank;
            case VesselTypes.Can:
                return this.selectedCan;
            case VesselTypes.Goblet:
                return this.selectedGoblet;
            default:
                return null;
        }
    }

    createVessel(parentNodeId: number, type: VesselTypes): void {
        this.formOptions = {
            parentNode: parentNodeId,
            type: type,
            vessel: this.inventoryVesselService.getEmptyInventoryVessel(),
        };
        this.showModal = true;
    }

    closeModal(): void {
        this.showModal = false;
        this.formOptions = null;
    }

    onSuccess(options: IVesselFormOptions): void {
        const { type, vessel } = options;
        switch (type) {
            case VesselTypes.Tank:
                this.addOrUpdateVessel(this.tanks, vessel);
                this.tankSelectionChanged(vessel);
                break;
            case VesselTypes.Can:
                this.addOrUpdateVessel(this.cans, vessel);
                this.canSelectionChanged(vessel);
                break;
            case VesselTypes.Goblet:
                this.addOrUpdateVessel(this.goblets, vessel);
                this.gobletSelectionChanged(vessel);
                break;
            default:
                break;
        }
        this.closeModal();
    }

    addOrUpdateVessel(collection: IInventoryVessel[], vessel: IInventoryVessel): void {
        const index = collection.findIndex((v) => v.Id === vessel.Id);
        if (index >= 0) {
            collection.splice(index, 1, vessel);
        } else {
            collection.push(vessel);
        }
    }

    vesselEquals(v1: IInventoryVessel, v2: IInventoryVessel): boolean {
        return v1 && v2 ? v1.Id === v2.Id : false;
    }
}
