import { Inject, Injectable, Optional } from '@angular/core';

import {
    IReportingModuleConfig,
    AdvancedReportingModuleConfigToken,
    IReportUsersService,
    AdvancedReportingModuleUserServiceToken,
} from '@mt-ng2/advanced-reporting-module-config';

@Injectable({
    providedIn: 'root',
})
export class AdvancedReportingModuleConfig implements IReportingModuleConfig {
    reportUsersService: IReportUsersService;
    reportingClaimTypeId: number;
    displayFiltersOnRenderedReport?: boolean;
    reportSizeExportWarningThreshold?: number;

    constructor(
        @Optional() @Inject(AdvancedReportingModuleConfigToken) config: IReportingModuleConfig,
        @Optional() @Inject(AdvancedReportingModuleUserServiceToken) usersService: IReportUsersService,
    ) {
        // we must have a config passed in
        if (!config) {
            throw new Error(
                'AdvancedReporting requires a config to be passed in, but none was found.  Please provide a config using the AdvancedReportingModuleConfigToken. Check the ReadMe of this package for detailed instructions.',
            );
        }
        Object.assign(this, config);

        // if we have been supplied the report users service through token, then use it
        if (usersService) {
            this.reportUsersService = usersService;
        }

        // we must have a report users service
        if (!this.reportUsersService) {
            throw new Error(
                'AdvancedReporting requires a reportUserService.  Please provide a report users service using the AdvancedReportingModuleUserServiceToken. Check the ReadMe of this package for detailed instructions.',
            );
        }
    }
}
