import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IProductType } from '@model/interfaces/product-type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProductTypeService extends MetaItemService<IProductType> {
    constructor(public http: HttpClient) {
        super('ProductTypeService', 'Type', 'TypeIds', '/product-types', http);
    }

    getAll(): Observable<IProductType[]> {
        return super.getAll().pipe(map((i) => i.filter((itm) => !itm.AddOn)));
    }

    getAddOns(): Observable<IProductType[]> {
        return super.getAll().pipe(map((i) => i.filter((itm) => itm.AddOn)));
    }

    updateItems(items: IProductType[]): Observable<object> {
        // mt-item-managed-list sets AddOn to "" in new items
        items.forEach((i) => (i.AddOn = i.AddOn ? true : false));
        return super.updateItems(items);
    }
}
