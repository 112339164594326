import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@model/ClaimTypes';
import { DonorCycleReportComponent } from './donor-cycle-report/donor-cycle-report.component';
import { RecipientsSalesReportComponent } from './recipients-sales-report/recipients-sales-report.component';
import { InventoryDonorReportComponent } from './inventory-donor-report/inventory-donor-report.component';
import { QualitySummaryReportComponent } from './quality-summary-report/quality-summary-report.component';

const donorCycleReportRoleGuard: any = {
    claimType: ClaimTypes.Recipients,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Donor Cycle Report',
};

const reportsRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: DonorCycleReportComponent,
        data: donorCycleReportRoleGuard,
        path: 'donor-cycle-report',
    },
    {
        canActivate: [AuthGuard],
        component: RecipientsSalesReportComponent,
        path: 'recipients-sales-report',
    },
    {
        canActivate: [AuthGuard],
        component: InventoryDonorReportComponent,
        path: 'inventory-donor-report',
    },
    {
        canActivate: [AuthGuard],
        component: QualitySummaryReportComponent,
        path: 'quality-summary-report',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(reportsRoutes)],
})
export class ReportsRoutingModule {}
