import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SalesOrderReservationDynamicControls } from '@model/form-controls/sales-order-reservation.form-controls';
import { ISalesOrderReservation } from '@model/interfaces/sales-order-reservation';
import { SalesOrderReservationService } from '../services/sales-order-reservation.service';

@Component({
    selector: 'update-sales-order-reservation',
    templateUrl: './update-sales-order-reservation.component.html',
})
export class UpdateSalesOrderReservationComponent implements OnInit {
    @Input() reservation: ISalesOrderReservation;
    @Output() onEditComplete: EventEmitter<ISalesOrderReservation> = new EventEmitter<ISalesOrderReservation>();

    // abstract controls
    abstractSalesOrderReservationControls: any;
    donorsControl: any;

    salesOrderReservationForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    donorsFormArray: any;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private soReservationService: SalesOrderReservationService,
    ) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        this.getControls();
        this.salesOrderReservationForm = this.assignFormGroups();
        this.donorsControl = (this.salesOrderReservationForm.controls.SalesOrderReservationDonor as FormArray).controls;
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractSalesOrderReservationControls = new SalesOrderReservationDynamicControls(this.reservation, {
            formGroup: 'SalesOrderReservation',
        }).Form;
    }

    assignFormGroups(): FormGroup {
        const donorsFormArray = this.fb.array(this.reservation.SalesOrderReservationDonors.map((d) => this.fb.group(d)));
        return this.fb.group({
            SalesOrderReservation: this.fb.group({}),
            SalesOrderReservationDonor: donorsFormArray,
        });
    }

    formSubmitted(): void {
        if (this.salesOrderReservationForm.valid) {
            // save logic here
            this.buildSoReservationForUpdate();
            this.soReservationService.updateWithFks(this.reservation).subscribe((res) => {
                this.success(res);
            });
        } else {
            markAllFormFieldsAsTouched(this.salesOrderReservationForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    buildSoReservationForUpdate(): void {
        Object.assign(this.reservation, this.salesOrderReservationForm.value.SalesOrderReservation);
        this.reservation.SalesOrderReservationDonors = this.salesOrderReservationForm.value.SalesOrderReservationDonor.map((d) => {
            return { DonorId: d.Id, EggCountReserved: d.EggCountReserved, Id: d.Id };
        });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.onEditComplete.emit();
        this.enableDoubleClick();
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
        this.enableDoubleClick();
    }

    success(updatedRes: ISalesOrderReservation): void {
        this.reservation = updatedRes;
        this.notificationsService.success('Reservation updated successfully.');
        this.onEditComplete.emit(this.reservation);
    }
}
