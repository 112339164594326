import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { ClinicAddComponent } from './clinic-add/clinic-add.component';
import { ClinicDetailComponent } from './clinic-detail/clinic-detail.component';
import { ClinicsComponent } from './clinic-list/clinics.component';
import { ClinicService } from './services/clinic.service';
import { ClinicHeaderComponent } from './clinic-header/clinic-header.component';

import { ClaimTypes } from '@model/ClaimTypes';

const clinicEntityConfig = {
    claimType: ClaimTypes.Clinics,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'clinicId',
    service: ClinicService,
    title: 'Clinic Detail',
};

const clinicListRoleGuard: any = {
    claimType: ClaimTypes.Clinics,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Clinics',
};

const clinicAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Clinics,
    claimValues: [ClaimValues.FullAccess],
};

export const clinicPaths = {
    clinics: 'clinics',
    clinicsAdd: 'clinics/add',
};

const clinicRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ClinicsComponent,
        data: clinicListRoleGuard,
        path: clinicPaths.clinics,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: ClinicAddComponent, path: '', pathMatch: 'full' }],
        component: ClinicHeaderComponent,
        data: clinicAddRoleGuard,
        path: clinicPaths.clinicsAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [{ component: ClinicDetailComponent, path: '', pathMatch: 'full' }],
        component: ClinicHeaderComponent,
        data: clinicEntityConfig,
        path: `clinics/:${clinicEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(clinicRoutes)],
})
export class ClinicRoutingModule {}
