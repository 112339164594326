import { Injectable } from '@angular/core';
import { IEntitySearchParams } from '@mt-ng2/common-classes';

/**
 * SELECTED CYLE STATE SERVICE
 *
 * List entities are added to the StickySearchEntity enum as needed
 *
 * Get search call in the component should be updated to take searchEntity as a param passed
 * in, in addition to building from user input
 */
export enum StickySearchEntity {
    Clinics = 1,
    Donors,
    Recipients,
    Applications,
}

@Injectable({providedIn: 'root'})
export class StickySearchStateService {
    private _searchEntity = {
        [StickySearchEntity.Clinics]: null,
        [StickySearchEntity.Donors]: null,
        [StickySearchEntity.Recipients]: null,
        [StickySearchEntity.Applications]: null,
    };

    constructor() { }

    /**
     * This should be added right after the complete searchEntity is built
     * but before transformed into SearchParams
     * @param {IEntitySearchParams} searchEntity The most recent search
     * @param {StickySearchEntity} entity Enum of configured list pages
     */
    public setSearch(searchEntity: IEntitySearchParams, entity: StickySearchEntity): void {
        this._searchEntity[entity] = searchEntity;
    }

    /**
     * Returns the most recently saved search params for the given entity
     * @param {StickySearchEntity} entity Enum of configured list pages
     */
    public getSearch(entity: StickySearchEntity): IEntitySearchParams {
        return this._searchEntity[entity];
    }

}
