import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IItemSelectedEvent } from '@mt-ng2/entity-list-module';

import { RecipientEntityListConfig } from './recipient-search.entity-list-config';
import { IRecipient } from '@model/interfaces/recipient';
import { RecipientService } from '../../../recipients/services/recipient.service';

@Component({
    selector: 'recipient-search',
    templateUrl: './recipient-search.component.html',
})
export class RecipientSearchComponent implements OnInit {
    @Output() onRecipientSelected = new EventEmitter<number>();
    recipients: IRecipient[];
    currentPage = 1;
    itemsPerPage = 10;
    query = '';
    total: number;
    entityListConfig = new RecipientEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(private recipientService: RecipientService, private router: Router) {}

    ngOnInit(): void {}

    getRecipientsCall(): Observable<HttpResponse<IRecipient[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.recipientService.get(searchparams);
    }

    getRecipients(): void {
        // this is a search based list, if no query clear the list
        if (!this.query) {
            this.recipients = null;
            return;
        }
        this.getRecipientsCall().subscribe((answer) => {
            this.recipients = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getRecipients();
    }

    recipientSelected(event: IItemSelectedEvent): void {
        this.onRecipientSelected.emit(event.entity);
    }
}
