<div *ngIf="viewReady">
    <div *ngIf="!isEditing">
        <span
            style="font-size: 18px; color: #652365; font-weight: bold"
            class="pull-right"
            *ngIf="canEdit"
            (click)="edit()"
            [style.cursor]="canEdit ? 'pointer' : 'default'"
        >
            <i class="fa fa-lg fa-edit"></i>
        </span>
        <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
        <div *ngIf="inventoryPosition" class="display-padded">
            <div>
                <span><label>Inventory Position:</label></span>
            </div>
            <span><b>Tank:</b> {{ inventoryPosition.Tank.Name }} - {{ inventoryPosition.Tank.Number }}</span>
            <span><b>Can:</b> {{ inventoryPosition.Can.Number }}</span>
            <span><b>Goblet:</b> {{ inventoryPosition.Goblet.Number }}</span>
        </div>
        <div class="display-padded ng-star-inserted">
            <span><label>Status:</label> {{ inventory?.Status ? inventory.Status : '' }}</span>
        </div>
    </div>
    <div *ngIf="isEditing">
        <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
            <br />
            <app-inventory-vessel [position]="inventoryPosition" (onGobletSelected)="inventoryPosition = $event"></app-inventory-vessel>
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </app-dynamic-form>
    </div>
</div>
<!-- Confirm Modal -->
<modal-wrapper *ngIf="showConfirm" [options]="confirmOptions">
    <div>
        <h2>Inventory Summary</h2>
        <p *ngIf="donorNumber"><b>Donor:</b> {{ donorNumber }}</p>
        <p><b>Retrieval Date:</b> {{ inventory.RetrievalDate | date: 'mediumDate' }}</p>
        <p><b>Straw #:</b> {{ inventory.StrawNumber }}</p>
        <p><b>Oocytes #:</b> {{ inventory.CountPerStraw }}</p>
        <p><b>Tank:</b> {{ inventoryPosition.Tank.Name }} - {{ inventoryPosition.Tank.Number }}</p>
        <p><b>Can:</b> {{ inventoryPosition.Can.Number }}</p>
        <p><b>Goblet:</b> {{ inventoryPosition.Goblet.Number }}</p>
    </div>
    <div class="row col-md-12">
        <button (click)="saveEggInventory()" class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelSave()">Cancel</button>
    </div>
</modal-wrapper>
