import { Component, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CycleDetailEnums } from '@model/enums/cycle-detail-enum';
import { YesNo } from '@model/enums/yes-no.enum';
import { ITriggerDataControlValue } from '@model/interfaces/custom/trigger-data-control-value';
import { ITriggerType } from '@model/interfaces/trigger-type';
import { IYesNoOption } from '@model/interfaces/yes-no-option';
import { DateParts } from '@mt-ng2/date-module';
import {
    CustomFormControlComponentBase,
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    IDynamicField,
    IDynamicFieldType,
    InputTypes,
    LabelPosition,
    LabelPositions,
} from '@mt-ng2/dynamic-form';

import { NumericControlTypes } from '@mt-ng2/numeric-control';
import { CycleDataService } from '../../../donors/services/cycle-data.service';

interface IFormValue {
    DateTime: Date;
    DateTime2?: Date;
    DualTrigger: number;
    TriggerAmount: string;
    TriggerAmount2?: string;
    TriggerTypeId: number;
    TriggerTypeId2?: number;
}

@Component({
    styles: [
        `
            div.pad {
                padding-left: 15px;
                padding-right: 15px;
            }
        `,
    ],
    styleUrls: ['../../override-bootstrap.less'],
    templateUrl: './trigger-data-control.component.html',
})
export class TriggerDataControlComponent extends CustomFormControlComponentBase {
    wrapperForm: FormGroup;
    datetimeField: DynamicField;
    triggerTypeField: DynamicField;
    triggerAmountField: DynamicField;
    dualTriggerField: DynamicField;
    datetimeField2: DynamicField;
    triggerTypeField2: DynamicField;
    triggerAmountField2: DynamicField;
    disabled = false;

    currentValue: ITriggerDataControlValue;
    numericControlTypes = NumericControlTypes;
    triggerTypes: ITriggerType[];
    yesnoOptions: IYesNoOption[];

    showSecondTrigger: boolean;
    controlInitialized: boolean;

    retrievalDateAndTime: Date;

    constructor(changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private cycleDataService: CycleDataService) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.cycleDataService.getCycleMetaData().subscribe((data) => {
            this.triggerTypes = data.TriggerTypes;
            this.yesnoOptions = data.YesNoOption;

            let value: ITriggerDataControlValue;
            if (this.config.value) {
                value = JSON.parse(this.config.value as string);
            } else {
                value = {
                    DateTime: null,
                    DateTime2: null,
                    DualTrigger: null,
                    TriggerAmount: null,
                    TriggerAmount2: null,
                    TriggerTypeId: null,
                    TriggerTypeId2: null,
                };
            }

            this.currentValue = value;
            if (this.currentValue.DualTrigger === YesNo.Yes) {
                this.showSecondTrigger = true;
            }

            this.wrapperForm = this.formBuilder.group({
                [this.config.formGroup]: this.formBuilder.group({}),
            });

            this.setFormFields();
            this.controlInitialized = true;
        });

        // wiring up the disabled property to propagate to our controls
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                const isDisabled = status === 'DISABLED';
                if (isDisabled && !this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.disable();
                    }, 0);
                    this.disabled = true;
                } else if (!isDisabled && this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.enable();
                    }, 0);
                    this.disabled = false;
                }
            }),
        );

        // subscribe to form value changes
        this.subscriptions.add(
            this.wrapperForm.valueChanges.subscribe((value) => {
                this.formValueChanged(value);
            }),
        );
        // subscribe retrieval date changes
        this.subscriptions.add(
            this.parentGroup.get(`CycleStep.${CycleDetailEnums.RetrievalDateandTime}`).valueChanges.subscribe((value) => {
                this.setTriggerValues(value);
            }),
        );
    }

    formValueChanged(value: any): void {
        if (value.CycleStep.DualTrigger === YesNo.Yes) {
            if (this.retrievalDateAndTime) {
                this.datetimeField2.value = new Date(this.retrievalDateAndTime).mtDate.subtract(27, DateParts.hours).date;
            }
            this.showSecondTrigger = true;
        } else if (value.CycleStep.DualTrigger === YesNo.No) {
            this.showSecondTrigger = false;
        }
        this.getControl().patchValue(JSON.stringify(value.CycleStep));
    }

    setTriggerValues(value): void {
        // Save a reference to the value so second trigger can be set automatically on dual trigger selected
        this.retrievalDateAndTime = value;
        const triggerDateTime = new Date(value).mtDate.subtract(35, DateParts.hours).date;
        this.wrapperForm.get('CycleStep.DateTime').setValue(triggerDateTime);
        if (this.wrapperForm.get('CycleStep.DualTrigger').value === YesNo.Yes) {
            const trigger2DateTime = new Date(value).mtDate.subtract(27, DateParts.hours).date;
            this.wrapperForm.get('CycleStep.DateTime2').setValue(trigger2DateTime);
        }
        this.wrapperForm.updateValueAndValidity();
    }

    private setFormFields(): void {
        this.datetimeField = this.buildDateTimeField('DateTime', 'Trigger Date and Time');
        this.triggerAmountField = this.buildInputField('TriggerAmount', 'Trigger Amount');
        this.triggerTypeField = this.buildSelectField('TriggerTypeId', 'Trigger Type', this.triggerTypes);
        this.dualTriggerField = this.buildSelectField('DualTrigger', 'Dual Trigger?', this.yesnoOptions);
        this.datetimeField2 = this.buildDateTimeField('DateTime2', 'Trigger 2 Date and Time');
        this.triggerAmountField2 = this.buildInputField('TriggerAmount2', 'Trigger 2 Amount');
        this.triggerTypeField2 = this.buildSelectField('TriggerTypeId2', 'Trigger 2 Type', this.triggerTypes);
    }

    private buildDateTimeField(field: string, title: string): DynamicField {
        return new DynamicField({
            formGroup: this.config.formGroup,
            label: title,
            labelPosition: new LabelPosition({ position: LabelPositions.Top }),
            name: field,
            type: new DynamicFieldType({
                datepickerOptions: this.config.type.datepickerOptions,
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.DateTimeInput,
            } as IDynamicFieldType),
            value: this.currentValue && this.currentValue[field] ? this.currentValue[field] : new Date(new Date().setHours(0, 0, 0, 0)),
        } as IDynamicField);
    }

    private buildSelectField(field: string, title: string, options: any[]): DynamicField {
        return new DynamicField({
            formGroup: this.config.formGroup,
            label: title,
            labelPosition: new LabelPosition({ position: LabelPositions.Top }),
            name: field,
            options: options,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
            } as IDynamicFieldType),
            value: this.currentValue[field] ? this.currentValue[field] : null,
        } as IDynamicField);
    }

    private buildInputField(field: string, title: string): DynamicField {
        return new DynamicField({
            formGroup: this.config.formGroup,
            label: title,
            labelPosition: new LabelPosition({ position: LabelPositions.Top }),
            name: field,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            value: this.currentValue && this.currentValue[field] ? this.currentValue[field] : null,
        } as IDynamicField);
    }
}
