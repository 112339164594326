import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IClinicVerification } from '@model/interfaces/clinic-verification';

export const emptyClinicVerification: IClinicVerification = {
    Id: 0,
    IsClinicVerified: null,
    PAndEReceivedDate: null,
    PAndESentDate: null,
    VerifiedById: null,
    VerifiedDate: null,
};

@Injectable({
    providedIn: 'root',
})
export class ClinicVerificationService extends BaseService<IClinicVerification> {
    constructor(public http: HttpClient) {
        super('/clinic-verifications', http);
    }

    getEmptyClinicVerification(): IClinicVerification {
        return { ...emptyClinicVerification };
    }
}
