import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICurrentReportUser } from '@mt-ng2/advanced-reporting-module-config';

import { ReportsService } from '../services/reports.service';

@Component({
    selector: 'email-report-button',
    styles: [
        `
            .email-icon {
                color: dodgerblue;
            }
        `,
    ],
    template: `
        <span *ngIf="reportId" (click)="emailReport($event)" class="btn btn-xs btn-transparent" title="email">
            <i class="fa fa-fw fa-lg fa-envelope email-icon"></i>
        </span>
    `,
})
export class EmailReportButtonComponent {
    @Input() reportId: number;

    constructor(private route: ActivatedRoute, private reportsService: ReportsService, private notificationsService: NotificationsService) {}

    private getCurrentReportUser(): ICurrentReportUser {
        return this.route.snapshot.data.currentUser;
    }

    emailReport(event: Event): void {
        event?.stopPropagation();
        const user = this.getCurrentReportUser();
        this.reportsService.emailReport({
            Email: user.Email,
            ReportId: this.reportId,
            UserName: user.Name,
        });
        this.notificationsService.info('Your report is being generated and will be emailed to you as soon as it is completed.');
    }
}
