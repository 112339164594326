import { IEventType } from '../interfaces/event-type';
import { IAuditConfiguration } from '../interfaces/audit-configuration';
import { AngularCsv } from 'angular-csv-ext';
import { IAuditLogExportConfig, IAuditLogExportColumn, IAuditLogUser, IAuditLogExport, IAuditLog } from '@mt-ng2/audit-logging-module-config';

export const defaultExportConfig: IAuditLogExportConfig = {
    exportPredicate: (log: IAuditLog) => true,
    valueForNulls: 'N/A',
};

const columnOrder: IAuditLogExportColumn[] = [
    {
        ColumnName: 'Event Date (UTC)',
        ExportEntryField: 'EventDateUTC',
    },
    {
        ColumnName: 'Event Type',
        ExportEntryField: 'EventType',
    },
    {
        ColumnName: 'Record Type',
        ExportEntryField: 'TypeFullName',
    },
    {
        ColumnName: 'User',
        ExportEntryField: 'UserName',
    },
    {
        ColumnName: 'Field',
        ExportEntryField: 'FieldName',
    },
    {
        ColumnName: 'Original Value',
        ExportEntryField: 'OriginalValue',
    },
    {
        ColumnName: 'New Value',
        ExportEntryField: 'NewValue',
    },
];

function getAuditLogConfiguration(auditConfigurations: IAuditConfiguration[], log: IAuditLog): IAuditConfiguration {
    return auditConfigurations.find((config) => log.TypeFullName === config.TypeFullName);
}

function getUser(auditLogUsers: IAuditLogUser[], log: IAuditLog): string {
    const result = auditLogUsers.find((user) => user.AuthUserId.toString() === log.UserName);
    return result ? result.Name : '';
}

function getType(auditConfig: IAuditConfiguration, log: IAuditLog): string {
    return auditConfig
        ? auditConfig.DisplayName
        : log.TypeFullName.replace('Model.', '')
              .replace(/([A-Z])/g, ' $1')
              .trim();
}

export function buildAuditLogsExport(
    auditLogs: IAuditLog[],
    eventTypes: IEventType[],
    auditConfigurations: IAuditConfiguration[],
    auditLogUsers: IAuditLogUser[],
    filterPredicate: (log: IAuditLog) => boolean,
): IAuditLogExport[] {
    const exportData: IAuditLogExport[] = [];
    auditLogs.filter(filterPredicate).forEach((log) => {
        const auditConfig = getAuditLogConfiguration(auditConfigurations, log);
        const logDetails = auditConfig
            ? log.LogDetails.filter((detail) => auditConfig.HideProperties.findIndex((propName) => propName === detail.PropertyName) === -1)
            : log.LogDetails;
        logDetails.forEach((logDetail) => {
            exportData.push({
                EventDateUTC: new Date(log.EventDateUTC).toString(),
                EventType: eventTypes.find((type) => type.Id === log.EventType).Name,
                FieldName: logDetail.PropertyName,
                NewValue: logDetail.NewValue,
                OriginalValue: logDetail.OriginalValue,
                TypeFullName: getType(auditConfig, log),
                UserName: getUser(auditLogUsers, log),
            });
        });
    });
    return exportData;
}

export function exportLogs(
    auditLogs: IAuditLog[],
    eventTypes: IEventType[],
    auditConfigurations: IAuditConfiguration[],
    auditLogUsers: IAuditLogUser[],
    exportConfig: IAuditLogExportConfig,
): void {
    const exportData = buildAuditLogsExport(auditLogs, eventTypes, auditConfigurations, auditLogUsers, exportConfig.exportPredicate).map((item) => {
        const exportItem: any = {};
        columnOrder.forEach((column, index) => {
            exportItem[index] = item[column.ExportEntryField] || exportConfig.valueForNulls;
        });
        return exportItem;
    });
    // tslint:disable-next-line: no-unused-expression
    new AngularCsv(exportData, 'Audit Logs', {
        headers: columnOrder.map((column) => column.ColumnName),
    });
}
