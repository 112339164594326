import { Component, Input, ViewChild, ElementRef } from '@angular/core';

import { IMetaItem } from '@mt-ng2/base-service';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { IReportFilter } from '../model/interfaces/report-filter';
import { IDatapoint } from '../model/interfaces/datapoint';
import { FilterTypesEnum, FilterTypes, emptyReportFilter, isFilterTypeWithoutValue } from '../libraries/report-filters.library';
import { IFilterType } from '../model/interfaces/filter-type';

const ConjunctionTypes: IMetaItem[] = [
    { Id: 1, Name: 'and' },
    { Id: 2, Name: 'or' },
];

@Component({
    selector: 'report-filters-filter',
    styles: [
        `
            .clickable-text {
                border-bottom: 1px dotted #aeaeae;
                color: #0099cc;
                display: inline-block;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                padding-left: 2px;
                padding-right: 2px;
                vertical-align: top;
            }
            .not-clickable-text {
                border-bottom: none;
                color: gray;
                display: inline-block;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: 2px;
                padding-right: 2px;
                vertical-align: top;
            }
            .clickable-text-value {
                color: #074b7a;
            }
            .show.dropdown {
                display: inline !important;
            }
            .dropdown-menu > li > a {
                cursor: pointer;
            }
            .dropdown-menu {
                height: auto;
                max-height: 400px;
                overflow-x: hidden;
            }
            .select-all-separator {
                margin-top: 2px;
                margin-bottom: 2px;
            }
            .select-all-button {
                margin-left: 5px;
                margin-right: 5px;
                font-style: italic;
                color: #808080;
            }
        `,
    ],
    templateUrl: './report-filters-filter.component.html',
})
export class ReportFiltersFilterComponent {
    @Input() filter: IReportFilter;
    @Input() includeConjunction: boolean;
    @Input() datapointOptions: IDatapoint[];

    @ViewChild('inputText') inputText: ElementRef;

    conjunctionOptions = ConjunctionTypes;
    editingValue = false;
    metaDataOptions: MultiselectItem[];

    constructor() {}

    toggleEditValue(): void {
        this.editingValue = !this.editingValue;
        if (this.editingValue) {
            setTimeout(() => {
                this.inputText.nativeElement.focus();
            }, 0);
        }
    }

    getConjunction(): string {
        return ConjunctionTypes.find((c) => c.Id === this.filter.ConjunctionId).Name;
    }

    getDisplayAs(): string {
        return this.datapointOptions.find((dp) => dp.Name === this.filter.DatapointName).DisplayAs;
    }

    getFilterType(): string {
        if (this.filter.FilterTypeId === FilterTypesEnum.WithinLastXDays) {
            return 'is within the last';
        }
        if (this.filter.FilterTypeId === FilterTypesEnum.WithinNextXDays) {
            return 'is within the next';
        }
        return FilterTypes.find((ft) => ft.Id === this.filter.FilterTypeId).Name;
    }

    getValue(): string {
        if (this.filter.DatapointType === 'metadata') {
            const valueArray = this.filter.Value ? JSON.parse(this.filter.Value) : [];
            if (!valueArray.length) {
                return `[select values]`;
            }
            const metadataOptions = this.getMetadataOptions();
            return valueArray.map((optionId) => metadataOptions.find((option) => option.Item.Id === optionId).Item.Name).join(', ');
        }
        return this.filter.Value ? this.filter.Value : `[enter value]`;
    }

    setTextValue(value: string): void {
        this.filter.Value = value;
        this.editingValue = false;
    }

    datapointSelected(datapoint: IDatapoint): void {
        if (datapoint.Name === this.filter.DatapointName) {
            return;
        }
        const filterTypeId = datapoint.Type === 'metadata' ? FilterTypesEnum.In : emptyReportFilter.FilterTypeId;
        this.metaDataOptions = datapoint.Type === 'metadata' ? datapoint.items.map((item) => new MultiselectItem(item, false)) : null;
        Object.assign(this.filter, {
            DatapointName: datapoint.Name,
            DatapointType: datapoint.Type,
            FilterTypeId: filterTypeId,
            Value: '',
        });
    }

    getfilterTypeOptions(): IFilterType[] {
        return FilterTypes.filter((item) => item.DataTypes.some((dt) => dt === this.filter.DatapointType));
    }

    setFilterType(option: IFilterType): void {
        const wasXDaysType = this.XDaysType();
        this.filter.FilterTypeId = option.Id;
        const isXDaysType = this.XDaysType();
        if (wasXDaysType !== isXDaysType) {
            this.filter.Value = '';
        }
    }

    getMetadataOptions(): MultiselectItem[] {
        if (!this.metaDataOptions) {
            let selectedIds: number[] = [];
            if (this.filter.Value) {
                selectedIds = JSON.parse(this.filter.Value);
            }
            this.metaDataOptions = this.datapointOptions
                .find((dp) => dp.Name === this.filter.DatapointName)
                .items.map(
                    (item) =>
                        new MultiselectItem(
                            item,
                            selectedIds.some((id) => id === item.Id),
                        ),
                );
        }
        return this.metaDataOptions;
    }

    itemSelected(index: number): void {
        const selectedItem = this.metaDataOptions[index];
        selectedItem.Selected = !selectedItem.Selected;
        this.setMetadataValue();
    }

    selectAll(checkAll: boolean): void {
        this.metaDataOptions.forEach((item) => {
            item.Selected = checkAll;
        });
        this.setMetadataValue();
    }

    setMetadataValue(): void {
        const selectedOptions = this.metaDataOptions.filter((option) => option.Selected);
        if (!selectedOptions.length) {
            this.filter.Value = null;
            return;
        }
        this.filter.Value = JSON.stringify(selectedOptions.map((item) => item.Item.Id));
    }

    showValue(): boolean {
        return !isFilterTypeWithoutValue(this.filter);
    }

    XDaysType(): boolean {
        return this.filter.FilterTypeId === FilterTypesEnum.WithinLastXDays || this.filter.FilterTypeId === FilterTypesEnum.WithinNextXDays;
    }
}
