import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { IVitDeviceType } from '@model/interfaces/vit-device-type';

@Injectable({
    providedIn: 'root',
})
export class VitDeviceTypeService extends MetaItemService<IVitDeviceType> {
    constructor(public http: HttpClient) {
        super('VitDeviceTypeService', 'Type', 'TypeIds', '/vit-device-types', http);
    }
}
