import { IDatapoint } from '../model/interfaces/datapoint';
import { IReportFilter } from '../model/interfaces/report-filter';
import { IReportFilterGroup } from '../model/interfaces/report-filter-group';
import { IFilterType } from '../model/interfaces/filter-type';
import { IMetaItem } from '@mt-ng2/base-service';

const ConjunctionTypes: IMetaItem[] = [
    { Id: 1, Name: 'and' },
    { Id: 2, Name: 'or' },
];

export enum ConjunctionTypesEnum {
    And = 1,
    Or = 2,
}

export enum FilterTypesEnum {
    Equals = 1,
    Contains = 2,
    GreaterThan = 3,
    GreaterThanEqualTo = 4,
    LessThan = 5,
    LessThanEqualTo = 6,
    In = 7,
    // Between = 8,
    NotEquals = 9,
    NotContains = 10,
    Null = 11,
    NotNull = 12,
    NotIn = 13,
    // NotBetween = 14,
    ThisWeek = 15,
    LastWeek = 16,
    ThisMonth = 17,
    LastMonth = 18,
    ThisYear = 19,
    LastYear = 20,
    WithinLastXDays = 21,
    WithinNextXDays = 22,
}

export const FilterTypes: IFilterType[] = [
    { Id: 1, Name: 'is equal to', DataTypes: ['string', 'number', 'boolean', 'Date'] },
    { Id: 9, Name: 'is not equal to', DataTypes: ['string', 'number', 'boolean', 'Date'] },
    { Id: 2, Name: 'contains', DataTypes: ['string'] },
    { Id: 10, Name: 'does not contain', DataTypes: ['string'] },
    { Id: 3, Name: 'is greater than', DataTypes: ['number', 'Date'] },
    { Id: 4, Name: 'is greater than or equal to', DataTypes: ['number', 'Date'] },
    { Id: 5, Name: 'is less than', DataTypes: ['number', 'Date'] },
    { Id: 6, Name: 'is less than or equal to', DataTypes: ['number', 'Date'] },
    { Id: 15, Name: 'is within this week', DataTypes: ['Date'] },
    { Id: 16, Name: 'is within last week', DataTypes: ['Date'] },
    { Id: 17, Name: 'is within this month', DataTypes: ['Date'] },
    { Id: 18, Name: 'is within last month', DataTypes: ['Date'] },
    { Id: 19, Name: 'is within this year', DataTypes: ['Date'] },
    { Id: 20, Name: 'is within last year', DataTypes: ['Date'] },
    { Id: 21, Name: 'is within the last [X] days', DataTypes: ['Date'] },
    { Id: 22, Name: 'is within the next [X] days', DataTypes: ['Date'] },
    { Id: 7, Name: 'is in', DataTypes: ['metadata'] },
    { Id: 13, Name: 'is not in', DataTypes: ['metadata'] },
    // { Id: 8, Name: 'is between', DataTypes: ['number', 'Date'] },
    // { Id: 14, Name: 'is not between', DataTypes: ['number', 'Date'] },
    { Id: 11, Name: 'is null', DataTypes: ['string', 'number', 'boolean', 'Date', 'metadata'] },
    { Id: 12, Name: 'is not null', DataTypes: ['string', 'number', 'boolean', 'Date', 'metadata'] },
];

export const emptyReportFilter: IReportFilter = {
    ConjunctionId: ConjunctionTypesEnum.And,
    DatapointName: null,
    DatapointType: null,
    FilterTypeId: FilterTypesEnum.Equals,
    Value: '',
};

export function getConjunctionFromFilter(filter: IReportFilter | IReportFilterGroup): string {
    return ConjunctionTypes.find((c) => c.Id === filter.ConjunctionId).Name;
}

export function getDisplayAsFromFilter(filter: IReportFilter, datapointOptions: IDatapoint[]): string {
    return datapointOptions.find((dp) => dp.Name === filter.DatapointName).DisplayAs;
}

export function getFilterTypeFromFilter(filter: IReportFilter): string {
    return FilterTypes.find((ft) => ft.Id === filter.FilterTypeId).Name;
}

export function getValueFromFilter(filter: IReportFilter, datapointOptions: IDatapoint[]): string {
    if (filter.DatapointType === 'metadata') {
        const valueArray = filter.Value ? JSON.parse(filter.Value) : [];
        if (!valueArray.length) {
            return null;
        }
        const metadataOptions = datapointOptions.find((dp) => dp.Name === filter.DatapointName).items;
        return valueArray.map((optionId) => metadataOptions.find((option) => option.Id === optionId).Name).join(', ');
    }
    return filter.Value ? filter.Value : null;
}

export function isFilterTypeWithoutValue(filter: IReportFilter): boolean {
    return (
        filter.FilterTypeId === FilterTypesEnum.Null ||
        filter.FilterTypeId === FilterTypesEnum.ThisWeek ||
        filter.FilterTypeId === FilterTypesEnum.ThisMonth ||
        filter.FilterTypeId === FilterTypesEnum.ThisYear ||
        filter.FilterTypeId === FilterTypesEnum.LastWeek ||
        filter.FilterTypeId === FilterTypesEnum.LastMonth ||
        filter.FilterTypeId === FilterTypesEnum.LastYear ||
        filter.FilterTypeId === FilterTypesEnum.NotNull
    );
}

function isValid(filter: IReportFilter, datapointOptions: IDatapoint[]): boolean {
    return isFilterTypeWithoutValue(filter) || getValueFromFilter(filter, datapointOptions) ? true : false;
}

function getValidFilters(filters: IReportFilter[], datapointOptions: IDatapoint[]): IReportFilter[] {
    return filters
        .filter((filter) => isValid(filter, datapointOptions))
        .map((filter) => {
            if (isFilterTypeWithoutValue(filter)) {
                filter.Value = null;
            }
            return filter;
        });
}

export function getGroupsWithValidFilters(
    groupedFilters: IReportFilterGroup[],
    datapointOptions: IDatapoint[],
): IReportFilterGroup[] {
    return groupedFilters
        .map((group) => {
            const validFilters = getValidFilters(group.Filters, datapointOptions);
            return {
                ConjunctionId: group.ConjunctionId,
                Filters: [...validFilters],
            } as IReportFilterGroup;
        })
        .filter((group) => group.Filters.length);
}

export function getFiltersAsText(groupedFilters: IReportFilterGroup[], datapointOptions: IDatapoint[]): string {
    let filtersText = '';

    const groupsWithValidFilters = getGroupsWithValidFilters(groupedFilters, datapointOptions);

    groupsWithValidFilters.forEach((group, groupIndex) => {
        if (groupIndex > 0) {
            filtersText += ` ${getConjunctionFromFilter(group)}`;
        }
        if (groupIndex > 0 || groupsWithValidFilters.length > 1) {
            filtersText += ' (';
        }
        group.Filters.forEach((filter, filterIndex) => {
            if (filterIndex > 0) {
                filtersText += ` ${getConjunctionFromFilter(filter)}`;
            }
            filtersText += ` ${getDisplayAsFromFilter(filter, datapointOptions)}`;
            if (!isFilterTypeWithoutValue(filter)) {
                if (filter.FilterTypeId === FilterTypesEnum.WithinLastXDays) {
                    filtersText += ` within the last ${getValueFromFilter(filter, datapointOptions)} days`;
                } else if (filter.FilterTypeId === FilterTypesEnum.WithinNextXDays) {
                    filtersText += ` within the next ${getValueFromFilter(filter, datapointOptions)} days`;
                } else {
                    filtersText += ` ${getFilterTypeFromFilter(filter)} ${getValueFromFilter(
                        filter,
                        datapointOptions,
                    )}`;
                }
            } else {
                filtersText += ` ${getFilterTypeFromFilter(filter)}`;
            }
        });
        if (groupIndex > 0 || groupsWithValidFilters.length > 1) {
            filtersText += ' )';
        }
    });
    return filtersText;
}
