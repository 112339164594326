import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ViewRef, OnDestroy, Input } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { debounceTime, finalize, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PreScreeningService } from '../../../../common/services/pre-screening.service';
import { ApplicationPreScreeningDynamicConfig } from './application-pre-screening.dynamic-config';
import { IPreScreening } from '@model/interfaces/pre-screening';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { IReferralType } from '@model/interfaces/referral-type';
import { IGender } from '@model/interfaces/gender';
import { GenderService } from '../../../../recipients/outcomes/service/gender.service';
import { CommonService } from '@common/services/common.service';
import { ReferralTypeService } from '@common/services/referral-type.service';
import { UnitsOfMeasurementService } from '@common/services/units-of-measurement.service';
import { IPreScreeningDynamicControlsParameters } from '@model/form-controls/pre-screening.form-controls';
import { IEducationCompletedType } from '@model/interfaces/education-completed-type';
import { ApplicationDataService, ICurrentSection } from '../../../../applications/application-data/services/application-data.service';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { ApplicationPreScreeningDynamicControlsPartial } from '@model/partials/application-pre-screening-partial.form-controls';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IApplicationPreScreeningChildren } from '@model/interfaces/application-pre-screening-children';
import { IApplication } from '@model/interfaces/application';
import { FormSections } from '../../../..//model/enums/form-sections.enum';
import { IApplicationValidationDTO } from '@model/interfaces/custom/application-validation-dto';
import { SharedFunctionsService } from '../../../../common/services/shared-functions-service';

@Component({
    selector: 'application-pre-screening',
    templateUrl: './application-pre-screening.component.html',
})
export class ApplicationPreScreeningComponent implements OnInit {

    //
    // Expose the "form is dirty" flag
    //
    get isDirty(): boolean {
        return this.preScreeningForm.dirty;
    }

    //
    // Expose the "pre-screeing is denied" flag
    //
    get isDenied(): boolean {
        return this.preScreening && this.preScreening.Denied;
    }

    @Input() preScreening: IPreScreening;
    @Input() application: IApplication;
    @Input() denialReasonMapping: any;

    showStatesCurrentAddress$: Observable<boolean>;
    formFactory: ApplicationPreScreeningDynamicConfig<IPreScreening>;
    doubleClickIsDisabled = false;

    formReady = false;
    referralTypes: IReferralType[];
    unitsOfMeasurement: IUnitsOfMeasurement[];
    heightUnits: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];
    states: IStateMetaItem[];
    countries: ICountryMetaItem[];
    genders: IGender[];
    educationCompletedTypes: IEducationCompletedType[];
    formCreated = false;
    preScreeningControls: any;
    preScreeningForm: FormGroup;
    subs = new Subscription();
    selectedHeightUnitId: number;
    hasGivenBirth?: boolean;
    subscription: Subscription = new Subscription();
    selectReferralTypeId?: number;
    isEditing = false;
    sharedFunctionsService: SharedFunctionsService;

    currentSection: ICurrentSection = {
        formGroup: null,
        nextTab: 'tab-basicsComplete',
        nextTabLabel: 'Basic Info',
        previousTab: null,
        previousTabLabel: '',
        routerLink: null,
        sectionId: FormSections.PreScreening,
    };

    private configControls = [
        'BirthYear',
        'Email',
        'ConfirmEmail',
        'Height',
        'HeightUnitId',
        'Weight',
        'WeightUnitId',
        'GenderId',
        'EducationCompletedTypeId',
        'IsUsCitizenOrGreenCardHolder',
        'CountryCode',
        'StateCode',
        'HasStd',
        'HasHiv',
        'HasFamilyMedicalHistory',
        'IsAdopted',
        'HasBothOvaries',
        'HasGivenBirth',
        'HowManyLiveBirths',
        'IsPregnantOrBreastFeeding',
        'HasPreviouslyDonatedEggs',
        'HasRegularPeriodsWithoutBirthControl',
        'ReferralTypeId',
    ];
    config: any = { formObject: [], viewOnly: [] };

    additionalConfigs: IPreScreeningDynamicControlsParameters;

    constructor(
        private fb: FormBuilder,
        private applicationService: ApplicationDataService,
        private notificationService: NotificationsService,
        private referralTypeService: ReferralTypeService,
        private unitsOfMeasurementService: UnitsOfMeasurementService,
        private preScreeningService: PreScreeningService,
        private commonService: CommonService,
        private genderService: GenderService,
    ) { }

    ngOnInit(): void {
        forkJoin([
            this.referralTypeService.getItems(),
            this.unitsOfMeasurementService.getItems(),
            this.commonService.getAllStates(),
            this.commonService.getCountries(),
            this.genderService.getItems(),
            this.applicationService.getPersonalInformationMetaData(),
        ]).subscribe((metaItems) => {
            this.referralTypes = this.referralTypeService.items;
            this.heightUnits = this.unitsOfMeasurementService.getHeightUnits();
            this.weightUnits = this.unitsOfMeasurementService.getWeightUnits();
            this.countries = this.commonService.getCountryMetaItems();
            this.states = this.commonService.getAllStateMetaItems();
            this.genders = this.genderService.items;
            this.educationCompletedTypes = (metaItems[5] as IPersonalInformationMetaData).EducationCompletedTypes;
            this.selectedHeightUnitId = this.preScreening.HeightUnitId;
            this.hasGivenBirth = this.preScreening.HasGivenBirth;
            this.selectReferralTypeId = this.preScreening.ReferralTypeId;
            this.sharedFunctionsService = new SharedFunctionsService();
            this.setConfig();
        });
    }
    setConfig(): void {
        this.additionalConfigs = {
            formGroup: 'PreScreening',
            countries: this.countries,
            states: this.states,
            heightUnits: this.heightUnits,
            weightUnits: this.weightUnits,
            referralTypes: this.referralTypes,
            genders: this.genders,
            educationCompletedTypes: this.educationCompletedTypes,

        } as IPreScreeningDynamicControlsParameters;

        this.formFactory = new ApplicationPreScreeningDynamicConfig<IPreScreening>(
            this.preScreening,
            this.configControls,
            this.additionalConfigs,
        );
        this.config = this.formFactory.getForUpdate();
        this.applicationService.currentSection.next(this.currentSection);
        this.createForm();
    }

    // Should height be entered in Meters?
    //
    usingMeters(): boolean {
        return this.selectedHeightUnitId === UnitsOfMeasurementEnums.Meters;
    }

    //
    // Create the primary form
    //
    createForm(): void {
        this.preScreeningForm = this.assignFormGroups();
        this.getControls();
        this.formCreated = true;
        this.subs.add(
            this.preScreeningForm.valueChanges.pipe(debounceTime(50)).subscribe(() => {
                if (this.preScreeningForm.dirty) {
                    this.assignFormValues(this.preScreeningForm.value.PreScreening);
                }
            }),
        );
    }

    //
    // Create the form group
    //
    assignFormGroups(): FormGroup {
        return this.fb.group({
            PreScreening: this.fb.group({}),
        });
    }

    //
    // Generate the controls
    //
    getControls(): void {
        this.preScreeningControls = new ApplicationPreScreeningDynamicControlsPartial(this.preScreening, this.additionalConfigs).Form;
    }

    //
    // Gather data from the form
    //
    assignFormValues(info: any): void {

        this.preScreening.BirthYear = info.BirthYear;
        this.preScreening.Email = info.Email;
        this.preScreening.ConfirmEmail = info.ConfirmEmail;

        if (this.usingMeters()) {
            this.preScreening.Height = Number(info.Meters);
        } else {
            this.preScreening.Height = Number(info.Feet) * 12 + Number(info.Inches);
        }
        this.preScreening.HeightUnitId = info.HeightUnitId;
        this.selectedHeightUnitId = info.HeightUnitId;
        this.preScreening.Weight = info.Weight;
        this.preScreening.WeightUnitId = info.WeightUnitId;
        this.preScreening.GenderId = info.GenderId;
        this.preScreening.EducationCompletedTypeId = info.EducationCompletedTypeId;
        this.preScreening.IsUsCitizenOrGreenCardHolder = info.IsUsCitizenOrGreenCardHolder;
        this.preScreening.CountryCode = this.findCountryCode(info.CountryCode);

        if (this.countryHasStatesList(this.preScreening.CountryCode)) {
            let state = this.states.find((s) => s.Id = info.StateCode);
            if (state) {
                this.preScreening.StateCode = state.StateCode;
            }
            this.preScreening.Province = null;
        } else {
            this.preScreening.Province = info.Province;
            this.preScreening.StateCode = null;
        }
        this.preScreening.HasStd = info.HasStd;
        this.preScreening.HasHiv = info.HasHiv;
        this.preScreening.HasFamilyMedicalHistory = info.HasFamilyMedicalHistory;
        this.preScreening.IsAdopted = info.IsAdopted;
        this.preScreening.HasBothOvaries = info.HasBothOvaries;
        this.preScreening.HasGivenBirth = info.HasGivenBirth;
        this.preScreening.HowManyLiveBirths = info.HowManyLiveBirths;
        this.preScreening.IsPregnantOrBreastFeeding = info.IsPregnantOrBreastFeeding;
        this.preScreening.HasPreviouslyDonatedEggs = info.HasPreviouslyDonatedEggs;
        this.preScreening.HasRegularPeriodsWithoutBirthControl = info.HasRegularPeriodsWithoutBirthControl;
        this.preScreening.ReferralTypeId = info.ReferralTypeId;
        this.preScreening.OtherReferral = info.OtherReferral;
        this.preScreening.Submitted = info.Submitted;
    }
    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
        this.enableDoubleClick();
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    formSubmitted(): void {

        if (this.preScreeningForm.valid) {
            this.assignFormValues(this.preScreeningForm.value.PreScreening);

            this.preScreeningService.updatePreScreening(this.preScreening).subscribe((appStatus) => {
                this.setConfig();
                this.success(appStatus);
            });
        } else {
            markAllFormFieldsAsTouched(this.preScreeningForm);
            this.error();
        }
    }

    error(): void {
        this.notificationService.error('Save failed. Please check the form and try again.');
        this.enableDoubleClick();
    }

    success(appStatus: IApplicationValidationDTO): void {
        this.notificationService.success('Pre-screening saved successfully.');
        if (appStatus.Denied) {
            this.notificationService.info(`Donor has been denied. ${this.denialReasonMapping[appStatus.DenialId]}`);
        }
        this.enableDoubleClick();
        this.isEditing = false;
        this.applicationService.emitChange(this.application);
    }

    //
    // Single point to check whether or not the country code is one that we have a defined list of states for
    //
    countryHasStatesList(value: string): boolean {
        return this.sharedFunctionsService.countryHasStatesList(value, this.states);
    }

    //
    // When the country changes, refresh the list of states based on the country selection
    //
    setStatesForCountry(value): void {
        this.filterStatesForCounty(this.findCountryCode(value));
    }

    //
    // Return the country code that matches the Id provided
    //
    findCountryCode(value): string {
        return this.sharedFunctionsService.getCountryCodeById(value, this.countries);
    }

    //
    // Return a list of country-specific states
    //
    filterStatesForCounty(countryCode: string): void {
        const stateCodeControl = this.preScreeningControls.StateCode;
        stateCodeControl.options = this.sharedFunctionsService.filterStatesForCounty(countryCode, this.states);
    }

    saveState(value: number): void {
        const stateCode = this.sharedFunctionsService.getStateCodeById(value, this.states);
        this.preScreening.StateCode = stateCode;
    }

    //
    // Determine if the selected referral type is "Other"
    //
    otherReferralType(): boolean {
        return this.sharedFunctionsService.otherReferralType(this.selectReferralTypeId, this.referralTypes);
    }

    //
    // Setup event handler so that when the country changes, the State Code or Province is displayed based upon whether or not there are states available for  the selected country
    //
    setShowStatesCurrentAddress(countryCodeControl: AbstractControl): void {
        this.showStatesCurrentAddress$ = countryCodeControl?.valueChanges.pipe(
            startWith(countryCodeControl.value ?? 0),
            map((value) => this.countryHasStatesList(this.findCountryCode(value))),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }

    //
    // When the value of the "Have you given birth?" control changes, update the "has given birth flag"
    //
    onHasGivenBirthValueChange(hasGivenBirth: number): void {
        this.hasGivenBirth = (hasGivenBirth === 1);
    }

    //
    // When the value of the "Referral Type" control changes, update the "selected referral type Id"
    //
    onReferralTypeValueChange(referralTypeId: number): void {
        this.selectReferralTypeId = referralTypeId;
    }

    //
    // When the value of the "height units" control changes, update the "current height unit Id"
    //
    onHeightUnitsValueChange(heightUnitId: number): void {
        this.selectedHeightUnitId = heightUnitId;
    }

    //
    // When the value of the "How Many Live Births" control changes, update the "live births value"
    //
    onHowManyLiveBirthsValueChanges(liveBirthsValue: number): void {
        this.preScreening.HowManyLiveBirths = liveBirthsValue;
    }

    //
    // When the births component changes, update the pre-screening entry with the arrary of children
    //
    onBirthsChanging(children: IApplicationPreScreeningChildren[]): void {
        this.preScreening.Children = children;
    }
}
