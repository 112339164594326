import { IMetaItem } from '@model/interfaces/base';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { NoteDynamicControls, INoteDynamicControlsParameters } from '../form-controls/note.form-controls';
import { INote } from '../interfaces/note';

export class NoteDynamicControlsPartial extends NoteDynamicControls {
    constructor(notePartial?: INote, additionalParameters?: INoteDynamicControlsParameters) {
        super(notePartial, additionalParameters);

        if (additionalParameters && additionalParameters.categories.length === 1) {
            notePartial.CategoryId = additionalParameters.categories[0].Id;
        }

        // examples shown below of how to alter Form fields that already exist from the extended DynamicControls class
        (<DynamicField>this.Form.NoteText).setRequired(true);
        (<DynamicField>this.Form.Title).setRequired(true);

        // examples shown below of how to alter View fields that already exist from the extended DynamicControls class
        (<DynamicLabel>this.View.DateCreated).label = 'Last Edited';
        (<DynamicLabel>this.View.UserId).label = 'Author';
        (<DynamicLabel>this.View.UserId).value =  this.getNoteAuthor(notePartial);
    }

    getNoteAuthor(partial): string {
        return partial.User ? partial.User.FirstName + ' ' + partial.User.LastName : '';
    }
}
