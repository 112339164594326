import { Component, Input, ViewChild, ElementRef } from '@angular/core';

import { ReportColumnsService } from '../services/report-columns.service';
import { IReportColumn } from '../model/interfaces/report-column';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ReportChangedService } from '../services/report-changed.service';
import { IColumnDefinition } from '../model/interfaces/column-definition';

@Component({
    selector: 'report-column-header',
    styles: [
        `
            span {
                -webkit-user-select: none; /* webkit (safari, chrome) browsers */
                -moz-user-select: none; /* mozilla browsers */
                -khtml-user-select: none; /* webkit (konqueror) browsers */
                -ms-user-select: none; /* IE10+ */
            }
            .header-buttons {
                opacity: 0.54;
                cursor: pointer;
            }
        `,
    ],
    template: `
        <div>
            <div [hidden]="!isEditing">
                <input type="text" #input [(ngModel)]="column.name" (blur)="saveColumn()" (click)="$event.stopPropagation()" />
            </div>
            <div [hidden]="isEditing">
                <span class="datatable-header-cell-wrapper">
                    <b><span class="datatable-header-cell-label" [class.draggable]="canEdit" [innerHTML]="column.name"></span></b>
                </span>
                <span *ngIf="direction">
                    <i [class]="direction === 'asc' ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
                </span>
                <span *ngIf="canEdit" class="pull-right header-buttons" style="margin-left: 5px">
                    <i class="fa fa-window-close" (click)="deleteColumn(); $event.stopPropagation()"></i>
                </span>
                <span *ngIf="canEdit" class="pull-right header-buttons" (click)="edit(); $event.stopPropagation()">
                    <i class="fa fa-lg fa-edit"></i>
                </span>
            </div>
        </div>
    `,
})
export class ReportColumnHeaderComponent {
    @Input('column')
    column: IColumnDefinition;
    @Input('direction')
    direction: string;
    @Input('canEdit')
    canEdit: boolean;
    @ViewChild('input', { static: true })
    input: ElementRef;
    isEditing = false;

    constructor(
        private reportColumnsService: ReportColumnsService,
        private reportChangedService: ReportChangedService,
        private notificationsService: NotificationsService,
    ) {}

    edit(): void {
        if (!this.canEdit) {
            return;
        }
        this.isEditing = true;
        setTimeout(() => {
            this.input.nativeElement.focus();
        });
    }

    saveColumn(): void {
        let reportColumn: IReportColumn = this.column.reportColumn;
        reportColumn.DisplayAs = this.column.name;
        this.reportColumnsService.update(reportColumn).subscribe(() => {
            this.isEditing = false;
            this.notificationsService.success('Column Saved Successfully');
        });
    }

    deleteColumn(): void {
        this.reportChangedService.columnDeleted(this.column.reportColumnId as number);
    }
}
