import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ISalesOrderReservation } from '../interfaces/sales-order-reservation';
import { IRecipient } from '../interfaces/recipient';

export interface ISalesOrderReservationDynamicControlsParameters {
    formGroup?: string;
    recipients?: IRecipient[];
}

export class SalesOrderReservationDynamicControls {

    formGroup: string;
    recipients: IRecipient[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private salesorderreservation?: ISalesOrderReservation, additionalParameters?: ISalesOrderReservationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'SalesOrderReservation';
        this.recipients = additionalParameters && additionalParameters.recipients || undefined;

        this.Form = {
            Canceled: new DynamicField({
                formGroup: this.formGroup,
                label: 'Canceled',
                name: 'Canceled',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorderreservation && this.salesorderreservation.hasOwnProperty('Canceled') && this.salesorderreservation.Canceled !== null ? this.salesorderreservation.Canceled : false,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.salesorderreservation && this.salesorderreservation.CreatedById || null,
            }),
            CreatedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created Date',
                name: 'CreatedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.salesorderreservation && this.salesorderreservation.CreatedDate || null,
            }),
            ExpirationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Expiration Date',
                name: 'ExpirationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorderreservation && this.salesorderreservation.ExpirationDate || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.salesorderreservation && this.salesorderreservation.hasOwnProperty('Notes') && this.salesorderreservation.Notes !== null ? this.salesorderreservation.Notes.toString() : '',
            }),
            RecipientId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Recipient',
                name: 'RecipientId',
                options: this.recipients,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.salesorderreservation && this.salesorderreservation.RecipientId || null,
            }),
            SalesOrderCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order Created',
                name: 'SalesOrderCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.salesorderreservation && this.salesorderreservation.hasOwnProperty('SalesOrderCreated') && this.salesorderreservation.SalesOrderCreated !== null ? this.salesorderreservation.SalesOrderCreated : false,
            }),
        };

        this.View = {
            Canceled: new DynamicLabel(
                'Canceled',
                this.salesorderreservation && this.salesorderreservation.hasOwnProperty('Canceled') && this.salesorderreservation.Canceled !== null ? this.salesorderreservation.Canceled : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CreatedById: new DynamicLabel(
                'Created By',
                this.salesorderreservation && this.salesorderreservation.CreatedById || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            CreatedDate: new DynamicLabel(
                'Created Date',
                this.salesorderreservation && this.salesorderreservation.CreatedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ExpirationDate: new DynamicLabel(
                'Expiration Date',
                this.salesorderreservation && this.salesorderreservation.ExpirationDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            Notes: new DynamicLabel(
                'Notes',
                this.salesorderreservation && this.salesorderreservation.hasOwnProperty('Notes') && this.salesorderreservation.Notes !== null ? this.salesorderreservation.Notes.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            RecipientId: new DynamicLabel(
                'Recipient',
                getMetaItemValue(this.recipients, this.salesorderreservation && this.salesorderreservation.hasOwnProperty('RecipientId') && this.salesorderreservation.RecipientId !== null ? this.salesorderreservation.RecipientId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SalesOrderCreated: new DynamicLabel(
                'Sales Order Created',
                this.salesorderreservation && this.salesorderreservation.hasOwnProperty('SalesOrderCreated') && this.salesorderreservation.SalesOrderCreated !== null ? this.salesorderreservation.SalesOrderCreated : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
