import { Validators } from '@angular/forms';
import { IReferralTypeDynamicControlsParameters, ReferralTypeDynamicControls } from '@model/form-controls/referral-type.form-controls';
import { IReferralType } from '@model/interfaces/referral-type';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';

export class ReferralTypeDynamicControlsPartial extends ReferralTypeDynamicControls {
    constructor(ReferralTypePartial?: IReferralType, additionalParameters?: IReferralTypeDynamicControlsParameters) {
        super(ReferralTypePartial, additionalParameters);
        this.Form = {};
        this.Form.Name = new DynamicField({
            formGroup: this.formGroup,
            label: 'Name',
            name: 'Name',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.maxLength(50)],
            validators: { required: true, maxlength: 50 },
            value:
                ReferralTypePartial && ReferralTypePartial.hasOwnProperty('Name') && ReferralTypePartial.Name !== null
                    ? ReferralTypePartial.Name.toString()
                    : '',
        });
        this.Form.HasComments = new DynamicField({
            formGroup: this.formGroup,
            label: 'Has Follow Up Question',
            name: 'HasComments',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value:
                ReferralTypePartial && ReferralTypePartial.hasOwnProperty('HasComments') && ReferralTypePartial.HasComments !== null
                    ? ReferralTypePartial.HasComments
                    : false,
        });
        this.Form.Placeholder = new DynamicField({
            formGroup: this.formGroup,
            label: 'Follow Up Question',
            name: 'Placeholder',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.maxLength(50)],
            validators: { maxlength: 50 },
            value:
                ReferralTypePartial && ReferralTypePartial.hasOwnProperty('Placeholder') && ReferralTypePartial.Placeholder !== null
                    ? ReferralTypePartial.Placeholder.toString()
                    : '',
        });
        this.Form.OutOfUse = new DynamicField({
            formGroup: this.formGroup,
            label: 'Out Of Use',
            name: 'OutOfUse',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value:
                ReferralTypePartial && ReferralTypePartial.hasOwnProperty('OutOfUse') && ReferralTypePartial.OutOfUse !== null
                    ? ReferralTypePartial.OutOfUse
                    : false,
        });
    }
}
