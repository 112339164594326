import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { INotification } from '../interfaces/notification';
import { IApplicationStatus } from '../interfaces/application-status';
import { IDonorStatus } from '../interfaces/donor-status';
import { INotificationType } from '../interfaces/notification-type';

export interface INotificationDynamicControlsParameters {
    formGroup?: string;
    applicationStatuses?: IApplicationStatus[];
    donorStatuses?: IDonorStatus[];
    notificationTypes?: INotificationType[];
}

export class NotificationDynamicControls {

    formGroup: string;
    applicationStatuses: IApplicationStatus[];
    donorStatuses: IDonorStatus[];
    notificationTypes: INotificationType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private notification?: INotification, additionalParameters?: INotificationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Notification';
        this.applicationStatuses = additionalParameters && additionalParameters.applicationStatuses || undefined;
        this.donorStatuses = additionalParameters && additionalParameters.donorStatuses || undefined;
        this.notificationTypes = additionalParameters && additionalParameters.notificationTypes || undefined;

        this.Form = {
            ApplicationStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Application Status',
                name: 'ApplicationStatusId',
                options: this.applicationStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.notification && this.notification.ApplicationStatusId || null,
            }),
            Body: new DynamicField({
                formGroup: this.formGroup,
                label: 'Body',
                name: 'Body',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.notification && this.notification.hasOwnProperty('Body') && this.notification.Body !== null ? this.notification.Body.toString() : '',
            }),
            DonorStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor Status',
                name: 'DonorStatusId',
                options: this.donorStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.notification && this.notification.DonorStatusId || null,
            }),
            Editable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Editable',
                name: 'Editable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.notification && this.notification.Editable || 0,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.notification && this.notification.hasOwnProperty('Name') && this.notification.Name !== null ? this.notification.Name.toString() : '',
            }),
            NotificationTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notification Type',
                name: 'NotificationTypeId',
                options: this.notificationTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.notification && this.notification.NotificationTypeId || null,
            }),
            ScheduleCadence: new DynamicField({
                formGroup: this.formGroup,
                label: 'Schedule Cadence',
                name: 'ScheduleCadence',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.notification && this.notification.hasOwnProperty('ScheduleCadence') && this.notification.ScheduleCadence !== null ? this.notification.ScheduleCadence.toString() : '',
            }),
            Subject: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subject',
                name: 'Subject',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.notification && this.notification.hasOwnProperty('Subject') && this.notification.Subject !== null ? this.notification.Subject.toString() : '',
            }),
        };

        this.View = {
            ApplicationStatusId: new DynamicLabel(
                'Application Status',
                getMetaItemValue(this.applicationStatuses, this.notification && this.notification.hasOwnProperty('ApplicationStatusId') && this.notification.ApplicationStatusId !== null ? this.notification.ApplicationStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Body: new DynamicLabel(
                'Body',
                this.notification && this.notification.hasOwnProperty('Body') && this.notification.Body !== null ? this.notification.Body.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            ),
            DonorStatusId: new DynamicLabel(
                'Donor Status',
                getMetaItemValue(this.donorStatuses, this.notification && this.notification.hasOwnProperty('DonorStatusId') && this.notification.DonorStatusId !== null ? this.notification.DonorStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Editable: new DynamicLabel(
                'Editable',
                this.notification && this.notification.Editable || 0,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Name: new DynamicLabel(
                'Name',
                this.notification && this.notification.hasOwnProperty('Name') && this.notification.Name !== null ? this.notification.Name.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            NotificationTypeId: new DynamicLabel(
                'Notification Type',
                getMetaItemValue(this.notificationTypes, this.notification && this.notification.hasOwnProperty('NotificationTypeId') && this.notification.NotificationTypeId !== null ? this.notification.NotificationTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ScheduleCadence: new DynamicLabel(
                'Schedule Cadence',
                this.notification && this.notification.hasOwnProperty('ScheduleCadence') && this.notification.ScheduleCadence !== null ? this.notification.ScheduleCadence.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Subject: new DynamicLabel(
                'Subject',
                this.notification && this.notification.hasOwnProperty('Subject') && this.notification.Subject !== null ? this.notification.Subject.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
