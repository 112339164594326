import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IProductType } from '@model/interfaces/product-type';
import { IRecipientCycleStatus } from '@model/interfaces/recipient-cycle-status';
import {
    IRecipientCycleAddDynamicControlsParameters,
    RecipientCycleAddDynamicControlsPartial,
} from '@model/partials/recipient-cycle-add-partial.form-controls';
import { INewRecipientCycleDto } from '@model/interfaces/custom/new-recipient-cycle-dto';

export class RecipientCycleAddDynamicConfig<T extends INewRecipientCycleDto> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(
        private recipientCycle: T,
        private productTypes: IProductType[],
        private statuses: IRecipientCycleStatus[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IRecipientCycleAddDynamicControlsParameters = {
            productTypes: this.productTypes,
            statuses: this.statuses,
        };
        const dynamicControls = new RecipientCycleAddDynamicControlsPartial(this.recipientCycle, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['ProductTypeId', 'StatusId'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
