import { DynamicLabel, DynamicField } from '@mt-ng2/dynamic-form';
import { IApplicationStatus } from '@model/interfaces/application-status';
import { ApplicationDynamicControls } from '@model/form-controls/application.form-controls';
import { IApplication } from '@model/interfaces/application';

export interface IApplicationReivewDynamicControlsPartialParameters {
    formGroup?: string;
    applicationStatus?: IApplicationStatus;
    applicationStatuses?: IApplicationStatus[];
}

export class ApplicationReivewDynamicControlsPartial extends ApplicationDynamicControls {
    formGroup: string;
    applicationStatus: IApplicationStatus;

    constructor(applicationPartial?: IApplication, additionalParameters?: IApplicationReivewDynamicControlsPartialParameters) {
        super(applicationPartial, additionalParameters);

        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'Application';
        this.applicationStatus = (additionalParameters && additionalParameters.applicationStatus) || undefined;

        (<DynamicField>this.Form.DonorId).doNotCreateControl = true;
        (<DynamicField>this.Form.ApplicationStatusId).labelHtml = '<label>Current Application Stage</label>';

        (<DynamicLabel>this.View.DonorId).value = applicationPartial.DonorId || '';
        (<DynamicLabel>this.View.ApplicationStatusId).value = applicationPartial.ApplicationStatusId
            ? this.applicationStatuses.find((status) => status.Id === applicationPartial.ApplicationStatusId).Name
            : '';
        (<DynamicLabel>this.View.ApplicationStatusId).label = 'Current Application Stage';
        (<DynamicLabel>this.View.DenialReasonId).value = applicationPartial.DenialReason?.Name;
    }
}
