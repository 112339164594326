import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IGeneticScreening } from '@model/interfaces/genetic-screening';
import { GeneticScreeningDynamicControlsPartial } from '@model/partials/genetic-screening-partial.form-controls';
import { IAcceptedRejectedOption } from '@model/interfaces/accepted-rejected-option';
export class GeneticScreeningDynamicConfig<T extends IGeneticScreening> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(AcceptedRejectedOptions: IAcceptedRejectedOption[], geneticScreening: IGeneticScreening, private configControls?: string[]) {
        super();
        const dynamicControls = new GeneticScreeningDynamicControlsPartial(AcceptedRejectedOptions, geneticScreening);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'GeneticScreeningAcceptedRejected',
                'GeneticScreeningAcceptedRejectedBy',
                'GeneticScreeningAcceptedRejectedDate',
                'GeneticsLabsAcceptedRejected',
                'GeneticsLabsApprovedBy',
                'GeneticsLabsApprovedDate',
                'GeneticsLabsPerformedByDate',
                'GeneticsLabsReceivedDate',
                'GeneticsLabsSentDate',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(),
        };
    }
}
