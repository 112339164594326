import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IClinicVerification } from '../interfaces/clinic-verification';

export interface IClinicVerificationDynamicControlsParameters {
    formGroup?: string;
}

export class ClinicVerificationDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private clinicverification?: IClinicVerification, additionalParameters?: IClinicVerificationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ClinicVerification';

        this.Form = {
            IsClinicVerified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Clinic Verified',
                name: 'IsClinicVerified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.clinicverification && this.clinicverification.hasOwnProperty('IsClinicVerified') && this.clinicverification.IsClinicVerified !== null ? this.clinicverification.IsClinicVerified : false,
            }),
            PAndEReceivedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'P And E Received Date',
                name: 'PAndEReceivedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.clinicverification && this.clinicverification.PAndEReceivedDate || null,
            }),
            PAndESentDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'P And E Sent Date',
                name: 'PAndESentDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.clinicverification && this.clinicverification.PAndESentDate || null,
            }),
            VerifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Verified By',
                name: 'VerifiedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.clinicverification && this.clinicverification.VerifiedById || null,
            }),
            VerifiedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Verified Date',
                name: 'VerifiedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.clinicverification && this.clinicverification.VerifiedDate || null,
            }),
        };

        this.View = {
            IsClinicVerified: new DynamicLabel(
                'Is Clinic Verified',
                this.clinicverification && this.clinicverification.hasOwnProperty('IsClinicVerified') && this.clinicverification.IsClinicVerified !== null ? this.clinicverification.IsClinicVerified : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            PAndEReceivedDate: new DynamicLabel(
                'P And E Received Date',
                this.clinicverification && this.clinicverification.PAndEReceivedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            PAndESentDate: new DynamicLabel(
                'P And E Sent Date',
                this.clinicverification && this.clinicverification.PAndESentDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            VerifiedById: new DynamicLabel(
                'Verified By',
                this.clinicverification && this.clinicverification.VerifiedById || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            VerifiedDate: new DynamicLabel(
                'Verified Date',
                this.clinicverification && this.clinicverification.VerifiedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
        };

    }
}
