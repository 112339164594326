export enum TemplateTypes {
    PlanningCalendarEmail = 22,
    RecipientWelcomeEmail = 23,
    ShippingConfirmationEmail = 24,
    ShippingScheduledEmail = 25,
    ShippingDeliveredEmail = 26,
    InitialVerificationEmail = 27,
    ShippingConfirmationEmailUnverified = 28,
    RemoteVerificationDetailEmail = 29,
    ReverificationEmail = 31,
    SpermTransportToTWEB = 32,
}
