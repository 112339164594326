import { Component, ChangeDetectorRef, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IOccytesControlValue } from '@model/interfaces/custom/oocytes-control-value';
import { CustomFormControlComponentBase, DynamicField } from '@mt-ng2/dynamic-form';

import { NumericControlTypes } from '@mt-ng2/numeric-control';

interface IFormValue {
    MiiNumber: number;
    MiNumber: number;
    GvNumber: number;
    AtreticBrokenAbnormalNumber: number;
}

@Component({
    styleUrls: ['../../override-bootstrap.less'],
    templateUrl: './oocytes-control.component.html',
})
export class OocytesControlComponent extends CustomFormControlComponentBase {
    wrapperForm: FormGroup;
    dateField: DynamicField;
    disabled = false;

    currentValue: IOccytesControlValue;
    numericControlTypes = NumericControlTypes;

    constructor(changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();

        let value: IOccytesControlValue;
        if (this.config.value) {
            value = JSON.parse(this.config.value as string);
        } else {
            value = {
                AtreticBrokenAbnormalNumber: null,
                GvNumber: null,
                MiiNumber: null,
                MiNumber: null,
                OocytesReceived: null,
            };
        }

        this.currentValue = value;

        this.wrapperForm = this.formBuilder.group({
            AtreticBrokenAbnormalNumber: this.formBuilder.control(value.AtreticBrokenAbnormalNumber, [Validators.min(0)]),
            GvNumber: this.formBuilder.control(value.GvNumber, [Validators.min(0)]),
            MiiNumber: this.formBuilder.control(value.MiiNumber, [Validators.min(0)]),
            MiNumber: this.formBuilder.control(value.MiNumber, [Validators.min(0)]),
        });

        // wiring up the disabled property to propagate to our controls
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                const isDisabled = status === 'DISABLED';
                if (isDisabled && !this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.disable();
                    }, 0);
                    this.disabled = true;
                } else if (!isDisabled && this.disabled) {
                    setTimeout(() => {
                        this.wrapperForm.enable();
                    }, 0);
                    this.disabled = false;
                }
            }),
        );

        // subscribe to form value changes
        this.subscriptions.add(
            this.wrapperForm.valueChanges.subscribe((value) => {
                this.formValueChanged(value);
            }),
        );
    }

    formValueChanged(value: IFormValue): void {
        this.currentValue = Object.assign(value, {
            OocytesReceived: this.getOocytesTotal(value),
        });
        this.getControl().patchValue(JSON.stringify(this.currentValue));
    }

    private getOocytesTotal(value: IFormValue): number {
        return value.AtreticBrokenAbnormalNumber + value.GvNumber + value.MiiNumber + value.MiNumber;
    }
}
