export enum ManagedLists {
    TestingCompanies = 1,
    RetrievalPhysicians,
    VitMethods,
    VitDeviceTypes,
    VitMediaTypes,
    KitLotNumber,
    IncubatorNumbers,
    ProductTypes,
    Shippers,
    SpermTypes,
    DeviceLotNumbers,
    ReferralTypes,
}
