<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Notifications"></mt-page-title>
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="notificationTypes" entity="Notification Type" (onSelectionChanged)="filterSelectionChanged()">
    </mt-search-filter-select>
    <results-per-page (onSizeChange)="onPageSizeChange($event)"></results-per-page>
</div>

<entity-list
    [entities]="notifications"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (onPageChanged)="getNotifications()"
    (onItemSelected)="notificationSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>

<!-- <div *ngIf="canAddNotification" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/notifications', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div> -->
