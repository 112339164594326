import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { setMax } from '@common/libraries/dynamic-field.library';
import { IVwDonorAvailableInventory } from '@model/interfaces/vw-donor-available-inventory';

@Component({
    selector: 'donor-search-result',
    template: `
        <div>
            <b>Name:</b>
            <span>{{ donor.FirstName }} {{ donor.LastName }}</span>
            <button type="button" class="btn btn-sm fa fa-trash pull-right" (click)="deleteDonor()"></button>
        </div>
        <div>
            <b>Donor Id:</b>
            <span>{{ donor.DonorId }}</span>
        </div>
        <div>
            <b>Email:</b>
            <span>{{ donor.Email }}</span>
        </div>
        <div>
            <b>Eggs Available:</b>
            <span>{{ donor.AvailableInventory }}</span>
        </div>
        <div>
            <b>Potential Families:</b>
            <span>{{ donor.PotentialFamilies }}</span>
        </div>
        <div *ngIf="abstractSalesOrderReservationDonorControls">
            <dynamic-field [field]="abstractSalesOrderReservationDonorControls.EggCountReserved" [form]="form" [overrideForm]="true"></dynamic-field>
        </div>
        <div></div>
    `,
})
export class DonorSearchResultComponent implements OnInit {
    @Input() donor: IVwDonorAvailableInventory;
    @Input() index: number;
    @Input() form: FormGroup;
    @Input() dynamicControls: any;

    @Output() onRemoveDonor = new EventEmitter<number>();

    abstractSalesOrderReservationDonorControls: any;
    reservationForm: FormGroup;

    constructor() {}

    ngOnInit(): void {
        this.getControls();
    }

    getControls(): void {
        this.abstractSalesOrderReservationDonorControls = new this.dynamicControls(
            { DonorId: this.donor.Id, Id: 0 },
            {
                formGroup: 'DonorSearchDetails',
            },
        ).Form;
    }

    deleteDonor(): void {
        this.onRemoveDonor.emit(this.index);
    }
}
