<div *ngIf="formCreated">
    <form class="padded row" [formGroup]="inventoryVesselForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12">
                <h4>Inventory Vessel</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dynamic-field [field]="abstractInventoryVesselControls.Name" [form]="inventoryVesselForm"></dynamic-field>
                <dynamic-field [field]="abstractInventoryVesselControls.Number" [form]="inventoryVesselForm"></dynamic-field>
            </div>
        </div>
        <div class="row col-md-12">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </div>
    </form>
</div>
