import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IDonorBasicInfo } from '@model/interfaces/donor-basic-info';

export const emptyDonorBasicInfo: IDonorBasicInfo = {
    ApplicantPhoneNumber: null,
    EmergencyContactName: null,
    EmergencyContactNumber: null,
    DateCreated: new Date(),
    Dob: null,
    HasTextCommunication: false,
    OptedOutOfEmails: false,
    Height: null,
    HeightUnitId: null,
    Id: 0,
    ReferralTypeId: null,
    Ssn: null,
    Weight: null,
    WeightUnitId: null,
    GenderId: null,
};

@Injectable({
    providedIn: 'root',
})
export class DonorBasicInfoService extends BaseService<IDonorBasicInfo> {
    constructor(public http: HttpClient) {
        super('/donor-basic-info', http);
    }

    getEmptyDonorBasicInfo(): IDonorBasicInfo {
        return { ...emptyDonorBasicInfo };
    }
}
