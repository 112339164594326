import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IUser } from '@model/interfaces/user';
import { CommonService } from '@common/services/common.service';
import { UserRegionDynamicControlsPartial } from '@model/partials/user-region-partial.form-controls';
import { forkJoin } from 'rxjs';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { IUserRegion } from '@model/interfaces/user-region';
import { UserService } from '../user.service';

@Component({
    selector: 'app-user-regions',
    templateUrl: './user-regions.component.html',
})
export class UserRegionsComponent implements OnInit {
    @Input() canEdit: boolean;
    @Input() user: IUser;
    // abstract controls
    abstractUserRegionControls: any;

    userRegionsForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;

    userRegions: IUserRegion[];

    isEditing: boolean;

    accountManagerRegions: ICountryMetaItem[];
    states: IStateMetaItem[];

    showSelectUsStates: boolean;
    showSelectAuStates: boolean;

    constructor(
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private commonService: CommonService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.commonService.getCountries(), this.commonService.getAllStates()]).subscribe(([]) => {
            this.accountManagerRegions = this.commonService.getAccountManagerRegions();
            this.states = this.commonService.getAllStateMetaItems();
            this.userRegions = this.user.UserRegions;
            if (this.userRegions.length) {
                this.determineFormFields();
            }
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.userRegionsForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractUserRegionControls = new UserRegionDynamicControlsPartial(this.userRegions, {
            formGroup: 'UserRegion',
            regions: this.accountManagerRegions,
            regionStates: this.states,
            users: null,
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            UserRegion: this.fb.group({}),
        });
    }

    determineFormFields(): void {
        const regionStates = this.userRegions.filter((region) => region.StateCode);
        if (regionStates.some((r) => r.CountryCode === 'US')) {
            this.showSelectUsStates = true;
        }
        if (regionStates.some((r) => r.CountryCode === 'AU')) {
            this.showSelectAuStates = true;
        }
    }

    edit(): void {
        this.isEditing = true;
    }

    onCountryChanged(value): void {
        if (value) {
            const regions = this.accountManagerRegions.filter((itm) => value.includes(itm.Id));
            this.showSelectUsStates = regions && regions.find((itm) => itm.CountryCode === 'US') ? true : false;
            this.showSelectAuStates = regions && regions.find((itm) => itm.CountryCode === 'AU') ? true : false;
        } else {
            this.showSelectUsStates = false;
            this.showSelectAuStates = false;
        }
    }

    formSubmitted(): void {
        if (this.userRegionsForm.valid) {
            this.userRegions = this.assignFormValues(this.userRegionsForm.value.UserRegion);
            this.userService.saveUserRegions(this.user.Id, this.userRegions).subscribe((answer) => {
                this.success();
            });
        } else {
            markAllFormFieldsAsTouched(this.userRegionsForm);
            this.error();
            this.enableDoubleClick();
        }
    }

    assignFormValues(value: any): IUserRegion[] {
        let auStates, usStates;
        if (this.showSelectAuStates) {
            auStates = this.parseStateFormValues(value.AuStateCodes);
        }
        if (this.showSelectUsStates) {
            usStates = this.parseStateFormValues(value.UsStateCodes);
        }
        return [].concat(this.parseCountryFormValues(value.CountryCodes), auStates, usStates).filter((itm) => itm);
    }

    parseCountryFormValues(values: number[]): IUserRegion[] {
        if (!values) {
            return [];
        }
        return this.accountManagerRegions
            .filter((itm) => values.includes(itm.Id))
            .map((region) => {
                return {
                    CountryCode: region.CountryCode,
                    Id: 0,
                    UserId: this.user.Id,
                };
            });
    }

    parseStateFormValues(values: number[]): IUserRegion[] {
        if (!values) {
            return [];
        }
        return this.states
            .filter((itm) => values.includes(itm.Id))
            .map((region) => {
                return {
                    CountryCode: region.CountryCode,
                    Id: 0,
                    StateCode: region.StateCode,
                    UserId: this.user.Id,
                };
            });
    }

    displayRegionNames(regions: IUserRegion[]): string[] {
        let strArray = [];
        const countries = regions.filter((itm) => itm.CountryCode && !itm.StateCode);
        countries.forEach((country) => {
            let regionStr = '';
            regionStr += `${this.parseCountryName(country)}`;
            const states = regions.filter((itm) => itm.CountryCode === country.CountryCode && itm.StateCode);
            if (states.length) {
                regionStr += ` (${states
                    .map((state) => {
                        return this.parseStateName(state);
                    })
                    .join(', ')}) `;
            }
            strArray.push(regionStr);
        });
        return strArray;
    }

    parseCountryName(region: IUserRegion): string {
        return this.accountManagerRegions.find((itm) => itm.CountryCode === region.CountryCode).Name;
    }

    parseStateName(region: IUserRegion): string {
        return this.states.find((itm) => itm.CountryCode === region.CountryCode && itm.StateCode === region.StateCode).Name;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error('Save failed. Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Account manager regions saved successfully.');
        this.enableDoubleClick();
        this.isEditing = false;
    }
}
