import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import { LoginOldStyleComponent, ForgotPasswordOldStyleComponent, ResetPasswordOldStyleComponent, AdminAccessComponent } from '@mt-ng2/login-module';

import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { PathNotFoundResolveService } from './default-routes/path-not-found-resolve.service';
import { appPaths } from './default-routes/app-paths.library';
import { OverrriddenDashboardComponent } from './dashboard/overridden-dashboard.component';

const homeRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Donors,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    isHomePage: true,
};

const appRoutes: Routes = [
    { path: 'login', component: LoginOldStyleComponent },
    { path: 'forgotpassword', component: ForgotPasswordOldStyleComponent },
    { path: 'forgotusername', component: ForgotPasswordOldStyleComponent },
    { path: 'resetpassword', component: ResetPasswordOldStyleComponent },
    { path: 'adminaccess', component: AdminAccessComponent },
    {
        canActivate: [AuthGuard],
        component: OverrriddenDashboardComponent,
        data: homeRoleGuard,
        path: appPaths.home,
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: appPaths.home,
    },
    {
        loadChildren: './donors/donor.module#DonorModule',
        path: 'donors',
    },
    {
        loadChildren: './recipients/recipient.module#RecipientModule',
        path: 'recipients',
    },
    {
        loadChildren: './appointments/appointment.module#AppointmentModule',
        path: 'appointments',
    },
    {
        canActivate: [AuthGuard],
        component: NotFoundComponent,
        data: homeRoleGuard,
        path: '**',
        resolve: {
            path: PathNotFoundResolveService,
        },
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
})
export class AppRoutingModule {}
