import { Validators } from '@angular/forms';

import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes } from '@mt-ng2/dynamic-form';

import { IEmail } from './email.interface';

export interface IEmailDynamicControlsParameters {
    formGroup?: string;
}

export class EmailDynamicControls {
    formGroup: string;

    Form: any;
    View: any;

    constructor(private email?: IEmail, additionalParameters?: IEmailDynamicControlsParameters) {
        this.formGroup = (additionalParameters && additionalParameters.formGroup) || 'Email';

        this.Form = {
            Body: new DynamicField({
                formGroup: this.formGroup,
                label: 'Body',
                name: 'Body',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.WYSIWYG,
                    wysiwygOptions: {
                        height: 200,
                        toolbar: ['bold', 'italic', 'underline', 'strike', 'link', 'bullet-list', 'ordered-list'],
                    },
                }),
                validation: [Validators.maxLength(10000)],
                validators: { maxlength: 10000 },
                value: this.email && this.email.hasOwnProperty('Body') && this.email.Body !== null ? this.email.Body.toString() : '',
            }),
            Subject: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subject',
                name: 'Subject',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.maxLength(100)],
                validators: { maxlength: 100 },
                value: this.email && this.email.hasOwnProperty('Subject') && this.email.Subject !== null ? this.email.Subject.toString() : '',
            }),
            To: new DynamicField({
                formGroup: this.formGroup,
                label: 'To',
                name: 'To',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [],
                validators: {},
                value: this.email && this.email.hasOwnProperty('To') && this.email.To !== null ? this.email.To.toString() : '',
            }),
        };

        this.View = {
            Body: new DynamicLabel(
                'Body',
                this.email && this.email.hasOwnProperty('Body') && this.email.Body !== null ? this.email.Body.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Subject: new DynamicLabel(
                'Subject',
                this.email && this.email.hasOwnProperty('Subject') && this.email.Subject !== null ? this.email.Subject.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            To: new DynamicLabel(
                'To',
                this.email && this.email.hasOwnProperty('To') && this.email.To !== null ? this.email.To.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };
        this.View.Body.valueHtml = `<div style="border:1px solid #776; padding: 5px;height: 200px; overflow-y:scroll;">${email.Body}</div>`;
    }
}
