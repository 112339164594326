import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { ExtraSearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { UserService } from '../user.service';
import { IUser } from '@model/interfaces/user';
import { ClaimTypes } from '@model/ClaimTypes';
import { UsersEntityListConfig } from './users.entity-list-config';
import { entityListModuleConfig } from '@common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { UserRoles } from '@model/enums/user-roles.enum';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    searchControl = new FormControl();
    users: IUser[];
    currentPage = 1;
    query = '';
    total: number;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    canAddUser = false;

    entityListConfig = new UsersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(private userService: UserService, private claimsService: ClaimsService, private router: Router) {}

    ngOnInit(): void {
        this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);

        this.getUsers();
    }

    getUsers(): void {
        const search = this.query;
        const searchEntity: IEntitySearchParams = {
            extraParams: [
                new ExtraSearchParams({
                    name: 'ExcludeRoleIds',
                    valueArray: [UserRoles.Donor],
                }),
            ],
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.userService.get(searchparams).subscribe((answer) => {
            this.total = +answer.headers.get('X-List-Count');
            this.users = answer.body;
        });
    }

    search(query: string): void {
        this.query = query;
        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/users', event.entity.Id]);
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.getUsers();
    }
}
