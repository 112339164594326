import { IReportGroupOption } from '../model/interfaces/report-group-option';

export class ReportGroupOption implements IReportGroupOption {
    DatapointPropertyName: string;
    Name: string;
    GroupOrder: number;
    Id: number;

    constructor(reportGroupOption: IReportGroupOption) {
        Object.assign(this, reportGroupOption);
    }
}

export enum ToggleGroups {
    Add = 1,
    Remove = 2,
}
