import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';

import { IAggregateType } from '../model/interfaces/aggregate-type';

@Injectable()
export class AggregateTypesService extends StaticMetaItemService<IAggregateType> {
    constructor(public http: HttpClient) {
        super('AggregateTypesService', 'Aggregate Type', 'AggregateTypeIds', '/reports/aggregate-types', http);
    }
}
