import { IReport } from '../model/interfaces/report';
import { IReportColumn } from '../model/interfaces/report-column';
import { AggregateTypes } from './report-aggregates.library';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { FrontEndFormats } from './datapoints.library';

export function getCanEdit(report: IReport, userId: number, userRoleId: number): boolean {
    if (report.OwnerId === userId) {
        return true;
    }
    if (!report.ReportAccessShares) {
        console.warn('getCanEdit: report.ReportAccessShares is null or undefined');
        return false;
    }
    return report.ReportAccessShares.some((item) => {
        return item.CanEdit && (item.UserId === userId || item.UserRoleId === userRoleId);
    });
}
