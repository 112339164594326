<div *ngIf="familyHistory.Id > 0 && metaData">
    <div class="form-section-border">
        <h3>Family History</h3>
    </div>

    <div *ngIf="familyHistory.MotherHistory">
        <individual-family-history
            [individual]="familyHistory.MotherHistory"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Maternal History"
            (onSave)="onSaved()"
        >
        </individual-family-history>
    </div>
    <h3 *ngIf="!familyHistory.MotherHistory">No Data To Display</h3>
    <br />

    <div *ngIf="familyHistory.FatherHistory">
        <individual-family-history
            [individual]="familyHistory.FatherHistory"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Paternal History"
            (onSave)="onSaved()"
        >
        </individual-family-history>
    </div>
    <h3 *ngIf="!familyHistory.FatherHistory">No Data To Display</h3>
    <br />

    <div *ngIf="familyHistory.MaternalGrandmotherHistory">
        <individual-family-history
            [individual]="familyHistory.MaternalGrandmotherHistory"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Maternal Grandmother History"
            (onSave)="onSaved()"
        >
        </individual-family-history>
    </div>
    <h3 *ngIf="!familyHistory.MaternalGrandmotherHistory">No Data To Display</h3>
    <br />

    <div *ngIf="familyHistory.MaternalGrandfatherHistory">
        <individual-family-history
            [individual]="familyHistory.MaternalGrandfatherHistory"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Maternal Grandfather History"
            (onSave)="onSaved()"
        >
        </individual-family-history>
    </div>
    <h3 *ngIf="!familyHistory.MaternalGrandfatherHistory">No Data To Display</h3>
    <br />

    <div *ngIf="familyHistory.PaternalGrandmotherHistory">
        <individual-family-history
            [individual]="familyHistory.PaternalGrandmotherHistory"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Paternal Grandmother History"
            (onSave)="onSaved()"
        >
        </individual-family-history>
    </div>
    <h3 *ngIf="!familyHistory.PaternalGrandmotherHistory">No Data To Display</h3>
    <br />

    <div *ngIf="familyHistory.PaternalGrandfatherHistory">
        <individual-family-history
            [individual]="familyHistory.PaternalGrandfatherHistory"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            sectionTitle="Paternal GrandfatherHistory"
            (onSave)="onSaved()"
        >
        </individual-family-history>
    </div>
    <h3 *ngIf="!familyHistory.PaternalGrandfatherHistory">No Data To Display</h3>
    <br />
    <div *ngFor="let sibling of familyHistory.SiblingFamilyHistories; let i = index">
        <individual-family-history
            [individual]="sibling"
            [metaData]="metaData"
            [heightUnits]="heightUnits"
            [weightUnits]="weightUnits"
            [siblingIndex]="i"
            sectionTitle="Sibling History"
            (onSave)="onSaved()"
        >
        </individual-family-history>
        <br />
    </div>
    <h3 *ngIf="!familyHistory.SiblingFamilyHistories.length">No Data To Display</h3>
</div>
