import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { IItemSelectedEvent } from '@mt-ng2/entity-list-module';

import { DonorService } from '../../../donors/services/donor.service';
import { DonorsEntityListConfig } from './donor-search.entity-list-config';
import { IVwDonorAvailableInventory } from '@model/interfaces/vw-donor-available-inventory';

@Component({
    selector: 'recipient-donor-search',
    templateUrl: './donor-search.component.html',
})
export class DonorSearchComponent implements OnInit {
    @Output() onDonorSelected = new EventEmitter<number>();
    donors: IVwDonorAvailableInventory[];
    currentPage = 1;
    itemsPerPage = 10;
    query = '';
    total: number;
    entityListConfig = new DonorsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    constructor(private donorService: DonorService) {}

    ngOnInit(): void {}

    getDonorsCall(): Observable<HttpResponse<IVwDonorAvailableInventory[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorService.getDonorInventory(searchparams);
    }

    getDonors(): void {
        // this is a search based list, if no query clear the list
        if (!this.query) {
            this.donors = null;
            return;
        }
        this.getDonorsCall().subscribe((answer) => {
            this.donors = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getDonors();
    }

    donorSelected(event: IItemSelectedEvent): void {
        this.onDonorSelected.emit(event.entity);
    }
}
