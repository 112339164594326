import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClinicService } from '../services/clinic.service';
import { IClinic } from '@model/interfaces/clinic';
import { ClaimTypes } from '@model/ClaimTypes';
import { getIdFromRoute } from '@common/libraries/get-id-from-route.library';
import { ClinicEntityTypeService } from '../services/clinic-entity-type.service';
import { IClinicEntityType } from '@model/interfaces/clinic-entity-type';
import { NoteTypes } from '@model/enums/note-types.enum';
import { DocumentEntityTypes } from '@model/enums/document-entity-types';

@Component({
    templateUrl: './clinic-detail.component.html',
})
export class ClinicDetailComponent implements OnInit {
    clinic: IClinic;
    canEdit: boolean;
    canAdd: boolean;

    clinicEntityTypes: IClinicEntityType[];
    clinicNoteType = NoteTypes.Clinics;

    claimType = ClaimTypes.Clinics;
    documentEntityType = DocumentEntityTypes.Clinics;

    constructor(
        private clinicService: ClinicService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
        private clinicEntityTypesService: ClinicEntityTypeService,
    ) {}

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Clinics, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        const id = getIdFromRoute(this.route, 'clinicId');
        if (id) {
            this.getClinicById(id);
            this.getClinicEntityTypes();
        } else {
            this.router.navigate(['clinics']); // if no id found, go back to list
        }
    }

    getClinicById(id: number): void {
        this.clinicService.getById(id).subscribe((clinic) => {
            if (clinic === null) {
                this.notificationsService.error('Clinic not found');
                this.router.navigate(['clinics']);
            }
            this.clinic = clinic;
        });
    }

    getClinicEntityTypes(): void {
        this.clinicEntityTypesService.getItems().subscribe((items) => (this.clinicEntityTypes = items));
    }
}
