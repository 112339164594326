import { Component, OnInit } from '@angular/core';
import { globalInjector } from '@common/libraries/global-injector.library';
import { UserRoles } from '@model/enums/user-roles.enum';
import { AuthService } from '@mt-ng2/auth-module';
import { DashboardComponent } from '@mt-ng2/dashboard';

@Component({
    selector: 'app-dashboard',
    template: `<div class="row">
            <div class="col-md-7">
                <h2>
                    <mt-page-title pageTitle="Dashboard"></mt-page-title>
                </h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-6">
                <app-recommended-page-list [recommendedRoutes]="recommendedRoutes" [label]="recommendedForUserLabel"></app-recommended-page-list>
            </div>
            <div *ngIf="userRoleRecommendedRoutes && userRoleRecommendedRoutes.length" class="col-lg-5 col-md-6">
                <app-recommended-page-list
                    [recommendedRoutes]="userRoleRecommendedRoutes"
                    [label]="recommendedForUserRoleLabel"
                ></app-recommended-page-list>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-lg-5 col-md-6" *ngIf="showAppointments">
                <app-appointments-mini-list [userId]="userId"></app-appointments-mini-list>
            </div>
        </div> `,
})
export class OverrriddenDashboardComponent extends DashboardComponent {
    showAppointments = true;
    userId: number;

    ngOnInit(): void {
        const authService = globalInjector.get(AuthService);
        this.showAppointments = authService.currentUser.getValue().CustomOptions.UserRoleId === UserRoles.DonorRecruiter;
        this.userId = authService.currentUser.getValue().Id;
        this.getRecommendedPages();
        this.getUserRoleRecommendedPages();
    }
}
