<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && notification.Id > 0"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Notification Info
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <app-dynamic-view [config]="config.viewOnly">
        <ng-container *ngIf="!isNotificationTypeText">
            <label>Body</label>
            <div style="border-style: solid; border-color: grey; border-width: 2px">
                <mt-wysiwyg-view [config]="noteWysiwygConfig" [(value)]="notification.Body"></mt-wysiwyg-view>
            </div>
        </ng-container>
    </app-dynamic-view>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Notification Info</h4>
    <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
        <ng-container *ngIf="!isNotificationTypeText">
            <label>Body</label>
            <mt-wysiwyg
                [config]="noteWysiwygConfig"
                (focus)="setBodyLength()"
                (blur)="showBodyLength = false"
                [(value)]="notification.Body"
                (contentChanged)="bodyContentChanged($event.text)"
            >
            </mt-wysiwyg>
            <span *ngIf="showBodyLength" [ngStyle]="{ color: bodyCurrentLength > bodyMaxLength ? 'red' : 'inherit' }">
                {{ bodyCurrentLength }}/{{ bodyMaxLength }}
            </span>
            <br />
        </ng-container>
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
    </app-dynamic-form>
</div>
