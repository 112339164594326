import { Component, OnInit } from '@angular/core';

import { IClinic } from '@model/interfaces/clinic';
import { ClinicService } from '../services/clinic.service';

@Component({
    templateUrl: './clinic-add.component.html',
})
export class ClinicAddComponent implements OnInit {
    clinic: IClinic;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private clinicService: ClinicService) {}

    ngOnInit(): void {
        this.clinic = this.clinicService.getEmptyClinic();
    }
}
