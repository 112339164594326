import { GeneticScreeningDynamicControls } from '@model/form-controls/genetic-screening.form-controls';
import { IAcceptedRejectedOption } from '@model/interfaces/accepted-rejected-option';
import { IAuditedDate } from '@model/interfaces/custom/audited-date';
import { IGeneticScreening } from '@model/interfaces/genetic-screening';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';

export class GeneticScreeningDynamicControlsPartial extends GeneticScreeningDynamicControls {
    constructor(AcceptedRejectedOptions: IAcceptedRejectedOption[], geneticScreening: IGeneticScreening) {
        super();
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejected).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejected).options = AcceptedRejectedOptions;
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejected).value = geneticScreening.GeneticScreeningAcceptedRejected
            ? AcceptedRejectedOptions[0].Id
            : AcceptedRejectedOptions[1].Id;
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejected).labelHtml = '<label>Genetic Screening Accepted / Rejected</label><br/>';

        (<DynamicField>this.Form.GeneticsLabsAcceptedRejected).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        (<DynamicField>this.Form.GeneticsLabsAcceptedRejected).options = AcceptedRejectedOptions;
        (<DynamicField>this.Form.GeneticsLabsAcceptedRejected).value = geneticScreening.GeneticsLabsAcceptedRejected
            ? AcceptedRejectedOptions[0].Id
            : AcceptedRejectedOptions[1].Id;
        (<DynamicField>this.Form.GeneticsLabsAcceptedRejected).labelHtml = '<label>Genetics Labs Accepted / Rejected</label><br/>';

        (<DynamicField>this.Form.GeneticsLabsSentDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticsLabsSentDate).value = geneticScreening.GeneticsLabsSentDate
            ? this.parseDatewithAudit(geneticScreening.GeneticsLabsSentDate)
            : null;

        (<DynamicField>this.Form.GeneticsLabsReceivedDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticsLabsReceivedDate).value = geneticScreening.GeneticsLabsReceivedDate
            ? this.parseDatewithAudit(geneticScreening.GeneticsLabsReceivedDate)
            : null;

        (<DynamicField>this.Form.GeneticsLabsPerformedByDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticsLabsPerformedByDate).value = geneticScreening.GeneticsLabsPerformedByDate
            ? this.parseDatewithAudit(geneticScreening.GeneticsLabsPerformedByDate)
            : null;

        (<DynamicField>this.Form.GeneticsLabsApprovedDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticsLabsApprovedDate).value = geneticScreening.GeneticsLabsApprovedDate
            ? this.parseDatewithAudit(geneticScreening.GeneticsLabsApprovedDate)
            : null;

        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedDate).value = geneticScreening.GeneticScreeningAcceptedRejectedDate
            ? this.parseDatewithAudit(geneticScreening.GeneticScreeningAcceptedRejectedDate)
            : null;

        (<DynamicField>this.Form.GeneticConsultSentToCounselorDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticConsultSentToCounselorDate).value = geneticScreening.GeneticConsultSentToCounselorDate
            ? this.parseDatewithAudit(geneticScreening.GeneticConsultSentToCounselorDate)
            : null;

        (<DynamicField>this.Form.GeneticConsultPerformedDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticConsultPerformedDate).value = geneticScreening.GeneticConsultPerformedDate
            ? this.parseDatewithAudit(geneticScreening.GeneticConsultPerformedDate)
            : null;

        (<DynamicField>this.Form.GeneticConsultReportReceivedDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticConsultReportReceivedDate).value = geneticScreening.GeneticConsultReportReceivedDate
            ? this.parseDatewithAudit(geneticScreening.GeneticConsultReportReceivedDate)
            : null;

        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedDate).labelHtml = '<label>Genetics Labs Accepted / Rejected Date</label><br/>';

        (<DynamicField>this.Form.GeneticsLabsApprovedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticsLabsApprovedBy).value = geneticScreening.GeneticsLabsApprovedBy;

        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).value = geneticScreening.GeneticScreeningAcceptedRejectedBy;
        (<DynamicField>this.Form.GeneticScreeningAcceptedRejectedBy).labelHtml = '<label>Genetics Labs Accepted / Rejected By</label><br/>';

        (<DynamicField>this.Form.GeneticConsultReportReceivedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticConsultReportReceivedBy).value = geneticScreening.GeneticConsultReportReceivedBy;
        (<DynamicField>this.Form.GeneticConsultReportReceivedBy).labelHtml = '<label>Genetic Consult Report Received By</label><br/>';

        (<DynamicField>this.Form.CarrierTestingResultsReceivedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.CarrierTestingResultsReceivedBy).value = geneticScreening.CarrierTestingResultsReceivedBy;
        (<DynamicField>this.Form.CarrierTestingResultsReceivedBy).labelHtml = '<label>Carrier Testing Results Received By</label><br/>';

        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedBy).value = geneticScreening.CarrierTestingAcceptedRejectedBy;
        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedBy).labelHtml = '<label>Carrier Testing Accepted/Rejected By</label><br/>';

        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedBy).type.inputType = InputTypes.Textbox;
        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedBy).value = geneticScreening.GeneticConsultAcceptedRejectedBy;
        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedBy).labelHtml = '<label>Genetic Consult Accepted/Rejected By</label><br/>';

        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedDate).value = geneticScreening.GeneticConsultAcceptedRejectedDate
            ? this.parseDatewithAudit(geneticScreening.GeneticConsultAcceptedRejectedDate)
            : null;
        (<DynamicField>this.Form.GeneticConsultAcceptedRejectedDate).labelHtml = '<label>Genetic Consult Accepted/Rejected Date</label><br/>';

        (<DynamicField>this.Form.CarrierTestingResultsReceivedDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.CarrierTestingResultsReceivedDate).value = geneticScreening.CarrierTestingResultsReceivedDate
            ? this.parseDatewithAudit(geneticScreening.CarrierTestingResultsReceivedDate)
            : null;

        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedDate).value = geneticScreening.CarrierTestingAcceptedRejectedDate
            ? this.parseDatewithAudit(geneticScreening.CarrierTestingAcceptedRejectedDate)
            : null;
        (<DynamicField>this.Form.CarrierTestingAcceptedRejectedDate).labelHtml = '<label>Carrier Testing Accepted/Rejected Date</label><br/>';

        (<DynamicField>this.Form.CarrierTestingDrawDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });
        (<DynamicField>this.Form.CarrierTestingDrawDate).value = geneticScreening.CarrierTestingDrawDate
            ? this.parseDatewithAudit(geneticScreening.CarrierTestingDrawDate)
            : null;

        (<DynamicField>this.Form.GeneticConsultAcceptedRejected).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        (<DynamicField>this.Form.GeneticConsultAcceptedRejected).options = AcceptedRejectedOptions;
        (<DynamicField>this.Form.GeneticConsultAcceptedRejected).value = geneticScreening.GeneticConsultAcceptedRejected
            ? AcceptedRejectedOptions[0].Id
            : AcceptedRejectedOptions[1].Id;
        (<DynamicField>this.Form.GeneticConsultAcceptedRejected).labelHtml = '<label>Genetic Consult Accepted/Rejected</label><br/>';

        (<DynamicField>this.Form.CarrierTestingAcceptedRejected).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
        });
        (<DynamicField>this.Form.CarrierTestingAcceptedRejected).options = AcceptedRejectedOptions;
        (<DynamicField>this.Form.CarrierTestingAcceptedRejected).value = geneticScreening.CarrierTestingAcceptedRejected
            ? AcceptedRejectedOptions[0].Id
            : AcceptedRejectedOptions[1].Id;
        (<DynamicField>this.Form.CarrierTestingAcceptedRejected).labelHtml = '<label>Carrier Testing Accepted/Rejected</label><br/>';
    }

    private parseDatewithAudit(value: string): Date {
        if (value === null) {
            return null;
        }
        const auditDate: IAuditedDate = JSON.parse(value);
        return auditDate.Date ? auditDate.Date : null;
    }
}
