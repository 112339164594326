import { IEmailDto } from '@model/interfaces/custom/email-dto';

export function parseEmailAttachment(email: IEmailDto): FormData {
    email.AttachmentInfo = email.Attachments.map((a) => a._file.name);
    const files: File[] = email.Attachments.map((a) => a._file);
    let formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i], files[i].name);
    }
    delete email.Attachments;
    formData.append('Email', JSON.stringify(email));
    return formData;
}
