import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { ICurrentReportUser } from '@mt-ng2/advanced-reporting-module-config';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { ReportsService } from '../services/reports.service';
import { IReport } from '../model/interfaces/report';
import { getCanEdit } from '../libraries/reports.library';
import { AdvancedReportingModuleConfig } from '../services/advanced-reporting-module-config.service';

@Component({
    selector: 'report-actions',
    template: `
        <ng-container *ngIf="report?.Id">
            <span
                *ngIf="showEditButton"
                (click)="edit($event)"
                class="btn btn-xs btn-transparent"
                [style.visibility]="canEdit ? null : 'hidden'"
                title="edit"
            >
                <i class="fa fa-lg fa-pencil fa-fw"></i>
            </span>
            <span *ngIf="showRenderButton" (click)="render($event)" class="btn btn-xs btn-transparent" title="render">
                <i class="fa fa-lg fa-play fa-fw"></i>
            </span>
            <report-access-shares [report]="report"></report-access-shares>
            <email-report-button [reportId]="report.Id"></email-report-button>
            <download-report-button [reportId]="report.Id"></download-report-button>
            <copy-report-button *ngIf="canAdd" (onCopy)="rebind.emit(null)" [reportId]="report.Id"></copy-report-button>
            <toggle-archived
                [entity]="report"
                [service]="reportsService"
                (onToggleArchived)="archiveToggled()"
                [style.visibility]="canEdit ? null : 'hidden'"
            >
            </toggle-archived>
        </ng-container>
    `,
})
export class MyReportActionsComponent implements IEntityListDynamicCellComponent {
    entityListComponentMembers: IEntityListComponentMembers;

    set entity(value: IEntity) {
        this.report = value as IReport;
    }

    private _report: IReport;
    @Input('report')
    set report(value: IReport) {
        this._report = value;
        this.setPermissions();
    }
    get report(): IReport {
        return this._report;
    }

    @Input() showEditButton = true;
    @Input() showRenderButton = false;

    canEdit = false;
    canAdd = false;

    @Output() rebind: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public reportsService: ReportsService,
        public route: ActivatedRoute,
        private router: Router,
        private claimsService: ClaimsService,
        private reportingModuleConfig: AdvancedReportingModuleConfig,
        private notificationsService: NotificationsService,
    ) {}

    setPermissions(): void {
        this.canAdd = this.claimsService.hasClaim(this.reportingModuleConfig.reportingClaimTypeId, [ClaimValues.FullAccess]);

        const report = this.report;
        if (!(report?.OwnerId && report?.ReportAccessShares)) {
            this.canEdit = false;
            return;
        }
        const user = this.getCurrentReportUser();
        this.canEdit = getCanEdit(report, user.Id, user.RoleId);
    }

    edit(event: Event): void {
        event?.stopPropagation();
        this.router.navigate([`/my-reports/${this.report.Id}/edit`]);
    }

    render(event: Event): void {
        event?.stopPropagation();

        this.router.navigate([`/my-reports/${this.report.Id}`]);
    }

    private getCurrentReportUser(): ICurrentReportUser {
        return this.route.snapshot.data.currentUser;
    }

    archiveToggled(): void {
        this.notificationsService.success(`Report successfully ${this.report.Archived ? 'un-archived' : 'archived'}`);
        this.rebindList();
    }

    rebindList(): void {
        this.entityListComponentMembers?.getEntities();
        this.rebind.emit(null);
    }
}
