import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IAppointment } from '@model/interfaces/appointment';
import { ILabelGenerator } from '@model/interfaces/custom/ILabelGenerator';
import { ExtraSearchParams, IEntityExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { AppointmentService } from '../services/appointment.service';
import { AppointmentLabelGenerator } from './appointment-label-generator';

@Component({
    selector: 'app-appointments-mini-list',
    template: ` <app-mini-dashboard-list *ngIf="appointments" [canEdit]="false" noOfItemsToShow="5"
            [labelGenerator]="labelGenerator" [totalItems]="appointmentCount" cardName="Appointments"
            [items]="appointments" (onSelectItem)="selectAppointment($event)"
            (onSeeAll)="seeAllAppointments()"
    ></app-mini-dashboard-list>`,
})
export class AppointmentsMiniListComponent {

    @Input() userId: number;
    appointments: IAppointment[];
    appointmentCount: number;

    constructor(
        private router: Router,
        private appointmentService: AppointmentService,
        protected labelGenerator: AppointmentLabelGenerator,
    ) { }

    ngOnInit(): void {
        this.getAppointments();
    }

    getAppointments(): void {
        const search = '';
        const _extraSearchParams: ExtraSearchParams[] = [
            new ExtraSearchParams({ name: 'AssignedUserIds', valueArray: [] } as IEntityExtraSearchParams),
        ];

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'StartTimeUtc',
            orderDirection: 'asc',
            query: search,
            skip: 0,
            take: 10,
        };

        const searchparams = new SearchParams(searchEntity);

        this.appointmentService.get(searchparams).subscribe((answer) => {
            this.appointments = answer.body;
            this.appointmentCount = +answer.headers.get('X-List-Count');
        });
    }

    seeAllAppointments(): void {
        this.router.navigate(['appointments'], { queryParams: { userId: this.userId } });
    }

    selectAppointment(appt: IAppointment): void {
        this.router.navigate(['appointments', appt.Id]);
    }

}
