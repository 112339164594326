import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { ApplicationDataService, ICurrentSection } from './services/application-data.service';
import { Subscription } from 'rxjs';
import { safeDetectChanges } from '@common/libraries/safe-detect-changes.library';
import { IApplication } from '@model/interfaces/application';
import { DenialReasons } from '@model/enums/denial-reasons.enum';
import { IModalOptions, ModalService } from '@mt-ng2/modal-module';
import { Router } from '@angular/router';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { UserService } from '../../users/user.service';

@Component({
    selector: 'app-application',
    styles: [''],
    templateUrl: 'application-data.component.html',
})
export class ApplicationDataComponent implements OnInit {
    @Input() application: IApplication;

    donor?: ILoggedIn;

    private _currentSection: ICurrentSection;
    set currentSection(val: ICurrentSection) {
        this._currentSection = val;
    }
    get currentSection(): ICurrentSection {
        return this._currentSection;
    }
    isEditing = false;
    subscription: Subscription = new Subscription();

    tabNames = {
        preScreening: 'tab-preScreening',
        basicsComplete: 'tab-basicsComplete',
        familyHistory: 'tab-familyHistory',
        medicalIssues: 'tab-medicalIssues',
        whoAreYou: 'tab-whoAreYou',
        introduceYourSelf: 'tab-introduceYourself',
    };
    tabsLoaded: boolean;
    tabNameSectionNameMap = {
        'tab-preScreening': 'Pre-screening',
        'tab-basicsComplete': 'Basics Complete',
        'tab-familyHistory': 'Who Are You?',
        'tab-medicalIssues': 'Medical Issues',
        'tab-whoAreYou': 'Who Are You?',
        'introduceYourself': 'Introduce Yourself?',
    };

    activeTab = 'tab-preScreening';

    DenialReasonsMapping = {
        [DenialReasons.BMI]: 'BMI is too high',
        [DenialReasons.Country]: 'Donor lives outside US or Canada, or is not a U.S. Citizen or Green Card Holder',
        [DenialReasons.HIV]: 'Donor has HIV',
        [DenialReasons.Age]: 'Donor is over 32 years old',
        [DenialReasons.Duplicate]: 'Donor\'s SSN already found in the system',
        [DenialReasons.Manual]: 'Manually denied',
        [DenialReasons.Gender]: 'Donor is the wrong gender',
        [DenialReasons.STD]: 'Donor has an STD',
        [DenialReasons.Ovaries]: 'Donor does not have both ovaries',
        [DenialReasons.PreviouslyScreened]: 'Donor was previously screened',
        [DenialReasons.Education]: 'Level of Education',
    };

    constructor(
        private userService: UserService,
        private applicationService: ApplicationDataService,
        private cdr: ChangeDetectorRef,
        private modalService: ModalService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.tabsLoaded = false;

        this.userService.getById(this.application.UserId).subscribe((d) => {
            this.donor = {
                Id: d.Id,
                AuthId: d.AuthUser.Id,
                Name: d.AuthUser.Username,
                Email: d.Email,
                ClaimValues: '',
                ProfileImagePath: '',
                CustomOptions: { FirstName: d.FirstName, MiddleInitial: d.MiddleInitial, LastName: d.LastName },
            };

            this.subscription.add(
                this.applicationService.currentSection.subscribe((val: ICurrentSection) => {
                    this.currentSection = val;
                    safeDetectChanges(this.cdr);
                }),
            );
        },
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ValidateAndSubmitApplication(): void {
        const options: IModalOptions = {
            showConfirmButton: true,
            showDenyButton: true,
            text: 'This cannot be undone',
            title: 'Are you sure you want to submit this application on behalf of the applicant?',
            type: 'question',
        };
        this.modalService.showModal(options).subscribe((confirmed) => {
            if (confirmed.isConfirmed) {
                this.applicationService.adminSubmitApplication(this.application.Id).subscribe(
                    (donorId) => {
                        this.router.navigate(['donors', donorId]);
                    },
                    (error) => {
                        console.log(error);
                        let errorText = error.error?.Message ? error.error.Message : error.error;
                        const errorModalOptions: IModalOptions = {
                            showCancelButton: false,
                            showCloseButton: true,
                            showConfirmButton: false,
                            showDenyButton: false,
                            text: errorText,
                            title: `Unable to submit`,
                            type: 'info',
                        };
                        this.modalService.showModal(errorModalOptions).subscribe((val) => { });
                    },
                );
            }
        });
    }
}
