import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';

@Injectable({ providedIn: 'root' })
export class ApplicationSsnService {
    constructor(private httpClient: HttpClient) {}

    getApplicationSsn(id: number): Observable<string> {
        return this.httpClient.get<string>(`/api/v1/application-ssn/${id}`);
    }

    updateApplicationSsn(id: number, data: IApplicationWhoAreYou): Observable<IApplicationWhoAreYou> {
        return this.httpClient.post<IApplicationWhoAreYou>(`/api/v1/application-ssn/${id}`, data);
    }
}
