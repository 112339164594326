import { Component, Input, OnInit } from '@angular/core';
import { FormSections } from '@model/enums/form-sections.enum';
import { IApplicationFamilyHistory } from '@model/interfaces/application-family-history';
import { ICurrentSection, ApplicationDataService } from '../../services/application-data.service';
import { IApplication } from '@model/interfaces/application';
import { FamilyHistoryDynamicConfig } from './family-history.dynamic-config';
import { forkJoin } from 'rxjs';
import { UnitsOfMeasurementService } from '@common/services/units-of-measurement.service';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { ApplicationService } from '../../../services/application.service';

@Component({
    selector: 'family-history',
    templateUrl: './family-history.component.html',
})
export class AppFamilyHistoryComponent implements OnInit {
    @Input() familyHistory: IApplicationFamilyHistory;
    @Input() application: IApplication;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: FamilyHistoryDynamicConfig<IApplicationFamilyHistory>;
    doubleClickIsDisabled = false;

    hasSiblings: boolean;
    metaData: IPersonalInformationMetaData;
    heightUnits: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];

    currentSection: ICurrentSection = {
        formGroup: null,
        nextTab: null,
        nextTabLabel: 'Submit Donor',
        previousTab: 'tab-whoAreYou',
        previousTabLabel: 'Who Are You',
        routerLink: null,
        sectionId: FormSections.FamilyHistory,
    };

    constructor(
        private applicationService: ApplicationDataService,
        private unitsOfMeasurementService: UnitsOfMeasurementService,
        private appService: ApplicationService,
    ) {}

    ngOnInit(): void {
        forkJoin([this.applicationService.getPersonalInformationMetaData(), this.unitsOfMeasurementService.getItems()]).subscribe(
            (forkJoinResponses) => {
                const [metaData] = forkJoinResponses;
                this.metaData = metaData;
                this.heightUnits = this.unitsOfMeasurementService.getHeightUnits();
                this.weightUnits = this.unitsOfMeasurementService.getWeightUnits();
                this.setConfig();
            },
        );
        this.hasSiblings = this.familyHistory && this.familyHistory.SiblingFamilyHistories.length > 0;
    }

    setConfig(): void {
        this.applicationService.currentSection.next(this.currentSection);
    }

    onSaved(): void {
        this.appService.emitChange(this.application);
    }
}
