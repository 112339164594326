<div class="miles-editable miles-card padded" *ngIf="!isEditing && clinic" (click)="edit()" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4>
        Embryologists
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div class="row">
        <div class="col-md-12">
            <span *ngIf="!clinic.Users || !clinic.Users.length"><i>No Embryologists Assigned</i></span>
            <div *ngIf="clinic.Users || clinic.Users.length">
                <div *ngFor="let user of clinic.Users">
                    <span>{{ user.FirstName }} {{ user.LastName }}</span>
                    <span *ngIf="user.EmbryologistDetails && user.EmbryologistDetails[0]?.DateVerified"
                        >- {{ user.EmbryologistDetails[0].DateVerified | date }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
<div [hidden]="!isEditing" class="miles-form padded">
    <form *ngIf="formCreated" [formGroup]="embryologistsForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12">
                <label>Embryologists</label>
                <mt-type-ahead
                    [items]="availableEmbryologists"
                    (selectionChanged)="selectionChanged($event)"
                    (ready)="typeAheadControlReady($event)"
                ></mt-type-ahead>
                <br />
                <ul class="list-group">
                    <span *ngIf="selectedEmbryologists.length < 1"><i>No Embryologists Assigned</i></span>
                    <div *ngIf="selectedEmbryologists.length >= 1">
                        <li *ngFor="let embryologist of selectedEmbryologists" class="list-group-item">
                            {{ embryologist.Name }}
                            <button
                                type="button"
                                class="btn-outline-danger pull-right"
                                [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                                (click)="removeEmbryologist(embryologist.Id)"
                            >
                                Remove
                            </button>
                        </li>
                    </div>
                </ul>
                <br />
            </div>
        </div>
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">Save</button>
    </form>
</div>
