<div [ngClass]="{ 'input-scaffold__group': anyIssuesSelected && subissues && subissues.length }">
    <div class="input-scaffold">
        <label class="input-label">
            <span class="input-scaffold__label">{{ medicalIssue.Name }}<span style="color: red"> *</span></span>
        </label>
        <multiselect
            [items]="relationshipTypes"
            [showSelectAllButtons]="false"
            [maxToShowInSelectedText]="5"
            [autoClose]="true"
            [filterMode]="false"
            entity="Relationships"
            (onSelectionChanged)="selectionChanged($event)"
        ></multiselect>
    </div>
    <div class="input-scaffold" *ngIf="anyIssuesSelected && subissues && subissues.length">
        <br />
        <label class="input-label">
            <span class="input-scaffold__label" style="color: red">Additional information required</span>
        </label>
        <div class="input-scaffold__group">
            <div class="input-scaffold" [style.padding-bottom]="'5px'">
                <label class="input-label">
                    <span class="input-scaffold__label">{{ subissues[0].Name }}<span style="color: red"> *</span></span>
                </label>
                <multiselect
                    [items]="subissueRelationshipTypes[0]"
                    [showSelectAllButtons]="false"
                    [maxToShowInSelectedText]="5"
                    [autoClose]="true"
                    [filterMode]="false"
                    entity="Relationships"
                    (onSelectionChanged)="subissueChanged($event, 0)"
                ></multiselect>
            </div>
            <div class="input-scaffold" [style.padding-bottom]="'5px'" *ngIf="subissues[1]">
                <label class="input-label">
                    <span class="input-scaffold__label">{{ subissues[1].Name }}<span style="color: red"> *</span></span>
                </label>
                <multiselect
                    [items]="subissueRelationshipTypes[1]"
                    [showSelectAllButtons]="false"
                    [maxToShowInSelectedText]="5"
                    [autoClose]="true"
                    [filterMode]="false"
                    entity="Relationships"
                    (onSelectionChanged)="subissueChanged($event, 1)"
                ></multiselect>
            </div>
        </div>
        <br />
    </div>
</div>
