import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IApplicationStatus } from '@model/interfaces/application-status';

@Injectable({
    providedIn: 'root',
})
export class ApplicationStatusService extends StaticMetaItemService<IApplicationStatus> {
    constructor(public http: HttpClient) {
        super('ApplicationStatusService', 'Status', 'StatusIds', '/options/applicationStatuses', http);
    }
}
