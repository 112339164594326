import { Component, OnInit } from '@angular/core';

import { INotification } from '@model/interfaces/notification';
import { NotificationService } from '../../common/services/notification.service';

// Admin cannot create new notification as of 1-28-2020

@Component({
    templateUrl: './notification-add.component.html',
})
export class NotificationAddComponent implements OnInit {
    notification: INotification;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private notificationService: NotificationService) {}

    ngOnInit(): void {
        // this.notification = this.notificationService.getEmptyNotification();
    }
}
