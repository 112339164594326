<div *ngIf="formCreated">
    <div class="form-section-border">
        <h3>Introduce Yourself</h3>
    </div>
    <form [formGroup]="introduceYourselfForm" (ngSubmit)="formSubmitted()">
        <dynamic-field [field]="abstractApplicationIntroduceYourselfControls.TalentsAbilities" [form]="introduceYourselfForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationIntroduceYourselfControls.Favorites" [form]="introduceYourselfForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationIntroduceYourselfControls.CharacterDescription" [form]="introduceYourselfForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationIntroduceYourselfControls.Goals" [form]="introduceYourselfForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationIntroduceYourselfControls.ExerciseRoutine" [form]="introduceYourselfForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationIntroduceYourselfControls.WhyInterestedInBecomingEggDonor" [form]="introduceYourselfForm"></dynamic-field>
        <dynamic-field [field]="abstractApplicationIntroduceYourselfControls.LanguagesSpoken" [form]="introduceYourselfForm"></dynamic-field>
        <div class="row col-md-12">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
            <button *ngIf="showApprovalButton" type="button" Class="btn btn-flag btn-danger" (click)="approveClick()">Approve</button>
        </div>
    </form>
</div>
