import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IEmbryologistDetail } from '../interfaces/embryologist-detail';
import { IUser } from '../interfaces/user';

export interface IEmbryologistDetailDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
}

export class EmbryologistDetailDynamicControls {

    formGroup: string;
    users: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private embryologistdetail?: IEmbryologistDetail, additionalParameters?: IEmbryologistDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'EmbryologistDetail';
        this.users = additionalParameters && additionalParameters.users || undefined;

        this.Form = {
            CertifiedTechnologies: new DynamicField({
                formGroup: this.formGroup,
                label: 'Certified Technologies',
                name: 'CertifiedTechnologies',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.embryologistdetail && this.embryologistdetail.hasOwnProperty('CertifiedTechnologies') && this.embryologistdetail.CertifiedTechnologies !== null ? this.embryologistdetail.CertifiedTechnologies.toString() : '',
            }),
            DateVerified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Verified',
                name: 'DateVerified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.embryologistdetail && this.embryologistdetail.DateVerified || null,
            }),
            IsActive: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Active',
                name: 'IsActive',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.embryologistdetail && this.embryologistdetail.hasOwnProperty('IsActive') && this.embryologistdetail.IsActive !== null ? this.embryologistdetail.IsActive : false,
            }),
            IsTrainer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Trainer',
                name: 'IsTrainer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.embryologistdetail && this.embryologistdetail.hasOwnProperty('IsTrainer') && this.embryologistdetail.IsTrainer !== null ? this.embryologistdetail.IsTrainer : false,
            }),
            IsVerified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Verified',
                name: 'IsVerified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.embryologistdetail && this.embryologistdetail.hasOwnProperty('IsVerified') && this.embryologistdetail.IsVerified !== null ? this.embryologistdetail.IsVerified : false,
            }),
            TestCompleted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Test Completed',
                name: 'TestCompleted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.embryologistdetail && this.embryologistdetail.hasOwnProperty('TestCompleted') && this.embryologistdetail.TestCompleted !== null ? this.embryologistdetail.TestCompleted : false,
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.embryologistdetail && this.embryologistdetail.UserId || null,
            }),
        };

        this.View = {
            CertifiedTechnologies: new DynamicLabel(
                'Certified Technologies',
                this.embryologistdetail && this.embryologistdetail.hasOwnProperty('CertifiedTechnologies') && this.embryologistdetail.CertifiedTechnologies !== null ? this.embryologistdetail.CertifiedTechnologies.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateVerified: new DynamicLabel(
                'Date Verified',
                this.embryologistdetail && this.embryologistdetail.DateVerified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            IsActive: new DynamicLabel(
                'Is Active',
                this.embryologistdetail && this.embryologistdetail.hasOwnProperty('IsActive') && this.embryologistdetail.IsActive !== null ? this.embryologistdetail.IsActive : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsTrainer: new DynamicLabel(
                'Is Trainer',
                this.embryologistdetail && this.embryologistdetail.hasOwnProperty('IsTrainer') && this.embryologistdetail.IsTrainer !== null ? this.embryologistdetail.IsTrainer : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsVerified: new DynamicLabel(
                'Is Verified',
                this.embryologistdetail && this.embryologistdetail.hasOwnProperty('IsVerified') && this.embryologistdetail.IsVerified !== null ? this.embryologistdetail.IsVerified : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            TestCompleted: new DynamicLabel(
                'Test Completed',
                this.embryologistdetail && this.embryologistdetail.hasOwnProperty('TestCompleted') && this.embryologistdetail.TestCompleted !== null ? this.embryologistdetail.TestCompleted : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            UserId: new DynamicLabel(
                'User',
                getMetaItemValue(this.users, this.embryologistdetail && this.embryologistdetail.hasOwnProperty('UserId') && this.embryologistdetail.UserId !== null ? this.embryologistdetail.UserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
