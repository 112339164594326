import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { ClinicDynamicControls, IClinicDynamicControlsParameters } from '@model/form-controls/clinic.form-controls';
import { IClinic } from '@model/interfaces/clinic';
import { IAddress } from '@model/interfaces/address';

export class ClinicDynamicConfig<T extends IClinic> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private clinic: T, private configControls?: string[]) {
        super();
        const additionalParams: IClinicDynamicControlsParameters = {};
        const dynamicControls = new ClinicDynamicControls(this.clinic, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'ApprovedForRetrievals'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
