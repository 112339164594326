import { IDataFormatOption } from '../model/interfaces/data-format-option';

export enum FrontEndFormats {
    Percentage = 1,
    Currency = 2,
    Decimal = 3,
    General = 4,
    Date_Short = 101,
    Date_Medium = 102,
    Date_Long = 103,
    Date_Full = 104,
    Date_ShortDate = 105,
    Date_MediumDate = 106,
    Date_LongDate = 107,
    Date_FullDate = 108,
    Date_ShortTime = 109,
    Date_MediumTime = 110,
    Date_LongTime = 111,
    Date_FullTime = 112,
    Phone = 113,
}

export function getColumnClass(format: IDataFormatOption): string | null {
    if (!format) {
        return null;
    }
    return format.Type === 'number' && format.FrontEndFormat !== FrontEndFormats.General ? 'report-number-align' : null;
}
