import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, SimpleChanges, OnChanges, NgModule } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { forkJoin, Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { AddressDynamicControls, IAddressDynamicControlsParameters } from '@model/partials/address.form-controls';
import { IAddress } from '@model/interfaces/address';
import { IAddress as IBaseAddress } from '@model/interfaces/base';
import { CommonService } from '../../services/common.service';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { SharedFunctionsService } from '@common/services/shared-functions-service';

@Component({
    selector: `address`,
    templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit {
    @Input() address: IAddress;
    @Input() isEditing: boolean;
    @Input() Address: FormGroup;
    @Input() canEdit: boolean;
    @Input() showButtons: boolean;
    @Input() showDelete: boolean;
    @Output() onAddressSubmitted: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
     @Output() onCancelClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSave: EventEmitter<IAddress> = new EventEmitter<IAddress>();
    @Output() onDelete: EventEmitter<IAddress> = new EventEmitter<IAddress>();

    denied = false;
    addressControls: any;

    formGroup = 'Address';

    doubleClickIsDisabled = false;
    formCreated = false;
    formSubscription: Subscription;
    countries: ICountryMetaItem[];
    states: IStateMetaItem[];
    subs = new Subscription();
    showStatesCurrentAddress$: Observable<boolean>;
    sharedFunctionsService: SharedFunctionsService;

    constructor(
        private fb: FormBuilder,
        private commonService: CommonService,
        private notificationService: NotificationsService,
    ) { }

    @HostListener('window:beforeunload', ['$event'])

    ///
    /// Subscribe to lists of countries and states then create the form
    ///
    ngOnInit(): void {

        forkJoin([this.commonService.getCountries(), this.commonService.getAllStates()]).subscribe(
            () => {
                this.countries = this.commonService.getCountryMetaItems();
                this.states = this.commonService.getAllStateMetaItems();
                this.sharedFunctionsService = new SharedFunctionsService();
                this.createForm();
            },
        );
    }

    ///
    /// Confirm that initializtion of the address form group has completed.  This prevents errors during page initialization/refresh
    ///
    addressReady(): boolean {
        return this.Address !== undefined && this.Address.controls !== undefined && this.Address.controls.Address !== undefined;
    }

    ///
    /// Find a property-specific control and set its value
    ///
    setAddressFieldValue(fieldName: string, value: any): void {
        if (this.addressReady()) {
            const control = this.Address.controls.Address.get(fieldName);
            if (control) {
                control.setValue(value);
            }
        }
    }

    ///
    /// Setup form group controls and setup event emitter for changes
    ///
    createForm(): void {
        this.getControls();
        this.Address = this.assignFormGroups();
        this.formCreated = true;
        if (this.denied) {
            this.disableForm();
        } else {
            this.subs.add(
                this.Address.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                    if (this.Address.dirty) {
                        this.getAddress();
                    }
                }),
            );
        }
        this.onAddressSubmitted.emit(this.Address);
    }

    ///
    /// Disable the form group controls
    ///
    disableForm(): void {
        setTimeout(() => this.Address.disable());
    }

    ///
    /// Add dynamic controls for the address
    ///
    getControls(): void {

        const addressAdditionalParameters: IAddressDynamicControlsParameters = {
            addressTypeId: null,
            countries: this.countries,
            formGroup: this.formGroup,
            states: this.states,
        };

        this.addressControls = new AddressDynamicControls(this.address, addressAdditionalParameters);
    }

    ///
    /// When the country changes, refresh the list of states based on the country selection
    ///
    setStatesForCountry(data): void {
        this.filterStatesForCounty(data);
    }

    //
    // Return a list of country-specific states
    //
    filterStatesForCounty(countryId: number): void {
        const stateCodeControl = this.addressControls.Form.StateCode;
        const countryCode = this.sharedFunctionsService.getCountryCodeById(countryId, this.countries);
        let options = this.sharedFunctionsService.filterStatesForCounty(countryCode, this.states);
        if (options) {
            stateCodeControl.options = options;
        }
    }

    ///
    /// Setup event handler so that when the country changes, the State Code or Province is displayed based upon whether or not there are states available for  the selected country
    ///
    setShowStatesCurrentAddress(countryCodeControl: AbstractControl): void {
        this.showStatesCurrentAddress$ = countryCodeControl?.valueChanges.pipe(
            startWith(countryCodeControl.value ?? 0),
            map((value) => this.sharedFunctionsService.countryIdHasStatesList(value, this.countries, this.states)),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }

    ///
    /// Assign the form group to the Address Form Group
    assignFormGroups(): FormGroup {
        return this.fb.group({
            Address: this.fb.group({}),
        });
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(): void {
        this.notificationService.error('Save failed.  Please check the form and try again.');
    }

    ///
    /// When the submit button is clicked, update the current address and trigger the address-submitted event emmitter
    ///
    submit(): void {

        this.getAddress();
        this.onAddressSubmitted.emit(this.Address);
        this.onSave.emit(this.address);
    }

    ///
    /// When the cancel button is clicked, trigger the cancel-clicked event emmitter
    ///
    cancel(): void {
        this.onCancelClicked.emit();
    }

    delete(): void {
        this.getAddress();
        this.onDelete.emit(this.address);
    }

    ///
    /// Update the current address from the form group.  Tried to use the  Object.assign() function, but it was adding a property
    /// to this.address called Address - with everything inside that, instead of the individual property values.
    ///
    getAddress(): void {
        this.address.Address1 = this.Address.value.Address.Address1;
        this.address.Address2 = this.Address.value.Address.Address2;
        this.address.City = this.Address.value.Address.City;
        if (this.sharedFunctionsService.countryIdHasStatesList(this.Address.value.Address.CountryCode, this.countries, this.states)) {
            this.address.StateCode = this.sharedFunctionsService.getStateCodeById(this.Address.value.Address.StateCode, this.states);
        } else {
            this.address.Province = this.Address.value.Address.Province;
        }
        this.address.Zip = this.Address.value.Address.Zip;
        this.address.CountryCode = this.sharedFunctionsService.getCountryCodeById(this.Address.value.Address.CountryCode, this.countries);
    }

    refreshAddress(newAddress: IBaseAddress): void {
         this.address.Address1 = newAddress.Address1;
         this.address.Address2 = newAddress.Address2;
         this.address.City = newAddress.City;
         this.address.Province = newAddress.Province;
         this.address.StateCode = newAddress.StateCode;
         this.address.Zip = newAddress.Zip;
         this.address.CountryCode = newAddress.CountryCode;

         this.setAddressFieldValue('Address1', newAddress.Address1);
         this.setAddressFieldValue('Address2', newAddress.Address2);
         this.setAddressFieldValue('City', newAddress.City);
         this.setAddressFieldValue('Province', newAddress.Province);
         this.setAddressFieldValue('StateCode', this.sharedFunctionsService.getStateId(newAddress.CountryCode, this.countries, newAddress.StateCode, this.states));
         this.setAddressFieldValue('Zip', newAddress.Zip);
         this.setAddressFieldValue('CountryCode', this.sharedFunctionsService.getCountryIdByCode(newAddress.CountryCode, this.countries));
  }
}
