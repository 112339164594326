<div *ngIf="application">
    <div class="row">
        <div class="col-md-6">
            <app-application-basic-info [application]="application" [canEdit]="canEdit"></app-application-basic-info>
            <br />
            <app-application [application]="application"></app-application>
            <br />
        </div>
        <div class="col-md-6">
            <app-notes [entityType]="noteType" [entityId]="application.Id" [canEdit]="canEdit"></app-notes>
            <br />
        </div>
        <div class="col-md-6">
            <app-application-photo-upload [application]="application" [canEdit]="canEdit"></app-application-photo-upload>
            <br />
        </div>
        <div class="col-md-6">
            <app-application-photo-identity-upload [application]="application" [canEdit]="canEdit"></app-application-photo-identity-upload>
        </div>
    </div>
    <a routerLink="/applications" class="btn btn-default">Close</a>
</div>
