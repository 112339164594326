import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { NotificationService } from '../../common/services/notification.service';
import { NotificationTypeService } from '../services/notification-type.service';
import { INotification } from '@model/interfaces/notification';
import { entityListModuleConfig } from '@common/shared.module';
import { NotificationsEntityListConfig } from './notifications.entity-list-config';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
    notifications: INotification[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    notificationTypes: MtSearchFilterItem[] = [];
    entityListConfig = new NotificationsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddNotification = false;

    constructor(
        private notificationService: NotificationService,
        private notificationTypeService: NotificationTypeService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.notificationTypeService.getSearchFilterItems().subscribe((answer) => (this.notificationTypes = answer));
        this.getNotifications();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedNotificationTypeIds: number[] = this.getSelectedFilters(this.notificationTypes);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'NotificationTypeIds',
                valueArray: selectedNotificationTypeIds,
            }),
        );

        return _extraSearchParams;
    }

    getNotificationsCall(): Observable<HttpResponse<INotification[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.notificationService.get(searchparams);
    }

    getNotifications(): void {
        this.getNotificationsCall().subscribe((answer) => {
            this.notifications = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getNotifications();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getNotifications();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getNotifications();
    }

    notificationSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/notifications', event.entity.Id]);
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.getNotifications();
    }
}
