import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class NotificationsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessors: ['NotificationType', 'Name'],
                    name: 'Notification Type',
                }),
                new EntityListColumn({
                    accessors: ['ScheduleCadence'],
                    name: 'Schedule Cadence',
                }),
            ],
        };
        super(listConfig);
    }
}
