import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { ISalesOrderReservationDonor } from '../interfaces/sales-order-reservation-donor';
import { IDonor } from '../interfaces/donor';
import { ISalesOrderReservation } from '../interfaces/sales-order-reservation';

export interface ISalesOrderReservationDonorDynamicControlsParameters {
    formGroup?: string;
    salesOrderReservations?: ISalesOrderReservation[];
    donors?: IDonor[];
}

export class SalesOrderReservationDonorDynamicControls {

    formGroup: string;
    salesOrderReservations: ISalesOrderReservation[];
    donors: IDonor[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private salesorderreservationdonor?: ISalesOrderReservationDonor, additionalParameters?: ISalesOrderReservationDonorDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'SalesOrderReservationDonor';
        this.salesOrderReservations = additionalParameters && additionalParameters.salesOrderReservations || undefined;
        this.donors = additionalParameters && additionalParameters.donors || undefined;

        this.Form = {
            DonorId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor',
                name: 'DonorId',
                options: this.donors,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.salesorderreservationdonor && this.salesorderreservationdonor.DonorId || null,
            }),
            EggCountReserved: new DynamicField({
                formGroup: this.formGroup,
                label: 'Egg Count Reserved',
                name: 'EggCountReserved',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.salesorderreservationdonor && this.salesorderreservationdonor.EggCountReserved || null,
            }),
            SalesOrderReservationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order Reservation',
                name: 'SalesOrderReservationId',
                options: this.salesOrderReservations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.salesorderreservationdonor && this.salesorderreservationdonor.SalesOrderReservationId || null,
            }),
        };

        this.View = {
            DonorId: new DynamicLabel(
                'Donor',
                getMetaItemValue(this.donors, this.salesorderreservationdonor && this.salesorderreservationdonor.hasOwnProperty('DonorId') && this.salesorderreservationdonor.DonorId !== null ? this.salesorderreservationdonor.DonorId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            EggCountReserved: new DynamicLabel(
                'Egg Count Reserved',
                this.salesorderreservationdonor && this.salesorderreservationdonor.EggCountReserved || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            SalesOrderReservationId: new DynamicLabel(
                'Sales Order Reservation',
                getMetaItemValue(this.salesOrderReservations, this.salesorderreservationdonor && this.salesorderreservationdonor.hasOwnProperty('SalesOrderReservationId') && this.salesorderreservationdonor.SalesOrderReservationId !== null ? this.salesorderreservationdonor.SalesOrderReservationId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
