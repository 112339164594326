<div class="miles-card padded">
    <form (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12">
                <recipient-donor-search *ngIf="showDonorSearch" (onDonorSelected)="onDonorSelected($event)"></recipient-donor-search>
                <div *ngIf="!showDonorSearch">
                    <div>
                        <button type="button" Class="btn btn-flat btn-primary" (click)="showDonorSearch = true">Add Another Donor</button>
                    </div>
                    <br />
                </div>
            </div>
        </div>
        <div class="row" *ngIf="formReady">
            <div *ngFor="let donor of donorsControl; let i = index">
                <div class="col-md-4">
                    <donor-search-result
                        [donor]="donor.value"
                        [form]="donor"
                        [index]="i"
                        (onRemoveDonor)="deleteDonor($event)"
                        [dynamicControls]="donorDynamicControls"
                    ></donor-search-result>
                    <br />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <recipient-search *ngIf="!recipientSelected" (onRecipientSelected)="onRecipientSelected($event)"></recipient-search>
                <div *ngIf="recipientSelected">
                    <div>
                        <button type="button" Class="btn btn-flat btn-default" (click)="changeRecipient()">Change Recipient</button>
                    </div>
                    <div>
                        <b>Name:</b>
                        <span>{{ recipientSelected.FirstName }} {{ recipientSelected.LastName }}</span>
                    </div>
                    <div>
                        <b>Recipient Id:</b>
                        <span>{{ recipientSelected.Id }}</span>
                    </div>
                    <div>
                        <b>Email:</b>
                        <span>{{ recipientSelected.Email }}</span>
                    </div>
                    <br />
                </div>
            </div>
            <br />
        </div>
        <div class="row">
            <div class="col-md-3">
                <dynamic-field [field]="abstractSalesOrderReservationDonorControls.ExpirationDate" [form]="reservationForm"></dynamic-field>
            </div>
            <div class="col-md-9">
                <dynamic-field [field]="abstractSalesOrderReservationDonorControls.Notes" [form]="reservationForm"></dynamic-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                    Save
                </button>
            </div>
        </div>
    </form>
</div>
