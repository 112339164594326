<strong style="padding-left: 5px">
    <span>{{ medicalIssue.Name }}</span>
</strong>
<ul>
    <li *ngFor="let relationship of selectedRelationships">{{ relationship.Name }}</li>
</ul>
<div style="padding-left: 20px" *ngIf="subIssues && subIssues.length">
    <strong style="padding-left: 5px">
        <span>{{ subIssues[0].Name }}</span>
    </strong>
    <ul>
        <li *ngFor="let relationship of subissue1Relationships">{{ relationship.Name }}</li>
    </ul>
    <div style="padding-left: 20px" *ngIf="subIssues[1]">
        <strong style="padding-left: 5px">
            <span>{{ subIssues[1].Name }}</span>
        </strong>
        <ul>
            <li *ngFor="let relationship of subissue2Relationships">{{ relationship.Name }}</li>
        </ul>
    </div>
</div>
