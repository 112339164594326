import { Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { EventTypeService } from '../../services/event-types.service';
import { IAuditConfiguration } from '../../interfaces/audit-configuration';
import { IAuditLog, IAuditLogUser, IAuditLogDetail } from '@mt-ng2/audit-logging-module-config';
import { IEventType } from '../../interfaces/event-type';
import { map } from 'rxjs/operators';

enum EventTypes {
    Deleted = 1,
    SoftDeleted = 3,
}

@Component({
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    'max-height': 'inherit',
                    overflow: 'visible',
                    transition: 'max-height',
                }),
            ),
            state(
                'closed',
                style({
                    'max-height': '0',
                    overflow: 'hidden',
                    transition: 'max-height',
                }),
            ),
            transition('open => closed', [animate('500ms ease-in')]),
            transition('closed => open', [animate('500ms ease-out')]),
        ]),
    ],
    selector: 'app-audit-log-row',
    styleUrls: ['./audit-log-row.component.less'],
    templateUrl: './audit-log-row.component.html',
})
export class AuditLogRowComponent {
    @Input('log') log;
    @Input('auditConfigurations') auditConfigurations: IAuditConfiguration[];
    @Input('auditLogUsers') auditLogUsers: IAuditLogUser[];
    @Input('eventTypes') eventTypes: IEventType[];
    @Input() showLink = true;
    eventType: IEventType;

    constructor(private eventTypeService: EventTypeService) {}

    ngOnInit(): void {
        this.setEventType(this.log.EventType);
    }

    getType(auditLog: IAuditLog): string {
        const auditConfiguration = this.getAuditConfigurationFromLog(auditLog);
        return auditConfiguration
            ? auditConfiguration.DisplayName
            : auditLog.TypeFullName.replace('Model.', '')
                  .replace(/([A-Z])/g, ' $1')
                  .trim();
    }

    setEventType(eventTypeId: number): void {
        if (this.eventTypes) {
            this.eventType = this.eventTypes.find((et) => et.Id === eventTypeId);
        } else {
            this.eventTypeService
                .getItems()
                .pipe(map((items) => items.find((et) => et.Id === eventTypeId)))
                .subscribe((item) => (this.eventType = item));
        }
    }

    getUserName(auditLog: IAuditLog): any {
        let user: IAuditLogUser = this.auditLogUsers.find((umi) => umi.AuthUserId.toString() === auditLog.UserName);
        return user ? user.Name : '';
    }

    getLinkToRecord(log: IAuditLog): string {
        let link = '';
        if (log.EventType === EventTypes.Deleted) {
            return link;
        }
        if (this.auditConfigurations) {
            let auditConfiguration = this.getAuditConfigurationFromLog(log);
            if (log.EventType === EventTypes.SoftDeleted && auditConfiguration && auditConfiguration.HideArchivedLinks) {
                return link;
            }
            link = auditConfiguration ? auditConfiguration.LinkFormatString.replace('{0}', log.RecordId) : '';
        }
        return link;
    }

    getDetails(log: IAuditLog): IAuditLogDetail[] {
        if (!this.auditConfigurations) {
            return log.LogDetails;
        }
        let configIndex = this.auditConfigurations.findIndex((ac) => log.TypeFullName === ac.TypeFullName);
        let config: IAuditConfiguration = this.auditConfigurations[configIndex];
        let hideProperties = config && config.HideProperties ? config.HideProperties : [];
        let details = log.LogDetails.filter((ld) => {
            return hideProperties.indexOf(ld.PropertyName) === -1;
        });
        return details;
    }

    getAuditConfigurationFromLog(log: IAuditLog): IAuditConfiguration {
        if (this.auditConfigurations) {
            return this.auditConfigurations.find((ac) => ac.TypeFullName === log.TypeFullName);
        }
    }
}
