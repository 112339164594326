import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { safeDetectChanges } from '@common/libraries/safe-detect-changes.library';
import { ManagedLists } from '@model/enums/managed-lists.enum';
import { IExpandableObject } from '@model/expandable-object';
import { DeviceLotNumberDynamicControls } from '@model/form-controls/device-lot-number.form-controls';
import { IncubatorNumberDynamicControls } from '@model/form-controls/incubator-number.form-controls';
import { KitLotNumberDynamicControls } from '@model/form-controls/kit-lot-number.form-controls';
import { ProductTypeDynamicControls } from '@model/form-controls/product-type.form-controls';
import { RetrievalPhysicianDynamicControls } from '@model/form-controls/retrieval-physician.form-controls';
import { ShipperDynamicControls } from '@model/form-controls/shipper.form-controls';
import { SpermTypeDynamicControls } from '@model/form-controls/sperm-type.form-controls';

import { TestingCompanyDynamicControls } from '@model/form-controls/testing-company.form-controls';
import { VitDeviceTypeDynamicControls } from '@model/form-controls/vit-device-type.form-controls';
import { VitMediaTypeDynamicControls } from '@model/form-controls/vit-media-type.form-controls';
import { VitMethodDynamicControls } from '@model/form-controls/vit-method.form-controls';
import { DeviceLotNumberDynamicControlsPartial } from '@model/partials/device-lot-number-partial.form-controls';
import { KitLotNumberDynamicControlsPartial } from '@model/partials/kit-lot-number-partial.form-controls';
import { ReferralTypeDynamicControlsPartial } from '@model/partials/referral-type-partial.form-controls';
import { IMetaItem, MetaItemService } from '@mt-ng2/base-service';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { DeviceLotNumberService } from './services/device-lot-number.service';
import { IncubatorNumberService } from './services/incubator-number.service';
import { KitLotNumberService } from './services/kit-lot-number.service';
import { ProductTypeService } from './services/product-type.service';
import { ReferralTypeService } from './services/referral-types.service';
import { RetrievalPhysicianService } from './services/retrieval-physician.service';
import { ShipperService } from './services/shipper.service';
import { SpermTypeService } from './services/sperm-type.service';
import { TestingCompaniesService } from './services/testing-companies.service';
import { VitDeviceTypeService } from './services/vit-device-type.service';
import { VitMediaTypeService } from './services/vit-media-type.service';
import { VitMethodService } from './services/vit-method.service';

const ManagedList: IMetaItem[] = [
    { Id: ManagedLists.DeviceLotNumbers, Name: 'Device Lot Numbers' },
    {
        Id: ManagedLists.IncubatorNumbers,
        Name: 'Incubator Numbers',
    },
    {
        Id: ManagedLists.KitLotNumber,
        Name: 'Kit Lot Numbers',
    },
    {
        Id: ManagedLists.ProductTypes,
        Name: 'Product Types',
    },
    { Id: ManagedLists.ReferralTypes, Name: 'How Did You Hear About Us' },
    {
        Id: ManagedLists.RetrievalPhysicians,
        Name: 'Retrieval Physicians',
    },
    {
        Id: ManagedLists.Shippers,
        Name: 'Shippers',
    },
    { Id: ManagedLists.SpermTypes, Name: 'Sperm Types' },
    {
        Id: ManagedLists.TestingCompanies,
        Name: 'Testing Companies',
    },
    {
        Id: ManagedLists.VitMethods,
        Name: 'Vit Methods',
    },
    {
        Id: ManagedLists.VitDeviceTypes,
        Name: 'Vit Device Types',
    },
    {
        Id: ManagedLists.VitMediaTypes,
        Name: 'Vit Media Types',
    },
];

@Component({
    selector: 'managed-list.component',
    templateUrl: './managed-list.component.html',
})
export class ManagedListComponent implements OnInit {
    // forms
    testingCompaniesDynamicForm = new TestingCompanyDynamicControls(null).Form;
    retrievalPhysiciansDynamicForm = new RetrievalPhysicianDynamicControls(null).Form;
    vitMethodsDynamicForm = new VitMethodDynamicControls(null).Form;
    vitDeviceTypeDynamicForm = new VitDeviceTypeDynamicControls(null).Form;
    vitMediaTypeDynamicForm = new VitMediaTypeDynamicControls(null).Form;
    kitLotNumberDynamicForm = new KitLotNumberDynamicControlsPartial(null).Form;
    incubatorNumberDynamicForm = new IncubatorNumberDynamicControls(null).Form;
    productTypesDynamicForm = new ProductTypeDynamicControls(null).Form;
    shippersDynamicForm = new ShipperDynamicControls(null).Form;
    spermTypesDynamicForm = new SpermTypeDynamicControls(null).Form;
    deviceLotNumberDynamicForm = new DeviceLotNumberDynamicControlsPartial(null).Form;
    referralTypesDynamicForm = new ReferralTypeDynamicControlsPartial(null).Form;

    managedList: IMetaItem[];
    isLoaded = false;
    isListLoaded = false;
    fields: string[]; // control what fields populate, leave undefined for default;

    dynamicForm: IExpandableObject;
    title: string;
    service: MetaItemService<any>;
    selectedList: IMetaItem;
    items: any[];
    hiddenItems: any[];

    IncludeNotInUse = false;
    KitLotNumbersSelected = false;
    ReferralTypesSelected = false;

    get cleanTitle(): string {
        return this.title.replace(/ +/g, '');
    }

    constructor(
        public testingCompaniesService: TestingCompaniesService,
        public retrievalPhysiciansService: RetrievalPhysicianService,
        public vitMethodService: VitMethodService,
        public vitDeviceTypeService: VitDeviceTypeService,
        public vitMediaTypeService: VitMediaTypeService,
        public kitLotNumberService: KitLotNumberService,
        public incubatorNumberService: IncubatorNumberService,
        public productTypeService: ProductTypeService,
        public shipperService: ShipperService,
        public spermTypesService: SpermTypeService,
        public deviceLotNumberService: DeviceLotNumberService,
        public referralTypeService: ReferralTypeService,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
    ) {}

    ngOnInit(): void {
        this.managedList = ManagedList;
        this.isLoaded = true;
    }

    selectionChanged(item: IMetaItem): void {
        this.isListLoaded = false;
        safeDetectChanges(this.cdr);
        if (!item) {
            return;
        }
        this.title = item.Name;
        this.setManagedListForm(item.Id);
        this.selectedList = item;
    }

    setManagedListForm(id: number): void {
        this.fields = undefined;
        this.KitLotNumbersSelected = false;
        this.ReferralTypesSelected = false;
        switch (id) {
            case ManagedLists.TestingCompanies:
                this.service = this.testingCompaniesService;
                this.dynamicForm = this.testingCompaniesDynamicForm;
                break;
            case ManagedLists.RetrievalPhysicians:
                this.service = this.retrievalPhysiciansService;
                this.dynamicForm = this.retrievalPhysiciansDynamicForm;
                break;
            case ManagedLists.VitMethods:
                this.service = this.vitMethodService;
                this.dynamicForm = this.vitMethodsDynamicForm;
                break;
            case ManagedLists.VitDeviceTypes:
                this.service = this.vitDeviceTypeService;
                this.dynamicForm = this.vitDeviceTypeDynamicForm;
                break;
            case ManagedLists.VitMediaTypes:
                this.service = this.vitMediaTypeService;
                this.dynamicForm = this.vitMediaTypeDynamicForm;
                break;
            case ManagedLists.KitLotNumber:
                this.service = this.kitLotNumberService;
                this.dynamicForm = this.kitLotNumberDynamicForm;
                this.KitLotNumbersSelected = true;
                break;
            case ManagedLists.IncubatorNumbers:
                this.service = this.incubatorNumberService;
                this.dynamicForm = this.incubatorNumberDynamicForm;
                break;
            case ManagedLists.ProductTypes:
                this.service = this.productTypeService;
                this.dynamicForm = this.productTypesDynamicForm;
                this.fields = ['Name'];
                break;
            case ManagedLists.Shippers:
                this.service = this.shipperService;
                this.dynamicForm = this.shippersDynamicForm;
                break;
            case ManagedLists.SpermTypes:
                this.service = this.spermTypesService;
                this.dynamicForm = this.spermTypesDynamicForm;
                break;
            case ManagedLists.DeviceLotNumbers:
                this.service = this.deviceLotNumberService;
                this.dynamicForm = this.deviceLotNumberDynamicForm;
                break;
            case ManagedLists.ReferralTypes:
                this.service = this.referralTypeService;
                this.dynamicForm = this.referralTypesDynamicForm;
                this.ReferralTypesSelected = true;
                break;
            default:
                break;
        }
        this.getItems();
    }

    getItems(): void {
        this.service.getAll().subscribe((answer) => {
            this.filterItems(answer);
            this.isListLoaded = true;
        });
    }

    saveItems(form: any): void {
        if (form.valid) {
            this.service
                .updateItems([...form.value[this.cleanTitle], ...this.hiddenItems])
                .pipe(finalize(() => this.getItems()))
                .subscribe(() => {
                    this.notificationsService.success(`${this.title} saved successfully`);
                });
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    filterItems(items?: any[]): void {
        if (!items) {
            items = [...this.items, ...this.hiddenItems];
        }
        if ((this.KitLotNumbersSelected || this.ReferralTypesSelected) && !this.IncludeNotInUse) {
            this.items = items.filter((i) => !i.OutOfUse);
            this.hiddenItems = items.filter((i) => i.OutOfUse);
        } else {
            this.items = items;
            this.hiddenItems = [];
        }
    }
}
