<div *ngIf="formCreated">
    <form class="padded row" [formGroup]="salesOrderReservationForm" (ngSubmit)="formSubmitted()">
        <div class="row">
            <div class="col-md-12"><label>Recipient:</label> {{ reservation.Recipient.FirstName }} {{ reservation.Recipient.LastName }}</div>
            <br />
        </div>
        <div class="row">
            <div *ngFor="let formGroup of donorsControl">
                <div class="col-md-12">
                    <donor-edit-quantity [donor]="formGroup.value" [form]="formGroup"></donor-edit-quantity>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <dynamic-field [field]="abstractSalesOrderReservationControls.ExpirationDate" [form]="salesOrderReservationForm"></dynamic-field>
                <dynamic-field [field]="abstractSalesOrderReservationControls.Notes" [form]="salesOrderReservationForm"></dynamic-field>
            </div>
        </div>
        <div class="row col-md-12">
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </div>
    </form>
</div>
