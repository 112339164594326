import { NotificationDynamicControls, INotificationDynamicControlsParameters } from '../form-controls/notification.form-controls';
import { INotification } from '../interfaces/notification';
import { DynamicField, DynamicFieldTypes, DynamicLabel, InputTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { AppNotificationTypes } from '@model/enums/app-notification-types.enum';

export class NotificationDynamicControlsPartial extends NotificationDynamicControls {
    constructor(notificationPartial?: INotification, additionalParameters?: INotificationDynamicControlsParameters) {
        super(notificationPartial, additionalParameters);

        if (notificationPartial.NotificationTypeId !== AppNotificationTypes.DonorText) {
            (<DynamicField>this.Form.Body).type.fieldType = DynamicFieldTypes.Input;
            (<DynamicField>this.Form.Body).type.inputType = InputTypes.WYSIWYG;
            (<DynamicField>this.Form.Body).type.wysiwygOptions = {
                height: 200,
                toolbar: ['bold', 'italic', 'underline', 'link', 'bullet-list', 'ordered-list'],
            };
            (<DynamicField>this.Form.Body).validation = [Validators.maxLength(10000)];
            (<DynamicField>this.Form.Body).validators = { maxlength: 10000 };
        }
        if (notificationPartial.NotificationTypeId === AppNotificationTypes.DonorText) {
            (<DynamicField>this.Form.Body).type.fieldType = DynamicFieldTypes.Input;
            (<DynamicField>this.Form.Body).type.inputType = InputTypes.Textarea;
            (<DynamicField>this.Form.Body).validation = [Validators.maxLength(300)];
            (<DynamicField>this.Form.Body).validators = { maxlength: 300 };
        }
        (<DynamicLabel>(
            this.View.Body
        )).valueHtml = `<div style="border:1px solid #776; padding: 5px;height: 200px; overflow-y:scroll;">${notificationPartial.Body}</div>`;
    }
}
