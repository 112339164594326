<div class="miles-editable miles-card padded" *ngIf="!isEditing" (click)="edit()" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4>
        {{ clinicEntityType.Name }}
        <span class="pull-right" *ngIf="canEdit">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <div *ngIf="showContact()">
        <div><b>Contact: </b>{{ formatContactForView() }}</div>
        <div><b>Contact Email: </b>{{ clinicEntity?.ClinicContact?.Email || '' }}</div>
    </div>
    <div *ngIf="showAddress()"><b>Address: </b>{{ formattedAddress }}</div>
    <div *ngIf="showPhone()">
        <div *ngIf="phones && phones.length > 0">
            <div *ngFor="let phone of phones">
                <span *ngIf="phone.IsPrimary">
                    <b>Primary: </b>
                </span>
                <span *ngIf="!phone.IsPrimary && phone.PhoneType">
                    <b>{{phone.PhoneType.Name}}: </b>
                </span>               <span>
                    {{phone.Phone | phone }}
                </span>
            </div>
        </div>
        <div *ngIf="!phones || phones.length === 0">
            <b>Primary Phone: </b>
            <i>No Phone</i>
        </div>
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>
        {{ clinicEntityType.Name }}
        <button
            *ngIf="isLabShippingAddress || isBillingAddress"
            class="btn btn-primary header-button pull-right"
            title="Copy Clinic Address"
            (click)="copyClinicAddress(); $event.stopPropagation()"
        >
            Same as Clinic
        </button>
    </h4>
    <form *ngIf="formGroup && clinicEntityType" [formGroup]="formGroup" (ngSubmit)="formSubmitted()">
        <div *ngIf="showContact()">
            <dynamic-field
                [field]="abstractClinicContactControls.Form.FirstName"
                [form]="formGroup.controls.ClinicContact"
                [overrideForm]="true"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractClinicContactControls.Form.MiddleInitial"
                [form]="formGroup.controls.ClinicContact"
                [overrideForm]="true"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractClinicContactControls.Form.LastName"
                [form]="formGroup.controls.ClinicContact"
                [overrideForm]="true"
            ></dynamic-field>
            <dynamic-field
                [field]="abstractClinicContactControls.Form.Email"
                [form]="formGroup.controls.ClinicContact"
                [overrideForm]="true"
            ></dynamic-field>
        </div>
        <div *ngIf="this && this.clinicEntity && this.clinicEntity.Address">
            <address [address]="this.clinicEntity.Address"
                [isEditing]="isEditing"
                [canEdit]="canEdit"
                [Address]="formGroup.controls.Address"
                (onCancelClicked)="cancel()"
                [showButtons]="false" [showDelete]="false"
                >
            </address>
        </div>
        <div *ngIf="showPhone()">
            <label for="phoneNumbers">{{ isMedicalDirector ? 'Phone Numbers' : 'Phone Number' }}</label>
            <app-mt-phone [PhoneArray]="phones" [parentForm]="formGroup.controls.ClinicEntityPhones" [canAdd]="canAdd"></app-mt-phone>
            <br />
        </div>
        <button type="submit" class="btn btn-flat btn-success" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled">Save</button>
        <button type="button" class="btn btn-flat btn-default" (click)="cancel()">Cancel</button>
    </form>
</div>
