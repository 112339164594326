import { Injectable } from '@angular/core';

import { AngularCsv } from 'angular-csv-ext';

import { IExportOptions } from '../model/interfaces/export-options';

@Injectable()
export class ExportService {
    constructor() {}

    exportToCsv(data: any[], filename: string, options: IExportOptions = null): void {
        // tslint:disable-next-line:no-unused-expression
        new AngularCsv(data, filename, options);
    }
}
