import { Validators } from '@angular/forms';
import { DynamicField } from '@mt-ng2/dynamic-form';

export function setMax(field: DynamicField, value: number): void {
    field.validators.max = value;
    if (!field.validation) {
        field.validation = [];
    }
    const index = field.validation.indexOf(Validators.max);
    if (index > -1) {
        field.validation.splice(index, 1);
    }
    if (value) {
        field.validation.push(Validators.max(value));
    }
}
