import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IInventory } from '../interfaces/inventory';
import { IInventoryType } from '../interfaces/inventory-type';

export interface IInventoryDynamicControlsParameters {
    formGroup?: string;
    inventoryTypes?: IInventoryType[];
}

export class InventoryDynamicControls {

    formGroup: string;
    inventoryTypes: IInventoryType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private inventory?: IInventory, additionalParameters?: IInventoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Inventory';
        this.inventoryTypes = additionalParameters && additionalParameters.inventoryTypes || undefined;

        this.Form = {
            Comments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comments',
                name: 'Comments',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.inventory && this.inventory.hasOwnProperty('Comments') && this.inventory.Comments !== null ? this.inventory.Comments.toString() : '',
            }),
            CountPerStraw: new DynamicField({
                formGroup: this.formGroup,
                label: 'Count Per Straw',
                name: 'CountPerStraw',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.CountPerStraw || null,
            }),
            GobletId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Goblet',
                name: 'GobletId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.GobletId || null,
            }),
            InventoryTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Inventory Type',
                name: 'InventoryTypeId',
                options: this.inventoryTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.inventory && this.inventory.InventoryTypeId || 1,
            }),
            ReserveForResearch: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reserve For Research',
                name: 'ReserveForResearch',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.hasOwnProperty('ReserveForResearch') && this.inventory.ReserveForResearch !== null ? this.inventory.ReserveForResearch : false,
            }),
            RetrievalDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Retrieval Date',
                name: 'RetrievalDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.RetrievalDate || null,
            }),
            StrawCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Straw Count',
                name: 'StrawCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.StrawCount || null,
            }),
            StrawNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Straw Number',
                name: 'StrawNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.inventory && this.inventory.StrawNumber || 0,
            }),
        };

        this.View = {
            Comments: new DynamicLabel(
                'Comments',
                this.inventory && this.inventory.hasOwnProperty('Comments') && this.inventory.Comments !== null ? this.inventory.Comments.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            CountPerStraw: new DynamicLabel(
                'Count Per Straw',
                this.inventory && this.inventory.CountPerStraw || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            GobletId: new DynamicLabel(
                'Goblet',
                this.inventory && this.inventory.GobletId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            InventoryTypeId: new DynamicLabel(
                'Inventory Type',
                getMetaItemValue(this.inventoryTypes, this.inventory && this.inventory.hasOwnProperty('InventoryTypeId') && this.inventory.InventoryTypeId !== null ? this.inventory.InventoryTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReserveForResearch: new DynamicLabel(
                'Reserve For Research',
                this.inventory && this.inventory.hasOwnProperty('ReserveForResearch') && this.inventory.ReserveForResearch !== null ? this.inventory.ReserveForResearch : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            RetrievalDate: new DynamicLabel(
                'Retrieval Date',
                this.inventory && this.inventory.RetrievalDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            StrawCount: new DynamicLabel(
                'Straw Count',
                this.inventory && this.inventory.StrawCount || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            StrawNumber: new DynamicLabel(
                'Straw Number',
                this.inventory && this.inventory.StrawNumber || 0,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };

    }
}
