import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IUserRegion } from '../interfaces/user-region';
import { IUser } from '../interfaces/user';

export interface IUserRegionDynamicControlsParameters {
    formGroup?: string;
    users?: IUser[];
}

export class UserRegionDynamicControls {

    formGroup: string;
    users: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private userregion?: IUserRegion, additionalParameters?: IUserRegionDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'UserRegion';
        this.users = additionalParameters && additionalParameters.users || undefined;

        this.Form = {
            CountryCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country Code',
                name: 'CountryCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(2) ],
                validators: { 'required': true, 'maxlength': 2 },
                value: this.userregion && this.userregion.hasOwnProperty('CountryCode') && this.userregion.CountryCode !== null ? this.userregion.CountryCode.toString() : '',
            }),
            StateCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'State Code',
                name: 'StateCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(3) ],
                validators: { 'maxlength': 3 },
                value: this.userregion && this.userregion.hasOwnProperty('StateCode') && this.userregion.StateCode !== null ? this.userregion.StateCode.toString() : '',
            }),
            UserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'User',
                name: 'UserId',
                options: this.users,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.userregion && this.userregion.UserId || null,
            }),
        };

        this.View = {
            CountryCode: new DynamicLabel(
                'Country Code',
                this.userregion && this.userregion.hasOwnProperty('CountryCode') && this.userregion.CountryCode !== null ? this.userregion.CountryCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            StateCode: new DynamicLabel(
                'State Code',
                this.userregion && this.userregion.hasOwnProperty('StateCode') && this.userregion.StateCode !== null ? this.userregion.StateCode.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            UserId: new DynamicLabel(
                'User',
                getMetaItemValue(this.users, this.userregion && this.userregion.hasOwnProperty('UserId') && this.userregion.UserId !== null ? this.userregion.UserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
