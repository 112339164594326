import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule } from '@mt-ng2/environment-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule } from '@mt-ng2/login-module';
import { LoginModuleConfigToken } from '@mt-ng2/login-module-config';

import { AppRoutingModule } from './app-routing.module';
import { AppNavModule } from './nav/app-nav.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';
import { LoginConfigOverride } from '@common/configs/login.config';

import { ToastrModule } from 'ngx-toastr';

import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserModule } from './users/user.module';
import { UserRoleModule } from './user-roles/user-roles.module';
import { ClinicModule } from './clinics/clinic.module';
import { SharedModule } from '@common/shared.module';
import { ApplicationModule } from './applications/application.module';
import { setGlobalInjector } from '@common/libraries/global-injector.library';
import { NotificationModule } from './donor-notifications/notification.module';
import { ManagedListModule } from './managed-lists/managed-list.module';
import { ReportsModule } from './reports/reports.module';
import { AuditLogEagerLoadedRoutingModule } from '@mt-ng2/audit-logging-module';
import { AuditLoggingModuleUserServiceToken, AuditLoggingModuleConfigToken } from '@mt-ng2/audit-logging-module-config';
import { AuditLogUserService } from '@common/services/audit-log-user.service';
import { auditLogModuleConfig } from '@common/configs/audit-log.config';
import { SalesOrderReservationModule } from './sales-order-reservations/sales-order-reservation.module';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, NotFoundComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule.forRoot(),
        SharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        EnvironmentModule.forRoot(environment),
        NotificationsModule,
        AuthModule.forRoot(),
        ClinicModule,
        ReportsModule,
        ApplicationModule,
        UserModule,
        NotificationModule,
        SalesOrderReservationModule,
        ManagedListModule,
        UserRoleModule,
        AuditLogEagerLoadedRoutingModule,
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: AuditLoggingModuleUserServiceToken, useClass: AuditLogUserService },
        { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
    ],
})
export class AppModule {
    constructor(injector: Injector) {
        setGlobalInjector(injector);
    }
}
