import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ILabelGenerator } from '@model/interfaces/custom/ILabelGenerator';
import { IEntity } from '@mt-ng2/base-service';

@Component({
    selector: 'app-mini-dashboard-list',
    styles: [`
        .alternateColorItem:hover {
            background-color: #f5f5f5;
        }
        .alternateColorItem:nth-child(even) {
            background-color: #ececec;
        }
        .alternateColorItem:first-child {
            border-top: 1px solid #ddd;
        }
        .alternateColorItem:last-child {
            border-bottom: 1px solid #ddd;
        }
    `],
    templateUrl: './mini-dashboard-list.component.html',
})
export class MiniDashboardListComponent implements OnInit {
    @Input()
    items: IEntity[];
    @Input()
    totalItems: number;
    @Input()
    canEdit: boolean;
    @Input()
    noOfItemsToShow: number;
    @Input()
    cardName: string;
    @Input()
    labelGenerator: ILabelGenerator;
    @Input()
    forceShowAll: boolean;
    @Input()
    hideShowAll: boolean;
    @Input()
    showTotalCount: boolean;
    @Input()
    alternateColor: boolean;
    @Input()
    canDelete: boolean;

    @Output('onSeeAll') onSeeAll: EventEmitter<any> = new EventEmitter<any>();
    @Output('onAddItem') onAddItem: EventEmitter<any> = new EventEmitter<any>();

    @Output('onSelectItem') onSelectItem: EventEmitter<IEntity> = new EventEmitter<IEntity>();
    @Output('onDeleteItem') onDeleteItem: EventEmitter<IEntity> = new EventEmitter<IEntity>();

    constructor(
    ) { }

    ngOnInit(): void {
    }

    showTotal(): boolean {
        return (this.totalItems && this.totalItems > this.noOfItemsToShow) || this.forceShowAll;
    }

    seeAll(): void {
        this.onSeeAll.emit();
    }

    addItem(): void {
        this.onAddItem.emit();
    }

    getItemName(item: IEntity): string {
        if (this.labelGenerator) {
            return this.labelGenerator.GetLabel(item);
        }
        return '';
    }

    selectItem(item: IEntity): void {
        this.onSelectItem.emit(item);
    }

    deleteItem(item: IEntity): void {
        this.onDeleteItem.emit(item);
    }
}
