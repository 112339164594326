import { DynamicConfig, IDynamicConfig, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { IPreScreening } from '@model/interfaces/pre-screening';
import { IPreScreeningDynamicControlsParameters } from '@model/form-controls/pre-screening.form-controls';
import { ApplicationPreScreeningDynamicControlsPartial } from '@model/partials/application-pre-screening-partial.form-controls';

export class ApplicationPreScreeningDynamicConfig<T extends IPreScreening> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private preScreening: IPreScreening, private configControls?: string[], private additionalParameters?: IPreScreeningDynamicControlsParameters) {
        super();
        const dynamicControls = new ApplicationPreScreeningDynamicControlsPartial(this.preScreening, this.additionalParameters);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'BirthYear',
                'Email',
                'ConfirmEmail',
                'Height',
                'HeightUnitId',
                'Weight',
                'WeightUnitId',
                'GenderId',
                'EducationCompletedTypeId',
                'IsUsCitizenOrGreenCardHolder',
                'CountryCode',
                'StateCode',
                'HasStd',
                'HasHiv',
                'HasFamilyMedicalHistory',
                'IsAdopted',
                'HasBothOvaries',
                'HasGivenBirth',
                'HowManyLiveBirths',
                'IsPregnantOrBreastFeeding',
                'HasPreviouslyDonatedEggs',
                'HasRegularPeriodsWithoutBirthControl',
                'ReferralTypeId',
            ];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
