import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, EntityListPrintConfig, EntityListExportConfig } from '@mt-ng2/entity-list-module';

import { SalesOrderReservationService } from '../services/sales-order-reservation.service';
import { ISalesOrderReservation } from '@model/interfaces/sales-order-reservation';
import { entityListModuleConfig } from '@common/shared.module';
import { ClaimTypes } from '@model/ClaimTypes';
import { SalesOrderReservationsEntityListConfig } from './sales-order-reservations.entity-list-config';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IReservationConversionDto } from '@model/interfaces/custom/reservation-conversion-dto';
import { INewRecipientCycleDto } from '@model/interfaces/custom/new-recipient-cycle-dto';
import { ProductTypeService } from '../../managed-lists/services/product-type.service';
import { RecipientCycleAddDynamicConfig } from '../../recipients/recipient-cycles/recipient-cycle-add/recipient-cycle-add.dynamic-config';
import { RecipientCycleService } from '../../recipients/recipient-cycles/services/recipient-cycle.service';
import { IProductType } from '@model/interfaces/product-type';
import { ReservationDispositionFilterService } from '../../recipients/services/reservation-disposition-filter.service';
import { IReservationDispositionFilter } from '@model/interfaces/reservation-disposition-filter';
import { MtSearchFilterItem, MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { FormGroup } from '@angular/forms';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { map } from 'rxjs/operators';
import { DateParts } from '@mt-ng2/date-module';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';

@Component({
    selector: 'app-sales-order-reservations',
    templateUrl: './sales-order-reservations.component.html',
})
export class SalesOrderReservationsComponent implements OnInit {
    salesOrderReservations: ISalesOrderReservation[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    entityListConfig = new SalesOrderReservationsEntityListConfig();
    order = 'CreatedDate';
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    // Created Date filter fields
    createdStartDate: Date = null;
    createdEndDate: Date = null;
    createdMaxDate: Date = new Date();
    // Expiration Date filter fields
    expStartDate: Date = null;
    expEndDate: Date = null;
    expMaxDate: Date = new Date().mtDate.add(2, DateParts.years).date;
    reservationDispositions = '1,3';    // Include uncancelled reservations and reservations without matching sales orders by default
    canAddSalesOrderReservation = false;
    selectedReservation: ISalesOrderReservation;
    showReservationActions: boolean;
    modalOptions: IModalOptions = {
        showCloseButton: true,
        showConfirmButton: false,
        titleText: 'Reservation',
        width: '45%',
    };
    get totalSelectedEggs(): number {
        return this.selectedReservation.SalesOrderReservationDonors.map((r) => r.EggCountReserved).reduce((c, p) => c + p);
    }

    showProductTypeSelection: boolean;
    productTypeModalOptions: IModalOptions = {
        showCloseButton: false,
        showConfirmButton: false,
        titleText: 'Select Product Type',
    };
    productTypes: IProductType[];
    reservationDispositionFilters: MtSearchFilterItem[] = [];
    recipientCycle: INewRecipientCycleDto;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: RecipientCycleAddDynamicConfig<INewRecipientCycleDto>;
    doubleClickIsDisabled = false;

    showUpdateReservation: boolean;
    updateReservationModalOptions: IModalOptions = {
        showCloseButton: true,
        showConfirmButton: false,
        titleText: 'Update Reservation',
    };

    constructor(
        private salesOrderReservationService: SalesOrderReservationService,
        private claimsService: ClaimsService,
        private notificationsService: NotificationsService,
        private recipientCycleService: RecipientCycleService,
        private productTypeService: ProductTypeService,
        private reservationDispositionFilterService: ReservationDispositionFilterService,
        private router: Router,
    ) {}

    ngOnInit(): void {

        this.reservationDispositionFilterService.getSearchFilterItems()
            .subscribe((answer) =>
            (
                    this.reservationDispositionFilters = answer,
                    this.reservationDispositionFilters[0].Selected = true,  // Include uncancelled reservations by default
                    this.reservationDispositionFilters[2].Selected = true,  // Include reservations without matching sales orders by default
                    this.reservationDispositions = '0,2'
            ));
        this.canAddSalesOrderReservation = this.claimsService.hasClaim(ClaimTypes.Reservations, [ClaimValues.FullAccess]);
        this.getSalesOrderReservations();
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Reservations Report',
            getDataForExport: this.getReservationsForExport.bind(this),
        });
    }

    getSalesOrderReservationsCall(options = { forExport: false }): Observable<HttpResponse<ISalesOrderReservation[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CreatedDateStart',
                value: this.createdStartDate ? this.getDateValueForParams(this.createdStartDate) : '',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CreatedDateEnd',
                value: this.createdEndDate ? this.getDateValueForParams(this.createdEndDate) : '',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ExpDateStart',
                value: this.expStartDate ? this.getDateValueForParams(this.expStartDate) : '',
            }),
        );
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'ExpDateEnd',
                value: this.expEndDate ? this.getDateValueForParams(this.expEndDate) : '',
            }),
        );
        // _extraSearchParams.push(
        //     new ExtraSearchParams({
        //         name: 'ReservationDistributionFilters',
        //         value: this.reservationDispositions ? this.reservationDispositions : '',
        //     }),
        // );
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: options.forExport ? 0 : (this.currentPage - 1) * this.itemsPerPage,
            take: options.forExport ? this.total : this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.salesOrderReservationService.get(searchparams);
    }

    getSalesOrderReservations(): void {
        this.getSalesOrderReservationsCall().subscribe((answer) => {
            this.salesOrderReservations = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getSalesOrderReservations();
    }

    createdDateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.currentPage = 1;
        this.createdStartDate = range.startDate;
        this.createdEndDate = range.endDate;
        this.getSalesOrderReservations();
    }

    expirationDateSelectionChanged(range: ISearchFilterDaterangeValue): void {
        this.currentPage = 1;
        this.expStartDate = range.startDate;
        this.expEndDate = range.endDate;
        this.getSalesOrderReservations();
    }

    getDateValueForParams(date: Date): string {
        return date.mtDate.format('MM-DD-yyyy');
    }

    filterSelectionChanged(items): void {
        this.reservationDispositions = items.selectedItems.map((x) => x.Id).join(',');
        this.currentPage = 1;
        this.getSalesOrderReservations();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getSalesOrderReservations();
    }

    salesOrderReservationSelected(event: IItemSelectedEvent): void {
        if (!this.selectedReservation || this.selectedReservation.Id !== event.entity.Id) {
            this.selectedReservation = event.entity;
        }
        // set form to select product type
        if (!this.productTypes) {
            this.productTypeService.getItems().subscribe((productTypes) => {
                this.productTypes = productTypes;
                this.initRecipientCycleForm();
                this.showReservationActions = true;
            });
        } else {
            this.initRecipientCycleForm();
            this.setConfig();
            this.showReservationActions = true;
        }
    }

    initRecipientCycleForm(): void {
        this.recipientCycle = this.recipientCycleService.getEmptyRecipientCycle();
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new RecipientCycleAddDynamicConfig<INewRecipientCycleDto>(this.recipientCycle, this.productTypes, null, ['ProductTypeId']);
        this.config = this.formFactory.getForCreate();
    }

    createSalesOrder(productTypeForm: FormGroup): void {
        if (productTypeForm.valid) {
            (this.selectedReservation as IReservationConversionDto).ProductTypeId = productTypeForm.value.RecipientCycle.ProductTypeId;
            this.salesOrderReservationService
                .convertReservationToSalesOrder(this.selectedReservation as IReservationConversionDto)
                .subscribe((soId) => {
                    this.notificationsService.success('Sales order successfully created.');
                    this.router.navigate([`/recipients/${this.selectedReservation.RecipientId}/salesorder/${soId}`]);
                });
        } else {
            this.saveFail(productTypeForm);
        }
    }

    cancelReservation(): void {
        let canceledReservation = Object.assign({}, this.selectedReservation);
        canceledReservation.Canceled = true;
        canceledReservation.Recipient = null;
        this.salesOrderReservationService.updateWithFks(canceledReservation).subscribe((response) => {
            this.showReservationActions = false;
            this.selectedReservation = response;
            this.getSalesOrderReservations();
            this.notificationsService.success('Reservation successfully canceled.');
        });
    }

    saveFail(form: FormGroup): void {
        markAllFormFieldsAsTouched(form);
        this.notificationsService.error('Save failed.  Please check the form and try again.');
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    onEditComplete(reservation: ISalesOrderReservation): void {
        if (reservation) {
            // if edit not cancel
            // refresh list with last search
            this.search(this.query);
            this.selectedReservation = reservation;
        }
        this.showUpdateReservation = false;
        this.showReservationActions = true;
    }

    getReservationsForExport(): Observable<ISalesOrderReservation[]> {
        return this.getSalesOrderReservationsCall({ forExport: true }).pipe(map((answer) => answer.body));
    }

    onPageSizeChange(event: number): void {
        this.itemsPerPage = event;
        this.getSalesOrderReservations();
    }
}
